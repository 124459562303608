import { Dispatch, SetStateAction } from 'react';

import { Flex, IconButton, Text } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';

import {
  FilterOptionsEnum,
  FilterOptionsType,
  FiltersAction,
  FiltersActionKind,
  handleClearFilter,
} from '../../utils';

interface ClearIconProps {
  title: FilterOptionsType;
  selectedFilters: FilterOptionsEnum[];
  setSelectedFilters: Dispatch<SetStateAction<FilterOptionsEnum[]>>;
  dispatch: Dispatch<FiltersAction>;
  actionKind: FiltersActionKind[];
}

const ClearFilterIcon = ({
  title,
  selectedFilters,
  setSelectedFilters,
  dispatch,
  actionKind,
}: ClearIconProps) => (
  <Flex align="center" justify="space-between" py={0}>
    <Text fontSize="sm" color="white" whiteSpace="nowrap">
      {title.label}
    </Text>
    <IconButton
      aria-label={`Clear ${title.label}`}
      size="sm"
      icon={<MdClose />}
      variant="icon"
      color="red"
      onClick={() =>
        handleClearFilter(title, actionKind, dispatch, selectedFilters, setSelectedFilters)
      }
    />
  </Flex>
);

export default ClearFilterIcon;
