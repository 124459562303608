import { Td, Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Deal } from '../../gql/dealGql';

import { handleClickOrCommandClick } from '../../libs/eventHandlers';
import { completedDealColumns } from '../../pages/CompletedDealsPage/constants';

type Props = {
  deal: Deal;
};

const CompletedDealsRow = ({ deal }: Props) => {
  const history = useHistory();

  return (
    <Tr
      height="55px"
      borderBottom="2px"
      borderX="1px"
      borderColor="backgroundGray"
      cursor="pointer"
      _hover={{ bgColor: 'hoverGray' }}
      key={`deal-${deal.id}`}
      onClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
      onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
      bgColor="white"
    >
      {completedDealColumns.map(({ header, getValue, maxW, minW }) => (
        <Td key={header} textAlign="center" whiteSpace="nowrap" maxW={maxW} minW={minW}>
          {getValue(deal)}
        </Td>
      ))}
    </Tr>
  );
};

export default CompletedDealsRow;
