import { Button, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { User, useSoftDeleteUserMutation } from '../../../gql/generated/graphql';

import Modal, { LEModalProps } from '../../../components/shared/Modal';

type DeleteUserModalProps = LEModalProps & {
  user: User | null;
};

const DeleteUserModal = ({ isOpen, onClose, user }: DeleteUserModalProps) => {
  const [softDeleteUser, { loading: softDeleteLoading }] = useSoftDeleteUserMutation();

  const handleDelete = async () => {
    try {
      await softDeleteUser({
        variables: { id: user?.id ?? '' },
      });

      toast.success('User deleted successfully');
    } catch (error) {
      toast.error('Failed to delete user.');
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      title="Delete User Confirmation"
      isOpen={isOpen}
      onClose={onClose}
      leftButtons={
        <Button variant="warning" onClick={onClose}>
          CANCEL
        </Button>
      }
      rightButtons={
        <Button
          variant="boot"
          onClick={async () => handleDelete()}
          loadingText="Delete"
          isLoading={softDeleteLoading}
        >
          Delete
        </Button>
      }
    >
      <Text align="center">Are you sure you want to delete {user?.name}?</Text>
    </Modal>
  );
};

export default DeleteUserModal;
