import { useMemo } from 'react';

import { Deal } from '../gql/dealGql';
import { TitleRegistrationOptionLabels } from '../gql/financialInfoGql';
import { TitleRegistrationOption, TtJurisdiction } from '../gql/generated/graphql';

import { Option } from '../components/shared/types';
import { states } from '../libs/states';

type JurisdictionTitleRegistrationFields = Pick<
  TtJurisdiction,
  'hasTitleOnly' | 'hasTitleAndRegistration' | 'allowRegistrationTransfer'
>;

const TitleRegistrationOptionJurisdictionFieldMap: Record<
  TitleRegistrationOption,
  keyof JurisdictionTitleRegistrationFields
> = {
  [TitleRegistrationOption.TitleOnly]: 'hasTitleOnly',
  [TitleRegistrationOption.TitleAndNewRegistration]: 'hasTitleAndRegistration',
  [TitleRegistrationOption.TitleAndRegistrationTransfer]: 'allowRegistrationTransfer',
} as const;

const statesRequiringBuyerAsLesseeForRegTransfer = [states.ILLINOIS, states.TEXAS];

/**
 * Returns an array of Title Registration options with enabled/disabled status based on the respective Jurisdiction fields.
 */
const useTitleRegistrationOptions = (
  jurisdiction: JurisdictionTitleRegistrationFields,
  deal: Deal,
): Option[] => {
  const { customer, financial_info } = deal;
  // For some reason, the condition can't be added to TT so we need to do it here
  const enableRegTransfer =
    !statesRequiringBuyerAsLesseeForRegTransfer.includes(customer?.address?.state ?? '') ||
    !financial_info?.buyer_not_lessee;

  // TODO: this executes too often. Tried some things but couldn't get it to work
  const titleRegistrationOptions = useMemo(
    () =>
      Object.entries(TitleRegistrationOptionLabels).map(([value, label]) => ({
        value,
        label,
        enabled: Boolean(
          jurisdiction[
            TitleRegistrationOptionJurisdictionFieldMap[value as TitleRegistrationOption]
          ] &&
            (value !== TitleRegistrationOption.TitleAndRegistrationTransfer || enableRegTransfer),
        ),
      })),
    [
      jurisdiction.hasTitleOnly,
      jurisdiction.hasTitleAndRegistration,
      jurisdiction.allowRegistrationTransfer,
      enableRegTransfer,
    ],
  );

  return titleRegistrationOptions;
};

export default useTitleRegistrationOptions;
