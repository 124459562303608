import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';

import { Box, Checkbox, Flex, HStack, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';

import { DealMediaTypeEnum } from '../../../../../gql/generated/graphql';

import DatePicker from '../../../../shared/DatePicker';
import { dateDoesNotMatchMessage } from '../validations';

import FormError from './FormError';

import { MediaModalType } from '..';
import {
  DealMedia,
  RegistrationMetadata,
  RegistrationMetadataValueFields,
  RegistrationVerifiedFieldMap,
} from '../../../../../types/media';

type ExpirationDateProps = {
  fieldName: RegistrationMetadataValueFields;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  requiredFields?: Map<RegistrationMetadataValueFields, boolean>;
  metadata?: RegistrationMetadata;
  errors?: RegistrationMetadata;
  applicationValues: Partial<Record<RegistrationMetadataValueFields, string>>;
};

const ExpirationDate = ({
  fieldName,
  onChange,
  requiredFields,
  metadata,
  errors,
  applicationValues,
}: ExpirationDateProps) => {
  const fieldPath = `metadata.${fieldName}`;
  const verifiedFieldName = RegistrationVerifiedFieldMap.get(fieldName);
  if (!verifiedFieldName) {
    return null;
  }
  const verifiedFieldPath = `metadata.${verifiedFieldName}`;

  return (
    <VStack hidden={!requiredFields?.has(fieldName)}>
      <HStack w="100%">
        <DatePicker
          leftLabel="Exp. Date:"
          placeholder="MM YYYY"
          name={fieldPath}
          dateFormat="MMMM yyyy"
          valueFormat="dateTimeUTC"
          showMonthYearPicker
          boxStyles={{ width: '100%' }}
          showErrorMessage={false}
          additionalHandleChange={onChange}
          formControlVariant="inline"
          isInvalid={
            (!!applicationValues[fieldName] && errors?.[fieldName] === dateDoesNotMatchMessage) ||
            (!!errors?.[fieldName] && errors?.[fieldName] !== dateDoesNotMatchMessage)
          }
        />
        <Checkbox
          isChecked={metadata?.[verifiedFieldName]}
          name={verifiedFieldPath}
          onChange={onChange}
          colorScheme="whatsapp"
          disabled={!!errors?.[fieldName] && errors?.[fieldName] !== dateDoesNotMatchMessage}
        />
      </HStack>
      <FormError
        documentType={DealMediaTypeEnum.Registration}
        fieldName={fieldName}
        documentValue={
          metadata?.[fieldName] ? format(new Date(metadata[fieldName]), 'MMMM yyyy') : ''
        }
        applicationValue={
          applicationValues[fieldName]
            ? format(new Date(applicationValues[fieldName]), 'MMMM yyyy')
            : ''
        }
        showError={!!metadata?.[fieldName] && !!errors?.[fieldName]}
        canUpdateApplication={errors?.[fieldName] === dateDoesNotMatchMessage}
      />
    </VStack>
  );
};

interface RegistrationFormProps {
  setIsVerifiable: (verifiable: boolean) => void;
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  isLoading: boolean;
  applicationValues: Partial<Record<RegistrationMetadataValueFields, string>>;
  requiredFields?: Map<RegistrationMetadataValueFields, boolean>;
}

const RegistrationForm = ({
  setIsVerifiable,
  showModal,
  setShowModal,
  isLoading,
  applicationValues,
  requiredFields,
}: RegistrationFormProps) => {
  const {
    values,
    errors: formikErrors,
    handleChange,
    setFieldValue,
    validateForm,
  } = useFormikContext<DealMedia>();
  const { metadata } = values as { metadata?: RegistrationMetadata };
  const { metadata: errors } = formikErrors as { metadata?: RegistrationMetadata };

  useEffect(() => {
    // Validates form on first render.
    // This is needed to avoid enabled verification checkboxes when form is invalid.
    if (!isLoading) {
      validateForm();
    }
  }, [isLoading, requiredFields]);

  useEffect(() => {
    if (metadata && showModal.dealMedia?.type === DealMediaTypeEnum.Registration) {
      setTimeout(
        () =>
          setIsVerifiable(
            (!requiredFields?.has('expirationDate') || !!metadata.expirationDateVerified) &&
              (!requiredFields?.has('expirationDate60Days') ||
                !!metadata.expirationDate60DaysVerified) &&
              (!requiredFields?.has('expirationDate90Days') ||
                !!metadata.expirationDate90DaysVerified),
          ),
        0,
      );
    }

    setTimeout(
      () =>
        setShowModal({
          ...showModal,
          dealMedia: {
            ...showModal.dealMedia,
            verified: !!values.verified,
            metadata,
          },
        }),
      0,
    );
  }, [metadata, values.verified, requiredFields]);

  // Uncheck verified field when a value field is changed.
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const fieldPath = e.target?.name ?? '';
    const fieldName = fieldPath.replace('metadata.', '') as RegistrationMetadataValueFields;
    const verifiedFieldName = RegistrationVerifiedFieldMap.get(fieldName);

    // Means a `value` field was changed because it can be mapped to a `verified` field.
    if (verifiedFieldName) {
      const verifiedFieldPath = `metadata.${verifiedFieldName}`;
      setFieldValue(verifiedFieldPath, false);
      setFieldValue('verified', false);
    }
    handleChange(e);
  };

  const commonProps: Omit<ExpirationDateProps, 'fieldName'> = {
    onChange,
    requiredFields,
    metadata,
    errors,
    applicationValues,
  };

  return (
    <Box
      flexBasis="50%"
      marginBottom={2}
      hidden={isLoading || showModal.dealMedia?.type !== DealMediaTypeEnum.Registration}
    >
      <Flex direction="column" justifyContent="center" flexBasis="50%">
        <ExpirationDate fieldName="expirationDate" {...commonProps} />
        <ExpirationDate fieldName="expirationDate60Days" {...commonProps} />
        <ExpirationDate fieldName="expirationDate90Days" {...commonProps} />
      </Flex>
    </Box>
  );
};

export default RegistrationForm;
