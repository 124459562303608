import { useContext, useMemo } from 'react';

import { Button, Textarea, useDisclosure } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { getFullName } from '../../gql/customerGql';
import { BootReasonsEnum, DealStateEnum } from '../../gql/dealGql';
import { useDealUpdateRequestBootMutation } from '../../gql/generated/graphql';

import DynamicSizedButton from '../shared/DynamicSizedButton';
import Modal from '../shared/Modal';
import Select from '../shared/Select';

import SELECT_OPTIONS from '../../constants/selectOptions';
import { useUser } from '../../hooks/useUser';
import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

const MAX_COMMENT_LENGTH = 150;
const validationSchema = Yup.object().shape({
  reason: Yup.string()
    .required('Reason is required')
    .oneOf(Object.values(BootReasonsEnum), 'Invalid Reason'),
  comment: Yup.string().max(MAX_COMMENT_LENGTH).required('Comment is required'),
});

const RequestBoot = () => {
  const { deal, dealRefetch } = useContext(DealContext);
  const [requestBoot] = useDealUpdateRequestBootMutation();
  const user = useUser();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const bootIsRequested = deal.request_boot;
  const bootRequestAuthorId = deal.boot_reason?.author_id;
  const buttonLabel = bootIsRequested ? 'CANCEL BOOT REQUEST' : 'REQUEST BOOT';
  const modalTitle = bootIsRequested
    ? 'Cancel Boot Request'
    : `Boot ${getFullName(deal.customer)}?`;
  const isHidden =
    deal.state === DealStateEnum.Booted || (bootIsRequested && bootRequestAuthorId !== user.id);

  const initialValues = useMemo(
    () => ({
      reason: deal?.boot_reason?.reason || ('' as BootReasonsEnum),
      comment: deal?.boot_reason?.comment || '',
    }),
    [deal.boot_reason?.comment, deal.boot_reason?.reason],
  );

  const handleSubmit = async (values: { reason: BootReasonsEnum; comment: string }) => {
    const requestBootStatus = deal.request_boot;

    try {
      if (!deal.id) {
        throw new Error('No deal id');
      }

      await requestBoot({
        variables: {
          id: deal.id,
          request_boot: !requestBootStatus,
          boot_reason: {
            reason: values.reason,
            comment: values.comment,
          },
          // This button only shows up when the user has `RequestBoot` permission.
          boot_deal: false,
        },
      });

      dealRefetch();
      toast.success(requestBootStatus ? 'Boot request canceled' : 'Deal boot requested');
      onClose();
    } catch (e) {
      logger.error('RequestBoot.tsx', '', null, e);
      toast.error(requestBootStatus ? 'Failed to cancel boot request.' : 'Failed to request boot.');
    }
  };

  return (
    <>
      <DynamicSizedButton
        ariaLabel="request-boot"
        variant="boot"
        size={{ base: 'md', sm: 'lgWithIconLeft' }}
        icon={<BiTrash size="20px" />}
        label={buttonLabel}
        onClick={onOpen}
        hidden={isHidden}
        isRound
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, isValid, handleChange }) => (
          <Modal
            title={modalTitle}
            isOpen={isOpen}
            onClose={onClose}
            showDivider={false}
            hasForm
            rightButtons={
              <>
                <Button variant="secondary" onClick={onClose}>
                  CLOSE
                </Button>
                <Button type="submit" variant="boot" isDisabled={!isValid}>
                  {buttonLabel}
                </Button>
              </>
            }
          >
            {bootIsRequested ? (
              'Are you sure you want to cancel the boot request?'
            ) : (
              <Form>
                <Select
                  label="Reason"
                  placeholder=""
                  id="reason"
                  name="reason"
                  options={SELECT_OPTIONS.BOOT_REASONS}
                  value={values.reason}
                  isRequired
                />
                <Textarea
                  placeholder="Comment"
                  borderRadius={3}
                  name="comment"
                  mt={4}
                  onChange={handleChange}
                  rows={2}
                  value={values.comment}
                  maxLength={MAX_COMMENT_LENGTH}
                  isRequired
                />
              </Form>
            )}
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default RequestBoot;
