type PaymentOptionName = 'vscPlusGap' | 'vsc' | 'gap' | 'noProducts';

export interface paymentOptionsTypes {
  name: PaymentOptionName;
  amountFinanced: number;
  buyPayment: number;
  sellPayment: number;
  profit: number;
  setterCommission: number;
  closerCommission: number;
  reserve: number;
}

export interface paymentOptions {
  vscPlusGap: paymentOptionsTypes;
  vsc: paymentOptionsTypes;
  gap: paymentOptionsTypes;
  noProducts: paymentOptionsTypes;
}

const initialProperties = {
  amountFinanced: 0,
  buyPayment: 0,
  sellPayment: 0,
  profit: 0,
  setterCommission: 0,
  closerCommission: 0,
  reserve: 0,
};

export const initialPaymentOptions: paymentOptions = {
  vscPlusGap: { ...initialProperties, name: 'vscPlusGap' },
  vsc: { ...initialProperties, name: 'vsc' },
  gap: { ...initialProperties, name: 'gap' },
  noProducts: { ...initialProperties, name: 'noProducts' },
};

// Must match enum on api side in salesAlgoInterface.ts
export enum VscPackageTypeEnum {
  Major = 'MAJOR',
  Value = 'VALUE',
  Basic = 'BASIC',
}
