import LETable from '../../shared/Table/LETable';

import { TEMP_TAG_COLUMNS } from './constants';

const TempTagsQueue = () => {
  return (
    <LETable columns={TEMP_TAG_COLUMNS} items={[]} rowKeyPrefix="temp_tags" rowKeyProp="test" />
  );
};

export default TempTagsQueue;
