import { HStack, Spinner, Text, TextProps } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';
import { MdCompareArrows } from 'react-icons/md';

import { CreditDecisionFromQuery } from './CreditDecisionsModal';

import { CreditDecisionStatusLabelEnum } from '../../constants/creditDecisions';
import {
  isCreditDecisionApproved,
  isCreditDecisionCountered,
  isCreditDecisionDenied,
  isCreditDecisionPending,
} from '../../utils/creditDecisions';

const getCreditDecisionStatusColor = ({
  isApproved,
  isPending,
  isDenied,
}: {
  isApproved: boolean;
  isPending: boolean;
  isDenied: boolean;
}): TextProps['color'] => {
  if (isApproved) {
    return 'green';
  }

  if (isPending) {
    return 'yellow.500';
  }

  if (isDenied) {
    return 'errorsRed';
  }

  return 'black';
};

const getCreditDecisionIcon = ({
  isApproved,
  isCountered,
  isPending,
  isDenied,
}: {
  isApproved: boolean;
  isCountered: boolean;
  isPending: boolean;
  isDenied: boolean;
}) => {
  if (isApproved) {
    return <FaCheck size={14} />;
  }
  if (isCountered) {
    return <MdCompareArrows size={16} />;
  }
  if (isPending) {
    return <Spinner size="xs" color="gray.800" />;
  }
  if (isDenied) {
    return <AiOutlineClose size={14} />;
  }

  return null;
};

type CreditDecisionStatusTextProps = {
  creditDecision: CreditDecisionFromQuery;
};

const CreditDecisionStatusText = ({ creditDecision }: CreditDecisionStatusTextProps) => {
  const isApproved = isCreditDecisionApproved(creditDecision);
  const isCountered = isCreditDecisionCountered(creditDecision);
  const isPending = isCreditDecisionPending(creditDecision?.application_status);
  const isDenied = isCreditDecisionDenied(creditDecision?.application_status);

  return (
    <HStack
      border="1px"
      borderColor="gray.500"
      borderRadius="md"
      py={0.5}
      px={2}
      alignItems="center"
      spacing={1}
    >
      {creditDecision?.application_status ? (
        <>
          <Text color={getCreditDecisionStatusColor({ isApproved, isPending, isDenied })}>
            {getCreditDecisionIcon({ isApproved, isCountered, isPending, isDenied })}
          </Text>
          <Text fontWeight="normal" textTransform="uppercase">
            {CreditDecisionStatusLabelEnum[creditDecision.application_status]}
          </Text>
        </>
      ) : (
        <Text py={2} fontSize="xl" fontWeight="semibold">
          UNKNOWN
        </Text>
      )}
    </HStack>
  );
};

export default CreditDecisionStatusText;
