import { Component, ReactNode } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { logger } from '../../libs/Logger';

export default class ErrorBoundary extends Component<
  { children: ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: ReactNode } | Readonly<{ children: ReactNode }>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    logger.error('ErrorBoundary.tsx', '', errorInfo, error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <Box my="300px">
        <Flex flexDirection="column" align="center">
          <Text mt={2} fontSize={16}>
            Sorry there was a problem loading this page
          </Text>
        </Flex>
      </Box>
    ) : (
      children
    );
  }
}
