import Big from 'big.js';
import * as Yup from 'yup';

import { DealStateEnum } from '../../gql/dealGql';
import { OptionTypeEnum, ProcessorEnum, isGapSelected } from '../../gql/financialInfoGql';

import {
  getTitleReceivedDateValidation,
  validateTodayOrEarlier,
} from '../../libs/yup-validators/dates';
import { isNullOrUndefined } from '../../utils';

export const QUICK_NOTES_MAX_LENGTH = 100;

const testCantBeFutureValidation = Yup.date()
  .nullable()
  .test('testCantBeFuture', `Can't be in the future`, validateTodayOrEarlier);

export const validationSchema = Yup.object().shape({
  car: Yup.object().shape({
    payoff: Yup.object().shape({
      vehicle_payoff: Yup.number()
        .moreThan(0, 'Payoff must be greater than 0')
        .required('Payoff is required')
        .nullable(),
    }),
  }),
  customer: Yup.object().shape({
    proof_of_insurance: Yup.object()
      .nullable()
      .shape({
        company_name: Yup.string()
          .nullable()
          .test((company_name, { options: { context } }) => {
            const state = context?.deal?.state;

            const companyIsRequired = state === DealStateEnum.Closing;
            if (companyIsRequired) {
              return !!company_name;
            }
            return true;
          }),
        policy_number: Yup.string()
          .nullable()
          .test((policy_number, { options: { context } }) => {
            const state = context?.deal?.state;

            const numberIsRequired = state === DealStateEnum.Closing;
            if (numberIsRequired) {
              return !!policy_number;
            }
            return true;
          }),
        expires: Yup.date()
          .nullable()
          .test((expires, { options: { context } }) => {
            const state = context?.deal?.state;

            const dateIsRequired = state === DealStateEnum.Closing;
            if (dateIsRequired) {
              return !!expires;
            }
            return true;
          }),
      }),
  }),
  financial_info: Yup.object().shape({
    money_down: Yup.number().required('Money Down is required').nullable(),
    bank_fees: Yup.number().required('Bank Fees is required').nullable(),
    base_tax_amount: Yup.number().optional().nullable(),
    warranty_tax_amount: Yup.number().optional().nullable(),
    title_fee: Yup.number()
      .moreThan(0, 'Title must be greater than 0')
      .required('Title is required')
      .nullable(),
    new_registration_fee: Yup.number().nullable().required('Registration is required'),
    sell_rate: Yup.number()
      .moreThan(0, 'Sell Rate must be greater than 0')
      .required('Sell Rate is required')
      .nullable(),
    term: Yup.number()
      .moreThan(0, 'Term must be greater than 0')
      .required('Term is required')
      .nullable(),
    vsc_price: Yup.number().nullable().required('VSC Price is required'),
    vsc_cost: Yup.number().nullable().required('VSC Cost is required'),
    gap_price: Yup.number().when('option_type', {
      is: (option_type?: OptionTypeEnum) => isGapSelected(option_type),
      then: Yup.number().nullable().required('GAP Price is required'),
      otherwise: Yup.number().nullable(),
    }),
    gap_cost: Yup.number().when('option_type', {
      is: (option_type?: OptionTypeEnum) => isGapSelected(option_type),
      then: Yup.number().nullable().required('GAP Cost is required'),
      otherwise: Yup.number().nullable(),
    }),
    buy_rate: Yup.number()
      .moreThan(0, 'Buy Rate must be greater than 0')
      .required('Buy Rate is required')
      .test('maxMarkup', (buy_rate, { path, parent, createError }) => {
        const { sell_rate, maxMarkup } = parent;

        if (isNullOrUndefined(maxMarkup)) {
          return true;
        }

        const currentMarkup = new Big(sell_rate ?? 0).minus(buy_rate ?? 0).toNumber();
        if (currentMarkup <= maxMarkup) {
          return true;
        }

        return createError({
          message:
            maxMarkup === 0
              ? 'Markup is not allowed'
              : `Markup can't be greater than ${maxMarkup}%`,
          path,
        });
      })
      .nullable(),
    processor: Yup.string()
      .nullable()
      .test('processor test', 'Processor is required', (value, ctx) => {
        if (ctx.options.context?.state === DealStateEnum.Finalized) {
          return !!value && Object.values(ProcessorEnum).includes(value as ProcessorEnum);
        }
        return true;
      }),
    tt_transaction_id: Yup.string().optional().nullable(),
    user_entered_reserve: Yup.number().optional().nullable(),
    quick_notes: Yup.string()
      .optional()
      .nullable()
      .max(QUICK_NOTES_MAX_LENGTH, `Please limit to ${QUICK_NOTES_MAX_LENGTH} characters`),

    // Switches section.
    plate_transfer: Yup.boolean().optional().nullable(),
    buyer_not_lessee: Yup.boolean().optional().nullable(),
    needs_temporary_registration_tags: Yup.boolean().optional().nullable(),

    // Frontend only fields.
    newBaseTaxAmount: Yup.number().optional().nullable(),
    totalTaxAmount: Yup.number().optional().nullable(),
    feesQueryFailed: Yup.boolean().optional().nullable(),
    tempUserEnteredReserve: Yup.number().optional().nullable(),
  }),
  deal_dates: Yup.object().shape({
    custom_dates: Yup.object()
      .nullable(true)
      .shape({
        [DealStateEnum.Signed]: Yup.date()
          .required('Signed Date is required')
          .typeError('Invalid Date')
          .transform((value, originalValue) => (originalValue === '' ? undefined : value))
          .test('testCantBeFuture', `Can't be in the future`, validateTodayOrEarlier),
        [DealStateEnum.WaitingForTitle]: Yup.date().optional().nullable(),
        [DealStateEnum.TitleReceived]: getTitleReceivedDateValidation(),
        [DealStateEnum.SentToProcessor]: testCantBeFutureValidation,
        sent_to_audit: Yup.date().optional().nullable(),
        wet_sig_sent_date: testCantBeFutureValidation,
        wet_sig_received_date: testCantBeFutureValidation,
      }),
  }),
});
