import { DealStateEnum } from '../../gql/dealGql';
import { DaysToPaymentEnum } from '../../gql/financialInfoGql';

import { Option } from '../shared/types';

export const daysToPaymentArray = [
  DaysToPaymentEnum.Thirty,
  DaysToPaymentEnum.FortyFive,
  DaysToPaymentEnum.Sixty,
  DaysToPaymentEnum.Ninety,
];

export const daysToPaymentOptions: Option[] = daysToPaymentArray.map((days) => ({
  label: days.toString(),
  value: days.toString(),
}));

export const getTermName = (months: number, miles: number) =>
  `${months / 12} years/${miles / 1000}k`;

export const plateTransferArr = [
  { id: 1, name: 'false', label: 'No' },
  { id: 2, name: 'true', label: 'Yes' },
];

export const CARD_PAYMENT_AMOUNT_MIN = 1000;
export const CARD_PAYMENT_AMOUNT_MAX = 3000;

export const CUSTOM_DATE_FIELDS = {
  customSentToAuditField: 'deal_dates.custom_dates.sent_to_audit',
  customSentToProcessorField: `deal_dates.custom_dates.${DealStateEnum.SentToProcessor}`,
  customSignedField: `deal_dates.custom_dates.${DealStateEnum.Signed}`,
  customTitleReceivedField: `deal_dates.custom_dates.${DealStateEnum.TitleReceived}`,
  customWetSigSentField: 'deal_dates.custom_dates.wet_sig_sent_date',
  customWetSigReceivedField: 'deal_dates.custom_dates.wet_sig_received_date',
  // `WaitingForTitle` means that the payoff has been sent because the previous state is `SendPayoff`.
  customPayoffSentField: `deal_dates.custom_dates.${DealStateEnum.WaitingForTitle}`,
};

export type CustomDateFieldsKey = keyof typeof CUSTOM_DATE_FIELDS;
export const isCustomDateField = (fieldName: string) =>
  Object.values(CUSTOM_DATE_FIELDS).includes(fieldName);

// --- Custom Date Fields are ordered by appearance in the UI ---
export const customDateFieldsLabels: Partial<Record<CustomDateFieldsKey, string>> = {
  customSignedField: 'Signed Date',
  customPayoffSentField: 'Payoff Sent Date',
  customWetSigSentField: 'Wet Signature Sent Date',
  customWetSigReceivedField: 'Wet Signature Received Date',
  customTitleReceivedField: 'Title Received Date',
};

export const processorSectionCustomDateFields: Partial<Record<CustomDateFieldsKey, string>> = {
  customSentToAuditField: 'Sent to Audit Date',
  customSentToProcessorField: 'Sent to Processor Date',
};
