import { ReactNode } from 'react';

import { Auction, getAuctionNameById } from '../../gql/auctionGql';
import { getFullName } from '../../gql/customerGql';
import { Deal, DealStateLabelMap, DealTypeLabelMap } from '../../gql/dealGql';
import { Notification, getDealNotificationCountByType } from '../../gql/notificationsGql';

import TextNotificationBadgeV2 from '../../components/NotificationBadge/TextNotificationBadgeV2';

import { formatDate, formatMoney } from '../../libs/utils';

export type CompletedDealColumn = {
  header: string;
  getValue: (d: Deal, auctions?: Auction[]) => string | number | ReactNode;
  minW?: string;
  maxW?: string;
};

const baseCompletedDealColumns = [
  {
    header: '', // Notification column
    getValue: (d: Deal) => {
      const notifications = (d.notifications?.filter((n) => n != null) ?? []) as Notification[];
      const { unseenMessages } = getDealNotificationCountByType({ notifications });
      return <TextNotificationBadgeV2 count={unseenMessages} />;
    },
    minW: '20px',
    maxW: '50px',
  },
  {
    header: 'Completion Date',
    getValue: (d: Deal) => formatDate(d.completion_date_utc, d.completion_date_tz),
    minW: '135px',
  },
  {
    header: 'Finalized Date',
    getValue: (d: Deal) =>
      d.deal_dates?.dates?.finalized ? formatDate(d.deal_dates.dates.finalized) : '',
    minW: '135px',
  },
  {
    header: 'Type',
    getValue: (d: Deal) => (d.type ? DealTypeLabelMap[d.type] : ''),
    minW: '90px',
  },
  {
    header: 'Name',
    getValue: (d: Deal) => getFullName(d.customer),
    minW: '90px',
  },

  { header: 'Year', getValue: (d: Deal) => d.car.year, minW: '90px' },
  { header: 'Make', getValue: (d: Deal) => d.car.make, minW: '90px' },
  { header: 'Model', getValue: (d: Deal) => d.car.model, minW: '90px' },
  { header: 'VIN', getValue: (d: Deal) => d.car.vin, minW: '90px' },
  { header: 'State', getValue: (d: Deal) => d.customer?.address?.state, minW: '90px' },
  { header: 'Advisor', getValue: (d: Deal) => d.setter?.name, minW: '90px' },
  {
    header: 'Financial Specialist',
    getValue: (d: Deal) => d.closer?.name,
    minW: '165px',
  },
  {
    header: 'Financial Specialist 2',
    getValue: (d: Deal) => d.closer2?.name,
    minW: '170px',
  },
  { header: 'Deal State', getValue: (d: Deal) => DealStateLabelMap[d.state], minW: '95px' },
  {
    header: 'Lienholder',
    getValue: (d: Deal) => d.car.payoff?.lienholder_name,
    minW: '95px',
  },
];

export const completedDealColumns = [
  ...baseCompletedDealColumns,
  {
    header: 'Good Through Date',
    getValue: (d: Deal) => formatDate(d.car.payoff?.good_through_date, 'utc'),
    minW: '90px',
  },
  { header: 'Bank', getValue: (d: Deal) => d.financial_info?.bank, minW: '90px' },
  {
    header: 'Amount Financed',
    getValue: (d: Deal) => formatMoney(d.financial_info?.amount_financed),
    minW: '90px',
  },
];

export const completedAcquisitionDealColumns = [
  ...baseCompletedDealColumns,
  {
    header: 'Transport Cost',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.transport_cost),
    minW: '130px',
  },
  {
    header: 'Auction',
    getValue: (d: Deal, auctions?: Auction[]) =>
      d.financial_info_acquisition?.auction_id && auctions?.length
        ? getAuctionNameById(d.financial_info_acquisition?.auction_id, auctions)
        : '',
    minW: '90px',
  },
  {
    header: 'Payoff',
    getValue: (d: Deal) => formatMoney(d.car.payoff?.vehicle_payoff),
    minW: '90px',
  },
  {
    header: 'Cash To Customer',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.cash_to_customer),
    minW: '130px',
  },
  {
    header: 'Auction Fees',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.auction_fees),
    minW: '125px',
  },
  {
    header: 'Total Cost',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.total_cost),
    minW: '90px',
  },
  {
    header: 'Sell Price',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.sell_price),
    minW: '90px',
  },
  {
    header: 'Net Profit',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.gross_profit),
    minW: '90px',
  },
  {
    header: 'KBB Lending',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.kbb_lending),
    minW: '90px',
  },
  {
    header: 'MMR',
    getValue: (d: Deal) => formatMoney(d.car.mmr),
    minW: '90px',
  },
  {
    header: 'Appraised Value',
    getValue: (d: Deal) => formatMoney(d.financial_info_acquisition?.appraised_value),
    minW: '130px',
  },
  {
    header: 'Sold Date',
    getValue: (d: Deal) => (d.sold_date ? formatDate(d.sold_date) : ''),
    minW: '90px',
  },
];
