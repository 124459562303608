import { Flex, FlexProps, StyleProps } from '@chakra-ui/react';

import DatePicker from '../../shared/DatePicker';
import { DebouncedSave } from '../DealInfoBuyoutForm';
import { CUSTOM_DATE_FIELDS, CustomDateFieldsKey, customDateFieldsLabels } from '../constants';

import InputRow from './InputRow';
import { QuickNotes } from './QuickNotes';

type TitlingSectionProps = FlexProps & {
  debouncedSave: DebouncedSave;
  saveCustomDate: (fieldName: string, value: string) => Promise<void>;
  defaultFontSize?: StyleProps['fontSize'];
};

export const TitlingSection = ({
  debouncedSave,
  saveCustomDate,
  defaultFontSize = 'sm',
  ...rest
}: TitlingSectionProps) => (
  <Flex direction="column" {...rest}>
    {Object.entries(customDateFieldsLabels).map(([fieldKey, label]) => (
      <InputRow
        key={fieldKey}
        label={label}
        labelProps={{
          whiteSpace: 'normal',
        }}
      >
        <DatePicker
          name={CUSTOM_DATE_FIELDS[fieldKey as CustomDateFieldsKey]}
          boxStyles={{
            w: '100%',
          }}
          inputProps={{
            fontSize: defaultFontSize,
          }}
          additionalHandleChange={(e) =>
            saveCustomDate(e.currentTarget.name, e.currentTarget.value)
          }
        />
      </InputRow>
    ))}
    <InputRow label="Quick Notes">
      <QuickNotes debouncedSave={debouncedSave} />
    </InputRow>
  </Flex>
);
