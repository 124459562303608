import { useContext, useMemo } from 'react';

import { TableRowProps, Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Notification, getDealNotificationCountByType } from '../../gql/notificationsGql';

import { StructuringFollowUpDeals } from './StructuringFollowUpTable';
import { TABLE_COLUMNS_ENTRIES } from './columnConfig';

import { useUser } from '../../hooks/useUser';
import { AbilityContext } from '../../libs/contextLib';
import { handleClickOrCommandClick } from '../../libs/eventHandlers';
import { getIsClickable } from '../../utils/deals';

interface StructuringFollowUpTableRowProps {
  deal: NonNullable<StructuringFollowUpDeals[number]>;
}

const StructuringFollowUpTableRow = ({ deal }: StructuringFollowUpTableRowProps) => {
  const user = useUser();
  const abilities = useContext(AbilityContext);
  const history = useHistory();

  const notifications = (deal.notifications?.filter((n) => n != null) as Notification[]) ?? [];
  const { unseenMessages } = getDealNotificationCountByType({
    notifications,
  });
  const isClickable = useMemo(
    () => getIsClickable(deal, abilities),
    [
      deal.source,
      deal.state,
      deal.closer_id,
      deal.closer?.id,
      deal.structuring_manager_id,
      deal.structuring_manager?.id,
      abilities,
    ],
  );

  const clickableProps: TableRowProps = isClickable
    ? {
        cursor: 'pointer',
        onClick: (e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history),
        onAuxClick: (e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history),
      }
    : {};

  return (
    <Tr
      key={deal.id}
      verticalAlign="top"
      height="55px"
      borderBottom="2px"
      borderX="1px"
      borderColor="backgroundGray"
      bgColor="white"
      _hover={{ bgColor: 'hoverGray' }}
      {...clickableProps}
    >
      {TABLE_COLUMNS_ENTRIES.map(([, { getComponent }]) =>
        getComponent({
          deal,
          userId: user.id,
          permissions: abilities,
          unseenMessages,
        }),
      )}
    </Tr>
  );
};

export default StructuringFollowUpTableRow;
