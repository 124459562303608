import { ChangeEvent, Dispatch } from 'react';

import { Select, SelectProps } from '@chakra-ui/react';
import { FaCaretDown } from 'react-icons/fa';

import { Option } from '../../../shared/types';

import { FiltersAction, StringPayload } from '../../utils';

interface DropDownProps extends SelectProps {
  placeholder: string;
  options: Option[];
  dispatch: Dispatch<FiltersAction>;
  actionKind: StringPayload['type'];
  value?: string;
}

const DropDown = ({
  placeholder,
  options,
  dispatch,
  actionKind,
  value,
  minW = '125px',
  ...rest
}: DropDownProps) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: actionKind, payload: e.target.value });
  };

  return (
    <Select
      placeholder={placeholder}
      icon={<FaCaretDown />}
      maxWidth={220}
      minW={minW}
      borderRadius={3}
      bgColor="white"
      onChange={handleChange}
      value={value}
      {...rest}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default DropDown;
