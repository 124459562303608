import {
  differenceInMilliseconds,
  eachDayOfInterval,
  format,
  isValid,
  isWeekend,
  isWithinInterval,
  parse,
  setHours,
  setMinutes,
} from 'date-fns';

export const WEEKS_IN_MONTH = 4.34;
export const MONTHS_IN_YEAR = 12;

export const tenMinInMilliseconds = 10 * 60 * 1000;
export const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
const businessStartHour = 9;
const businessEndHour = 17;
const businessEndMinute = 30;

export const get24HoursAgo = () => new Date(Date.now() - oneDayInMilliseconds);

export const getMinutesAgo = (time: Date | string | null) => {
  if (!time) {
    return null;
  }

  const parsedTime = typeof time === 'string' ? new Date(time) : time;
  return Math.floor(differenceInMilliseconds(new Date(), parsedTime) / 60000); // 60000 ms in a minute
};

export const isWithinBusinessHours = (date: Date) => {
  if (isWeekend(date)) {
    return false;
  }

  const businessInterval = {
    start: setMinutes(setHours(date, businessStartHour), 0),
    end: setMinutes(setHours(date, businessEndHour), businessEndMinute),
  };

  return isWithinInterval(date, businessInterval);
};

export const calculateBusinessMilliseconds = (startDate: Date, endDate: Date) => {
  const businessInterval = {
    start: setMinutes(setHours(startDate, businessStartHour), 0),
    end: setMinutes(setHours(endDate, businessEndHour), businessEndMinute),
  };

  // Ensure startDate and endDate are within business hours
  const adjustedStartDate = isWithinInterval(startDate, businessInterval)
    ? startDate
    : businessInterval.start;
  const adjustedEndDate = isWithinInterval(endDate, businessInterval)
    ? endDate
    : businessInterval.end;

  const totalMilliseconds = eachDayOfInterval({
    start: adjustedStartDate,
    end: adjustedEndDate,
  }).reduce((milliseconds, day) => {
    if (isWeekend(day)) {
      return milliseconds;
    }

    const dayStart = setMinutes(setHours(day, businessStartHour), 0);
    const dayEnd = setMinutes(setHours(day, businessEndHour), businessEndMinute);

    const currentStart = isWithinInterval(adjustedStartDate, { start: dayStart, end: dayEnd })
      ? adjustedStartDate
      : dayStart;
    const currentEnd = isWithinInterval(adjustedEndDate, { start: dayStart, end: dayEnd })
      ? adjustedEndDate
      : dayEnd;

    return milliseconds + differenceInMilliseconds(currentEnd, currentStart);
  }, 0);

  return totalMilliseconds;
};

export const isDateLessThanNumDaysAway = (numDays: number, date?: Date | string | null) => {
  const parsedDate = date && typeof date === 'string' ? new Date(date) : date;
  if (parsedDate) {
    const futureDate = new Date(Date.now() + numDays * oneDayInMilliseconds);
    return parsedDate < futureDate;
  }

  return false;
};

export const createDateISO = (date?: string) =>
  date ? new Date(date).toISOString().split('T')[0] : '';

export const parseAndFormatDate = (dateString?: string | null, targetFormat = 'yyyy-MM-dd') => {
  if (!dateString) {
    return '';
  }

  const possibleFormats = ['yyyy-MM-dd', 'MM/dd/yyyy'];
  // eslint-disable-next-line no-restricted-syntax
  for (const possibleFormat of possibleFormats) {
    const parsedDate = parse(dateString, possibleFormat, new Date());
    const isValidDate = isValid(parsedDate);

    if (isValidDate) {
      return format(parsedDate, targetFormat);
    }
  }

  return '';
};

export const formatDateAndTime = (dateToFormat: Date | string | undefined) => {
  if (!dateToFormat) {
    return '';
  }

  const date = new Date(dateToFormat);

  // Format to MM/DD/YY HH:MMam/pm
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().substring(2);
  const hour = date.getHours() % 12 === 0 ? 12 : date.getHours() % 12;
  const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const amOrPm = date.getHours() >= 12 ? ' PM' : ' AM';

  return `${month}/${day}/${year} ${hour}:${minute}${amOrPm}`;
};

export const valueOfDate = (date: Date | string | undefined) => {
  if (!date) {
    return 0;
  }
  return new Date(date).valueOf();
};
