import { Dispatch, useEffect } from 'react';

import { Box, Flex, IconButton, StackProps, Text } from '@chakra-ui/react';
import { GoTriangleDown } from 'react-icons/go';
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import Select from 'react-select';

import { FiltersAction, FiltersActionKind } from '../Titling/utils';

import {
  FIRST_PAGE,
  ITEMS_PER_PAGE_OPTIONS,
  PaginationContextType,
} from '../../hooks/usePagination';

interface PropsBasic extends StackProps {
  filters: {
    currentPage: number;
    itemsPerPage: number;
  };
  numItemsShown: number;
  paginationContext: PaginationContextType;
  dispatchFilters?: Dispatch<FiltersAction>;
  parentSetItemsPerPage?: (itemsPerPage: number) => void;
  parentSetCurrentPage?: (currentPage: number) => void;
  itemsPerPageOptions?: number[];
}

interface PropsWithSetState extends PropsBasic {
  parentSetItemsPerPage: (itemsPerPage: number) => void;
  parentSetCurrentPage: (currentPage: number) => void;
}

interface PropsWithDispatch extends PropsBasic {
  dispatchFilters: Dispatch<FiltersAction>;
}

type PaginationProps = PropsWithSetState | PropsWithDispatch;

const Pagination = ({
  dispatchFilters,
  parentSetItemsPerPage,
  parentSetCurrentPage,
  filters,
  paginationContext,
  itemsPerPageOptions,
  ...rest
}: PaginationProps) => {
  const {
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    maxPage,
    totalRecords,
    nextPage,
    prevPage,
    jumpToPage,
  } = paginationContext;

  useEffect(() => {
    if (totalRecords > 0) {
      const actualPage =
        filters.currentPage * itemsPerPage > totalRecords ? FIRST_PAGE : filters.currentPage;

      if (dispatchFilters) {
        dispatchFilters({
          type: FiltersActionKind.SET_CURRENT_PAGE,
          payload: actualPage,
        });
        dispatchFilters({
          type: FiltersActionKind.SET_ITEMS_PER_PAGE,
          payload: filters.itemsPerPage,
        });
      } else if (parentSetCurrentPage && parentSetItemsPerPage) {
        parentSetCurrentPage(actualPage);
        parentSetItemsPerPage(filters.itemsPerPage);
      }

      setItemsPerPage(filters.itemsPerPage);
      jumpToPage(actualPage);
    }
  }, [totalRecords]);

  useEffect(() => {
    if (totalRecords <= 0) {
      return;
    }

    if (dispatchFilters) {
      dispatchFilters({
        type: FiltersActionKind.SET_CURRENT_PAGE,
        payload: currentPage * itemsPerPage > totalRecords ? FIRST_PAGE : currentPage,
      });
      dispatchFilters({ type: FiltersActionKind.SET_ITEMS_PER_PAGE, payload: itemsPerPage });
    } else if (parentSetCurrentPage && parentSetItemsPerPage) {
      parentSetCurrentPage(currentPage * itemsPerPage > totalRecords ? FIRST_PAGE : currentPage);
      parentSetItemsPerPage(itemsPerPage);
    }
  }, [currentPage, itemsPerPage]);

  return (
    <Flex
      bgColor="gray.100"
      borderBottomRadius="10px"
      justifyContent="space-between"
      flexWrap="wrap"
      alignItems="center"
      py={2}
      px={4}
      fontSize={14}
      {...rest}
    >
      <Flex alignItems="center" gap={2} w="25%" minW="150px">
        <Text flexShrink="0">Per Page</Text>
        <Select
          value={{ label: itemsPerPage, value: itemsPerPage }}
          defaultValue={{
            label: Math.min(...(itemsPerPageOptions ?? ITEMS_PER_PAGE_OPTIONS)),
            value: Math.min(...(itemsPerPageOptions ?? ITEMS_PER_PAGE_OPTIONS)),
          }}
          onChange={(val) => setItemsPerPage(val?.value ?? 0)}
          components={{
            DropdownIndicator: () => (
              <Box color="primary" mx={2}>
                <GoTriangleDown size={20} />
              </Box>
            ),
          }}
          options={(itemsPerPageOptions ?? ITEMS_PER_PAGE_OPTIONS).map((pageSize) => ({
            label: pageSize,
            value: pageSize,
          }))}
          styles={{
            indicatorSeparator: (base) => ({
              ...base,
              backgroundColor: 'white',
            }),
          }}
        />
      </Flex>

      <Flex justifyContent="center" gap={3} minW="300px">
        <Flex gap={2}>
          <IconButton
            variant="iconHover"
            color="oxfordBlue"
            aria-label="first-page"
            onClick={() => jumpToPage(FIRST_PAGE)}
            isDisabled={currentPage === FIRST_PAGE}
            icon={<MdFirstPage />}
            fontSize="36px"
          />
          <IconButton
            variant="iconHover"
            color="oxfordBlue"
            aria-label="previous"
            onClick={prevPage}
            isDisabled={currentPage === FIRST_PAGE}
            icon={<MdNavigateBefore />}
            fontSize="36px"
          />
        </Flex>
        <Flex alignItems="center">
          <Text flexShrink="0" mx={4}>
            {`${currentPage + 1} of ${maxPage}`}
          </Text>
        </Flex>
        <Flex gap={2}>
          <IconButton
            variant="iconHover"
            color="oxfordBlue"
            aria-label="next"
            onClick={nextPage}
            isDisabled={currentPage === maxPage - 1}
            icon={<MdNavigateNext />}
            fontSize="36px"
          />
          <IconButton
            variant="iconHover"
            color="oxfordBlue"
            aria-label="last-page"
            onClick={() => jumpToPage(maxPage - 1)}
            isDisabled={currentPage === maxPage - 1}
            icon={<MdLastPage />}
            fontSize="36px"
          />
        </Flex>
      </Flex>

      <Flex alignItems="center" w="25%" minW="120px" justifyContent="flex-end">
        <Text>Total Records: {totalRecords}</Text>
      </Flex>
    </Flex>
  );
};

export default Pagination;
