import { useContext } from 'react';

import { Button } from '@chakra-ui/react';
import { BsXLg } from 'react-icons/bs';

import { FiltersContext } from '../../../../libs/contextLib';
import { FiltersActionKind } from '../../utils';

const ClearFiltersButton = () => {
  const { dispatch: dispatchFilters } = useContext(FiltersContext);

  return (
    <Button
      size="smWithIconLeft"
      variant="secondary"
      leftIcon={<BsXLg />}
      onClick={() =>
        dispatchFilters({
          type: FiltersActionKind.CLEAR_TITLING_FILTERS,
          payload: undefined,
        })
      }
    >
      CLEAR FILTERS
    </Button>
  );
};

export default ClearFiltersButton;
