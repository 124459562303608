import { useMemo } from 'react';

import { Deal, isUneditable } from '../gql/dealGql';
import { useBanksQuery } from '../gql/generated/graphql';

import { isNullOrUndefined } from '../utils';

interface UseBanksProps {
  onlyActive?: boolean;
  deal?: Deal;
}

export const useBanks = (props: UseBanksProps = {}) => {
  const { onlyActive = false, deal } = props;
  const dealIsUneditable = isUneditable(deal?.state);

  const { data, loading, refetch } = useBanksQuery({
    variables: {
      state: deal?.customer?.address?.state,
      county: deal?.customer?.address?.county,
      active: onlyActive,
      selectedBank: dealIsUneditable ? deal?.financial_info?.bank : undefined,
    },
  });

  const banks = useMemo(() => {
    if (!data?.banks?.length) {
      return [];
    }

    return data?.banks.filter((bank) => !isNullOrUndefined(bank));
  }, [data?.banks]);

  return { banks, loading, refetch };
};
