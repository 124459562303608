import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { Box, Checkbox, Divider, Flex, IconButton, Link, Text } from '@chakra-ui/react';
import { FaDownload } from 'react-icons/fa';

import { File } from '../../gql/contractGql';

import { DealMediaTypeLabels } from '../../constants/media';

interface Props {
  infoText: string;
  files: File[];
  selectedFilesIndexes: number[];
  setSelectedFilesIndexes: Dispatch<SetStateAction<number[]>>;
  onOpenFile: (file: File) => void;
  modal: 'generate' | 'upload' | 'sign';
  showDownloadColumn?: boolean;
  selectAllByDefault?: boolean;
  numDocsInR1?: number;
  numExistingFiles?: number;
  existingFiles?: string[];
}

const DocumentsTable: FC<Props> = ({
  infoText,
  files,
  selectedFilesIndexes,
  setSelectedFilesIndexes,
  onOpenFile,
  showDownloadColumn = false,
  selectAllByDefault = false,
  modal,
  numDocsInR1 = 0,
  numExistingFiles = 0,
  existingFiles = [],
}) => {
  const columnsWidth = showDownloadColumn
    ? {
        checkbox: '5%',
        document: '35%',
        filename: '45%',
        download: '15%',
      }
    : {
        checkbox: '5%',
        document: '45%',
        filename: '50%',
      };

  const WarningMessage: FC = () => {
    if (!numDocsInR1 && !numExistingFiles) {
      return null;
    }

    switch (modal) {
      case 'generate':
        return (
          <Text fontStyle="italic" color="errorsRed" mb={5}>
            {numExistingFiles !== 1
              ? `There are (${numExistingFiles}) selected files with duplicate file names in the Media Center. Would you like to replace these files with the newly generated ones?`
              : `There is (${numExistingFiles}) selected file with a duplicate file name in the Media Center. Would you like to replace this file with the newly generated one?`}
          </Text>
        );
      case 'upload':
        return (
          <Text fontStyle="italic" color="errorsRed" mb={5}>
            {`There ${numDocsInR1 !== 1 ? 'are' : 'is'} currently (${numDocsInR1}) document${
              numDocsInR1 !== 1 ? 's' : ''
            } uploaded to R1`}
          </Text>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!files || !selectAllByDefault) {
      return;
    }

    const allFilesIndexes = Array.from({ length: files.length }, (_, index) => index);
    setSelectedFilesIndexes(allFilesIndexes);
  }, [files]);

  const handleCheckboxClick = (index: number) => {
    if (selectedFilesIndexes.includes(index)) {
      setSelectedFilesIndexes((previousIndexes) =>
        previousIndexes.filter((fileIndex) => fileIndex !== index),
      );
      return;
    }

    setSelectedFilesIndexes((previousIndexes) => [...previousIndexes, index]);
  };

  return (
    <Box>
      <Box>{infoText}</Box>
      <WarningMessage />
      <Flex px={1}>
        <Text w="5%" display="flex" justifyContent="center" alignItems="center">
          <Checkbox
            m={0}
            isChecked={selectedFilesIndexes.length === files.length}
            onChange={() => {
              if (selectedFilesIndexes.length === files.length) {
                setSelectedFilesIndexes([]);
                return;
              }

              const allFilesIndexes = Array.from({ length: files.length }, (_, index) => index);
              setSelectedFilesIndexes(allFilesIndexes);
            }}
          />
        </Text>
        <Text w={columnsWidth.document} variant="inbox" m={1}>
          Document
        </Text>
        <Text w={columnsWidth.filename} variant="inbox" m={1}>
          Filename
        </Text>
        {showDownloadColumn ? (
          <Text w={columnsWidth.download} whiteSpace="nowrap" m={1}>
            Download
          </Text>
        ) : null}
      </Flex>
      <Divider mb={0} />
      {files.map((file, index) => (
        <Flex key={file.key} px={1} _hover={{ bgColor: 'gray.100' }}>
          <Text w="5%" display="flex" justifyContent="center" alignItems="center">
            <Checkbox
              m={0}
              isChecked={selectedFilesIndexes.includes(index)}
              onChange={() => handleCheckboxClick(index)}
            />
          </Text>
          <Text w={columnsWidth.document} variant="inbox" m={1}>
            {DealMediaTypeLabels[file.mediaType]}
          </Text>
          <Text w={columnsWidth.filename} variant="inbox" m={1}>
            <Link
              color={existingFiles.includes(file.filename) ? 'errorsRed' : 'secondary'}
              onClick={() => onOpenFile(file)}
            >{`${file.filename}`}</Link>
          </Text>
          {showDownloadColumn ? (
            <Text
              w={columnsWidth.download}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                variant="iconHover"
                size="xs"
                aria-label="Download"
                // eslint-disable-next-line security/detect-non-literal-fs-filename
                onClick={() => window.open(file.url, 'PRINT')}
              >
                <FaDownload />
              </IconButton>
            </Text>
          ) : null}
        </Flex>
      ))}
    </Box>
  );
};

export default DocumentsTable;
