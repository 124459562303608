import LeaseEndContainer from '../../components/Container/LEContainer';
import ButtonsRow from '../../components/Dashboards/ButtonsRow';

import TeamManager from './TeamManager';

const TeamsPage = () => {
  return (
    <>
      <ButtonsRow />
      <LeaseEndContainer h="full">
        <TeamManager />
      </LeaseEndContainer>
    </>
  );
};

export default TeamsPage;
