import { useContext, useEffect, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Button, Flex, HStack, Spinner, Text } from '@chakra-ui/react';
import Big from 'big.js';
import { useFormikContext } from 'formik';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

import { Auction, auctionAverageTransportCostQuery, auctionsQuery } from '../../gql/auctionGql';
import { Deal, DealStateEnum } from '../../gql/dealGql';

import ConditionReport from '../ConditionReport';
import EditDealInfoButton from '../EditDealInfoButton';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';
import Select from '../shared/Select';

import { AddAuction } from './AddAuction';
import DetermineBestAuctionButton from './DetermineBestAuctionButton';
import LogisticsViewSaveButton from './LogisticsViewSaveButton';
import SaveEditButton from './SaveEditButton';
import StructuringAndClosedView from './StructuringAndClosedView';

import { DealContext } from '../../libs/DealContext';
import { ColorsEnum } from '../../libs/colorLib';
import { formatMoney } from '../../libs/utils';

const editableDealStates = [
  DealStateEnum.ReadyForPickup,
  DealStateEnum.PaidOff,
  DealStateEnum.AtAuction,
  DealStateEnum.Sold,
];

const LogisticsView = () => {
  const { deal, dealRefetch } = useContext(DealContext);
  const { values, handleBlur, touched, errors, setFieldValue } = useFormikContext<Deal>();
  const {
    customer: {
      address: { address_line, zip },
    },
  } = deal;
  useFormikContext<Deal>();

  const [isEditable, setIsEditable] = useState(editableDealStates.includes(deal.state));
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAuctionModal, setShowAuctionModal] = useState<boolean>(false);

  const auctionsGql = useQuery(auctionsQuery, { fetchPolicy: 'network-only' });
  let isConditionReportCompleted = false;

  const inReadyForPickup = deal.state === DealStateEnum.ReadyForPickup;
  const inPaidOff = deal.state === DealStateEnum.PaidOff;
  const isReadOnly = isEditable
    ? false
    : ![DealStateEnum.SoftClose, DealStateEnum.Structuring, DealStateEnum.Closing].includes(
        deal.state,
      );

  if (
    deal?.condition_report?.accidents &&
    deal?.condition_report.tires &&
    deal?.condition_report.smoked_in !== null &&
    deal?.condition_report.lights_on_dash !== null &&
    deal?.condition_report.overall_condition
  ) {
    isConditionReportCompleted = true;
  }

  const [getAverageTransportCost, { loading }] = useLazyQuery(auctionAverageTransportCostQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ auctionAverageTransportCost }) => {
      setFieldValue('financial_info_acquisition.transport_cost', auctionAverageTransportCost);
    },
  });

  useEffect(() => {
    if (auctionsGql.data && auctionsGql.data.auctions) {
      setAuctions(auctionsGql.data.auctions);
    }
  }, [auctionsGql.data]);

  useEffect(() => {
    if (
      values.financial_info_acquisition.total_cost &&
      values.financial_info_acquisition.sell_price
    ) {
      setFieldValue(
        'financial_info_acquisition.gross_profit',
        new Big(values.financial_info_acquisition.sell_price)
          .minus(values.financial_info_acquisition.total_cost)
          .toNumber(),
      );
    }
  }, [values.financial_info_acquisition.total_cost, values.financial_info_acquisition.sell_price]);

  const handleAuctionChange = (id: string) => {
    if (id) {
      setFieldValue('financial_info_acquisition.auction_id', id);
      const customerAddress = `${address_line} ${zip}`;
      getAverageTransportCost({ variables: { id, customerAddress } });
    } else {
      setFieldValue('financial_info_acquisition.transport_cost', '');
    }
  };

  return (
    <Box bgColor="white" w="50vw" p={4}>
      <Button isDisabled={!isEditable} onClick={() => setShowAuctionModal(true)}>
        ADD AUCTION
      </Button>
      {(inReadyForPickup || inPaidOff) && <DetermineBestAuctionButton auctions={auctions} />}
      <Modal
        title="Add Auction"
        isOpen={showAuctionModal}
        onClose={() => setShowAuctionModal(false)}
      >
        <AddAuction setShowModal={setShowAuctionModal} auctionsRefetch={auctionsGql.refetch} />
      </Modal>
      <Select
        maxW="50%"
        label="Auction"
        name="financial_info_acquisition.auction_id"
        options={auctions.map((e) => ({
          label: e.name ?? '',
          value: e?.id?.toString() ?? '',
        }))}
        value={values.financial_info_acquisition?.auction_id}
        onChange={(e) => handleAuctionChange(e.target.value)}
        onBlur={handleBlur}
        isDisabled={!isEditable}
        isInvalid={
          touched.financial_info_acquisition?.auction_id &&
          !!errors.financial_info_acquisition?.auction_id
        }
      />
      {loading && <Spinner animation="grow" size="sm" />}

      <HStack>
        <NumberInput
          label="Transport Cost"
          isMoney
          name="financial_info_acquisition.total_cost"
          isDisabled={isReadOnly}
        />
        <NumberInput
          label="Auction Fees"
          isMoney
          name="financial_info_acquisition.auction_fees"
          isDisabled={isReadOnly}
        />
      </HStack>
      <StructuringAndClosedView />
      <Flex align="center" justify="center" gap={9} mb={4}>
        <HStack>
          <Text>Gross Profit:</Text>
          <Text>{formatMoney(values.financial_info_acquisition.gross_profit)}</Text>
        </HStack>
        <NumberInput
          width="50%"
          label="Sell Price"
          isMoney
          name="financial_info_acquisition.sell_price"
          isDisabled={isReadOnly}
        />
      </Flex>
      <HStack>
        <Button
          size="lgWithIconLeft"
          rightIcon={
            isConditionReportCompleted ? (
              <BiCheckCircle color={ColorsEnum.GREEN} />
            ) : (
              <BiXCircle color={ColorsEnum.RED} />
            )
          }
          isLoading={showModal}
          onClick={() => setShowModal(true)}
        >
          VIEW CONDITION REPORT
        </Button>
        <Modal
          size="2xl"
          title="Condition Report"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <ConditionReport setShowModal={setShowModal} isEditing={false} />
        </Modal>
        <LogisticsViewSaveButton dealRefetch={dealRefetch} />
        <EditDealInfoButton isEditing={isEditable} setIsEditing={setIsEditable} />
        <SaveEditButton isEditing={isEditable} setIsEditing={setIsEditable} />
      </HStack>
    </Box>
  );
};

export default LogisticsView;
