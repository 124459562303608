import { useContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { HStack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Deal, bootedDealsQuery } from '../../gql/dealGql';
import { getDealNotificationCountByType } from '../../gql/notificationsGql';

import ExportDealsToXlsxButton from '../ExportDealsToXlsxButton/ExportDealsToXlsxButton';
import Loader from '../Loader';
import { NewTableNotificationsContainer } from '../NotificationBadge/NotificationsContainer';
import TextNotificationBadgeV2 from '../NotificationBadge/TextNotificationBadgeV2';

import { FiltersContext } from '../../libs/contextLib';
import { handleClickOrCommandClick } from '../../libs/eventHandlers';
import { DealWithCalculatedProps, bootedDealColumns } from '../../pages/BootedDealsPage/constants';
import { getBootedDealState } from '../../pages/BootedDealsPage/utils';

interface BootedDealsProps {
  start_date: Date;
  end_date: Date;
  showOnlyUnreadMessages: boolean;
}

const BootedDeals = ({ start_date, end_date, showOnlyUnreadMessages }: BootedDealsProps) => {
  const {
    filters: {
      global: {
        selectedPodId,
        sources,
        types,
        showAssignedToMe,
        showAssignedToDeletedUsers,
        showActiveTags,
        showMatureFollowUps,
      },
    },
  } = useContext(FiltersContext);

  const history = useHistory();

  const { data, loading } = useQuery<{ bootedDeals: Deal[] }>(bootedDealsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      assigned_to_me_filter: showAssignedToMe,
      assigned_to_deleted_users_filter: showAssignedToDeletedUsers,
      start_date,
      end_date,
      sources,
      types,
      pod_id: selectedPodId,
      unread_messages_filter: showOnlyUnreadMessages,
      showActiveTags,
      showMatureFollowUps,
    },
  });

  const processedDeals = useMemo<DealWithCalculatedProps[]>(() => {
    if (data?.bootedDeals) {
      return data.bootedDeals.map((deal) => ({
        ...deal,
        dealNotificationCountByType: getDealNotificationCountByType(deal),
        lastDealState: getBootedDealState(deal),
      }));
    }
    return [];
  }, [data?.bootedDeals]);

  return (
    <>
      <HStack p={3} bgColor="oxfordBlue" borderTopRadius={8} spacing="auto">
        <Text fontSize={20} textColor="white">
          Booted Deals
        </Text>
        <ExportDealsToXlsxButton
          deals={processedDeals}
          columns={bootedDealColumns}
          dealType="booted"
        />
      </HStack>
      <Table>
        <Thead bgColor="queenBlue" px={6} height="50px">
          <Tr>
            <Th width="20px" />
            {bootedDealColumns.map(({ header }) => (
              <Th textColor="white" key={header}>
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {processedDeals.map((deal) => (
            <Tr
              key={deal.id}
              cursor="pointer"
              height="55px"
              borderBottom="2px"
              borderX="1px"
              borderColor="backgroundGray"
              _hover={{ bgColor: 'hoverGray' }}
              onClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
              onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
              bgColor="white"
            >
              <Td key="Notifications">
                <NewTableNotificationsContainer style={{ margin: 0 }}>
                  <TextNotificationBadgeV2
                    count={deal.dealNotificationCountByType?.messages}
                    deal={deal}
                  />
                </NewTableNotificationsContainer>
              </Td>
              {bootedDealColumns.map(({ header, getValue }) => (
                <Td key={header} whiteSpace="normal" maxWidth="lg">
                  {getValue(deal)}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Loader isLoading={loading} />
    </>
  );
};

export default BootedDeals;
