import { ChangeEventHandler, useContext } from 'react';

import { Flex, TabPanel } from '@chakra-ui/react';
import { getIn, useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum } from '../../gql/dealGql';
import {
  FinancialInfo,
  ProcessorEnum,
  TitleRegistrationOptionLabels,
} from '../../gql/financialInfoGql';
import { useUpdateProcessorMutation } from '../../gql/generated/graphql';

import DaysCount from './components/DaysCount';
import { ProcessorSection } from './components/ProcessorSection';
import { TitlingSection } from './components/TitlingSection';
import TopSection from './components/TopSection';
import TopSectionData from './components/TopSectionData';

import {
  DebouncedSave,
  dividerProps,
  sectionWidth,
  sectionsContainerProps,
} from './DealInfoBuyoutForm';
import { isCustomDateField } from './constants';

import useUpdateCustomDates from '../../hooks/useUpdateCustomDates';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

type TitleInfoPanelProps = {
  debouncedSave: DebouncedSave;
  autosaveTimer: () => void;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
};

export const TitleInfoPanel = ({
  debouncedSave,
  autosaveTimer,
  customHandleChange,
}: TitleInfoPanelProps) => {
  const { deal, dispatch, setUserChangedSomething } = useContext(DealContext);

  const { values, validateForm } = useFormikContext<Deal>();

  const updateCustomDates = useUpdateCustomDates();
  const [updateProcessor] = useUpdateProcessorMutation();

  const saveCustomDate = async (fieldName: string, fieldValue: string) => {
    setUserChangedSomething(true);
    const validatedForm = await validateForm();
    const fieldError = getIn(validatedForm, fieldName);

    if (
      !fieldError &&
      values?.id &&
      values?.deal_dates?.custom_dates &&
      Object.keys(values.deal_dates.custom_dates).length &&
      isCustomDateField(fieldName)
    ) {
      autosaveTimer();

      await updateCustomDates({
        fieldName,
        fieldValue,
        componentName: 'DealInfoBuyoutForm.tsx',
      });
    }
  };

  const saveProcessor = async (newValue?: ProcessorEnum) => {
    setUserChangedSomething(true);
    const { financial_info: financialInfoErrors } = await validateForm();

    if (values.id && values.financial_info.id && !financialInfoErrors?.processor) {
      autosaveTimer();
      try {
        const { data } = await updateProcessor({
          variables: {
            deal_id: values.id,
            fi_id: values.financial_info.id,
            processor:
              newValue && Object.values(ProcessorEnum).includes(newValue) ? newValue : null,
          },
        });
        if (!data?.updateProcessor) {
          toast.error('Failed to update Processor');
          return;
        }

        dispatch({
          type: DealActionsEnum.UpdateDeal,
          payload: {
            // TODO: temporary conversion. We should remove all classes and use the generated classes
            financial_info: { ...deal.financial_info, ...(data.updateProcessor as FinancialInfo) },
          },
        });
        toast.success('Processor updated');
      } catch (error) {
        toast.error('Failed to update processor');
        logger.error('DealInfoBuyoutForm.tsx', 'Failed to update processor', null, error);
      }
    }
  };

  return (
    <TabPanel p={0}>
      <TopSection>
        <TopSectionData label="Bank">{values.financial_info.bank}</TopSectionData>
        <TopSectionData label="Transaction Type">
          {values.financial_info.title_registration_option
            ? TitleRegistrationOptionLabels[values.financial_info.title_registration_option]
            : null}
        </TopSectionData>
      </TopSection>

      <Flex direction="row" bgColor="brightGray" p={2} justifyContent="space-around">
        <DaysCount
          label="Days Since Payoff Sent"
          date={values.deal_dates?.custom_dates?.[DealStateEnum.WaitingForTitle]}
        />
        <DaysCount
          label="Days Since Title Received"
          date={values.deal_dates?.custom_dates?.[DealStateEnum.TitleReceived]}
        />
        <DaysCount
          label="Days Since Sent to Processor"
          date={values.deal_dates?.custom_dates?.[DealStateEnum.SentToProcessor]}
        />
      </Flex>

      <Flex direction="row" {...dividerProps} />

      <Flex {...sectionsContainerProps}>
        <Flex direction="column" w={sectionWidth}>
          <TitlingSection debouncedSave={debouncedSave} saveCustomDate={saveCustomDate} />
        </Flex>
        <Flex direction="column" {...dividerProps} />
        <Flex direction="column" w={sectionWidth}>
          <ProcessorSection
            saveCustomDate={saveCustomDate}
            saveProcessor={saveProcessor}
            customHandleChange={customHandleChange}
          />
        </Flex>
      </Flex>
    </TabPanel>
  );
};
