import { useContext, useMemo, useReducer, useState } from 'react';

import { useSubscription } from '@apollo/client';
import { Box, Flex, Icon, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import { onDealUpdate } from '../../gql/dealGql';
import {
  Deal as GeneratedDeal,
  Maybe,
  PayoffRequestPopulated,
  SortDirection,
  useOnPayoffRequestUpdateSubscription,
  usePayoffDashboardDealsQuery,
} from '../../gql/generated/graphql';
import { PayoffRequest } from '../../gql/payoffRequestGql';

import Loader from '../Loader';
import { SortingActionKind, isSortingAsc, sortingReducer } from '../Titling/utils';
import { payoffTableColumns } from './components/ColumnComponents';
import DealRowContainer from './components/DealRowContainer';
import TableFilters from './components/TableFilters';

import { AbilityContext, FiltersContext } from '../../libs/contextLib';
import {
  filterPayoffRequests,
  parsePayoffRequestsFromDashboard,
  shouldAddOrUpdatePayoffRequest,
  sortPayoffRequests,
} from '../../utils/payoffRequests';

const INITIAL_SORTING_STATE = {
  field: 'created_at',
  direction: SortDirection.Asc,
};

const PayoffDealsTable = () => {
  const abilities = useContext(AbilityContext);

  const [sorting, dispatchSorting] = useReducer(sortingReducer, INITIAL_SORTING_STATE);
  const { field = INITIAL_SORTING_STATE.field, direction } = sorting;

  const [payoffRequests, setPayoffRequests] = useState<Maybe<PayoffRequestPopulated>[]>([]);

  const dealIds = useMemo(
    () =>
      payoffRequests.reduce(
        (acc, pr) => (pr?.deal?.id ? [...acc, pr.deal.id] : acc),
        [] as number[],
      ),
    [payoffRequests],
  );

  const {
    filters: {
      payoff: payoffFilters,
      global: { sources },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const { loading } = usePayoffDashboardDealsQuery({
    fetchPolicy: 'no-cache', // TODO: temporary solution. We should remove this when we have a better solution
    onCompleted: (data) => {
      setPayoffRequests(data.payoffDashboardDeals?.payoffRequests ?? []);
    },
  });

  useOnPayoffRequestUpdateSubscription({
    onData: ({ data }) => {
      if (!data?.data?.onPayoffRequestUpdate) {
        return;
      }

      const payoffRequest = data.data.onPayoffRequestUpdate;
      const payoffRequestIndex = payoffRequests.findIndex((pr) => pr?.id === payoffRequest.id);

      const addOrUpdatePayoffRequest = shouldAddOrUpdatePayoffRequest(payoffRequest, abilities);

      // Update the payoff request
      if (addOrUpdatePayoffRequest && payoffRequestIndex !== -1) {
        const updatedPayoffRequests = [...payoffRequests];
        updatedPayoffRequests[payoffRequestIndex] = payoffRequest;
        setPayoffRequests(updatedPayoffRequests);
        return;
      }

      // Add the payoff request
      if (addOrUpdatePayoffRequest) {
        setPayoffRequests([payoffRequest, ...payoffRequests]);
        return;
      }

      // Remove the payoff request
      if (payoffRequestIndex !== -1) {
        const updatedPayoffRequests = [...payoffRequests];
        updatedPayoffRequests.splice(payoffRequestIndex, 1);
        setPayoffRequests(updatedPayoffRequests);
      }
    },
  });

  // Subscribed only to the deal updates that are in the current table.
  useSubscription<{ onDealUpdate?: GeneratedDeal[] }>(onDealUpdate, {
    variables: { sources, dealIds },
    onData: ({ data }) => {
      if (!data?.data?.onDealUpdate?.length) {
        return;
      }

      const updatedPayoffRequests = [...payoffRequests];

      data.data.onDealUpdate.forEach((updatedDeal) => {
        const payoffRequestIndex = updatedPayoffRequests.findIndex(
          (pr) => pr?.deal?.id && pr.deal.id === updatedDeal.id,
        );
        if (payoffRequestIndex === -1) {
          return;
        }

        // Only update the notifications.
        updatedPayoffRequests[payoffRequestIndex] = {
          ...updatedPayoffRequests[payoffRequestIndex],
          deal: {
            ...updatedPayoffRequests[payoffRequestIndex]?.deal,
            notifications: updatedDeal.notifications ?? [],
          },
        };
      });

      setPayoffRequests(updatedPayoffRequests);
    },
  });

  const sortedPayoffRequests = useMemo(() => {
    // TODO: temporary conversion. We should remove all classes and use the generated classes
    const currentPayoffRequests = payoffRequests as PayoffRequest[];

    if (!currentPayoffRequests || currentPayoffRequests.length === 0) {
      return currentPayoffRequests;
    }

    const parsedPayoffRequests = parsePayoffRequestsFromDashboard(currentPayoffRequests);
    const filteredPayoffRequests = filterPayoffRequests(parsedPayoffRequests, payoffFilters);

    return sortPayoffRequests(filteredPayoffRequests, field, direction);
  }, [payoffRequests, sorting, payoffFilters]);

  const handleSorting = (sortingFieldName: string) => {
    // This will handle the attempt of sorting by the `actions` column
    const validSortingFieldName = sortingFieldName || INITIAL_SORTING_STATE.field;

    if (sorting.field !== validSortingFieldName) {
      dispatchSorting({
        type: SortingActionKind.SET_FIELD,
        payload: validSortingFieldName,
      });

      return;
    }

    dispatchSorting({
      type: SortingActionKind.SET_DIRECTION,
      payload: isSortingAsc(sorting.direction) ? SortDirection.Asc : SortDirection.Desc,
    });
  };

  const calculateLeftPx = (name: string) => {
    return `${(document.getElementsByClassName(name)[0] as HTMLElement)?.offsetWidth ?? 100}px`;
  };

  const generateRow = (payoffRequest: PayoffRequest) => (
    <DealRowContainer key={payoffRequest.id} payoffRequest={payoffRequest} />
  );

  const addSortProps = (column: string, isDisabled?: boolean) => {
    if (isDisabled) {
      return {};
    }

    return {
      onClick: () => handleSorting(column),
      cursor: 'pointer',
    };
  };

  return (
    <>
      <Loader isLoading={loading} />
      <TableFilters filters={payoffFilters} dispatch={dispatchFilters} />
      <Box width="100%" overflowX="scroll">
        <Table>
          <Thead bgColor="silverLakeBlue">
            <Tr>
              {Object.entries(payoffTableColumns).map(
                ([header, { sortingFieldName, isDisabled }]) => (
                  <Th
                    position="relative"
                    color="white"
                    key={`${header}-key`}
                    px={6}
                    whiteSpace="nowrap"
                  >
                    <Flex
                      {...addSortProps(sortingFieldName, isDisabled)}
                      alignItems="center"
                      position="relative"
                    >
                      <span className={`${header}-title`}>{header}</span>
                      {sortingFieldName === sorting.field && (
                        <Icon
                          position="absolute"
                          left={calculateLeftPx(`${header}-title`)}
                          boxSize={5}
                          as={isSortingAsc(sorting.direction) ? BiChevronUp : BiChevronDown}
                        />
                      )}
                    </Flex>
                  </Th>
                ),
              )}
            </Tr>
          </Thead>
          <Tbody>{sortedPayoffRequests?.map(generateRow)}</Tbody>
        </Table>
      </Box>
    </>
  );
};

export default PayoffDealsTable;
