import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Button, HStack, Stack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { Auction, auctionUpsert } from '../../gql/auctionGql';

import Input from '../shared/Input';
import NumberInput from '../shared/NumberInput';

import { validationSchemaAddAuction } from './validationSchemas';

import { phoneNumberMask } from '../../constants/masks';
import { logger } from '../../libs/Logger';

interface AddAuctionProps {
  setShowModal: (showModal: boolean) => void;
  auctionsRefetch: () => Promise<ApolloQueryResult<Auction>>;
}

export const AddAuction = ({ setShowModal, auctionsRefetch }: AddAuctionProps) => {
  const initialVals = { auction: new Auction() };
  const [upsertAuction] = useMutation(auctionUpsert);

  const handleSubmit = async (auction: Auction) => {
    try {
      await upsertAuction({
        variables: {
          auction: {
            ...auction,
            state: auction.state?.toUpperCase(),
          },
        },
      });

      setShowModal(false);
      await auctionsRefetch();
    } catch (e) {
      logger.error('AddAuction.tsx', '', null, e);
    }
  };

  return (
    <Formik
      initialValues={initialVals}
      onSubmit={({ auction }) => {
        handleSubmit(auction);
      }}
      validationSchema={validationSchemaAddAuction}
      validateOnMount
    >
      {({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        isValid,
        submitForm,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Stack spacing={2} mb={4}>
              <HStack>
                <Input
                  label="Name"
                  name="auction.name"
                  value={values.auction?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.name && !!errors.auction?.name}
                />
                <Input
                  label="Phone Number"
                  name="auction.phone_number"
                  value={values.auction?.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.phone_number && !!errors.auction?.phone_number}
                  mask={phoneNumberMask}
                />
              </HStack>
              <HStack>
                <Input
                  label="Address"
                  name="auction.address_line"
                  value={values.auction?.address_line}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.address_line && !!errors.auction?.address_line}
                />
                <Input
                  label="City"
                  name="auction.city"
                  value={values.auction?.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.city && !!errors.auction?.city}
                />
              </HStack>
              <HStack>
                <Input
                  label="State"
                  name="auction.state"
                  value={values.auction?.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.state && !!errors.auction?.state}
                />
                <Input
                  label="Zip"
                  name="auction.zip"
                  value={values.auction?.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.auction?.zip && !!errors.auction?.zip}
                />
              </HStack>
              <HStack>
                <NumberInput
                  label="Average Transport Cost (optional)"
                  name="auction.average_transport_cost"
                  isMoney
                  isWhole
                  showThousandSeparator
                  isInvalid={
                    touched.auction?.average_transport_cost &&
                    !!errors.auction?.average_transport_cost
                  }
                />
              </HStack>
            </Stack>
            <Button
              isLoading={isSubmitting}
              loadingText="SAVE"
              isDisabled={!isValid}
              onClick={submitForm}
            >
              SAVE
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddAuction;
