export enum LDFlags {
  DD_SIGNING_USER = 'dd-signing-user',
  AUTO_ASSIGNING_DEALS = 'auto-assigning-deals',
  MULTIPLE_CREDIT_APPS = 'multiple-credit-apps',
  PREQUAL_AUTOSTRUCTURE = 'prequal-smart-autostructure',
  COM_DASHBOARD_DOWN_PAYMENT = 'com-dashboard-down-payment',
  IGNORE_TT_VEHICLE_MILEAGE_ERROR = 'ignore-tt-vehicle-mileage-error',
  BREAK_OUT_CREDIT_AND_DEBIT_CARDS = 'break-out-credit-and-debit-cards',
}

export type FlagOverrides = {
  [key in LDFlags]?: unknown;
};
