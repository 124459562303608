import { Checkbox, Flex, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { AiOutlineFileText } from 'react-icons/ai';

const CreditDecisionSkeleton = () => {
  return (
    <VStack rounded="md" borderWidth={1} borderStyle="solid" borderColor="queenBlue">
      <VStack w="full" p={2} spacing={2}>
        <VStack w="full" spacing={0.5}>
          <HStack w="full" justifyContent="space-between">
            <Skeleton h={6} w="66%" />
            <Skeleton h={6} w="20%" />
          </HStack>
          <HStack w="full" alignItems="center" justifyContent="space-between" spacing={2}>
            <HStack w="full" spacing={2}>
              <Text fontSize="xs">Bank App #:</Text>
              <Skeleton h={5} w="40%" />
              <AiOutlineFileText size={20} />
            </HStack>
          </HStack>
        </VStack>
        <Flex w="full" justifyContent="flex-start">
          <Skeleton h={8} w="50%" />
        </Flex>
        <VStack w="full" alignItems="flex-start">
          <HStack w="full">
            <HStack w="full" spacing={1}>
              <Text fontSize="sm" fontWeight="normal">
                Buy Rate:
              </Text>
              <Skeleton h={4} w="40%" />
            </HStack>
            <HStack w="full" spacing={1}>
              <Text fontSize="sm" fontWeight="normal">
                Term:
              </Text>
              <Skeleton h={4} w="40%" />
            </HStack>
          </HStack>
          <HStack w="full">
            <HStack w="full" spacing={1}>
              <Text fontSize="sm" fontWeight="normal">
                Submitted:
              </Text>
              <Skeleton h={4} w="40%" />
            </HStack>
            <HStack w="full">
              <Text fontSize="sm" fontWeight="normal">
                Expires:
              </Text>
              <Skeleton h={4} w="40%" />
            </HStack>
          </HStack>
        </VStack>
      </VStack>
      <Checkbox bg="queenBlue" color="white" w="full" p={2} isDisabled>
        SELECT
      </Checkbox>
    </VStack>
  );
};

export default CreditDecisionSkeleton;
