import {
  TextareaProps as ChakraTextAreaProps,
  Textarea as ChakraTextarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightAddon,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

type TextareaProps = {
  label: string;
  name: string;
  rightAddon?: string;
  formControlVariant?: 'inline' | undefined;
} & ChakraTextAreaProps;

const TextArea = ({ label, name, rightAddon, formControlVariant, ...rest }: TextareaProps) => {
  const { handleBlur, handleChange, getFieldMeta, setFieldTouched, isSubmitting } =
    useFormikContext();
  const { value, error, touched } = getFieldMeta(name);
  const styles = useMultiStyleConfig('FormControl', { variant: formControlVariant });

  // Workaround for dynamic form fields.
  if (isSubmitting && !touched) {
    setFieldTouched(name, true, false);
  }

  return (
    <FormControl isInvalid={touched && !!error} sx={styles.control}>
      <FormLabel sx={styles.label}>{label}</FormLabel>
      <InputGroup sx={styles.input}>
        <ChakraTextarea
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value as string}
          as="textarea"
          {...rest}
        />
        {rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
      </InputGroup>
      <FormErrorMessage sx={styles.error}>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default TextArea;
