import { Dispatch, SetStateAction } from 'react';

import { parseISO } from 'date-fns';

import { DealStateEnum, TITLING_STATES } from '../../gql/dealGql';
import { SortDirection } from '../../gql/generated/graphql';

import { snakeCaseToUpperCase } from '../../utils/text';

export enum FiltersActionKind {
  SEARCH = 'SEARCH',
  SET_ASSIGNEES = 'SET_ASSIGNEES',
  SET_BANKS = 'SET_BANKS',
  SET_CLOSED_FROM_DATE = 'SET_CLOSED_FROM_DATE',
  SET_CLOSED_TO_DATE = 'SET_CLOSED_TO_DATE',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_DEAL_STATE = 'SET_DEAL_STATE',
  SET_DIRECTION = 'SET_DIRECTION',
  SET_FIELD = 'SET_FIELD',
  SET_FILTERS = 'SET_FILTERS',
  SET_FROM_DATE = 'SET_FROM_DATE',
  SET_HAS_TITLE_RECEIVED = 'SET_HAS_TITLE_RECEIVED',
  SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_PROBLEM_DEALS = 'SET_PROBLEM_DEALS',
  SET_TITLE_FROM_DATE = 'SET_TITLE_FROM_DATE',
  SET_TITLE_TO_DATE = 'SET_TITLE_TO_DATE',
  SET_TITLING_PODS = 'SET_TITLING_PODS',
  SET_TO_DATE = 'SET_TO_DATE',
  SET_USER_CHANGED_FILTERS = 'SET_USER_CHANGED_FILTERS',
  SET_US_STATE = 'SET_US_STATE',
  SET_TYPE = 'SET_TYPE',
  SET_VINS = 'SET_VINS',
  SET_LIENHOLDERS = 'SET_LIENHOLDERS',
  SET_PROCESSORS = 'SET_PROCESSORS',
  CLEAR_TITLING_FILTERS = 'CLEAR_TITLING_FILTERS',
}

export type FiltersState = {
  dealStates: string[] | undefined;
  usStates: string[] | undefined;
  type: string | undefined;
  banks: string[] | undefined;
  notifications: boolean;
  problemDeals: boolean;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  closedDateFrom: Date | undefined;
  closedDateTo: Date | undefined;
  titleDateFrom: Date | undefined;
  titleDateTo: Date | undefined;
  titlingPods: string[] | undefined;
  assignees: string[] | undefined;
  hasTitleReceived: boolean | undefined;
  search: string | undefined;
  currentPage: number;
  itemsPerPage: number;
  field: string;
  direction: SortDirection;
  userChangedFilters: boolean | undefined;
  vins: string[] | undefined;
  lienholders: string[] | undefined;
  processors: string[] | undefined;
};

export const getFiltersStates = (searchParams?: {
  [K in keyof FiltersState]?: string;
}): FiltersState => ({
  dealStates: searchParams?.dealStates
    ? searchParams.dealStates.split(',')
    : TITLING_STATES.slice(0, -1),
  usStates: searchParams?.usStates ? searchParams.usStates.split(',') : undefined,
  type: searchParams?.type ?? undefined,
  notifications: searchParams?.notifications === 'true',
  problemDeals: searchParams?.problemDeals === 'true',
  dateFrom: searchParams?.dateFrom ? parseISO(searchParams.dateFrom) : undefined,
  dateTo: searchParams?.dateTo ? parseISO(searchParams.dateTo) : undefined,
  closedDateFrom: searchParams?.closedDateFrom ? parseISO(searchParams.closedDateFrom) : undefined,
  closedDateTo: searchParams?.closedDateTo ? parseISO(searchParams.closedDateTo) : undefined,
  titleDateFrom: searchParams?.titleDateFrom ? parseISO(searchParams.titleDateFrom) : undefined,
  titleDateTo: searchParams?.titleDateTo ? parseISO(searchParams.titleDateTo) : undefined,
  titlingPods: searchParams?.titlingPods ? searchParams.titlingPods.split(',') : undefined,
  assignees: searchParams?.assignees ? searchParams.assignees.split(',') : undefined,
  hasTitleReceived: searchParams?.hasTitleReceived
    ? searchParams.hasTitleReceived === 'true'
    : undefined,
  search: searchParams?.search ?? undefined,
  currentPage: searchParams?.currentPage ? +searchParams?.currentPage : 0,
  itemsPerPage: searchParams?.itemsPerPage ? +searchParams?.itemsPerPage : 25,
  field: searchParams?.field ? searchParams.field : 'deal_dates.custom_dates.signed',
  direction: searchParams?.direction
    ? (searchParams.direction as SortDirection)
    : SortDirection.Asc,
  userChangedFilters: searchParams?.userChangedFilters === 'true',
  banks: searchParams?.banks ? searchParams.banks.split(',') : undefined,
  vins: searchParams?.vins ? searchParams.vins.split(',') : undefined,
  lienholders: searchParams?.processors ? searchParams.processors.split(',') : undefined,
  processors: searchParams?.processors ? searchParams.processors.split(',') : undefined,
});

export type StringArrayPayload = {
  type:
    | FiltersActionKind.SET_TITLING_PODS
    | FiltersActionKind.SET_ASSIGNEES
    | FiltersActionKind.SET_DEAL_STATE
    | FiltersActionKind.SET_US_STATE
    | FiltersActionKind.SET_BANKS
    | FiltersActionKind.SET_VINS
    | FiltersActionKind.SET_PROCESSORS
    | FiltersActionKind.SET_LIENHOLDERS;
  payload: string[];
};

export type StringPayload = {
  type:
    | FiltersActionKind.SET_HAS_TITLE_RECEIVED
    | FiltersActionKind.SEARCH
    | FiltersActionKind.SET_FIELD
    | FiltersActionKind.SET_TYPE;
  payload: string;
};

type BooleanPayload = {
  type:
    | FiltersActionKind.SET_NOTIFICATIONS
    | FiltersActionKind.SET_PROBLEM_DEALS
    | FiltersActionKind.SET_USER_CHANGED_FILTERS;
  payload: boolean;
};

type DatePayload = {
  type:
    | FiltersActionKind.SET_FROM_DATE
    | FiltersActionKind.SET_TO_DATE
    | FiltersActionKind.SET_CLOSED_FROM_DATE
    | FiltersActionKind.SET_CLOSED_TO_DATE
    | FiltersActionKind.SET_TITLE_FROM_DATE
    | FiltersActionKind.SET_TITLE_TO_DATE;
  payload: Date | undefined;
};

type NumberPayload = {
  type: FiltersActionKind.SET_ITEMS_PER_PAGE | FiltersActionKind.SET_CURRENT_PAGE;
  payload: number;
};

type SortingDirectionPayload = {
  type: FiltersActionKind.SET_DIRECTION;
  payload: SortDirection;
};

type ClearFiltersPayload = {
  type: FiltersActionKind.CLEAR_TITLING_FILTERS;
  payload: undefined;
};

export type FiltersAction =
  | NumberPayload
  | StringPayload
  | StringArrayPayload
  | BooleanPayload
  | DatePayload
  | SortingDirectionPayload
  | ClearFiltersPayload;

const clearDealStatesPayload = (states: string[]) =>
  states
    .concat()
    .filter((state) =>
      Object.keys(DealStateEnum).includes(
        snakeCaseToUpperCase(state.toLowerCase()).replace(/\s+/g, ''),
      ),
    );

export const filtersReducer = (state: FiltersState, action: FiltersAction): FiltersState => {
  const { type, payload } = action;

  switch (type) {
    case FiltersActionKind.SET_DEAL_STATE:
      return {
        ...state,
        dealStates: clearDealStatesPayload(payload),
      };
    case FiltersActionKind.SET_US_STATE:
      return { ...state, usStates: payload };
    case FiltersActionKind.SET_TYPE:
      return { ...state, type: payload };
    case FiltersActionKind.SET_NOTIFICATIONS:
      return { ...state, notifications: payload };
    case FiltersActionKind.SET_PROBLEM_DEALS:
      return { ...state, problemDeals: payload };
    case FiltersActionKind.SET_FROM_DATE:
      return { ...state, dateFrom: payload };
    case FiltersActionKind.SET_TO_DATE:
      return { ...state, dateTo: payload };
    case FiltersActionKind.SET_CLOSED_FROM_DATE:
      return { ...state, closedDateFrom: payload };
    case FiltersActionKind.SET_CLOSED_TO_DATE:
      return { ...state, closedDateTo: payload };
    case FiltersActionKind.SET_TITLE_FROM_DATE:
      return { ...state, titleDateFrom: payload };
    case FiltersActionKind.SET_TITLE_TO_DATE:
      return { ...state, titleDateTo: payload };
    case FiltersActionKind.SET_TITLING_PODS:
      return { ...state, titlingPods: payload };
    case FiltersActionKind.SET_ASSIGNEES:
      return { ...state, assignees: payload };
    case FiltersActionKind.SET_HAS_TITLE_RECEIVED:
      return { ...state, hasTitleReceived: payload !== '' ? payload === 'true' : undefined };
    case FiltersActionKind.SEARCH:
      return { ...state, search: payload };
    case FiltersActionKind.SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: payload };
    case FiltersActionKind.SET_CURRENT_PAGE:
      return { ...state, currentPage: payload };
    case FiltersActionKind.SET_FIELD:
      return { ...state, field: payload };
    case FiltersActionKind.SET_DIRECTION:
      return { ...state, direction: payload };
    case FiltersActionKind.SET_USER_CHANGED_FILTERS:
      return { ...state, userChangedFilters: payload };
    case FiltersActionKind.SET_BANKS:
      return { ...state, banks: payload };
    case FiltersActionKind.SET_VINS:
      return { ...state, vins: payload };
    case FiltersActionKind.SET_LIENHOLDERS:
      return { ...state, lienholders: payload };
    case FiltersActionKind.SET_PROCESSORS:
      return { ...state, processors: payload };
    case FiltersActionKind.CLEAR_TITLING_FILTERS:
      return getFiltersStates();
    default:
      return state;
  }
};

export enum SortingActionKind {
  SET_FIELD = 'SET_FIELD',
  SET_DIRECTION = 'SET_DIRECTION',
}

export type SortingState = {
  field: string | undefined;
  direction: SortDirection;
};

export type SortingStringPayload = {
  type: SortingActionKind.SET_FIELD;
  payload: string;
};

type DirectionPayload = {
  type: SortingActionKind.SET_DIRECTION;
  payload: SortDirection.Asc | SortDirection.Desc;
};

export type SortingAction = SortingStringPayload | DirectionPayload;

export const sortingReducer = (state: SortingState, action: SortingAction): SortingState => {
  const { type, payload } = action;

  switch (type) {
    case SortingActionKind.SET_FIELD:
      return { ...state, field: payload, direction: SortDirection.Asc };
    case SortingActionKind.SET_DIRECTION:
      return { ...state, direction: payload };
    default:
      return state;
  }
};

export const isSortingAsc = (direction: SortDirection) => direction === SortDirection.Asc;

export enum FilterOptionsEnum {
  SIGNED_DATE = 'Signed Date',
  US_STATE = 'U.S. State',
  TYPE = 'Type',
  BANK = 'Bank',
  DEAL_AGE = 'Deal Age',
  TITLE_RECEIVED = 'Title Received',
  PROBLEM_DEALS = 'Problem Deals',
  PAID_OFF_DATE = 'Paid Off Date',
  TITLE_RECEIVED_DATE = 'Title Received Date',
  VINS = 'VIN',
  LIENHOLDER = ' Lienholder',
  PROCESSOR = 'Processor',
}

export interface FilterOptionsType {
  label: FilterOptionsEnum;
  value: string;
  toValue?: string;
  actionKind: FiltersActionKind[];
}

export const filterOptions: FilterOptionsType[] = [
  {
    label: FilterOptionsEnum.SIGNED_DATE,
    value: 'dateFrom',
    toValue: 'dateTo',
    actionKind: [FiltersActionKind.SET_FROM_DATE, FiltersActionKind.SET_TO_DATE],
  },
  {
    label: FilterOptionsEnum.PAID_OFF_DATE,
    value: 'closedDateFrom',
    toValue: 'closedDateTo',
    actionKind: [FiltersActionKind.SET_CLOSED_FROM_DATE, FiltersActionKind.SET_CLOSED_TO_DATE],
  },
  {
    label: FilterOptionsEnum.TITLE_RECEIVED_DATE,
    value: 'titleDateFrom',
    toValue: 'titleDateTo',
    actionKind: [FiltersActionKind.SET_TITLE_FROM_DATE, FiltersActionKind.SET_TITLE_TO_DATE],
  },
  {
    label: FilterOptionsEnum.US_STATE,
    value: 'usStates',
    actionKind: [FiltersActionKind.SET_US_STATE],
  },
  {
    label: FilterOptionsEnum.TYPE,
    value: 'type',
    actionKind: [FiltersActionKind.SET_TYPE],
  },
  { label: FilterOptionsEnum.BANK, value: 'banks', actionKind: [FiltersActionKind.SET_BANKS] },
  { label: FilterOptionsEnum.VINS, value: 'vins', actionKind: [FiltersActionKind.SET_VINS] },
  {
    label: FilterOptionsEnum.DEAL_AGE,
    value: 'dateFrom',
    toValue: 'dateTo',
    actionKind: [FiltersActionKind.SET_FROM_DATE, FiltersActionKind.SET_TO_DATE],
  },
  {
    label: FilterOptionsEnum.TITLE_RECEIVED,
    value: 'hasTitleReceived',
    actionKind: [FiltersActionKind.SET_HAS_TITLE_RECEIVED],
  },
  {
    label: FilterOptionsEnum.PROBLEM_DEALS,
    value: 'problemDeals',
    actionKind: [FiltersActionKind.SET_PROBLEM_DEALS],
  },
  {
    label: FilterOptionsEnum.LIENHOLDER,
    value: 'lienholder',
    actionKind: [FiltersActionKind.SET_LIENHOLDERS],
  },
  {
    label: FilterOptionsEnum.PROCESSOR,
    value: 'processors',
    actionKind: [FiltersActionKind.SET_PROCESSORS],
  },
];

export const handleClearFilter = (
  title: FilterOptionsType,
  actionKind: FiltersActionKind[],
  dispatch: Dispatch<FiltersAction>,
  selectedFilters: FilterOptionsEnum[],
  setSelectedFilters: Dispatch<SetStateAction<FilterOptionsEnum[]>>,
) => {
  actionKind.forEach((ak) => {
    if (
      ak === FiltersActionKind.SET_CLOSED_FROM_DATE ||
      ak === FiltersActionKind.SET_CLOSED_TO_DATE ||
      ak === FiltersActionKind.SET_TITLE_FROM_DATE ||
      ak === FiltersActionKind.SET_TITLE_TO_DATE ||
      ak === FiltersActionKind.SET_FROM_DATE ||
      ak === FiltersActionKind.SET_TO_DATE
    ) {
      dispatch({ type: ak, payload: undefined });
    }
    if (
      ak === FiltersActionKind.SET_BANKS ||
      ak === FiltersActionKind.SET_US_STATE ||
      ak === FiltersActionKind.SET_VINS ||
      ak === FiltersActionKind.SET_PROCESSORS
    ) {
      dispatch({ type: ak, payload: [] });
    }
    if (ak === FiltersActionKind.SET_PROBLEM_DEALS) {
      dispatch({ type: ak, payload: false });
    }
    if (ak === FiltersActionKind.SET_HAS_TITLE_RECEIVED || ak === FiltersActionKind.SET_TYPE) {
      dispatch({ type: ak, payload: '' });
    }
  });
  setSelectedFilters([...selectedFilters].filter((f) => f !== title.label));
};
