import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import { Box, Flex, useMultiStyleConfig } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { DealMediaTypeEnum, Maybe } from '../../../../gql/generated/graphql';

import Autocomplete from '../../../shared/Autocomplete';
import DatePicker from '../../../shared/DatePicker';
import Input from '../../../shared/Input';
import { MediaModalType } from '../MediaModal';
import UploadPreview from '../UploadPreview';

import { DealMediaTypeDateFieldMap, DealMediaTypeOptions } from '../../../../constants/media';
import { DealMediaEdit } from '../../../../types/media';

type MediaUploadFormProps = {
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  index: number;
  remove?: (index: number) => void;
  variant?: 'reduced' | undefined;
  initialMediaType?: Maybe<DealMediaTypeEnum>;
};

const MediaUploadForm = ({
  showModal,
  setShowModal,
  index,
  remove,
  variant,
  initialMediaType,
}: MediaUploadFormProps) => {
  const { handleChange, values } = useFormikContext<{ dealMedia: DealMediaEdit[] }>();
  const { dealMedia } = values;

  const styles = useMultiStyleConfig('MediaForm', { variant });
  const formControlVariant = variant === 'reduced' ? 'reduced' : 'inline';

  const isNewMedia = !dealMedia[index]?.id;
  const previewUrl = isNewMedia
    ? URL.createObjectURL(dealMedia[index]?.selectedFile ?? new Blob())
    : dealMedia[index]?.signed_url ?? '';

  const mediaTypeDateField = useMemo(
    () => (dealMedia[index]?.type ? DealMediaTypeDateFieldMap[dealMedia[index].type] : undefined),
    [dealMedia[index]?.type],
  );
  const { field: dateField, label: dateLabel } = mediaTypeDateField ?? {};

  useEffect(() => {
    if (
      dealMedia[index]?.type ||
      dealMedia[index]?.fileName ||
      dealMedia[index]?.extension ||
      (dateField && dealMedia[index]?.[dateField])
    ) {
      setShowModal({
        ...showModal,
        dealMedia: {
          ...showModal.dealMedia,
          extension: dealMedia[index]?.extension,
          type: dealMedia[index]?.type,
          fileName: dealMedia[index]?.fileName,
          ...(dateField
            ? {
                [dateField]: dealMedia[index]?.[dateField],
              }
            : {}),
        },
      });
    }
  }, [
    dealMedia[index]?.type,
    dealMedia[index]?.fileName,
    dealMedia[index]?.extension,
    dateField ? dealMedia[index]?.[dateField] : undefined,
  ]);

  return (
    <Flex __css={styles.flexContainer}>
      <Box __css={styles.previewContainer}>
        <UploadPreview
          index={index}
          url={previewUrl}
          sourceFileName={dealMedia[index]?.sourceFileName ?? ''}
          remove={remove}
        />
      </Box>
      <Flex __css={styles.inputsContainer}>
        <Input
          formControlVariant={formControlVariant}
          label="File Name"
          name={`dealMedia[${index}].fileName`}
          rightAddon={dealMedia[index]?.extension ? `.${dealMedia[index]?.extension}` : ''}
          onChange={handleChange}
        />
        <Autocomplete
          formControlVariant={formControlVariant}
          label="What is it?"
          name={`dealMedia[${index}].type`}
          options={DealMediaTypeOptions}
          defaultValue={initialMediaType ?? undefined}
          additionalOnChange={(newValue) => {
            setShowModal({
              ...showModal,
              dealMedia: {
                ...showModal.dealMedia,
                verified: false,
                type: newValue?.value as DealMediaTypeEnum,
              },
            });
          }}
        />
        {dateField ? (
          <DatePicker
            key={dateField}
            leftLabel={dateLabel}
            labelStyles={{
              whiteSpace: 'break-spaces',
            }}
            formControlVariant={formControlVariant}
            name={`dealMedia[${index}].${dateField}`}
          />
        ) : null}
      </Flex>
    </Flex>
  );
};

export default MediaUploadForm;
