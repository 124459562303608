import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import { Box, Icon, IconButton } from '@chakra-ui/react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { Customer, CustomerContactTypeEnum } from '../../../gql/customerGql';
import { Deal } from '../../../gql/dealGql';
import { Notification, useNotificationsSeenMutation } from '../../../gql/generated/graphql';
import { notificationMessageMap } from '../../../gql/notificationsGql';

import ContactBox from './ContactBox';

import { logger } from '../../../libs/Logger';
import { TextWidgetContext } from '../../../libs/contextLib';

type Props = {
  showContacts: boolean;
  setShowContacts: Dispatch<SetStateAction<boolean>>;
  deal: Deal | null;
  setRecipient: Dispatch<SetStateAction<Customer>>;
};

const ContactPanel = ({ showContacts, setShowContacts, deal, setRecipient }: Props) => {
  const { activeConversation, setActiveConversation, unreadNotifications, setUnreadNotifications } =
    useContext(TextWidgetContext);
  const [notificationsSeen] = useNotificationsSeenMutation();

  const onSetActiveConversation = (role: CustomerContactTypeEnum) => {
    switch (role) {
      case CustomerContactTypeEnum.Customer:
        setRecipient(deal?.customer as Customer);
        setActiveConversation({
          role: CustomerContactTypeEnum.Customer,
          phone_number: deal?.customer?.phone_number ?? '',
          first_name: deal?.customer?.first_name ?? '',
          last_name: deal?.customer?.last_name ?? '',
        });
        break;
      case CustomerContactTypeEnum.Cobuyer:
        setRecipient(deal?.cobuyer as Customer);
        setActiveConversation({
          role: CustomerContactTypeEnum.Cobuyer,
          phone_number: deal?.cobuyer?.phone_number ?? '',
          first_name: deal?.cobuyer?.first_name ?? '',
          last_name: deal?.customer?.last_name ?? '',
        });
        break;
      case CustomerContactTypeEnum.Contact:
        setRecipient(deal?.contact as Customer);
        setActiveConversation({
          role: CustomerContactTypeEnum.Contact,
          phone_number: deal?.contact?.phone_number ?? '',
          first_name: deal?.contact?.first_name ?? '',
          last_name: deal?.contact?.last_name ?? '',
        });
        break;
      case CustomerContactTypeEnum.Second:
        setRecipient(deal?.second_contact as Customer);
        setActiveConversation({
          role: CustomerContactTypeEnum.Second,
          phone_number: deal?.second_contact?.phone_number ?? '',
          first_name: deal?.second_contact?.first_name ?? '',
          last_name: deal?.second_contact?.last_name ?? '',
        });
        break;
      default:
        break;
    }
  };
  const cobuyerCheck =
    deal?.cobuyer && deal.cobuyer.first_name && deal.cobuyer.last_name && deal.cobuyer.phone_number;
  const contactCheck =
    deal?.contact && deal.contact.first_name && deal.contact.last_name && deal.contact.phone_number;
  const secondContactCheck =
    deal?.second_contact &&
    deal.second_contact.first_name &&
    deal.second_contact.last_name &&
    deal.second_contact.phone_number;

  const handleNotificationsSeen = async (
    newUnreadNotifications: Notification[],
    dealId?: number,
    customerContactId?: number,
  ) => {
    try {
      if (dealId && newUnreadNotifications.length) {
        const { data } = await notificationsSeen({
          variables: {
            notificationIds: newUnreadNotifications.map((n) => n.id),
            dealId,
          },
        });
        if (data?.notificationsSeen) {
          if (customerContactId) {
            setUnreadNotifications(
              unreadNotifications.filter((n) => n.customer_id !== customerContactId),
            );
            return;
          }

          setUnreadNotifications(
            unreadNotifications.filter((n) => !newUnreadNotifications.includes(n)),
          );
        }
      }
    } catch (e) {
      logger.error('ContactPanel.tsx', 'notificationsSeen failed', null, e);
    }
  };

  const setActiveConversationAndCallNotificationsSeen = (
    customerContactType: CustomerContactTypeEnum,
    id?: number,
    dealId?: number,
  ) => {
    onSetActiveConversation(customerContactType);
    const filteredNotifications = unreadNotifications.filter((n) =>
      customerContactType === CustomerContactTypeEnum.Customer ||
      customerContactType === CustomerContactTypeEnum.Cobuyer
        ? n.customer_id === id
        : n.deal_contact_id === id,
    );

    handleNotificationsSeen(filteredNotifications, dealId, id);
  };

  useEffect(() => {
    if (deal?.notifications?.length) {
      setUnreadNotifications(
        deal.notifications.filter(
          (n) => n.message === notificationMessageMap.MESSAGE_RECEIVED && n.seen === false,
        ) as Notification[],
      );
    }
  }, [deal?.notifications]);

  // This useEffect is used to call notificationsSeen for the default activeConversation because we want to clear the notifications if the user is assigned to the deal
  useEffect(() => {
    if (deal && unreadNotifications.length) {
      const newUnreadNotifications = unreadNotifications.filter((n) => {
        if (!n.deal_contact_id && !n.customer_id) {
          return true;
        }
        if (activeConversation.role === CustomerContactTypeEnum.Customer) {
          return n.customer_id === deal.customer.id;
        }
        if (activeConversation.role === CustomerContactTypeEnum.Cobuyer) {
          return n.customer_id === deal.cobuyer?.id;
        }
        if (activeConversation.role === CustomerContactTypeEnum.Contact) {
          return n.deal_contact_id === deal.contact?.id;
        }
        if (activeConversation.role === CustomerContactTypeEnum.Second) {
          return n.deal_contact_id === deal.second_contact?.id;
        }
        return false;
      });

      handleNotificationsSeen(newUnreadNotifications, deal.id);
    }
  }, [unreadNotifications]);

  return (
    <Box
      borderRight="2px solid"
      borderColor="gray.100"
      w={showContacts ? '35%' : '60px'}
      minH="100%"
    >
      <Box display="flex" justifyContent="right">
        <IconButton
          variant="iconHover"
          aria-label="hide-contact"
          icon={<Icon as={showContacts ? MdChevronLeft : MdChevronRight} boxSize={6} />}
          onClick={() => setShowContacts(!showContacts)}
        />
      </Box>
      {deal?.customer && (
        <ContactBox
          customer={deal.customer as Customer}
          customerType={CustomerContactTypeEnum.Customer}
          handleClick={() =>
            setActiveConversationAndCallNotificationsSeen(
              CustomerContactTypeEnum.Customer,
              deal.customer.id,
              deal.id,
            )
          }
          isActive={activeConversation.role === CustomerContactTypeEnum.Customer}
          nameShown={showContacts}
          unreadCount={unreadNotifications.filter((n) => n.customer_id === deal.customer.id).length}
        />
      )}
      {cobuyerCheck && (
        <ContactBox
          customer={deal.cobuyer as Customer}
          customerType={CustomerContactTypeEnum.Cobuyer}
          handleClick={() =>
            setActiveConversationAndCallNotificationsSeen(
              CustomerContactTypeEnum.Cobuyer,
              deal.cobuyer?.id,
              deal.id,
            )
          }
          isActive={activeConversation.role === CustomerContactTypeEnum.Cobuyer}
          nameShown={showContacts}
          unreadCount={unreadNotifications.filter((n) => n.customer_id === deal.cobuyer?.id).length}
        />
      )}
      {contactCheck && (
        <ContactBox
          customer={deal.contact as Customer}
          customerType={CustomerContactTypeEnum.Contact}
          handleClick={() =>
            setActiveConversationAndCallNotificationsSeen(
              CustomerContactTypeEnum.Contact,
              deal.contact?.id,
              deal.id,
            )
          }
          isActive={activeConversation.role === CustomerContactTypeEnum.Contact}
          nameShown={showContacts}
          unreadCount={
            unreadNotifications.filter((n) => n.deal_contact_id === deal.contact?.id).length
          }
        />
      )}
      {secondContactCheck && (
        <ContactBox
          customer={deal.second_contact as Customer}
          customerType={CustomerContactTypeEnum.Second}
          handleClick={() =>
            setActiveConversationAndCallNotificationsSeen(
              CustomerContactTypeEnum.Second,
              deal.second_contact?.id,
              deal.id,
            )
          }
          isActive={activeConversation.role === CustomerContactTypeEnum.Second}
          nameShown={showContacts}
          unreadCount={
            unreadNotifications.filter((n) => n.deal_contact_id === deal.second_contact?.id).length
          }
        />
      )}
    </Box>
  );
};

export default ContactPanel;
