import { Box, Button, Flex } from '@chakra-ui/react';
import { Form, useFormikContext } from 'formik';

import { Deal } from '../../gql/dealGql';

import { AddressForm } from '../CreditApplication/components/AddressForm';
import { PersonalInformationForm } from '../CreditApplication/components/PersonalInformationForm';
import FollowUpForm from '../FollowUp/FollowUpForm';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import CardSubHeaderV2 from '../shared/Card/components/CardSubHeaderV2';
import { GridFormColumn } from '../shared/GridForm';

const NewEstimateWithFollowUpForm = () => {
  const { handleSubmit, isValid, touched } = useFormikContext<Deal>();

  const canSchedule = Object.keys(touched).length !== 0 && isValid;

  return (
    <Form onSubmit={handleSubmit}>
      <Box bgColor="white" pb={4} borderBottomRadius="10px" mb={4}>
        <CardHeaderV2 title="Customer Info" />
        <Box pb={5}>
          <PersonalInformationForm
            name="customer"
            isUneditable={false}
            hideBuyerNotLessee
            inScheduleFollowUpForm
          />
          <AddressForm name="customer" objectName="address" isUneditable={false} />
        </Box>
        <CardSubHeaderV2 title="Schedule Follow Up" />
        <GridFormColumn>
          <FollowUpForm setTypeAvailable={false} needsNamePrefix />
          <Flex flex={1} justifyContent="end">
            <Button type="submit" mt={3} isDisabled={!canSchedule}>
              Schedule Follow Up
            </Button>
          </Flex>
        </GridFormColumn>
      </Box>
    </Form>
  );
};

export default NewEstimateWithFollowUpForm;
