import { MouseEvent, useContext, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useBreakpointValue } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import { dealsWithNotificationQuery } from '../../gql/dealGql';
import { DealsWithNotification } from '../../gql/generated/graphql';

import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import { getDefaultView, getNavBarButtons } from './utils';

import ROUTES from '../../constants/routes';
import { FiltersActionKind } from '../../globalFiltersUtils';
import {
  getSearchParams,
  getSearchString,
} from '../../hooks/useUrlQueryParamsWithMultipleReducers';
import { AbilityContext, FiltersContext } from '../../libs/contextLib';
import { handleClickOrCommandClick } from '../../libs/eventHandlers';
import { CurrentView } from '../../pages/Dashboard';

export type NavBarButton = {
  label: string;
  shouldRender: boolean;
  page: string;
  menuOnly?: boolean;
  view?: CurrentView;
  messageNotifications?: number;
  notMessageNotifications?: number;
  search?: string;
};

export type NavBarProps = {
  buttons: NavBarButton[];
  currentPage: string;
  currentView: CurrentView | undefined; // Always want it in props, but it's not always defined
  defaultView: CurrentView;
  isAuthenticated: boolean;
  handleNavBarButtonClick: (
    e: MouseEvent,
    { page, view, search }: Pick<NavBarButton, 'page' | 'view' | 'search'>,
  ) => void;
};
const Navbar = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const abilities = useContext(AbilityContext);
  const defaultView = useMemo(() => getDefaultView(abilities), [abilities]);
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const location = useLocation();
  const {
    filters: {
      global: { sources, types, selectedPodId, currentView },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const { data } = useQuery<{ dealsWithNotification: DealsWithNotification }>(
    dealsWithNotificationQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        sources,
        types,
        pod_id: selectedPodId,
      },
    },
  );

  const handleNavBarButtonClick = (
    e: MouseEvent,
    { page, view, search }: Pick<NavBarButton, 'page' | 'view' | 'search'>,
  ) => {
    const currentSearchParams = getSearchParams(location.search);
    const newSearchString = getSearchString({
      ...currentSearchParams,
      currentView: view || currentSearchParams.currentView,
    });

    const pageWithSearch = history.createHref({
      pathname: page,
      search: newSearchString,
    });

    handleClickOrCommandClick(e, isAuthenticated ? pageWithSearch : ROUTES.HOME, undefined, () => {
      if (view) {
        dispatchFilters({
          type: FiltersActionKind.SET_CURRENT_VIEW,
          payload: view,
        });
      }

      if (history.location.pathname !== page) {
        history.push({ pathname: page, search });
      }
    });
  };

  // Sets the default view on first render
  useEffect(() => {
    dispatchFilters({
      type: FiltersActionKind.SET_CURRENT_VIEW,
      payload: currentView ?? defaultView,
    });
  }, []);

  const buttons = useMemo(
    () => getNavBarButtons(abilities, data?.dealsWithNotification),
    [abilities, data?.dealsWithNotification],
  );
  const props = {
    buttons,
    currentPage: location.pathname,
    currentView,
    defaultView,
    isAuthenticated,
    handleNavBarButtonClick,
  };

  if (isMobile) {
    return <MobileNavbar {...props} />;
  }
  return <DesktopNavbar {...props} />;
};

export default Navbar;
