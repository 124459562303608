import { useLazyQuery } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../gql/dealGql';
import { mmrQuery } from '../../gql/mmr';

import Tooltip from '../shared/Tooltip';

const MmrButton = () => {
  const { values, setFieldValue, errors } = useFormikContext<Deal>();
  const { car, condition_report, customer } = values;

  const disabled = !car.vin || !car.mileage || !car.color || !condition_report?.score;

  const [getMmr, { loading }] = useLazyQuery(mmrQuery, {
    onCompleted: (data) => {
      if (data?.mmr.average) {
        setFieldValue('car.mmr', data.mmr.average);
      } else {
        setFieldValue('car.mmr', (car?.book_value ?? 0) * 0.9);
      }
    },
  });

  return (
    <Tooltip errors={errors}>
      <Button
        isLoading={loading}
        loadingText="CALCULATE MMR"
        isDisabled={disabled}
        onClick={() => {
          getMmr({
            variables: {
              vin: car.vin,
              mileage: car.mileage,
              color: car.color,
              grade: condition_report?.score,
              trim: car.kbb_trim_name,
              state: customer.address?.state,
            },
          });
        }}
      >
        CALCULATE MMR
      </Button>
    </Tooltip>
  );
};

export default MmrButton;
