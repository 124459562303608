import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import { Deal, DealStateEnum, duplicateCheckQuery } from '../../gql/dealGql';
import {
  DealType,
  NewEstimateCustomerInput,
  useNewEstimateUpsertMutation,
} from '../../gql/generated/graphql';

import LeaseEndContainer from '../../components/Container/LEContainer';
import EstimateForm from '../../components/EstimateForm';

import { getPreFilledEstimate } from './utils';

import ROUTES from '../../constants/routes';
import useGenerateBookSheets from '../../hooks/useGenerateBookSheets';
import { useUser } from '../../hooks/useUser';
import { logger } from '../../libs/Logger';
import { getTimezoneStr } from '../../libs/utils';
import { cleanDealForUpdate } from '../../utils/deals';

const NewEstimate = () => {
  const location = useLocation();
  const preFilledEstimate = getPreFilledEstimate(location);

  const [creationDate] = useState<Date>(new Date());
  const { id } = useUser();
  const [estimate, setEstimate] = useState<Deal>(preFilledEstimate);
  const [setHandleSubmitting, setSetHandleSubmitting] = useState<(isSubmitting: boolean) => void>(
    (_: boolean) => _,
  );
  const [duplicateDeals, setDuplicateDeals] = useState<Deal[]>([]);
  const {
    isOpen: isDuplicateModalOpen,
    onOpen: onOpenDuplicateModal,
    onClose: onCloseDuplicateModal,
  } = useDisclosure();

  const [upsertEstimate, { loading: upsertEstimateLoading }] = useNewEstimateUpsertMutation();
  const { generateBookSheets } = useGenerateBookSheets();

  const [checkDuplicates, { data: duplicateData, error: duplicateError }] = useLazyQuery(
    duplicateCheckQuery,
    { fetchPolicy: 'network-only' },
  );

  const history = useHistory();
  if (location.search) {
    const removeQueryParamsFromUrl = () => history.replace(ROUTES.NEW_ESTIMATE);
    removeQueryParamsFromUrl();
  }

  const submitEstimate = async () => {
    const newDeal = cleanDealForUpdate(estimate);

    const partialCustomer: NewEstimateCustomerInput = {
      address: newDeal.customer.address,
      auth0_id: newDeal.customer.auth0_id,
      dl_expiration_date: newDeal.customer.dl_expiration_date,
      dob: newDeal.customer.dob,
      email: newDeal.customer.email,
      employment: newDeal.customer.employment,
      first_name: newDeal.customer.first_name,
      has_same_address_as_cobuyer: newDeal.customer.has_same_address_as_cobuyer,
      home_phone_number: newDeal.customer.home_phone_number,
      id: newDeal.customer.id,
      last_name: newDeal.customer.last_name,
      marital_status: newDeal.customer.marital_status,
      middle_name: newDeal.customer.middle_name,
      name_suffix: newDeal.customer.name_suffix,
      no_email: newDeal.customer.no_email,
      phone_number: newDeal.customer.phone_number,
      relation_to_buyer: newDeal.customer.relation_to_buyer,
      ssn: newDeal.customer.ssn,
    };

    const partialPayoff = newDeal.car.payoff;
    delete partialPayoff?.doubleTaxApplied;
    delete partialPayoff?.previousTotalPayoff;
    delete partialPayoff?.totalPayoff;

    const partialFinancialInfo = newDeal.financial_info;
    delete partialFinancialInfo?.totalTaxAmount;

    const car = {
      ...newDeal.car,
      payoff: partialPayoff,
    };

    try {
      const res = await upsertEstimate({
        variables: {
          newEstimateUpsertInput: {
            id: newDeal.id,
            type: newDeal.type as unknown as DealType,
            request_boot: newDeal.request_boot,
            state: newDeal.state,
            setter_id: newDeal.setter_id,
            customer: partialCustomer,
            financial_info: partialFinancialInfo,
            source: newDeal.source,
            missing_required_external_documents: newDeal.missing_required_external_documents,
            opened_by_financial_specialist: newDeal.opened_by_financial_specialist,
            needs_electronic_signature_verification:
              newDeal.needs_electronic_signature_verification,
            car,
            creation_date_utc: creationDate,
            creation_date_tz: getTimezoneStr(),
            notes: [
              ...newDeal.notes,
              {
                text: 'Deal Created',
                author_id: id,
                creation_date_tz: getTimezoneStr(),
              },
            ],
            referral_source: newDeal.referral_source?.source_name ? newDeal.referral_source : null,
          },
        },
      });

      const { state = '', customer, id: dealId } = res?.data?.newEstimateUpsert ?? {};

      if (state === DealStateEnum.SoftClose || state === DealStateEnum.Floor) {
        await generateBookSheets({ dealId, customerState: customer?.address?.state });
      }

      history.push(
        dealId && state === DealStateEnum.SoftClose ? `/deals/${dealId}` : ROUTES.DASHBOARD,
      );
    } catch (e) {
      logger.error('NewEstimate.tsx', '', null, e);
    } finally {
      setHandleSubmitting(false);
    }
  };

  useEffect(() => {
    const dups = duplicateData?.duplicateDeals.filter(
      (deal: { id: number | undefined }) => deal.id !== estimate.id,
    );

    if (dups) {
      setDuplicateDeals(dups);
      if (dups.length) {
        onOpenDuplicateModal();
      } else {
        submitEstimate();
      }
    }
  }, [duplicateData]);

  useEffect(() => {
    if (duplicateError) {
      setHandleSubmitting(false);
    }
  }, [duplicateError]);

  const handleSubmit = async (deal: Deal, newSetSubmitting: (isSubmitting: boolean) => void) => {
    setEstimate(deal);
    setSetHandleSubmitting(() => newSetSubmitting);

    checkDuplicates({
      variables: {
        first_name: deal.customer.first_name,
        last_name: deal.customer.last_name,
        vin: deal.car.vin,
        phone_number: deal.customer.phone_number,
        home_phone_number: deal.customer.home_phone_number,
      },
    });
  };

  const closeDuplicateModal = () => {
    onCloseDuplicateModal();
    setHandleSubmitting(false);
  };

  return (
    <LeaseEndContainer>
      <Flex flexDirection="column" justify="center" maxW="1000px" mx="auto">
        <HStack p={2} bgColor="oxfordBlue" borderTopRadius={8} spacing={0}>
          <Text pl={2} fontSize={18} textColor="white">
            New Estimate
          </Text>
        </HStack>

        <EstimateForm
          estimate={estimate}
          onSave={handleSubmit}
          showNoteField
          duplicateDeals={duplicateDeals}
          setDuplicateDeals={setDuplicateDeals}
          submitEstimate={submitEstimate}
          upsertEstimateLoading={upsertEstimateLoading}
          isDuplicateModalOpen={isDuplicateModalOpen}
          closeDuplicateModal={closeDuplicateModal}
          onOpenDuplicateModal={onOpenDuplicateModal}
        />
      </Flex>
    </LeaseEndContainer>
  );
};
export default NewEstimate;
