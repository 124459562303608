import { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import { Deal, DealStateEnum } from '../../gql/dealGql';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';

import Modal from '../shared/Modal';
import Tooltip from '../shared/Tooltip';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { logger } from '../../libs/Logger';
import { AbilityContext } from '../../libs/contextLib';

const SendToClosing = () => {
  const history = useHistory();
  const ability = useContext(AbilityContext);
  const { values, isValid, isSubmitting, setSubmitting } = useFormikContext<Deal>();

  const [showApprovalModal, setShowApprovalModal] = useState(false);

  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert);

  const canOverrideFailedApprovalAcquisitions = ability.has(
    PermissionEnum.OverrideFailedApprovalAcquisitionDeals,
  );

  const onSendToClosing = (deal: Deal) => {
    upsertFinancialInfoAcquisition({
      variables: {
        financialInfoAcquisition: deal.financial_info_acquisition,
        id: deal.id,
        state: deal.state,
        car_id: deal.car?.id,
        mmr: deal.car?.mmr,
        vehicle_payoff: deal.car?.payoff?.vehicle_payoff,
        override:
          canOverrideFailedApprovalAcquisitions && !values.financial_info_acquisition.is_approved,
        clear_override: !deal.financial_info_acquisition.is_approved,
      },
    })
      .then(() => history.push(ROUTES.DASHBOARD))
      .catch((e) => logger.error('SendToClosingButton.tsx', '', null, e))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Tooltip errors={values.errors}>
        <Button
          isLoading={showApprovalModal}
          loadingText="SEND TO CLOSING"
          isDisabled={!isValid}
          hidden={values.state !== DealStateEnum.Structuring}
          name="send-closing"
          onClick={() => {
            setShowApprovalModal(true);
          }}
        >
          SEND TO CLOSING
        </Button>
      </Tooltip>
      <Modal
        title="Is Deal"
        isOpen={showApprovalModal}
        onClose={() => setShowApprovalModal(false)}
        centerButtons={
          <>
            <Button
              isLoading={isSubmitting}
              loadingText="YES"
              onClick={() =>
                onSendToClosing({
                  ...values,
                  state: DealStateEnum.Closing,
                  financial_info_acquisition: {
                    ...values.financial_info_acquisition,
                    is_approved: true,
                  },
                })
              }
            >
              YES
            </Button>
            <Button
              isLoading={isSubmitting}
              loadingText="NO"
              onClick={() =>
                onSendToClosing({
                  ...values,
                  state: DealStateEnum.Closing,
                  financial_info_acquisition: {
                    ...values.financial_info_acquisition,
                    is_approved: false,
                  },
                })
              }
            >
              NO
            </Button>
          </>
        }
      />
    </>
  );
};

export default SendToClosing;
