import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import { Deal, DealStateEnum, dealInfoUpsert } from '../../../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../../../gql/generated/graphql';

import Tooltip from '../../../shared/Tooltip';

import { PermissionEnum } from '../../../../constants/permissions';
import ROUTES from '../../../../constants/routes';
import { Can } from '../../../../libs/Can';
import { DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';

const SendToCloser = () => {
  const { deal, isRecalculatingPayoff } = useContext(DealContext);

  const history = useHistory();

  const { values, errors, isSubmitting, setSubmitting } = useFormikContext<Deal>();

  const [upsertDealInfo] = useMutation(dealInfoUpsert);
  const [updateDealState] = useDealUpdateStateMutation();

  const onSendToCloser = async (newValues: Deal) => {
    if (!deal.id) {
      return;
    }

    try {
      setSubmitting(true);
      await upsertDealInfo({
        variables: {
          financialInfo: newValues.financial_info,
          car: newValues.car,
        },
      });
      await updateDealState({
        variables: {
          id: deal.id,
          state: newValues.state,
        },
      });

      history.push(ROUTES.DASHBOARD);
    } catch (e) {
      logger.error('SendToCloserButton.tsx', '', null, e);
    } finally {
      setSubmitting(false);
    }
  };

  const isFollowUpScheduled = !!deal.follow_up;
  const errorFields = [
    errors.car?.payoff?.vehicle_payoff,
    errors.financial_info?.sell_rate,
    errors.financial_info?.term,
    errors.financial_info?.bank,
    errors.financial_info?.money_down,
    errors.financial_info?.new_registration_fee,
    errors.financial_info?.registration_transfer_fee,
    errors.financial_info?.vsc_price,
    errors.financial_info?.vsc_cost,
    errors.financial_info?.gap_price,
    errors.financial_info?.gap_cost,
    errors.financial_info?.buy_rate,
    isFollowUpScheduled ? 'Cannot have a current follow up scheduled' : undefined,
  ];
  const hasErrors = errorFields.some(Boolean);

  return (
    <Can I={PermissionEnum.BuildDeal}>
      <Tooltip errors={errorFields}>
        <Button
          isLoading={isSubmitting || isRecalculatingPayoff}
          loadingText="FINALIZE DEAL STRUCTURE"
          isDisabled={hasErrors}
          hidden={deal.state !== DealStateEnum.Structuring}
          onClick={() => onSendToCloser({ ...values, state: DealStateEnum.Closing })}
        >
          FINALIZE DEAL STRUCTURE
        </Button>
      </Tooltip>
    </Can>
  );
};

export default SendToCloser;
