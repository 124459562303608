import { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Icon, IconButton } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';

import { Deal, dealQuery } from '../../gql/dealGql';

import TextModal from './TextModal';

import { FiltersContext, TextWidgetContext } from '../../libs/contextLib';

const TextWidget = () => {
  const { textDealId, setTextDealId, setActiveConversation } = useContext(TextWidgetContext);
  const {
    filters: {
      global: { sources, types },
    },
  } = useContext(FiltersContext);
  const [deal, setDeal] = useState<Deal | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { data, refetch } = useQuery(dealQuery, {
    skip: !textDealId,
    variables: {
      id: textDealId,
      sources,
      types,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ deal: fetchedDeal }) => {
      setDeal(fetchedDeal);
      setOpen(true);
    },
  });

  useEffect(() => {
    if (textDealId && data?.deal?.id !== textDealId) {
      refetch();
    }
  }, [data, textDealId]);

  return (
    <Box>
      {open && (
        <TextModal deal={deal}>
          <IconButton
            icon={<Icon as={MdClose} boxSize={6} />}
            variant="icon"
            color="white"
            aria-label="close-modal"
            onClick={() => {
              setTextDealId(null);
              setActiveConversation({
                role: '',
                phone_number: '',
                first_name: '',
                last_name: '',
              });
              setOpen(false);
            }}
          />
        </TextModal>
      )}
    </Box>
  );
};

export default TextWidget;
