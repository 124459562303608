import { useCallback, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { SingleValue } from 'react-select';

import { getFullName } from '../../gql/customerGql';
import { DealSourceEnum, dealQuery } from '../../gql/dealGql';
import {
  ConsentNameEnumType,
  Deal,
  DealType,
  ImportType,
  Maybe,
  TemporaryData,
  TemporaryInfo,
  TransformType,
  useImportDealMutation,
  useSearchWebLeadsLazyQuery,
  useTemporaryInfoAuthenticatedLazyQuery,
  useTemporaryInfoImportMutation,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import Suggest, { Suggestion } from './Suggest';

import useDebounce from '../../hooks/useDebounce';

const WebLeadSuggest = () => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<Maybe<TemporaryInfo>>(null);

  const excludeKeyArr: (keyof TemporaryData)[] = [
    'lienholderLogo',
    '__typename',
    'kbb_selected_options',
  ];

  const [searchWebLeads, { loading }] = useSearchWebLeadsLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.searchWebLeads) {
        setSuggestions(
          data.searchWebLeads.map((lead) => ({
            isDeal: !!lead?.deal_id,
            isClickable: true,

            id: lead?.id,
            state: null,
            customerName: getFullName(lead?.data),
            phoneNumber: lead?.data?.phone_number,
            homePhoneNumber: null,
            email: lead?.data?.email,
            vin: lead?.data?.vin,
          })),
        );
      }
    },
  });

  const [getDeal] = useLazyQuery<{ deal: Maybe<Deal> }>(dealQuery);
  const [importDeal] = useImportDealMutation();

  const [getTempInfo] = useTemporaryInfoAuthenticatedLazyQuery();
  const [importTempInfo, { loading: importLoading }] = useTemporaryInfoImportMutation();

  const debouncedSearch = useDebounce((value: string) => {
    searchWebLeads({
      variables: {
        query: value,
      },
    });
  }, 500);

  const handleChange = useCallback(async (newValue: SingleValue<Suggestion>) => {
    if (!newValue?.id) {
      return;
    }

    if (newValue?.isDeal) {
      const { data: dealData } = await getDeal({
        variables: {
          id: newValue?.id,
          sources: [DealSourceEnum.Web],
          types: Object.values(DealType),
        },
      });

      if (dealData?.deal) {
        const { deal } = dealData;
        // Map Deal to TemporaryInfo for the Modal
        setSelectedData({
          deal_id: deal.id,
          id: deal.id,
          data: {
            vin: deal.car?.vin,
            year: deal.car?.year,
            make: deal.car?.make,
            model: deal.car?.model,
            fuel_type: deal.car?.fuel_type,
            vehicle_type: deal.car?.vehicle_type,
            first_name: deal.customer?.first_name,
            last_name: deal.customer?.last_name,
            email: deal.customer?.email,
          },
        });
      }
    } else {
      const { data: tempInfoData } = await getTempInfo({ variables: { id: newValue?.id } });

      if (tempInfoData?.temporaryInfoAuthenticated) {
        setSelectedData(tempInfoData.temporaryInfoAuthenticated);
      }
    }
    setShowModal(true);
  }, []);

  const importWebLead = (tempInfo: Maybe<TemporaryInfo>) => {
    if (!tempInfo?.id) {
      return;
    }
    if (tempInfo.deal_id) {
      importDeal({
        variables: {
          id: tempInfo.id,
        },
      });
    } else {
      const hasSoftPullConsent = tempInfo?.consents?.some(
        (consent) => consent?.type === ConsentNameEnumType.CreditScoreSoftPull,
      );

      importTempInfo({
        variables: {
          id: tempInfo.id,
          transformType: hasSoftPullConsent
            ? TransformType.ManualImportPostSoftPull
            : TransformType.ManualImportPreSoftPull,
          importType: hasSoftPullConsent
            ? ImportType.ManualImportPostSoftPull
            : ImportType.ManualImportPreSoftPull,
        },
      });
    }
    setShowModal(false);
  };

  return (
    <>
      <Suggest
        label="Web Leads"
        suggestions={suggestions}
        setSuggestions={setSuggestions}
        search={debouncedSearch}
        isLoading={loading}
        handleChange={handleChange}
      />

      {selectedData?.data ? (
        <Modal
          title="Import Web Lead"
          variant="noPadding"
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          rightButtons={
            <Button isLoading={importLoading} onClick={() => importWebLead(selectedData)}>
              IMPORT LEAD
            </Button>
          }
        >
          <Box>
            {Object.keys(selectedData.data).map((key) => {
              const typedKey = key as keyof TemporaryData;

              return selectedData?.data?.[typedKey] && !excludeKeyArr.includes(typedKey) ? (
                <HStack>
                  <Text>{`${key.replace(/_/g, ' ')}: ${selectedData.data[typedKey]}`}</Text>
                </HStack>
              ) : null;
            })}
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default WebLeadSuggest;
