import { ChangeEvent, SyntheticEvent, memo, useContext, useEffect, useState } from 'react';

import { Box, Checkbox, HStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { relationshipToBuyerNoChildOptions } from '../../gql/customerGql';
import { DealStateEnum, isUneditable } from '../../gql/dealGql';
import { isEmploymentInfoRequired } from '../../gql/employmentGql';
import { AddressTypeEnum, MaritalStatusEnum } from '../../gql/generated/graphql';

import MaskedSsnInput from '../MaskedSsn/MaskedSsnInput';
import { TotalPayoff } from '../TotalPayoff/TotalPayoff';
import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import DatePicker from '../shared/DatePicker';
import { GridFormColumn, GridFormRow } from '../shared/GridForm';
import Select from '../shared/Select';
import Switch from '../shared/Switch';
import { AddressForm } from './components/AddressForm';
import Autosaving from './components/Autosaving';
import { EmploymentForm } from './components/EmploymentForm';
import { InsuranceForm } from './components/InsuranceForm';
import { PersonalInformationForm } from './components/PersonalInformationForm';
import { ResidenceForm } from './components/ResidenceForm';

import { CreditAppOnDeal } from './useCreditAppInitialValues';
import { requirePreviousAddress, requirePreviousEmployment } from './validationSchema';

import { emptySsnFullMask, ssnFullMask } from '../../constants/masks';
import { DealContext } from '../../libs/DealContext';
import { states } from '../../libs/states';
import DealStatesModal from '../../pages/DealDetail/DealStatesModal';
import { snakeCaseToUpperCase } from '../../utils/text';

export const closeOrCancelButtonTriggedBlur = (
  e: SyntheticEvent<HTMLInputElement | HTMLSelectElement> | undefined,
) => {
  if ((e as unknown as MouseEvent).relatedTarget) {
    const relatedTarget = (e as unknown as MouseEvent).relatedTarget as HTMLElement;

    const isCloseButton =
      relatedTarget.className.toLowerCase().includes('close') ||
      relatedTarget.innerHTML.toLowerCase().includes('close') ||
      relatedTarget.innerText.toLowerCase().includes('close') ||
      relatedTarget.className.toLowerCase().includes('cancel') ||
      relatedTarget.innerHTML.toLowerCase().includes('cancel') ||
      relatedTarget.innerText.toLowerCase().includes('cancel');

    if (relatedTarget.tagName === 'BUTTON' && isCloseButton) {
      return true;
    }
  }

  return false;
};

type CreditPersonProps = {
  isCobuyer?: boolean;
  isInsideModal?: boolean;
  autoSaveTime?: string;
  customHandleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  adminEditOverride?: boolean;
};

export const CreditPerson = memo(
  ({
    isCobuyer,
    isInsideModal,
    autoSaveTime,
    customHandleChange,
    adminEditOverride = false,
  }: CreditPersonProps) => {
    const {
      deal,
      isRecalculatingPayoff,
      setIsRecalculatingPayoff,
      autosaving,
      setUserChangedSomething,
    } = useContext(DealContext);

    const {
      values,
      setFieldValue: formikSetFieldValue,
      setFieldTouched,
    } = useFormikContext<CreditAppOnDeal>();

    // https://github.com/jaredpalmer/formik/issues/2059
    const setFieldValue = (field: string, value: unknown, shouldValidate = true) => {
      formikSetFieldValue(field, value, shouldValidate);
      setTimeout(() => setFieldTouched(field, true, shouldValidate), 0);
    };

    const updateCobuyerAddressToMatchBuyerAddress = () => {
      if (values.cobuyer) {
        const { address: buyerAddress } = values.customer;
        setFieldValue('customer.has_same_address_as_cobuyer', true);
        setFieldValue('cobuyer.has_same_address_as_cobuyer', true);
        setFieldValue('cobuyer.address.residence_type', buyerAddress?.residence_type);
        setFieldValue('cobuyer.address.address_type', AddressTypeEnum.Current);
        setFieldValue('cobuyer.address.address_line', buyerAddress?.address_line);
        setFieldValue('cobuyer.address.address_line_2', buyerAddress?.address_line_2);
        setFieldValue('cobuyer.address.zip', buyerAddress?.zip);
        setFieldValue('cobuyer.address.city', buyerAddress?.city);
        setFieldValue('cobuyer.address.state', buyerAddress?.state);
        setFieldValue('cobuyer.address.county', buyerAddress?.county);
        setFieldValue('cobuyer.address.years_at_home', buyerAddress?.years_at_home);
        setFieldValue('cobuyer.address.months_at_home', buyerAddress?.months_at_home);
        setFieldValue('cobuyer.address.monthly_payment', buyerAddress?.monthly_payment);
      }
    };

    const nullOutCobuyerAddress = () => {
      setFieldValue('customer.has_same_address_as_cobuyer', false);
      if (values.cobuyer) {
        const { address: cobuyerAddress } = values.cobuyer;
        setFieldValue('cobuyer.has_same_address_as_cobuyer', false);
        setFieldValue('cobuyer.address.address_line', cobuyerAddress?.address_line || '');
        setFieldValue('cobuyer.address.address_line_2', cobuyerAddress?.address_line_2 || '');
        setFieldValue('cobuyer.address.residence_type', cobuyerAddress?.residence_type || '');
        setFieldValue('cobuyer.address.address_type', cobuyerAddress?.address_type || '');
        setFieldValue('cobuyer.address.zip', cobuyerAddress?.zip || '');
        setFieldValue('cobuyer.address.city', cobuyerAddress?.city || '');
        setFieldValue('cobuyer.address.state', cobuyerAddress?.state || '');
        setFieldValue('cobuyer.address.county', cobuyerAddress?.county || '');
        setFieldValue('cobuyer.address.years_at_home', cobuyerAddress?.years_at_home || null);
        setFieldValue('cobuyer.address.months_at_home', cobuyerAddress?.months_at_home || null);
        setFieldValue('cobuyer.address.monthly_payment', cobuyerAddress?.monthly_payment || null);
      }
    };

    const onToggleLivesWithBuyer = (e: ChangeEvent<HTMLInputElement>) => {
      if (!isCobuyer) {
        return;
      }

      setUserChangedSomething(true);
      if (e.target.checked) {
        updateCobuyerAddressToMatchBuyerAddress();
      } else {
        nullOutCobuyerAddress();
      }
    };

    useEffect(() => {
      if (!isCobuyer || !values.customer?.has_same_address_as_cobuyer) {
        return;
      }

      updateCobuyerAddressToMatchBuyerAddress();
    }, [
      values.customer.address?.address_line,
      values.customer.address?.address_line_2,
      values.customer.address?.zip,
      values.customer.address?.city,
      values.customer.address?.state,
      values.customer.address?.county,
      values.customer.address?.years_at_home,
      values.customer.address?.months_at_home,
      values.customer.address?.monthly_payment,
    ]);

    let name: 'customer' | 'cobuyer' = 'customer';
    let person = values.customer;

    if (isCobuyer && values.cobuyer) {
      name = 'cobuyer';
      person = values.cobuyer;
    }

    const [previousIsEmploymentInfoRequired, setPreviousIsEmploymentInfoRequired] =
      useState<boolean>(isEmploymentInfoRequired(person?.employment?.status));
    const [tempEmployment, setTempEmployment] = useState<
      Partial<CreditAppOnDeal['customer']['employment']>
    >(person?.employment);

    const cleanEmploymentValues = (
      employmentToClean: CreditAppOnDeal['customer']['employment'],
    ) => {
      setTempEmployment(employmentToClean);

      setFieldValue(`${name}.employment.name`, '');
      setFieldValue(`${name}.employment.job_title`, '');
      setFieldValue(`${name}.employment.phone_number`, '');
    };

    const restoreEmploymentValues = (
      employmentToRestore: Partial<CreditAppOnDeal['customer']['employment']>,
    ) => {
      setFieldValue(`${name}.employment.name`, employmentToRestore?.name);
      setFieldValue(`${name}.employment.job_title`, employmentToRestore?.job_title);
      setFieldValue(`${name}.employment.phone_number`, employmentToRestore?.phone_number);
    };

    useEffect(() => {
      if (!isEmploymentInfoRequired(person?.employment?.status)) {
        cleanEmploymentValues(person?.employment);
      }
    }, []);

    useEffect(() => {
      const currentIsEmploymentInfoRequired = isEmploymentInfoRequired(person?.employment?.status);

      const shouldCleanEmploymentValues =
        previousIsEmploymentInfoRequired && !currentIsEmploymentInfoRequired;

      const shouldRestoreEmploymentValues =
        !previousIsEmploymentInfoRequired && currentIsEmploymentInfoRequired;

      if (shouldCleanEmploymentValues) {
        cleanEmploymentValues(person?.employment);
      }

      if (shouldRestoreEmploymentValues) {
        restoreEmploymentValues(tempEmployment);
      }

      setPreviousIsEmploymentInfoRequired(currentIsEmploymentInfoRequired);
    }, [person?.employment?.status]);

    const isLocked = !adminEditOverride && isUneditable(values.state as DealStateEnum);

    return (
      <Card variant="rounded">
        <CardHeaderV2
          title={`${isCobuyer ? 'Co-Buyer ' : ''}Credit Application`}
          showPodColor={!isCobuyer}
          pod={deal.pod}
          variant={isCobuyer ? 'square' : 'rounded'}
        >
          <Autosaving autosaving={autosaving} saveTime={autoSaveTime} />
          {!isCobuyer ? <DealStatesModal /> : null}
        </CardHeaderV2>

        {isCobuyer ? (
          <Box mx={6} mb={5}>
            <HStack pt={3} alignItems="end">
              <Select
                label="Relation To Buyer"
                name={`${name}.relation_to_buyer`}
                onChange={customHandleChange}
                emptyOption={false}
                options={relationshipToBuyerNoChildOptions}
                formControlProps={{
                  width: '25%',
                }}
              />
              <Switch
                name={`${name}.has_same_address_as_cobuyer`}
                label="Lives With Buyer"
                customHandleChange={onToggleLivesWithBuyer}
              />
            </HStack>
          </Box>
        ) : null}

        <Box mb={5}>
          <PersonalInformationForm
            name={name}
            customHandleChange={customHandleChange}
            isUneditable={isLocked}
            hideBuyerNotLessee={isInsideModal}
          />

          {!isCobuyer || !values.cobuyer?.has_same_address_as_cobuyer ? (
            <AddressForm
              name={name}
              objectName="address"
              customHandleChange={customHandleChange}
              isUneditable={isLocked}
            />
          ) : null}

          <GridFormColumn>
            <GridFormRow minChildWidth={180}>
              <DatePicker
                name={`${name}.dob`}
                topLabel="Date of Birth"
                valueFormat="dateUTC"
                additionalHandleChange={customHandleChange}
                isDisabled={isLocked}
              />
              <MaskedSsnInput
                name={`${name}.ssn`}
                ssnDetails={{ mask: ssnFullMask, label: 'SSN', emptyMask: emptySsnFullMask }}
                customHandleChange={customHandleChange}
                needsHidden={name === 'customer' ? !!deal.customer?.ssn : !!deal.cobuyer?.ssn}
                isDisabled={isLocked}
                replaceFixedCharacters
              />
              <Box />
              <Box />
            </GridFormRow>
          </GridFormColumn>

          {person.address?.state === states.WISCONSIN ||
          (!person.address?.state && values.customer.address?.state === states.WISCONSIN) ? (
            <Box mx={6}>
              <HStack mt={3} alignItems="start">
                <Select
                  label="Marital Status"
                  emptyOption
                  name={`${name}.marital_status`}
                  onChange={customHandleChange}
                  isDisabled={isLocked}
                  options={Object.values(MaritalStatusEnum).map((status) => ({
                    label: snakeCaseToUpperCase(status),
                    value: status,
                  }))}
                  formControlProps={{
                    width: '50%',
                  }}
                />
              </HStack>
              {isCobuyer &&
              [MaritalStatusEnum.Married, MaritalStatusEnum.Separated].includes(
                person?.marital_status as unknown as MaritalStatusEnum,
              ) ? (
                <HStack mt={3} alignItems="start">
                  <Checkbox
                    name="cobuyer.wi_notification_agreement"
                    isChecked={person.wi_notification_agreement}
                    isDisabled={deal.deal_states.some(
                      (state) => state.state === DealStateEnum.Structuring,
                    )}
                    onChange={(e) => {
                      setFieldValue('cobuyer.wi_notification_agreement', e.target.checked);
                    }}
                  >
                    By checking this box, the co-buyer acknowledges that Wisconsin law may require
                    that notice of this application of credit and subsequent account may be given to
                    their spouse.
                  </Checkbox>
                </HStack>
              ) : null}
            </Box>
          ) : null}
        </Box>

        <EmploymentForm
          name={name}
          objectName="employment"
          customHandleChange={customHandleChange}
          isUneditable={isLocked}
          isEmploymentInfoRequired={isEmploymentInfoRequired(person?.employment?.status)}
        />

        {requirePreviousEmployment(person?.employment?.years_at_job) ? (
          <EmploymentForm
            name={name}
            objectName="prev_employment"
            customHandleChange={customHandleChange}
            isUneditable={isLocked}
            isEmploymentInfoRequired
          />
        ) : null}

        {!isCobuyer || !person.has_same_address_as_cobuyer ? (
          <ResidenceForm
            name={name}
            objectName="address"
            isUneditable={isLocked}
            customHandleChange={customHandleChange}
          />
        ) : null}

        {requirePreviousAddress(person?.address?.years_at_home) ? (
          <>
            <AddressForm
              name={name}
              objectName="prev_address"
              customHandleChange={customHandleChange}
              isUneditable={isLocked}
            />
            <ResidenceForm
              name={name}
              objectName="prev_address"
              isUneditable={isLocked}
              showTitle={false}
              customHandleChange={customHandleChange}
            />
          </>
        ) : null}

        <InsuranceForm name={name} customHandleChange={customHandleChange} />

        {!isCobuyer && !isLocked ? (
          <TotalPayoff
            hidden
            isRecalculating={isRecalculatingPayoff}
            setIsRecalculating={setIsRecalculatingPayoff}
            useDealContextValues
          />
        ) : null}
      </Card>
    );
  },
);
