import { useContext, useEffect, useRef, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, Checkbox, HStack } from '@chakra-ui/react';
import Big from 'big.js';
import { Form, useFormikContext } from 'formik';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

import { acquisitionOfferQuery } from '../../gql/acquisitionsGql';
import { ColorEnum } from '../../gql/carGql';
import { determineApprovalQuery } from '../../gql/conditionReportGql';
import { Deal } from '../../gql/dealGql';
import { financialInfoAcquisitionUpsert } from '../../gql/financialInfoAcquisitionGql';
import { mmrQuery } from '../../gql/mmr';

import ConditionReport from '../ConditionReport';
import MaskedSsnInput from '../MaskedSsn/MaskedSsnInput';
import CardSubHeaderV2 from '../shared/Card/components/CardSubHeaderV2';
import Input from '../shared/Input';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';
import Select from '../shared/Select';

import MmrButton from './MmrButton';
import SaveButton from './SaveButtonSoftClose';
import SendToStructuringOrClosing from './SendToStructuringOrClosingButton';

import { emptySsnFullMask, phoneNumberMask, ssnFullMask } from '../../constants/masks';
import { DealContext } from '../../libs/DealContext';

const SoftCloseView = () => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext<Deal>();
  const { customer, car, condition_report, financial_info_acquisition } = values;
  const {
    payoff: { vehicle_payoff },
  } = car;
  const { max_total_cost, appraised_value } = financial_info_acquisition;
  const { deal } = useContext(DealContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const firstUpdate = useRef(true);

  let isConditionReportCompleted = false;
  if (
    deal?.condition_report?.accidents &&
    deal?.condition_report.tires &&
    deal?.condition_report.smoked_in !== null &&
    deal?.condition_report.lights_on_dash !== null &&
    deal?.condition_report.overall_condition
  ) {
    isConditionReportCompleted = true;
  }

  const [determineApproval] = useLazyQuery(determineApprovalQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ determineApprovalQuery: { approved } }) => {
      setFieldValue('financial_info_acquisition.is_approved', approved);
    },
  });

  const [getMmr] = useLazyQuery(mmrQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.mmr.average) {
        setFieldValue('car.mmr', data.mmr.average);
      } else {
        setFieldValue('car.mmr', (car?.book_value ?? 0) * 0.9);
      }
    },
  });

  const [acquisitionOffer] = useLazyQuery(acquisitionOfferQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ acquisitionOffer: { appraisedValue, offer } }) => {
      if (!appraised_value) {
        setFieldValue('financial_info_acquisition.appraised_value', appraisedValue);
      }
      setFieldValue('financial_info_acquisition.max_total_cost', offer);
    },
  });

  const [upsertFinancialInfoAcquisition] = useMutation(financialInfoAcquisitionUpsert, {
    fetchPolicy: 'network-only',
    onCompleted: ({ financialInfoAcquisitionUpsert: { id } }) => {
      setFieldValue('financial_info_acquisition.id', id);
    },
  });

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (typeof deal.condition_report?.score === 'number') {
      getMmr({
        variables: {
          vin: values.car.vin,
          mileage: values.car.mileage,
          color: values.car.color,
          grade: deal.condition_report?.score,
          trim: values.car.kbb_trim_name,
          state: customer.address?.state,
        },
      });
    }
  }, [deal.condition_report?.score]);

  useEffect(() => {
    if (isConditionReportCompleted) {
      const accidents = condition_report?.accidents;
      const { mileage, mmr: mmr_value, book_value, vehicle_type, make } = car;
      const lienholder = car?.payoff?.lienholder_name;

      determineApproval({
        variables: {
          data: {
            accidents,
            tires: condition_report?.tires,
            exterior: condition_report?.exterior,
            interior: condition_report?.interior,
            smoked_in: condition_report?.smoked_in,
            lights_on_dash: condition_report?.lights_on_dash,
            overall_condition: condition_report?.overall_condition,
            year: car.year,
            payoff: vehicle_payoff,
            book_value,
            kbb_trim: car.kbb_trim_name,
            vin: car.vin,
            mileage,
            color: car.color,
            vehicle_type,
            state: customer.address?.state,
            financial_info_acquisition_id: values.financial_info_acquisition.id,
          },
        },
      });

      if (accidents && mileage && mmr_value && book_value && vehicle_type) {
        acquisitionOffer({
          variables: {
            accidents,
            mileage,
            mmr_value,
            book_value,
            vehicle_type,
            make,
            lienholder,
          },
        });
      }
    }
  }, [condition_report, car, isConditionReportCompleted]);

  useEffect(() => {
    if (max_total_cost && vehicle_payoff) {
      let maxCashToCustomer = new Big(max_total_cost).minus(vehicle_payoff).toNumber();

      if (maxCashToCustomer < 0) {
        maxCashToCustomer = 0;
      }

      setFieldValue('financial_info_acquisition.max_cash_to_customer', maxCashToCustomer);
    }
  }, [max_total_cost, vehicle_payoff]);

  useEffect(() => {
    upsertFinancialInfoAcquisition({
      variables: {
        financialInfoAcquisition: financial_info_acquisition,
        id: deal.id,
        state: deal.state,
        car_id: car?.id,
        mmr: car?.mmr,
        vehicle_payoff,
      },
    });
  }, [financial_info_acquisition]);

  return (
    <Form>
      <Box bgColor="white">
        <CardSubHeaderV2 title="Personal Information" />
        <Box p={6}>
          <HStack>
            <Input
              label="First Name"
              name="customer.first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.customer?.first_name && !!errors.customer?.first_name}
            />
            <Input
              label="Middle Name"
              name="customer.middle_name"
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.customer?.middle_name && !!errors.customer?.middle_name}
            />
            <Input
              label="Last Name"
              name="customer.last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.customer?.last_name && !!errors.customer?.last_name}
            />
          </HStack>
          <HStack my={2}>
            <Input
              label="Mobile Phone"
              name="customer.phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              mask={phoneNumberMask}
              isInvalid={touched.customer?.phone_number && !!errors.customer?.phone_number}
              copyButton
            />
            <Input
              label="Home Phone"
              name="customer.home_phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              mask={phoneNumberMask}
              isInvalid={
                touched.customer?.home_phone_number && !!errors.customer?.home_phone_number
              }
              copyButton
            />
            <Input
              label="Email"
              name="customer.email"
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.customer?.email && !!errors.customer?.email}
              floatingInput={
                <Checkbox
                  size="sm"
                  name="customer.no_email"
                  checked={values.customer?.no_email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  No email
                </Checkbox>
              }
            />
          </HStack>
          <HStack>
            <MaskedSsnInput
              name="$customer.ssn"
              ssnDetails={{ mask: ssnFullMask, label: 'SSN', emptyMask: emptySsnFullMask }}
              needsHidden={!!deal.customer?.ssn}
              replaceFixedCharacters
              isDisabled
              width="33%"
            />
          </HStack>
        </Box>

        <CardSubHeaderV2 title="Vehicle Info" />
        <HStack p={6}>
          <Input
            label="VIN"
            name="car.vin"
            value={car.vin}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.car?.vin && !!errors.car?.vin}
          />
          <NumberInput
            label="Mileage"
            name="car.mileage"
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.car?.mileage && !!errors.car?.mileage}
            showThousandSeparator
            isWhole
          />
          <Select
            label="Color"
            name="car.color"
            value={car.color}
            onChange={handleChange}
            onBlur={handleBlur}
            options={Object.values(ColorEnum).map((color) => ({
              value: color,
              label: color,
            }))}
            isInvalid={touched.car?.color && !!errors.car?.color}
          />
        </HStack>

        <CardSubHeaderV2 title="Financial Info" />
        <Box p={6}>
          <HStack>
            <NumberInput
              label="Payoff"
              name="car.payoff.vehicle_payoff"
              showThousandSeparator
              isMoney
            />
            <NumberInput
              label="KBB Lending"
              name="financial_info_acquisition.kbb_lending"
              showThousandSeparator
              isMoney
            />
          </HStack>
          <HStack>
            <NumberInput label="MMR" name="car.mmr" showThousandSeparator isMoney />
            <NumberInput label="CR Score" name="condition_report.score" />
          </HStack>
        </Box>
        <HStack px={4} pb={6}>
          <MmrButton />
          <Button
            size="lgWithIconRight"
            isLoading={showModal}
            loadingText="VIEW CONDITION REPORT"
            rightIcon={
              isConditionReportCompleted ? (
                <BiCheckCircle color="green" />
              ) : (
                <BiXCircle color="errorsRed" />
              )
            }
            name="condition-report"
            onClick={() => setShowModal(true)}
          >
            VIEW CONDITION REPORT
          </Button>
          <Modal
            title="Condition Report"
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            size="2xl"
          >
            <ConditionReport setShowModal={setShowModal} />
          </Modal>
          <SaveButton />
          <SendToStructuringOrClosing />
        </HStack>
      </Box>
    </Form>
  );
};

export default SoftCloseView;
