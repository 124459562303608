import { useContext, useEffect, useMemo, useState } from 'react';

import { Box, Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import { RiDashboardLine } from 'react-icons/ri';
import { useHistory, useLocation } from 'react-router-dom';

import { MetricsPages, useMetricsPagesQuery } from '../../gql/generated/graphql';

import KeyMetrics from '../../components/KeyMetrics';
import MetricsSidebar from './components/MetricsSidebar';

import { PermissionEnum } from '../../constants/permissions';
import { useUser } from '../../hooks/useUser';
import { Can } from '../../libs/Can';
import { AbilityContext } from '../../libs/contextLib';

const ROUTE_PAGE_PARAM_VALUE_DIVIDER = '?page=';
const ROUTE_USER_PARAM_VALUE_DIVIDER = '?user_id=';
const ROUTE_ROLES_PARAM_VALUE_DIVIDER = '&roles=';
const USER_ID_DIVIDER = 'auth0|';
const TRANSACTIONS = 'Transactions';

const MetricsPage = () => {
  const user = useUser();
  const abilities = useContext(AbilityContext);
  const history = useHistory();
  const { search } = useLocation();

  const [currentPage, setCurrentPage] = useState<MetricsPages | undefined>();

  const userId = user.id.split(USER_ID_DIVIDER)[1];
  const roles = Array.from(abilities).filter((ability) => ability.startsWith('Role:'));

  const hasPagePermission = (page: MetricsPages): boolean =>
    !page.permission_name || abilities.has(page.permission_name as PermissionEnum);

  const { data } = useMetricsPagesQuery();

  const metricsPages = useMemo(() => {
    if (data?.metricsPages?.length) {
      let myMetricsPages = [
        ...data?.metricsPages,
        {
          name: TRANSACTIONS,
          permission_name: PermissionEnum.SeeKeyMetrics,
          sort_order: 100,
        },
      ];

      myMetricsPages = myMetricsPages.filter((page) => page && hasPagePermission(page));
      return myMetricsPages as MetricsPages[];
    }
    return [];
  }, [data?.metricsPages]);

  const iframeUrl = useMemo(() => {
    return `${
      currentPage?.iframe_url
    }${ROUTE_USER_PARAM_VALUE_DIVIDER}${userId}${ROUTE_ROLES_PARAM_VALUE_DIVIDER}${encodeURIComponent(
      roles.join(','),
    )}`;
  }, [currentPage?.iframe_url, userId, roles]);

  const handleOnPressItem = (item: MetricsPages): void => {
    history.push({ search: `${ROUTE_PAGE_PARAM_VALUE_DIVIDER}${item.name}` });
  };

  useEffect(() => {
    if (metricsPages?.length) {
      let page: MetricsPages | undefined;
      if (search) {
        const currentSearch = search.split(ROUTE_PAGE_PARAM_VALUE_DIVIDER)[1];
        page = metricsPages.find(({ name }) => name === currentSearch);
      } else {
        page = metricsPages.find(
          ({ permission_name }) => permission_name === PermissionEnum.SeeMetricsOverView,
        );
      }
      if (page && hasPagePermission(page)) {
        setCurrentPage(page);
      } else {
        history.push({ search: `${ROUTE_PAGE_PARAM_VALUE_DIVIDER}My Stats` });
      }
    }
  }, [search, metricsPages]);

  return (
    <MetricsSidebar
      metricsPages={metricsPages}
      onPressItem={handleOnPressItem}
      itemSelected={currentPage}
    >
      <Flex boxSize="fit-content" alignItems="center" ml={{ md: 0 }} marginTop={0} marginBottom={3}>
        <RiDashboardLine size={30} />
        <Text fontWeight="bold" fontSize={24} fontFamily="sans-serif">
          {currentPage?.name}
        </Text>
        <Spacer margin={1} />
        <Text fontSize={24} fontFamily="sans-serif">
          Dashboard
        </Text>
      </Flex>
      <Stack>
        <Box
          ml={{ base: 0, md: 0 }}
          backgroundColor="white"
          display="flex"
          overflowY="hidden"
          borderRadius={10}
          shadow="lg"
        >
          {currentPage?.iframe_url && currentPage?.name ? (
            <iframe
              id={currentPage.name}
              title="Retool Inline Frame"
              style={{
                borderRadius: 10,
                height: 'calc(100vh - 130px)',
                width: '100%',
              }}
              src={iframeUrl}
            />
          ) : currentPage?.name === TRANSACTIONS ? (
            <Can I={PermissionEnum.SeeKeyMetrics}>
              <Box width="100%">
                <KeyMetrics />
              </Box>
            </Can>
          ) : null}
        </Box>
      </Stack>
    </MetricsSidebar>
  );
};

export default MetricsPage;
