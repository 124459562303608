import { Grid, GridItem } from '@chakra-ui/react';

import { Deal } from '../../../../gql/dealGql';

import { getLtvRatio } from '../../../../utils/deals';

type ExtraLtvsProps = {
  deal: Deal;
  ltv: number;
};

const ExtraLtvs = ({ deal, ltv }: ExtraLtvsProps) => {
  const formatPercent = (value: number) => (value ? `${(value * 100).toFixed()}%` : '--');

  // Instead of calling to get an estimated payment, reverse the LTV calculation to get the amount financed
  const amountFinanced = ltv * (deal?.car?.book_value ?? 0);

  const kbbRetailLtv = formatPercent(getLtvRatio(amountFinanced, deal?.car?.retail_book_value));
  const kbbLendingLtv = formatPercent(ltv); // Normal LTV is based off of KBB Lending
  const jdpRetailLtv = formatPercent(
    getLtvRatio(amountFinanced, deal?.car?.jdp_adjusted_clean_retail),
  );

  return (
    <Grid
      fontWeight="normal"
      gap={2}
      pl={8}
      templateColumns="repeat(2, 1fr)"
      templateRows="repeat(3, 1fr)"
      maxW="275px"
    >
      <GridItem>• KBB Retail</GridItem>
      <GridItem>{kbbRetailLtv}</GridItem>
      <GridItem>• KBB Lending</GridItem>
      <GridItem>{kbbLendingLtv}</GridItem>
      <GridItem>• JDP Retail</GridItem>
      <GridItem>{jdpRetailLtv}</GridItem>
    </Grid>
  );
};

export default ExtraLtvs;
