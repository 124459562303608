import { Box, Button, Heading, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { MdWarning } from 'react-icons/md';

import Modal from '../../shared/Modal';

import { DealMediaTypeLabels } from '../../../constants/media';
import { DealMedia } from '../../../types/media';
import { hasKey } from '../../../utils/objects';

interface WarningListProps {
  label: string;
  list: string[];
}

const WarningList = ({ label, list }: WarningListProps) => {
  if (list.length === 0) {
    return null;
  }

  return (
    <Box pt={2}>
      <Heading as="p" size="sm" pb={1}>
        {label}
      </Heading>
      <List spacing={3}>
        {list.map((item) => (
          <ListItem key={item} alignItems="center" display="flex" flexDirection="row" pl={4}>
            <ListIcon as={MdWarning} color="#F3C43D" />
            <Text>{item}</Text>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  missingRequiredMediaList: DealMedia[];
  onSuccess: () => void;
  onSuccessButtonLabel: string;
  unverifiedRequiredMediaList: DealMedia[];
}

const RequiredDocModal = ({
  isOpen,
  onClose,
  missingRequiredMediaList,
  onSuccessButtonLabel,
  onSuccess,
  unverifiedRequiredMediaList,
}: Props) => {
  const getMediaListLabels = (mediaList: DealMedia[]) =>
    mediaList.map((media) =>
      media.type && hasKey(DealMediaTypeLabels, media.type)
        ? DealMediaTypeLabels[media.type]
        : 'Uncategorized',
    );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      title="Missing or Unverified Docs"
      rightButtons={
        <>
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
              onSuccess();
            }}
          >
            {onSuccessButtonLabel.toUpperCase()}
          </Button>
          <Button onClick={onClose}>REVIEW DOCUMENTS</Button>
        </>
      }
    >
      <Text>There are the following issues with the external docs for this deal:</Text>
      <Box pt={2}>
        <WarningList
          label="Missing required document(s)"
          list={getMediaListLabels(missingRequiredMediaList)}
        />
        <WarningList
          label="Unverified required document(s)"
          list={getMediaListLabels(unverifiedRequiredMediaList)}
        />
      </Box>
    </Modal>
  );
};

export default RequiredDocModal;
