import { Dispatch, SetStateAction } from 'react';

import { useApolloClient } from '@apollo/client';
import { Button, Image, Text } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Article } from '../../../gql/articleGql';
import { ArticleImageUploadUrlDocument } from '../../../gql/generated/graphql';

import Dropzone from '../../../components/Dropzone';
import Input from '../../../components/shared/Input';
import Modal from '../../../components/shared/Modal';
import TextArea from '../../../components/shared/TextArea';

import { urlValidation } from '../../../libs/yup-validators/url';

interface UpsertArticleModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (a: Article) => void;
  articleToEdit: Article | null;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a title'),
  headline: Yup.string().required('Please enter a headline'),
  url: urlValidation,
});

const convertFileToDataUrl = async (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result);
    });

    reader.readAsDataURL(file);
  });
};

const UpsertArticleModal = ({
  showModal,
  setShowModal,
  handleSubmit,
  articleToEdit,
}: UpsertArticleModalProps) => {
  const client = useApolloClient();

  const initialValues = {
    title: '',
    headline: '',
    url: '',
    thumbnail_key: '',
    thumbnail: '',
  };

  const uploadImage = async (file: File) => {
    try {
      const { url: putUrl, key } = (
        await client.query({
          query: ArticleImageUploadUrlDocument,
        })
      )?.data?.articleImageUploadUrl;

      await fetch(putUrl, {
        method: 'PUT',
        body: file,
      });

      const url = await convertFileToDataUrl(file);

      return { url, key };
    } catch {
      toast.error('Unable to upload files');
      return null;
    }
  };

  const onSubmit = async (values: Article, { resetForm }: FormikHelpers<Article>) => {
    handleSubmit(values);
    resetForm();
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={articleToEdit || initialValues}
      validateOnBlur={false}
      enableReinitialize
    >
      {({
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }) => (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title={articleToEdit ? 'Edit Featured Article' : 'Add Featured Article'}
          showDivider={false}
          centerButtons={
            <Form>
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText={articleToEdit ? 'EDIT ARTICLE' : 'ADD ARTICLE'}
                isDisabled={articleToEdit ? false : !isValid}
              >
                {articleToEdit ? 'EDIT ARTICLE' : 'ADD ARTICLE'}
              </Button>
            </Form>
          }
        >
          <Form>
            <Input
              label="Title"
              name="title"
              value={values.title}
              onChange={handleChange}
              isInvalid={touched.title && !!errors.title}
              onBlur={handleBlur}
              maxLength={100}
              mb={4}
            />
            <TextArea
              label="Headline"
              name="headline"
              value={values.headline}
              onChange={handleChange}
              isInvalid={touched.headline && !!errors.headline}
              onBlur={handleBlur}
              maxLength={300}
              mb={4}
            />
            <Input
              label="URL"
              name="url"
              value={values.url}
              onChange={handleChange}
              isInvalid={touched.url && !!errors.url}
              onBlur={handleBlur}
              maxLength={250}
            />
            <Text mt={4}>Thumbnail</Text>
            <Image src={values.thumbnail} alt="thumbnail" mb={4} />
            <Dropzone
              onDrop={async (acceptedFiles) => {
                const image = await uploadImage(acceptedFiles[0]);
                if (image) {
                  setFieldValue('thumbnail', image.url);
                  setFieldValue('thumbnail_key', image.key);
                }
              }}
              accept="image/png, image/jpeg"
              showFiles={false}
              multiple={false}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default UpsertArticleModal;
