import { Dispatch, createContext } from 'react';

import { ApolloQueryResult } from '@apollo/client';

import { CustomerContactTypeEnum } from '../gql/customerGql';
import { Notification } from '../gql/generated/graphql';
import { Note } from '../gql/noteGql';

import { ActiveConversation } from '../components/TextWidget/Components/Message';
import { PermissionEnum } from '../constants/permissions';
import {
  CombinedFilterActions,
  CombinedFilterStates,
} from '../hooks/useUrlQueryParamsWithMultipleReducers';

export const InteractedContext = createContext<boolean>(false);

export const NotesContext = createContext<{
  notes: Note[];
  notesRefetch: () => Promise<ApolloQueryResult<Note[]>>;
}>({
  notes: [],
  notesRefetch: () => Promise.resolve({} as ApolloQueryResult<Note[]>),
});

export const AbilityContext = createContext<Set<PermissionEnum>>(new Set<PermissionEnum>());

export const TextWidgetContext = createContext<{
  textDealId: number | null;
  setTextDealId: Dispatch<number | null>;
  activeConversation: {
    role: CustomerContactTypeEnum | '';
    phone_number: string;
    first_name: string;
    last_name: string;
  };
  setActiveConversation: Dispatch<ActiveConversation>;
  unreadCount: number;
  setUnreadCount: Dispatch<number>;
  unreadNotifications: Notification[];
  setUnreadNotifications: Dispatch<Notification[]>;
}>({
  textDealId: null,
  setTextDealId: () => undefined,
  activeConversation: {
    role: '',
    phone_number: '',
    first_name: '',
    last_name: '',
  },
  setActiveConversation: () => undefined,
  unreadCount: 0,
  setUnreadCount: () => undefined,
  unreadNotifications: [],
  setUnreadNotifications: () => undefined,
});

export type ModalType = {
  ConfirmEdit: boolean;
  CreditDecisions: boolean;
  CustomerInfo: boolean;
  UserAssignment: boolean;
  PreviewModals: boolean;
  MediaModal: boolean;
  PaymentEstimator: boolean;
  PayoffInfo: boolean;
  UpdatePayoff: boolean;
  VehicleInfo: boolean;
  DealStateSelect: boolean;
  PayoffCheck: boolean;
};

export const initialModalState: ModalType = {
  ConfirmEdit: false,
  CreditDecisions: false,
  CustomerInfo: false,
  UserAssignment: false,
  PreviewModals: false,
  MediaModal: false,
  PaymentEstimator: false,
  PayoffInfo: false,
  UpdatePayoff: false,
  VehicleInfo: false,
  DealStateSelect: false,
  PayoffCheck: false,
};

export const ModalContext = createContext<{
  modals: ModalType;
  setModal: (modals: Partial<ModalType>) => void;
  modalCloseCount: number;
}>({
  modals: initialModalState,
  setModal: () => undefined,
  modalCloseCount: 0,
});

export const FiltersContext = createContext<{
  filters: CombinedFilterStates;
  dispatch: Dispatch<CombinedFilterActions>;
}>({
  filters: {
    global: {},
    titling: {},
    completed: {},
  } as CombinedFilterStates,
  dispatch: () => undefined,
});
