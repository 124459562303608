import { CreditDecision, CreditDecisionStatusEnum, Maybe } from '../gql/generated/graphql';

import { CreditDecisionStatusLabelEnum } from '../constants/creditDecisions';

type LeanCreditDecision = Pick<CreditDecision, 'application_status' | 'r1_credit_decision_data'>;

export const isCreditDecisionCountered = <T extends LeanCreditDecision>(cd?: Maybe<T>) => {
  const status = cd?.application_status;
  if (!status) {
    return false;
  }

  if (status === CreditDecisionStatusEnum.O || status === CreditDecisionStatusEnum.C) {
    return true;
  }

  const { applicationStatus, applicationStatusDetail } = cd.r1_credit_decision_data || {};

  return (
    applicationStatus === CreditDecisionStatusEnum.A &&
    !!applicationStatusDetail &&
    applicationStatusDetail.toLowerCase().includes(CreditDecisionStatusLabelEnum.C.toLowerCase())
  );
};

export const isCreditDecisionPendingStructure = <T extends LeanCreditDecision>(cd?: Maybe<T>) => {
  const status = cd?.application_status;
  if (!status) {
    return false;
  }

  if (status === CreditDecisionStatusEnum.P) {
    return true;
  }

  const { applicationStatus, applicationStatusDetail } = cd.r1_credit_decision_data || {};

  return (
    applicationStatus === CreditDecisionStatusEnum.A &&
    applicationStatusDetail &&
    applicationStatusDetail.toLowerCase().includes(CreditDecisionStatusLabelEnum.P.toLowerCase())
  );
};

export const isCreditDecisionApproved = <T extends LeanCreditDecision>(cd?: Maybe<T>) => {
  if (!cd?.application_status) {
    return false;
  }

  return (
    cd.application_status === CreditDecisionStatusEnum.A &&
    !isCreditDecisionCountered(cd) &&
    !isCreditDecisionPendingStructure(cd)
  );
};

export const isCreditDecisionPending = (status?: Maybe<CreditDecisionStatusEnum>) => {
  if (!status) {
    return false;
  }

  return (
    status === CreditDecisionStatusEnum.S ||
    status === CreditDecisionStatusEnum.R ||
    status === CreditDecisionStatusEnum.B ||
    status === CreditDecisionStatusEnum.N
  );
};

export const isCreditDecisionDenied = (status?: Maybe<CreditDecisionStatusEnum>) => {
  if (!status) {
    return false;
  }

  return status === CreditDecisionStatusEnum.D || status === CreditDecisionStatusEnum.I;
};

export const isCreditDecisionSelectable = <T extends LeanCreditDecision>(cd?: Maybe<T>) => {
  if (!cd?.application_status) {
    return false;
  }

  return (
    !isCreditDecisionDenied(cd.application_status) &&
    !isCreditDecisionPendingStructure(cd) &&
    (isCreditDecisionApproved(cd) || isCreditDecisionCountered(cd))
  );
};
