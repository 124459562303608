import { Box, Button, Stack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Review } from '../../../gql/reviewGql';

import Input from '../../../components/shared/Input';
import Modal from '../../../components/shared/Modal';
import Switch from '../../../components/shared/Switch';
import TextArea from '../../../components/shared/TextArea';

interface UpsertReviewModalProps {
  showModal: boolean;
  setShowModal: (a: boolean) => void;
  handleOnSubmit: (a: Review) => void;
  reviewToEdit: Review | null;
}

const validationSchema = Yup.object().shape({
  customer_name: Yup.string().required('Please enter a customer name'),
  review: Yup.string().required('Please enter a review'),
  com_visible: Yup.boolean().required('Please enter COM visibility'),
});

const UpsertReviewModal = ({
  showModal,
  setShowModal,
  handleOnSubmit,
  reviewToEdit,
}: UpsertReviewModalProps) => {
  const initialValues = {
    customer_name: reviewToEdit?.customer_name ?? '',
    location: reviewToEdit?.location ?? '',
    review: reviewToEdit?.review ?? '',
    com_visible: reviewToEdit?.com_visible ?? false,
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values) => handleOnSubmit(values)}
      initialValues={reviewToEdit || initialValues}
      validateOnBlur={false}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }) => (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title={reviewToEdit ? 'Edit Review' : 'Add Review to COM'}
          centerButtons={
            <Form>
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="SAVE REVIEW"
                isDisabled={reviewToEdit ? false : !isValid}
              >
                SAVE REVIEW
              </Button>
            </Form>
          }
        >
          <Form onSubmit={handleSubmit}>
            <Stack spacing={8}>
              <Input
                name="customer_name"
                label="Customer Name"
                value={values.customer_name}
                onChange={handleChange}
                isInvalid={touched.customer_name && !!errors.customer_name}
                onBlur={handleBlur}
                maxLength={30}
              />
              <Input
                name="location"
                label="Location"
                value={values.location}
                onChange={handleChange}
                isInvalid={touched.location && !!errors.location}
                onBlur={handleBlur}
                maxLength={30}
              />

              <Box>
                <TextArea
                  name="review"
                  label="Customer Review"
                  value={values.review}
                  onChange={handleChange}
                  isInvalid={touched.review && !!errors.review}
                  onBlur={handleBlur}
                  maxLength={1500}
                  h="200px"
                />
                <Switch
                  name="com_visible"
                  label="COM Visibility"
                  onChange={handleChange}
                  isInvalid={touched.com_visible && !!errors.com_visible}
                  onBlur={handleBlur}
                  mt={2}
                  justifySelf="right"
                />
              </Box>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default UpsertReviewModal;
