import {
  Collapse,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BsFillCaretDownFill, BsFillCaretRightFill } from 'react-icons/bs';
import Toggle from 'react-toggle';

import { WorkingHours, daysOfTheWeek } from '../utils';

const WorkingHoursTable = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<{ workingHours: WorkingHours }>();

  const collapse = useDisclosure({ defaultIsOpen: true });

  return (
    <Flex flexDirection="column" rounded="md" border="1px" p={2} width="100%" marginTop={2}>
      <Flex alignItems="center" gap={2} ml={1} onClick={collapse.onToggle} cursor="pointer">
        {collapse.isOpen ? (
          <BsFillCaretDownFill size="12px" />
        ) : (
          <BsFillCaretRightFill size="12px" />
        )}
        <Text fontWeight="bold">Hours</Text>
      </Flex>

      <Collapse in={collapse.isOpen}>
        <Flex>
          <Table className="mt-2">
            <Thead>
              <Tr>
                <Th className="col-md-2">Day</Th>
                <Th className="col-md-1">Working</Th>
                <Th className="col-md-4">Start</Th>
                <Th className="col-md-1" />
                <Th className="col-md-4">Finish</Th>
              </Tr>
            </Thead>
            <Tbody>
              {daysOfTheWeek.map(({ label, day }) => {
                const working = `working_${day}` as keyof WorkingHours;
                const start = `start_${day}` as keyof WorkingHours;
                const finish = `finish_${day}` as keyof WorkingHours;

                return (
                  <Tr key={day}>
                    <Td>{label}</Td>
                    <Td>
                      <Toggle
                        name={`workingHours.${working}`}
                        checked={values.workingHours[working] as boolean}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icons={false}
                      />
                    </Td>
                    <Td>
                      {values.workingHours[working] && (
                        <Input
                          type="time"
                          name={`workingHours.${start}`}
                          value={values.workingHours[start] as string}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.workingHours &&
                            errors.workingHours &&
                            touched.workingHours[start] &&
                            !!errors.workingHours[start]
                          }
                          placeholder="Start Time"
                          size="sm"
                        />
                      )}
                    </Td>
                    <Td>{values.workingHours[working] && 'to'}</Td>
                    <Td>
                      {values.workingHours[working] && (
                        <Input
                          type="time"
                          name={`workingHours.${finish}`}
                          value={values.workingHours[finish] as string}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.workingHours &&
                            errors.workingHours &&
                            touched.workingHours[finish] &&
                            !!errors.workingHours[finish]
                          }
                          placeholder="Finish Time"
                          size="sm"
                        />
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
      </Collapse>
    </Flex>
  );
};

export default WorkingHoursTable;
