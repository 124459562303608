import { useEffect, useState } from 'react';

import { Button, Text, Textarea } from '@chakra-ui/react';

import Modal from '../shared/Modal';

import { ModalDetails } from './index';

interface ModalProps extends ModalDetails {
  setShowModal: (arg: boolean) => void;
  showModal: boolean;
}

const UpdateNoteModal = ({
  showModal,
  setShowModal,
  loading,
  title,
  handleClick,
  clickText,
  type,
  noteText,
}: ModalProps) => {
  const [val, setVal] = useState(noteText);
  const isUpdateModal = type === 'update';

  useEffect(() => {
    setVal(noteText);
  }, [noteText]);

  return (
    <Modal
      title={title}
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      rightButtons={
        <>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            CLOSE
          </Button>
          <Button
            isLoading={loading}
            loadingText={clickText}
            variant={isUpdateModal ? 'primary' : 'warning'}
            onClick={() => {
              if (isUpdateModal) {
                handleClick(val);
              } else {
                handleClick();
              }
            }}
          >
            {clickText}
          </Button>
        </>
      }
    >
      {isUpdateModal ? (
        <Textarea
          value={val}
          name="note"
          onChange={(e) => {
            setVal(e.target.value);
          }}
        />
      ) : (
        <Text>{noteText}</Text>
      )}
    </Modal>
  );
};

export default UpdateNoteModal;
