import { Auth0RoleName, PodUser, TeamType } from '../gql/generated/graphql';

import { PodUsersByPod } from '../types/user';

export enum PodModalFormRoles {
  Closer = 'closer',
  Setter = 'setter',
  SetterLead = 'setter_lead',
  TitleClerk = 'title_clerk',
}

export const getAuth0RoleNameForTeam = (
  teamType: TeamType,
  role: PodModalFormRoles,
): Auth0RoleName => {
  switch (teamType) {
    case TeamType.D2d:
      if (role === PodModalFormRoles.Closer) {
        return Auth0RoleName.D2dCloser;
      }
      return Auth0RoleName.D2dSetter;
    case TeamType.Inbound:
      if (role === PodModalFormRoles.Closer) {
        return Auth0RoleName.InboundFinancialSpecialist;
      } // Inbound/Outbound financial specialists are pod agnostic
      if (role === PodModalFormRoles.SetterLead) {
        return Auth0RoleName.InboundSalesPodLead;
      }
      return Auth0RoleName.InboundSalesAdvisor;
    case TeamType.Outbound:
      if (role === PodModalFormRoles.SetterLead) {
        return Auth0RoleName.OutboundAdvisorManager;
      }
      if (role === PodModalFormRoles.Closer) {
        return Auth0RoleName.InboundFinancialSpecialist;
      } // Inbound/Outbound financial specialists are pod agnostic
      return Auth0RoleName.OutboundSetter;
    case TeamType.Titling:
      return Auth0RoleName.TitleClerk;
    default:
      return Auth0RoleName.InboundSalesAdvisor;
  }
};

export const groupTitlingUsersByPods = (allPodUsers: PodUser[]) => {
  const byPod = allPodUsers.reduce((acc, podUser) => {
    if (podUser.pod_id) {
      acc[podUser.pod_id] = acc[podUser.pod_id] ?? [];
      acc[podUser.pod_id].push(podUser);
    }

    return acc;
  }, {} as PodUsersByPod);

  return {
    ...byPod,
    all: allPodUsers,
    active: allPodUsers.filter((podUser) => !podUser.user?.deleted_at),
  };
};
