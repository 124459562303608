import { ChangeEventHandler, ComponentProps, useContext, useEffect } from 'react';

import { Flex, FlexProps, StyleProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../gql/dealGql';
import { StateAbbreviation, TitleRegistrationOption } from '../../../gql/generated/graphql';

import Switch from '../../shared/Switch';
import { sectionWidth, sectionsContainerProps } from '../DealInfoBuyoutForm';

import InputRow from './InputRow';

import { DealContext } from '../../../libs/DealContext';

const switchContainerProps: Partial<ComponentProps<typeof Switch>> = {
  pt: 2,
  pl: 3,
  switchProps: {
    size: 'md',
  },
};
interface SwitchesSectionProps extends FlexProps {
  isEditable: boolean;
  defaultFontSize?: StyleProps['fontSize'];
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const SwitchesSection = ({
  isEditable,
  defaultFontSize = 'sm',
  customHandleChange,
  ...rest
}: SwitchesSectionProps) => {
  const { jurisdiction } = useContext(DealContext);
  const { values, handleChange: formikHandleChange, setFieldValue } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const isTitleOnly =
    values.financial_info?.title_registration_option === TitleRegistrationOption.TitleOnly;
  const isRegistrationTransfer =
    values.financial_info?.title_registration_option ===
    TitleRegistrationOption.TitleAndRegistrationTransfer;
  const isMassachusetts = values.customer?.address?.state === StateAbbreviation.Ma;

  useEffect(() => {
    if (isTitleOnly && values.financial_info.plate_transfer) {
      setFieldValue('financial_info.plate_transfer', false);
    }
    if (
      (isTitleOnly || isRegistrationTransfer || isMassachusetts) &&
      values.financial_info.needs_temporary_registration_tags
    ) {
      setFieldValue('financial_info.needs_temporary_registration_tags', false);
    }
  }, [
    isTitleOnly,
    isRegistrationTransfer,
    isMassachusetts,
    values.financial_info.plate_transfer,
    values.financial_info.needs_temporary_registration_tags,
  ]);

  return (
    <Flex {...sectionsContainerProps} {...rest}>
      <Flex direction="column" w={sectionWidth}>
        <InputRow
          label="Moved State During Lease"
          infoTooltipLabel="Should be used to indicate when the title/lease originated in a state other than where they currently live or plan to move within 90 days of purchasing the vehicle."
          labelProps={{
            whiteSpace: 'normal',
          }}
        >
          <Switch
            id="moved_states"
            name="customer.address.moved_states"
            isNoYes
            noYesFontSize={defaultFontSize}
            isDisabled={!isEditable}
            customHandleChange={handleChange}
            {...switchContainerProps}
          />
        </InputRow>

        <InputRow
          label="Transfer Custom Plates"
          infoTooltipLabel="Should be used when customer has license plates they’d like to keep and transfer to their new registration."
          labelProps={{
            whiteSpace: 'normal',
          }}
        >
          <Switch
            id="plate_transfer"
            name="financial_info.plate_transfer"
            isNoYes
            noYesFontSize={defaultFontSize}
            isDisabled={!isEditable || isTitleOnly || !jurisdiction.allowPlateTransfer}
            customHandleChange={handleChange}
            {...switchContainerProps}
          />
        </InputRow>
      </Flex>

      <Flex direction="column" w={sectionWidth}>
        <InputRow
          label="Needs Temporary Registration Tags"
          infoTooltipLabel="Should be used when customer is near the end of their valid registration and needs temporary tags sent to them while we complete title work."
          labelProps={{
            whiteSpace: 'normal',
          }}
        >
          <Switch
            id="needs_temporary_registration_tags"
            name="financial_info.needs_temporary_registration_tags"
            isNoYes
            noYesFontSize={defaultFontSize}
            isDisabled={!isEditable || isTitleOnly || isRegistrationTransfer || isMassachusetts}
            customHandleChange={handleChange}
            {...switchContainerProps}
          />
        </InputRow>

        <InputRow
          label="Buyer/Co-Buyer are Different Than Original Lease"
          infoTooltipLabel="Should be used to indicate when the vehicle is being purchased by a buyer/cobuyer that was not on the original lease. Can result in double sales tax."
          labelProps={{
            whiteSpace: 'normal',
          }}
        >
          <Switch
            id="buyer_not_lessee"
            name="financial_info.buyer_not_lessee"
            isNoYes
            noYesFontSize={defaultFontSize}
            isDisabled={!isEditable}
            customHandleChange={handleChange}
            {...switchContainerProps}
          />
        </InputRow>
      </Flex>
    </Flex>
  );
};
