import { useState } from 'react';

import { Box, Icon, Image, Text, Tooltip, VStack } from '@chakra-ui/react';
import { MdPerson } from 'react-icons/md';
import { VscFilePdf } from 'react-icons/vsc';

import { CustomerContactTypeEnum } from '../../../gql/customerGql';

import { ChatMessage } from '../TextModal';

import CustomerBubble from './CustomerBubble';

import { getInitials } from '../../../utils/text';

export interface ActiveConversation {
  role: CustomerContactTypeEnum | '';
  first_name: string;
  last_name: string;
  phone_number: string;
}

type MessageProps = {
  message: ChatMessage;
  activeConversation: ActiveConversation;
};

const MessageBubble = ({ message, activeConversation }: MessageProps) => {
  const { first_name, last_name, role } = activeConversation;
  const { mediaListObj } = message.data;
  const [showDate, setShowDate] = useState<boolean>(false);
  const textMessage = message.data.text;
  const textDate = message.data.dateCreated;
  const isToday = () => {
    const date = textDate?.split(' at')[0];
    const thisWeekday = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date());
    const thisMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(new Date());
    const thisDate = new Date().getDate();
    return date === `${thisWeekday}, ${thisMonth} ${thisDate}`;
  };
  return (
    <Box>
      <Box
        display="flex"
        onClick={() => {
          setShowDate(!showDate);
        }}
        justifyContent={message.fromCustomer ? 'left' : 'right'}
      >
        {message.fromCustomer && (
          <CustomerBubble firstName={first_name} lastName={last_name} customerType={role} />
        )}
        <VStack ml={4} maxW="75%" display="grid">
          {mediaListObj?.data?.length
            ? mediaListObj?.data?.map((ml) => {
                if (ml.url && ml.contentType === 'application/pdf') {
                  return (
                    <Box
                      bgColor="gray.200"
                      p={2}
                      borderRadius={
                        message.fromCustomer ? '10px 10px 10px 0px' : '10px 10px 0px 10px'
                      }
                      ml={4}
                      justifySelf={message.fromCustomer ? 'left' : 'right'}
                    >
                      <a href={ml.url} target="_blank" rel="noreferrer">
                        <Icon as={VscFilePdf} boxSize={12} />
                      </a>
                    </Box>
                  );
                }
                if (ml.url) {
                  return (
                    <Box
                      bgColor="gray.200"
                      p={2}
                      borderRadius={
                        message.fromCustomer ? '10px 10px 10px 0px' : '10px 10px 0px 10px'
                      }
                      justifySelf={message.fromCustomer ? 'left' : 'right'}
                    >
                      <Box minH="150px">
                        <Image src={ml.url} maxH="400px" />
                      </Box>
                    </Box>
                  );
                }
                return null;
              })
            : null}
          {textMessage?.length ? (
            <Box
              bgColor={message.fromCustomer ? 'gray.200' : 'azureishWhite'}
              p={2}
              borderRadius={message.fromCustomer ? '10px 10px 10px 0px' : '10px 10px 0px 10px'}
              justifySelf={message.fromCustomer ? 'left' : 'right'}
              wordBreak="break-word"
            >
              {textMessage.split('\n').map((line) => {
                return <Text>{line}</Text>;
              })}
            </Box>
          ) : null}
        </VStack>
        {!message.fromCustomer && (
          <Tooltip hidden={!message.author} label={<span>{message.author}</span>}>
            <Box
              ml={4}
              borderRadius="50%"
              w="42px"
              h="42px"
              bgColor="primary"
              color="white"
              alignSelf="end"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize={19}
            >
              {message.author ? (
                getInitials(message.author.toUpperCase())
              ) : (
                <Icon as={MdPerson} boxSize={7} />
              )}
            </Box>
          </Tooltip>
        )}
      </Box>
      {showDate && (
        <Box textAlign={message.fromCustomer ? 'left' : 'right'} mr="58px" ml="58px">
          {!isToday() ? textDate : textDate?.split('at ')[1]}
        </Box>
      )}
    </Box>
  );
};

export default MessageBubble;
