import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button, Checkbox, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import { isUneditable } from '../../gql/dealGql';
import {
  CreditApplicationSubmissionsQuery,
  Maybe,
  useManuallySelectBankMutation,
  useSelectCreditDecisionMutation,
} from '../../gql/generated/graphql';

import CustomReactSelect from '../shared/CustomReactSelect';
import Modal, { LEModalProps } from '../shared/Modal';
import Tooltip from '../shared/Tooltip';
import { Option } from '../shared/types';

import CreditDecision from './CreditDecision';
import CreditDecisionDetails from './CreditDecisionDetails';
import CreditDecisionSkeleton from './CreditDecisionSkeleton';
import useCreditDecisions from './useCreditDecisions';

import { BankEnum } from '../../constants/bank';
import { useBanks } from '../../hooks/useBanks';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { ModalContext } from '../../libs/contextLib';

export type CreditDecisionFromQuery = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        CreditApplicationSubmissionsQuery['creditApplicationSubmissions'][number]
      >['credit_applications']
    >[number]
  >['credit_decisions']
>[number];

type CreditDecisionsModalProps = LEModalProps;

const CreditDecisionsModal = (props: CreditDecisionsModalProps) => {
  const { deal, dispatch } = useContext(DealContext);
  const isDealEditable = !isUneditable(deal.state);
  const { setModal } = useContext(ModalContext);
  const [creditDecisionViewId, setCreditDecisionViewId] = useState<Maybe<string>>(null);

  const [isManuallySelectBankChecked, setIsManuallySelectBankChecked] = useState(
    !!deal.financial_info.bank && !deal.financial_info.selected_credit_decision_id,
  );
  const [selectedManualBank, setSelectedManualBank] = useState<BankEnum | undefined>(
    deal.financial_info.bank,
  );

  const { banks } = useBanks({ deal, onlyActive: true });
  const bankOptions: Option[] = useMemo(
    () => banks.map((bank) => ({ label: bank.name ?? '', value: bank.name ?? '' })),
    [banks, deal.customer.address.state],
  );

  const { creditDecisions, creditDecisionsLoading, getBankNamesByR1Fsid } = useCreditDecisions({
    isManuallySelectBankChecked,
  });
  const [selectedCreditDecisionId, setSelectedCreditDecisionId] = useState<Maybe<number>>(
    deal.financial_info.selected_credit_decision_id || null,
  );

  const selectedCreditDecisionView = creditDecisions.find((cd) => cd?.id === creditDecisionViewId);

  const setSelectedCreditDecision = useCallback(
    (id: Maybe<number>) => {
      if (isManuallySelectBankChecked) {
        setIsManuallySelectBankChecked(false);
      }

      setSelectedCreditDecisionId(id);
    },
    [isManuallySelectBankChecked],
  );

  useEffect(() => {
    if (isManuallySelectBankChecked) {
      setSelectedCreditDecisionId(null);
    }
  }, [isManuallySelectBankChecked]);

  const handleManualBankChange = useCallback(
    (bankOption: SingleValue<Option>) => {
      if (!isManuallySelectBankChecked || !bankOption) {
        return;
      }

      setSelectedManualBank(bankOption.value as BankEnum);
    },
    [isManuallySelectBankChecked],
  );

  const handleViewDetailsClick = useCallback((id: Maybe<string>) => {
    setCreditDecisionViewId(id);
  }, []);

  const [selectCreditDecision, { loading: selectCreditDecisionLoading }] =
    useSelectCreditDecisionMutation();
  const [manuallySelectBank, { loading: manuallySelectBankLoading }] =
    useManuallySelectBankMutation();

  const isSaveButtonLoading =
    creditDecisionsLoading || selectCreditDecisionLoading || manuallySelectBankLoading;

  const handleSave = useCallback(async () => {
    if (isManuallySelectBankChecked) {
      if (!deal.id) {
        return;
      }

      if (!selectedManualBank) {
        toast.error('Please select a Bank');
        return;
      }

      try {
        const { data } = await manuallySelectBank({
          variables: {
            dealId: deal.id,
            bank: selectedManualBank,
          },
        });

        if (!data?.manuallySelectBank) {
          toast.error('Failed to save Credit Decision');
          return;
        }

        dispatch({
          type: DealActionsEnum.DeepUpdateDeal,
          payload: {
            financial_info: {
              bank: data?.manuallySelectBank.bank as BankEnum,
              selected_credit_decision_id: data?.manuallySelectBank.selected_credit_decision_id,
              first_payment_date: data?.manuallySelectBank.first_payment_date,
            },
          },
        });
      } catch (ignore) {
        toast.error('Failed to save Credit Decision');
        return;
      }

      // eslint-disable-next-line react/destructuring-assignment
      props.onClose();
      setModal({ CreditDecisions: false });
      return;
    }

    if (!selectedCreditDecisionId) {
      toast.error('Please select a Credit Decision');
      return;
    }

    try {
      const { data } = await selectCreditDecision({
        variables: {
          id: selectedCreditDecisionId,
        },
      });

      const updatedFinancialInfo = data?.selectCreditDecision?.financialInfo;

      if (!updatedFinancialInfo) {
        toast.error('Failed to save Credit Decision');
        return;
      }

      dispatch({
        type: DealActionsEnum.DeepUpdateDeal,
        payload: {
          financial_info: {
            bank: updatedFinancialInfo.bank as BankEnum,
            selected_credit_decision_id: updatedFinancialInfo.selected_credit_decision_id as number,
            term: updatedFinancialInfo.term as number,
            sell_rate: updatedFinancialInfo.sell_rate as number,
            buy_rate: updatedFinancialInfo.buy_rate as number,
            first_payment_date: updatedFinancialInfo.first_payment_date,
          },
        },
      });
    } catch (e) {
      const err = e as Error;
      toast.error(err.message, { autoClose: false });
      return;
    }

    // eslint-disable-next-line react/destructuring-assignment
    props.onClose();
    setModal({ CreditDecisions: false });
  }, [isManuallySelectBankChecked, selectedManualBank, selectedCreditDecisionId]);

  const shouldSelectCreditDecision = !isManuallySelectBankChecked && !selectedCreditDecisionId;
  const shouldManuallySelectBank = isManuallySelectBankChecked && !selectedManualBank;
  const tooltipErrors = [
    shouldSelectCreditDecision ? 'Please select a Credit Decision' : '',
    shouldManuallySelectBank ? 'Please select a Bank' : '',
  ];

  return (
    <Modal
      title="Credit App Decisions"
      size="4xl"
      canDismiss={false}
      {...props}
      {...(creditDecisionViewId == null && {
        leftButtons: (
          <Button
            variant="warning"
            onClick={() => {
              // eslint-disable-next-line react/destructuring-assignment
              props.onClose();
              setModal({ CreditDecisions: false });
            }}
          >
            CANCEL
          </Button>
        ),
      })}
      rightButtons={
        creditDecisionViewId == null ? (
          <Tooltip errors={tooltipErrors}>
            <Button
              variant="primary"
              type="submit"
              isDisabled={
                shouldSelectCreditDecision || isSaveButtonLoading || shouldManuallySelectBank
              }
              isLoading={isSaveButtonLoading}
              onClick={handleSave}
            >
              SAVE
            </Button>
          </Tooltip>
        ) : (
          <Button variant="primary" type="button" onClick={() => setCreditDecisionViewId(null)}>
            Back
          </Button>
        )
      }
    >
      {creditDecisionViewId == null ? (
        <VStack spacing={4} alignItems="flex-start">
          {creditDecisionsLoading ? (
            <SimpleGrid columns={[1, null, 2]} spacing={4}>
              <CreditDecisionSkeleton />
            </SimpleGrid>
          ) : !creditDecisions?.length ? (
            <Text fontSize="lg">There are no Credit Decisions</Text>
          ) : (
            <SimpleGrid w="full" columns={[1, null, 2]} spacing={4}>
              {creditDecisions.map((decision) => (
                <CreditDecision
                  key={decision?.id}
                  bankName={getBankNamesByR1Fsid(decision?.r1_fsid)}
                  creditDecision={decision}
                  isSelected={decision?.id === selectedCreditDecisionId}
                  isEditable={isDealEditable}
                  setSelectedCreditDecisionId={setSelectedCreditDecision}
                  handleViewDetailsClick={handleViewDetailsClick}
                />
              ))}
            </SimpleGrid>
          )}
          <VStack spacing={1} alignItems="flex-start">
            <HStack spacing={8} w="full">
              <Checkbox
                fontWeight="bold"
                isDisabled={creditDecisionsLoading || !isDealEditable}
                isChecked={isManuallySelectBankChecked}
                onChange={(e) => setIsManuallySelectBankChecked(e.target.checked)}
              >
                Manually Select Bank
              </Checkbox>

              {isManuallySelectBankChecked && (
                <Box w="25%">
                  <CustomReactSelect
                    isDisabled={
                      creditDecisionsLoading || !isManuallySelectBankChecked || !isDealEditable
                    }
                    isClearable={false}
                    options={bankOptions}
                    value={bankOptions.find((option) => option.value === selectedManualBank)}
                    onChange={handleManualBankChange}
                  />
                </Box>
              )}
            </HStack>
            {isManuallySelectBankChecked && (
              <Text fontSize="xs" color="errorsRed">
                This is for informational purposes only. You must manually select the Credit
                Application in Route One and set the term and rate in DD.
              </Text>
            )}
          </VStack>
        </VStack>
      ) : (
        <CreditDecisionDetails
          bankName={getBankNamesByR1Fsid(selectedCreditDecisionView?.r1_fsid)}
          creditDecision={selectedCreditDecisionView}
        />
      )}
    </Modal>
  );
};

export default CreditDecisionsModal;
