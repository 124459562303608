import { useMemo } from 'react';

import { Deal } from '../gql/dealGql';
import {
  DealMediaTypeEnum,
  DealType,
  Deal as GeneratedDeal,
  DealMedia as GeneratedDealMedia,
  Maybe,
  RequiredDocumentsAndValidationsQueryVariables,
  StateAbbreviation,
  TitleRegistrationOption,
  TtProductType,
} from '../gql/generated/graphql';
import { mapBankEnumToTTNewBankEnum } from '../gql/taterTitleGql';

import { BankEnum } from '../constants/bank';
import { isValidStateAbbreviation } from '../libs/states';
import { DealMedia, DriverLicenseMetadata } from '../types/media';

const useRequiredDocsVariables = (
  deal: Deal | GeneratedDeal,
  media: Maybe<Maybe<DealMedia | GeneratedDealMedia>[]>,
): {
  skipRequiredDocs: boolean;
  requiredDocsVariables: RequiredDocumentsAndValidationsQueryVariables;
} => {
  const skipRequiredDocs = useMemo(
    () => !deal.customer?.address?.state || !deal.car?.year || deal.type === DealType.Acquisition,
    [deal.customer?.address?.state, deal.car?.year, deal.type],
  );

  const requiredDocsVariables = useMemo((): RequiredDocumentsAndValidationsQueryVariables => {
    const frontOfDriverLicense = media?.find(
      (m) => m?.type === DealMediaTypeEnum.FrontOfDriversLicense,
    );
    const frontOfDriverLicenseMetadata = (frontOfDriverLicense?.metadata ||
      {}) as DriverLicenseMetadata;

    return {
      state: deal.customer?.address?.state as StateAbbreviation,
      conditions: {
        // Send null if empty string. Empty string causes an error.
        lienholder: deal.car?.payoff?.lienholder_slug || null,
        hasCobuyer: !!deal.cobuyer?.first_name,
        driversLicenseAddress:
          frontOfDriverLicenseMetadata.address || deal.customer?.address?.address_line,
        currentAddress: deal.customer?.address?.address_line,
        vehicleAge: new Date().getFullYear() - parseInt(deal.car?.year || '0', 10),
        driversLicenseState: isValidStateAbbreviation(frontOfDriverLicenseMetadata.state)
          ? frontOfDriverLicenseMetadata.state
          : deal.customer?.address?.state,
        movedStateDuringLease: !!deal.customer?.address?.moved_states,
        transferringPlates: !!deal.financial_info?.plate_transfer,
        transferringRegistration:
          deal.financial_info?.title_registration_option ===
          TitleRegistrationOption.TitleAndRegistrationTransfer,
        jurisdictionState: deal.customer?.address?.state,
        canVerifyDigitalSignatures: !!deal.signing_on_com,
        newBank: mapBankEnumToTTNewBankEnum(deal.financial_info?.bank as Maybe<BankEnum>),
      },
      types:
        deal.financial_info?.title_registration_option === TitleRegistrationOption.TitleOnly
          ? [TtProductType.Title]
          : [TtProductType.Title, TtProductType.Registration],
    };
  }, [
    deal.customer?.address?.state,
    deal.car?.payoff?.lienholder_slug,
    deal.cobuyer?.first_name,
    deal.customer?.address?.address_line,
    deal.car?.year,
    media,
    deal.customer?.address?.moved_states,
    deal.financial_info?.plate_transfer,
    deal.signing_on_com,
    deal.financial_info?.bank,
    deal.financial_info?.title_registration_option,
  ]);

  return { skipRequiredDocs, requiredDocsVariables };
};

export default useRequiredDocsVariables;
