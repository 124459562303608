import { SyntheticEvent, useContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Center,
  Input as ChakraInput,
  HStack,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';

import { determineApprovalQuery } from '../../gql/conditionReportGql';
import { Deal } from '../../gql/dealGql';

import { AutoExplodeZip } from '../AutoExplodeZip';
import { BootActionsWithContext } from '../Task/TaskActions/BootAction';
import CardSubHeaderV2 from '../shared/Card/components/CardSubHeaderV2';
import Input from '../shared/Input';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';
import Select from '../shared/Select';

import FinalizeDealButton from './FinalizeDealButton';
import RequestApprovalButton from './RequestApprovalButton';

import SELECT_OPTIONS from '../../constants/selectOptions';
import { DealContext } from '../../libs/DealContext';
import { ColorsEnum } from '../../libs/colorLib';
import { formatMoney } from '../../libs/utils';

const ClosingView = () => {
  const { deal } = useContext(DealContext);
  const { values, setFieldValue, touched, errors, handleChange, handleBlur } =
    useFormikContext<Deal>();
  const { car, condition_report, financial_info_acquisition, customer } = values;
  const [showXModal, setShowXModal] = useState<boolean>(false);
  const [zipLoading, setZipLoading] = useState<boolean>(false);
  const [failedCriteria, setFailedCriteria] = useState<string[]>();

  const [isAddressLineFocused, setAddressLineFocused] = useState<boolean>(false);
  const [approvedAmount, setApprovedAmount] = useState<number | undefined>(undefined);

  const [determineApproval] = useLazyQuery(determineApprovalQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ determineApprovalQuery: { approved, failedCriteria: failReason } }) => {
      setFieldValue('financial_info_acquisition.is_approved', approved);
      setFailedCriteria(failReason);
    },
  });

  useEffect(() => {
    determineApproval({
      variables: {
        data: {
          accidents: condition_report?.accidents,
          tires: condition_report?.tires,
          exterior: condition_report?.exterior,
          interior: condition_report?.interior,
          smoked_in: condition_report?.smoked_in,
          lights_on_dash: condition_report?.lights_on_dash,
          overall_condition: condition_report?.overall_condition,
          year: car.year,
          payoff: car.payoff.vehicle_payoff,
          book_value: car.book_value,
          kbb_trim: car.kbb_trim_name,
          vin: car.vin,
          mileage: car.mileage,
          color: car.color,
          vehicle_type: car.vehicle_type,
          state: customer.address?.state,
          financial_info_acquisition_id: financial_info_acquisition.id,
        },
      },
    });
  }, [condition_report, car]);

  useEffect(() => {
    if (approvedAmount && financial_info_acquisition.cash_to_customer === approvedAmount) {
      setFieldValue('financial_info_acquisition.is_approved', true);
    } else if (deal.financial_info_acquisition.is_approved) {
      if (
        financial_info_acquisition.cash_to_customer &&
        financial_info_acquisition.cash_to_customer >
          (financial_info_acquisition.max_cash_to_customer ?? 0)
      ) {
        setFieldValue('financial_info_acquisition.is_approved', false);
      } else {
        setFieldValue('financial_info_acquisition.is_approved', true);
      }
    }
  }, [financial_info_acquisition.cash_to_customer]);

  useEffect(() => {
    if (
      deal.financial_info_acquisition.overriding_user_id &&
      deal.financial_info_acquisition.is_approved
    ) {
      setApprovedAmount(deal.financial_info_acquisition.cash_to_customer);
    }
  }, []);

  return (
    <Box bgColor="white" p={4}>
      <Center>
        {financial_info_acquisition.is_approved ? (
          <BiCheckCircle size="200" color={ColorsEnum.GREEN} />
        ) : (
          <>
            <BiXCircle
              size="200"
              className="ml-2 mb-4"
              color={ColorsEnum.RED}
              onClick={() => setShowXModal(true)}
            />
            <Modal
              title="Reasons for not being approved..."
              isOpen={showXModal}
              onClose={() => setShowXModal(false)}
            >
              <List>
                {failedCriteria?.map((fc) => (
                  <ListItem key={fc} color="warning">
                    {fc}
                  </ListItem>
                ))}
              </List>
            </Modal>
          </>
        )}
      </Center>
      <HStack>
        <NumberInput
          label="Cash To Customer"
          isMoney
          name="financial_info_acquisition.cash_to_customer"
        />
      </HStack>
      <HStack>
        <Text>Max Cash To Customer:</Text>
        <Text>{formatMoney(values.financial_info_acquisition.max_cash_to_customer)}</Text>
      </HStack>

      <HStack>
        <Text>Max Total Cost:</Text>
        <Text>{formatMoney(values.financial_info_acquisition.max_total_cost)}</Text>
      </HStack>
      <CardSubHeaderV2 title="Personal Info" />
      <HStack>
        <Input
          label="Address Line 1"
          name="customer.address.address_line"
          value={values.customer.address?.address_line}
          onChange={handleChange}
          onFocus={() => setAddressLineFocused(true)}
          onBlur={(e: SyntheticEvent) => {
            handleBlur(e);
            setAddressLineFocused(false);
          }}
          isInvalid={
            touched.customer?.address?.address_line && !!errors.customer?.address?.address_line
          }
        />
        <Input
          label="Address Line 2"
          name="customer.address.address_line_2"
          value={values.customer.address?.address_line_2}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={
            touched.customer?.address?.address_line_2 && !!errors.customer?.address?.address_line_2
          }
        />
      </HStack>
      <HStack my={4}>
        <Input
          label="Zip Code"
          name="customer.address.zip"
          value={values.customer.address?.zip}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.customer?.address?.zip && !!errors.customer?.address?.zip}
        />
        <Input
          label="City"
          name="customer.address.city"
          value={values.customer.address?.city}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.customer?.address?.city && !!errors.customer?.address?.city}
        />

        <Select
          label="State"
          emptyOption={false}
          placeholder="State"
          id="customer.address.state"
          name="customer.address.state"
          options={SELECT_OPTIONS.US_STATES}
          isRequired
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.customer?.address?.state && !!errors.customer?.address?.state}
        />
        <Spinner
          animation="border"
          className="spinner"
          variant="primary"
          size="sm"
          hidden={!zipLoading}
        />
      </HStack>
      <HStack>
        <Input
          label="County"
          name="customer.address.county"
          value={values.customer.address?.county}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.customer?.address?.county && !!errors.customer?.address?.county}
        />
        <AutoExplodeZip
          name="customer"
          setZipLoading={setZipLoading}
          isAddressLineFocused={isAddressLineFocused}
        />
        <Stack>
          <Text fontSize="14px">Date of Birth</Text>
          <ChakraInput type="date" bgColor="white" defaultValue={new Date().toISOString()} />
        </Stack>
      </HStack>
      <HStack>
        {financial_info_acquisition.is_approved ? (
          <FinalizeDealButton />
        ) : deal.financial_info_acquisition.is_approved ? (
          <RequestApprovalButton />
        ) : (
          <BootActionsWithContext allowAllToBoot />
        )}
      </HStack>
    </Box>
  );
};

export default ClosingView;
