import { useContext } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Auction, auctionDistancesQuery } from '../../gql/auctionGql';
import { Deal } from '../../gql/dealGql';

import { DealContext } from '../../libs/DealContext';

interface Props {
  auctions: Auction[];
}

const DetermineBestAuctionButton = ({ auctions }: Props) => {
  const { deal } = useContext(DealContext);
  const { setFieldValue } = useFormikContext<Deal>();
  const {
    customer: {
      address: { address_line, zip, state },
    },
  } = deal;

  const [getAuctionDistances, { loading }] = useLazyQuery(auctionDistancesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ auctionDistances }) => {
      setFieldValue('financial_info_acquisition.transport_cost', auctionDistances.transport_cost);
      setFieldValue('financial_info_acquisition.auction_id', auctionDistances.id);
    },
  });

  return (
    <Button
      isLoading={loading}
      loadingText="DETERMINE BEST AUCTION"
      onClick={() => {
        const mappedAuctions = auctions.map((auction) => ({
          name: auction.name,
          address: `${auction.address_line} ${auction.zip}`,
          state: auction.state,
          id: auction.id,
        }));

        getAuctionDistances({
          variables: {
            data: {
              customerAddress: `${address_line} ${zip}`,
              customerState: state,
              auctions: mappedAuctions,
            },
          },
        });
      }}
    >
      DETERMINE BEST AUCTION
    </Button>
  );
};

export default DetermineBestAuctionButton;
