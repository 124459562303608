import { DealStateEnum } from '../gql/dealGql';
import {
  DealMediaTypeEnum,
  Maybe,
  TtDocumentName as TTDocumentName,
} from '../gql/generated/graphql';

import { CustomDates } from '../types/dealDates';

export const DocumentTypeMap: Record<TTDocumentName, DealMediaTypeEnum> = {
  [TTDocumentName.BackOfDriversLicense]: DealMediaTypeEnum.BackOfDriversLicense,
  [TTDocumentName.CobuyerBackOfDriversLicense]: DealMediaTypeEnum.BackOfCoBuyersLicense,
  [TTDocumentName.CobuyerDriversLicense]: DealMediaTypeEnum.FrontOfCoBuyersLicense,
  [TTDocumentName.DriversLicense]: DealMediaTypeEnum.FrontOfDriversLicense,
  [TTDocumentName.Emissions]: DealMediaTypeEnum.Emissions,
  [TTDocumentName.Odometer]: DealMediaTypeEnum.OdoDoc,
  [TTDocumentName.HondaSpecificOdometerStatement]: DealMediaTypeEnum.HondaSpecificOdometerDoc,
  [TTDocumentName.AcuraSpecificOdometerStatement]: DealMediaTypeEnum.AcuraSpecificOdometerDoc,
  [TTDocumentName.ProofOfInsurance]: DealMediaTypeEnum.FrontOfInsuranceCard,
  [TTDocumentName.ProofOfResidence]: DealMediaTypeEnum.ProofOfResidence,
  [TTDocumentName.ProofOfResidence2]: DealMediaTypeEnum.ProofOfResidence2,
  [TTDocumentName.PropertyTaxReceipt]: DealMediaTypeEnum.PropertyTaxReceipt,
  [TTDocumentName.Registration]: DealMediaTypeEnum.Registration,
  [TTDocumentName.VinInspection]: DealMediaTypeEnum.VinInspection,
  [TTDocumentName.SafetyInspection]: DealMediaTypeEnum.SafetyInspection,
  [TTDocumentName.PowerOfAttorney]: DealMediaTypeEnum.PowerOfAttorney,
  [TTDocumentName.StateSpecificPowerOfAttorney]: DealMediaTypeEnum.StateSpecificPowerOfAttorney,
  [TTDocumentName.SecurePowerOfAttorney]: DealMediaTypeEnum.SecurePowerOfAttorney,
  [TTDocumentName.BillOfSale]: DealMediaTypeEnum.PurchaseOrder,
  [TTDocumentName.StateSpecificBillOfSale]: DealMediaTypeEnum.StateSpecificPurchaseOrder,
  [TTDocumentName.OdometerStatement]: DealMediaTypeEnum.LeaseendOdoDoc,
  [TTDocumentName.CobuyerOdometerStatement]: DealMediaTypeEnum.LeaseendOdoDocCobuyer,
  [TTDocumentName.JointOdometerStatement]: DealMediaTypeEnum.LeaseendOdoDocJoint,
  [TTDocumentName.StateSpecificOdometerStatement]: DealMediaTypeEnum.StateSpecificOdoDoc,
  [TTDocumentName.CobuyerStateSpecificOdometerStatement]:
    DealMediaTypeEnum.CobuyerStateSpecificOdoDoc,
  [TTDocumentName.TitleApp]: DealMediaTypeEnum.TitleApplication,
  [TTDocumentName.StateSpecificDamageDisclosure]: DealMediaTypeEnum.StateSpecificDamageDisclosure,
  [TTDocumentName.RegistrationSpecificPowerOfAttorney]:
    DealMediaTypeEnum.RegistrationSpecificPowerOfAttorney,
  [TTDocumentName.CobuyerPowerOfAttorney]: DealMediaTypeEnum.PowerOfAttorneyCobuyer,
  [TTDocumentName.CobuyerStateSpecificPowerOfAttorney]:
    DealMediaTypeEnum.StateSpecificPowerOfAttorneyCobuyer,
  [TTDocumentName.CobuyerRegistrationSpecificPowerOfAttorney]:
    DealMediaTypeEnum.CobuyerRegistrationSpecificPowerOfAttorney,
  [TTDocumentName.CobuyerSecurePowerOfAttorney]: DealMediaTypeEnum.CobuyerSecurePowerOfAttorney,
  [TTDocumentName.SalesTaxExemption]: DealMediaTypeEnum.SalesTaxExemption,
  [TTDocumentName.OhioSpecificHondaSpecificPowerOfAttorney]:
    DealMediaTypeEnum.StateAndLienholderSpecificPowerOfAttorney,
  [TTDocumentName.PurchaseOrder]: DealMediaTypeEnum.PurchaseOrder,
  [TTDocumentName.SupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause]:
    DealMediaTypeEnum.ColoradoSupplementalIdentificationClause,
  [TTDocumentName.CobuyerSupplementalSecureAndVerifiableIdentificationInformationAndAttestationClause]:
    DealMediaTypeEnum.ColoradoCobuyerSupplementalIdentificationClause,
  [TTDocumentName.CobuyerJointTenancyWithRightsOfSurvivorship]:
    DealMediaTypeEnum.ColoradoJointTenancy,
  [TTDocumentName.GmLeasePacket]: DealMediaTypeEnum.GmLeasePacket,
  [TTDocumentName.PlateTransferLetter]: DealMediaTypeEnum.PlateTransferLetter,
  [TTDocumentName.ElectronicSignatureAttestation]: DealMediaTypeEnum.ElectronicSignatureAttestation,
  [TTDocumentName.ElectronicSignatureCertification]:
    DealMediaTypeEnum.ElectronicSignatureCertification,
  [TTDocumentName.LicensePlate]: DealMediaTypeEnum.LicensePlate,
  [TTDocumentName.IdahoSalesTaxExemption]: DealMediaTypeEnum.IdahoSalesTaxExemption,

  [TTDocumentName.PorscheSpecificPayoffInstructions]:
    DealMediaTypeEnum.PorscheSpecificPayoffInstructions,
  [TTDocumentName.PorscheSpecificOdometerStatement]: DealMediaTypeEnum.PorscheSpecificOdometerDoc,

  [TTDocumentName.ElectronicRegistrationAndTitleAgreement]:
    DealMediaTypeEnum.ConnecticutElectronicRegistrationAndTitleAgreement,
};

// keep in sync with the backend
export const DealMediaTypeLabels: Record<DealMediaTypeEnum, string> = {
  [DealMediaTypeEnum.RateExceptionForm]: 'Rate Exception Form',
  [DealMediaTypeEnum.PicturesOfVehicle]: 'Pictures of Vehicle',
  [DealMediaTypeEnum.OdometerPicture]: 'Odometer Picture',
  [DealMediaTypeEnum.FrontOfDriversLicense]: "Driver's License - Front",
  [DealMediaTypeEnum.BackOfDriversLicense]: "Driver's License - Back",
  [DealMediaTypeEnum.FrontOfInsuranceCard]: 'Insurance Card - Front',
  [DealMediaTypeEnum.BackOfInsuranceCard]: 'Insurance Card - Back',
  [DealMediaTypeEnum.Registration]: 'Registration',
  [DealMediaTypeEnum.LeaseAgreement]: 'Lease Agreement',
  [DealMediaTypeEnum.OdoDoc]: 'Odo Doc',
  [DealMediaTypeEnum.LeaseendOdoDoc]: 'Lease End Odo',
  [DealMediaTypeEnum.LeaseendOdoDocCobuyer]: 'Lease End Odo (Co-buyer)',
  [DealMediaTypeEnum.LeaseendOdoDocJoint]: 'Lease End Odo (Joint)',
  [DealMediaTypeEnum.GmLeasePacket]: 'GM Lease Packet',
  [DealMediaTypeEnum.HondaSpecificOdometerDoc]: 'Honda Specific Odo Doc',
  [DealMediaTypeEnum.AcuraSpecificOdometerDoc]: 'Acura Specific Odo Doc',
  [DealMediaTypeEnum.StateSpecificOdoDoc]: 'State Specific Odo Doc',
  [DealMediaTypeEnum.CobuyerStateSpecificOdoDoc]: 'State Specific Odo Doc (Co-buyer)',
  [DealMediaTypeEnum.VinInspection]: 'VIN Inspection',
  [DealMediaTypeEnum.SafetyInspection]: 'Safety Inspection',
  [DealMediaTypeEnum.BuyersGuide]: "Buyer's Guide",
  [DealMediaTypeEnum.TaterDocs]: 'Tater Docs',
  [DealMediaTypeEnum.TaxExemption]: 'Tax Exemption',
  [DealMediaTypeEnum.PowerOfAttorney]: 'Power of Attorney',
  [DealMediaTypeEnum.StateSpecificPowerOfAttorney]: 'State Specific Power of Attorney',
  [DealMediaTypeEnum.PowerOfAttorneyCobuyer]: 'Power of Attorney (Co-buyer)',
  [DealMediaTypeEnum.StateSpecificPowerOfAttorneyCobuyer]:
    'State Specific Power of Attorney (Co-buyer)',
  [DealMediaTypeEnum.SecurePowerOfAttorney]: 'Secure Power of Attorney',
  [DealMediaTypeEnum.StateSpecificDocs]: 'State Specific Docs',
  [DealMediaTypeEnum.PlateTransferLetter]: 'Plate Transfer Letter',
  [DealMediaTypeEnum.Contract]: 'Contract',
  [DealMediaTypeEnum.Title]: 'Title',
  [DealMediaTypeEnum.TitleApplication]: 'Title Application',
  [DealMediaTypeEnum.TitlePacket]: 'Title Packet',
  [DealMediaTypeEnum.TitleRelease]: 'Title Release',
  [DealMediaTypeEnum.CreditApproval]: 'Credit Approval',
  [DealMediaTypeEnum.ReassignmentDisclosure]: 'Reassignment Disclosure',
  [DealMediaTypeEnum.PayoffAuthorization]: 'Payoff Authorization',
  [DealMediaTypeEnum.PurchaseOrder]: 'Purchase Order',
  [DealMediaTypeEnum.StateSpecificPurchaseOrder]: 'State Specific Purchase Order',
  [DealMediaTypeEnum.LeaseEndCashback]: 'Lease End Cashback',
  [DealMediaTypeEnum.DamageDisclosure]: 'Damage Disclosure',
  [DealMediaTypeEnum.StateSpecificDamageDisclosure]: 'State Specific Damage Disclosure',
  [DealMediaTypeEnum.TexasHidalgoDoc]: 'Texas Hidalgo Doc',
  [DealMediaTypeEnum.AgreementToFurnishInsurance]: 'Agreement to Furnish Insurance',
  [DealMediaTypeEnum.LeaseEndAcqContract]: 'Lease End Acq Contract',
  [DealMediaTypeEnum.BookSheet]: 'Book Sheet',
  [DealMediaTypeEnum.RegistrationComplete]: 'Registration/Title - Complete',
  [DealMediaTypeEnum.R1EContract]: 'R1 E-Contract',
  [DealMediaTypeEnum.MembershipForm]: 'Membership Form',
  [DealMediaTypeEnum.ESignConsent]: 'E-Sign Consent',
  [DealMediaTypeEnum.PrivacyPolicy]: 'Privacy Policy',
  [DealMediaTypeEnum.Other]: 'Other',

  [DealMediaTypeEnum.FrontOfCoBuyersLicense]: 'Driver’s License (Co-Buyer) - Front',
  [DealMediaTypeEnum.BackOfCoBuyersLicense]: 'Driver’s License (Co-Buyer) - Back',
  [DealMediaTypeEnum.FrontOfCoBuyersInsurance]: 'Insurance (Co-Buyer) - Front',
  [DealMediaTypeEnum.BackOfCoBuyersInsurance]: 'Insurance (Co-buyer) - Back',
  [DealMediaTypeEnum.Emissions]: 'Emissions',
  [DealMediaTypeEnum.PropertyTaxReceipt]: 'Property Tax Receipt',
  [DealMediaTypeEnum.ProofOfResidence]: 'Proof of Residence',
  [DealMediaTypeEnum.ProofOfResidence2]: 'Proof of Residence 2',

  [DealMediaTypeEnum.RegistrationSpecificPowerOfAttorney]:
    'Registration Specific Power of Attorney',
  [DealMediaTypeEnum.CobuyerRegistrationSpecificPowerOfAttorney]:
    'Registration Specific Power of Attorney (Co-buyer)',
  [DealMediaTypeEnum.CobuyerSecurePowerOfAttorney]: 'Secure Power of Attorney (Co-buyer)',

  [DealMediaTypeEnum.PhysicalSignatureCoverLetter]: 'Physical Signature Cover Letter',
  [DealMediaTypeEnum.CreditApplication]: 'Credit Application',

  [DealMediaTypeEnum.PayoffDocs]: 'Payoff Docs',
  [DealMediaTypeEnum.ProofOfIncome]: 'Proof of Income',
  [DealMediaTypeEnum.DownPayment]: 'Down Payment',
  [DealMediaTypeEnum.SignedDocs]: 'Signed Docs',
  [DealMediaTypeEnum.SalesTaxExemption]: 'Sales Tax Exemption',

  [DealMediaTypeEnum.VscAndGap]: 'VSC & GAP',
  [DealMediaTypeEnum.DemandLetter]: 'Demand Letter',

  [DealMediaTypeEnum.StateAndLienholderSpecificPowerOfAttorney]:
    'State and Lienholder Specific Power of Attorney',

  [DealMediaTypeEnum.ColoradoSupplementalIdentificationClause]:
    'Colorado Supplemental Identification',
  [DealMediaTypeEnum.ColoradoCobuyerSupplementalIdentificationClause]:
    'Colorado Supplemental Identification (Co-buyer)',
  [DealMediaTypeEnum.ColoradoJointTenancy]: 'Colorado Joint Tenancy Form',

  [DealMediaTypeEnum.CaliforniaStatementOfFacts]: 'California Statement of Facts',
  [DealMediaTypeEnum.CaliforniaStatementOfFactsCobuyer]: 'California Statement of Facts (Co-buyer)',

  [DealMediaTypeEnum.ElectronicSignatureVerification]: 'Electronic Signature Verification',
  [DealMediaTypeEnum.ElectronicSignatureAttestation]: 'Electronic Signature Attestation',
  [DealMediaTypeEnum.ElectronicSignatureCertification]: 'Electronic Signature Certification',

  [DealMediaTypeEnum.LicensePlate]: 'License Plate',
  [DealMediaTypeEnum.TempTag]: 'Temp Tag',

  [DealMediaTypeEnum.IdahoSalesTaxExemption]: 'Idaho Sales Tax Exemption',

  [DealMediaTypeEnum.PorscheSpecificOdometerDoc]: 'Porsche Specific Odo Doc',
  [DealMediaTypeEnum.PorscheSpecificPayoffInstructions]: 'Porsche Specific Payoff Instructions',
  [DealMediaTypeEnum.WetSignature]: 'Wet Signature',

  [DealMediaTypeEnum.ConnecticutElectronicRegistrationAndTitleAgreement]:
    'Connecticut Electronic Registration and Title Agreement',
};

// Used in the Media Form. We don't need the Wet Signature Sent Date there.
export const DealMediaTypeDateFieldMap: {
  [key in DealMediaTypeEnum]?: { field: keyof CustomDates; label: string };
} = {
  [DealMediaTypeEnum.Title]: {
    field: DealStateEnum.TitleReceived,
    label: 'Title Received Date',
  },
  [DealMediaTypeEnum.WetSignature]: {
    field: 'wet_sig_received_date',
    label: 'Wet Signature Received Date',
  },
};

export const DealMediaGroupOnlyTypes = [DealMediaTypeEnum.ElectronicSignatureVerification];
export const getDealMediaTypeLabel = (value: Maybe<DealMediaTypeEnum>) =>
  value ? DealMediaTypeLabels[value] : '';
export const DealMediaTypeOptions = Object.values(DealMediaTypeEnum)
  .filter((value) => !DealMediaGroupOnlyTypes.includes(value))
  .map((value) => ({
    value,
    label: getDealMediaTypeLabel(value),
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export const DealMediaCustomerTypes = [
  DealMediaTypeEnum.PicturesOfVehicle,
  DealMediaTypeEnum.OdometerPicture,
  DealMediaTypeEnum.FrontOfDriversLicense,
  DealMediaTypeEnum.BackOfDriversLicense,
  DealMediaTypeEnum.FrontOfInsuranceCard,
  DealMediaTypeEnum.BackOfInsuranceCard,
  DealMediaTypeEnum.Registration,
  DealMediaTypeEnum.VinInspection,
  DealMediaTypeEnum.SafetyInspection,

  DealMediaTypeEnum.FrontOfCoBuyersLicense,
  DealMediaTypeEnum.BackOfCoBuyersLicense,
  DealMediaTypeEnum.FrontOfCoBuyersInsurance,
  DealMediaTypeEnum.BackOfCoBuyersInsurance,
  DealMediaTypeEnum.Emissions,
  DealMediaTypeEnum.PropertyTaxReceipt,
  DealMediaTypeEnum.ProofOfResidence,
  DealMediaTypeEnum.ProofOfResidence2,

  DealMediaTypeEnum.ProofOfIncome,
  DealMediaTypeEnum.PlateTransferLetter,

  DealMediaTypeEnum.LicensePlate,
];

export const DealMediaCustomerNoPhotoTypes = [
  DealMediaTypeEnum.Registration,
  DealMediaTypeEnum.VinInspection,
  DealMediaTypeEnum.SafetyInspection,
  DealMediaTypeEnum.Emissions,
  DealMediaTypeEnum.PlateTransferLetter,
];

export const DealMediaNoSignatureUpload = [
  ...DealMediaCustomerTypes,
  DealMediaTypeEnum.PayoffDocs,
  DealMediaTypeEnum.BookSheet,
  DealMediaTypeEnum.CreditApproval,
  DealMediaTypeEnum.RateExceptionForm,
  DealMediaTypeEnum.TitleRelease,
  DealMediaTypeEnum.ElectronicSignatureAttestation,
  DealMediaTypeEnum.ElectronicSignatureCertification,
  DealMediaTypeEnum.SignedDocs,
];

export enum MismatchSelectionEnum {
  Application = 'application',
  Document = 'document',
}

export enum DealMediaStatusEnum {
  All = 'all',
  Missing = 'missing',
  Verified = 'verified',
  Unverified = 'unverified',
  Uncategorized = 'uncategorized',
}
