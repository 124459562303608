import { ReactNode } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  IconButton,
  Stack,
} from '@chakra-ui/react';
import { MdKeyboardArrowRight } from 'react-icons/md';

type LEDrawerProps = DrawerProps & {
  children: ReactNode;
  title: ReactNode | string;
};

const LEDrawer = ({ title, children, isOpen, onClose, ...rest }: LEDrawerProps) => {
  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader bgColor="oxfordBlue" textColor="white">
          <Stack direction="row" align="center" w="full">
            {title}
            <IconButton
              aria-label="close"
              variant="icon"
              color="white"
              size="sm"
              icon={<MdKeyboardArrowRight />}
              onClick={onClose}
            />
          </Stack>
        </DrawerHeader>
        <DrawerBody p={0}>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default LEDrawer;
