import { Box } from '@chakra-ui/react';
import { ScaleLoader } from 'react-spinners';

interface LoaderProps {
  isLoading: boolean;
}

const Loader = ({ isLoading }: LoaderProps) => {
  return (
    <Box
      position="fixed"
      backgroundColor="rgba(0, 0, 0, 0.3)"
      top={0}
      left={0}
      zIndex={1000}
      boxSize="100%"
      display={isLoading ? 'block' : 'none'}
    >
      <ScaleLoader
        loading
        height={75}
        width={10}
        color="white"
        css={`
          z-index: 1000;
          position: absolute;
          top: calc(50% - 50px);
          left: calc(50% - 50px);
        `}
      />
    </Box>
  );
};

export default Loader;
