import { useContext, useState } from 'react';

import { HStack, Input, Text, VStack } from '@chakra-ui/react';
import { parseISO, startOfMonth } from 'date-fns';

import BootedDeals from '../../components/BootedDeals/BootedDeals';
import LeaseEndContainer from '../../components/Container/LEContainer';
import ButtonsRow from '../../components/Dashboards/ButtonsRow';

import { FiltersContext } from '../../libs/contextLib';
import { formatDateISO } from '../../libs/utils';

const BootedDealsPage = () => {
  const {
    filters: {
      global: { showOnlyUnread },
    },
  } = useContext(FiltersContext);

  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(new Date());

  return (
    <>
      <ButtonsRow />
      <LeaseEndContainer>
        <HStack gap={4} mb={4} mx={10}>
          <VStack align="start">
            <Text>Start Date</Text>
            <Input
              type="date"
              bgColor="white"
              defaultValue={formatDateISO(startDate)}
              onChange={(e) => setStartDate(parseISO(e.target.value))}
            />
          </VStack>
          <VStack align="start">
            <Text>End Date</Text>
            <Input
              type="date"
              bgColor="white"
              defaultValue={formatDateISO(endDate)}
              onChange={(e) => setEndDate(parseISO(e.target.value))}
            />
          </VStack>
        </HStack>
        <BootedDeals
          start_date={startDate}
          end_date={endDate}
          showOnlyUnreadMessages={showOnlyUnread}
        />
      </LeaseEndContainer>
    </>
  );
};

export default BootedDealsPage;
