import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { BiArrowBack, BiEdit, BiTrash } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Review, createReview, deleteReview, listReviews, updateReview } from '../../gql/reviewGql';

import LeaseEndContainer from '../../components/Container/LEContainer';
import DeleteReviewModal from './components/DeleteReviewModal';
import UpsertReviewModal from './components/UpsertReviewModal';

import ROUTES from '../../constants/routes';
import { formatDate, truncateString } from '../../libs/utils';

const ReviewsPage = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewToEdit, setReviewToEdit] = useState<Review | null>(null);
  const [createReviewMutation] = useMutation(createReview);
  const [deleteReviewMutation] = useMutation(deleteReview);
  const [updateReviewMutation] = useMutation(updateReview);

  const { data, loading } = useQuery(listReviews, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.reviews) {
      setReviews(data.reviews);
    }
  }, [data]);

  const handleUpdate = async (values: Review) => {
    try {
      await updateReviewMutation({ variables: { review: { ...values } } });
      const index = reviews.findIndex((review) => review.id === values.id);
      setReviews((prev) => [...prev.slice(0, index), values, ...prev.slice(index + 1)]);

      setShowModal(false);
    } catch {
      toast.error('Failed to update review.');
    }
  };

  const handleCreate = async (values: Review) => {
    try {
      const {
        data: {
          createReview: { id },
        },
      } = await createReviewMutation({
        variables: { review: { ...values } },
      });
      setReviews((prev) => [{ ...values, id }, ...prev]);
      setShowModal(false);
    } catch {
      toast.error('Failed to add review.');
    }
  };

  const handleSubmit = async (values: Review) => {
    if (reviewToEdit) {
      await handleUpdate(values);
    } else {
      await handleCreate(values);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteReviewMutation({
        variables: { id: idToDelete },
      });
      setReviews((prev) => prev.filter((review) => review.id !== idToDelete));
      setShowDeleteModal(false);
    } catch {
      toast.error('Failed to delete review.');
    }
  };

  return (
    <>
      <HStack
        direction={{ base: 'column', lg: 'row' }}
        py={2}
        px={8}
        bgColor="queenBlue"
        justify="space-between"
      >
        <Button
          as={RouterLink}
          to={ROUTES.DASHBOARD}
          size="lgWithIconLeft"
          leftIcon={<BiArrowBack />}
          variant="secondary"
        >
          DASHBOARD
        </Button>
      </HStack>

      <LeaseEndContainer isLoading={loading}>
        <HStack>
          <VStack />
        </HStack>
        <HStack p={3} bgColor="oxfordBlue" borderTopRadius={8} spacing="auto">
          <Text fontSize={20} textColor="white">
            COM Reviews
          </Text>
          <Button
            size="md"
            onClick={() => {
              setReviewToEdit(null);
              setShowModal(true);
            }}
          >
            ADD REVIEW
          </Button>
        </HStack>
        <Table>
          <Thead bgColor="queenBlue" height="50px">
            <Tr>
              {['Customer Name', 'Location', 'Customer Review', 'Date Added', 'COM Visibility'].map(
                (colName) => (
                  <Th textColor="white" key={colName}>
                    {colName}
                  </Th>
                ),
              )}

              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {reviews.map((review) => (
              <Tr
                key={review.id}
                cursor="pointer"
                height="55px"
                borderBottom="2px"
                borderX="1px"
                borderColor="backgroundGray"
                bgColor="white"
                _hover={{ bgColor: 'hoverGray' }}
              >
                <Td>{review.customer_name}</Td>
                <Td>{review.location}</Td>
                <Td>{truncateString(review.review, 100)}</Td>
                <Td>{formatDate(review.created_at)}</Td>
                <Td>
                  <Flex justify="center">
                    <Switch
                      name="com_visible"
                      isChecked={review.com_visible}
                      onChange={() =>
                        handleUpdate({
                          ...review,
                          com_visible: !review.com_visible,
                        })
                      }
                    />
                  </Flex>
                </Td>
                <Td>
                  <IconButton
                    icon={<BiEdit />}
                    variant="iconHover"
                    size="xs"
                    aria-label="Edit Review"
                    onClick={() => {
                      setShowModal(true);
                      setReviewToEdit(review);
                    }}
                  />

                  <IconButton
                    icon={<BiTrash />}
                    variant="iconHover"
                    color="errorsRed"
                    size="xs"
                    aria-label="Delete Review"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setIdToDelete(review.id);
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </LeaseEndContainer>
      <UpsertReviewModal
        handleOnSubmit={handleSubmit}
        showModal={showModal}
        setShowModal={setShowModal}
        reviewToEdit={reviewToEdit}
      />
      <DeleteReviewModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default ReviewsPage;
