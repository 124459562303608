import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import { Deal, DealStateEnum, acquisitionDealInfoUpsert } from '../../gql/dealGql';

import Tooltip from '../shared/Tooltip';

import ROUTES from '../../constants/routes';
import { logger } from '../../libs/Logger';
import { cleanDealForUpdate } from '../../utils/deals';

const SendToStructuringOrClosing = () => {
  const { values, isValid, isSubmitting, setSubmitting } = useFormikContext<Deal>();
  const [upsertAcquisitionDealInfo] = useMutation(acquisitionDealInfoUpsert);
  const history = useHistory();

  const onSendToStructuringOrClosing = async (deal: Deal) => {
    const newDeal = cleanDealForUpdate(deal);

    try {
      await upsertAcquisitionDealInfo({
        variables: {
          deal: newDeal,
        },
      });
      history.push(ROUTES.DASHBOARD);
    } catch (error) {
      logger.error('SendToStructuringOrClosingButton.tsx', '', null, error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Tooltip errors={values.errors}>
      <Button
        isLoading={isSubmitting}
        loadingText={
          values.financial_info_acquisition.is_approved ? 'SEND TO CLOSING' : 'SEND TO STRUCTURING'
        }
        isDisabled={!isValid}
        hidden={values.state !== DealStateEnum.SoftClose}
        style={!isValid ? { pointerEvents: 'none' } : {}}
        onClick={() =>
          onSendToStructuringOrClosing({
            ...values,
            state: values.financial_info_acquisition.is_approved
              ? DealStateEnum.Closing
              : DealStateEnum.Structuring,
          })
        }
      >
        {values.financial_info_acquisition.is_approved ? 'SEND TO CLOSING' : 'SEND TO STRUCTURING'}
      </Button>
    </Tooltip>
  );
};

export default SendToStructuringOrClosing;
