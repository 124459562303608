import { ComponentProps, useContext } from 'react';

import { Checkbox, Flex, HStack, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../../gql/dealGql';

import Card from '../../../shared/Card';

import Reserve from './Reserve';

import { PermissionEnum } from '../../../../constants/permissions';
import { AbilityContext } from '../../../../libs/contextLib';
import { formatMoney } from '../../../../libs/utils';
import { DebouncedSave } from '../../DealInfoBuyoutForm';
import { paymentOptionsTypes } from '../../types';

type PackageBaseProps = Omit<ComponentProps<typeof Card>, 'onClick'> & {
  name: string;
  option: paymentOptionsTypes;
  isDisabled?: boolean;
  isAvailable?: boolean;
  onClick?: (options: paymentOptionsTypes) => void;
};
type PackageConditionalProps =
  | {
      showReserveEdit: boolean;
      debounceSave: DebouncedSave;
    }
  | {
      showReserveEdit?: never;
      debounceSave?: never;
    };
type PackageProps = PackageBaseProps & PackageConditionalProps;

const Package = ({
  name,
  option,
  isDisabled,
  isAvailable,
  onClick,
  showReserveEdit,
  debounceSave,
  ...rest
}: PackageProps) => {
  const ability = useContext(AbilityContext);

  const { getFieldMeta } = useFormikContext<Deal>();

  const fieldName = 'financial_info.option_type';
  const { value } = getFieldMeta(fieldName);

  const isSelected = value === option.name;

  const darkestAvailableColor = 'queenBlue';
  const getDarkestColor = () => {
    if (isAvailable) {
      return isDisabled ? 'queenBlueLight' : darkestAvailableColor;
    }
    return isDisabled ? 'gray.500' : 'gray.600';
  };
  const darkestColor = getDarkestColor();

  return (
    <Card
      variant="roundedWithBorder"
      w="165px"
      minW="140px"
      h="150px"
      color={darkestColor}
      bgColor={isSelected ? 'azureishWhite' : isAvailable ? 'white' : 'backgroundGray'}
      borderColor={darkestColor}
      fontSize="sm"
      cursor={!isAvailable || isDisabled ? 'not-allowed' : 'pointer'}
      onClick={() => {
        if (!onClick || !isAvailable || isDisabled) {
          return;
        }

        onClick(option);
      }}
      {...rest}
    >
      <HStack
        minH="37px"
        borderBottom="1px"
        borderBottomColor={isSelected ? 'white' : isAvailable ? 'azureishWhite' : 'gray.400'}
      >
        <Text px={3} py={2} whiteSpace="nowrap">
          {name}
        </Text>
      </HStack>
      <HStack
        minH="40px"
        justify="space-between"
        py={1}
        px={
          ability.has(PermissionEnum.SeeCommission) && ability.has(PermissionEnum.SeeProfit) ? 1 : 2
        }
      >
        <Text fontWeight="normal">
          {ability.has(PermissionEnum.SeeCommission) ? formatMoney(option.closerCommission) : ''}
        </Text>
        {showReserveEdit ? (
          <Flex color={darkestAvailableColor}>
            {/* Only shown in the No Protection/No Products package */}
            <Reserve debouncedSave={debounceSave} noProductOption={option} />
          </Flex>
        ) : ability.has(PermissionEnum.SeeProfit) ? (
          <Text fontWeight="normal">{formatMoney(option.profit)}</Text>
        ) : null}
      </HStack>
      <HStack minH="45px" justify="center">
        <Text
          fontSize={{
            base: 'lg',
            '2xl': 'xl',
            '3xl': '2xl',
            '4xl': option.sellPayment >= 100000 ? '2xl' : '3xl',
          }}
          fontWeight="normal"
          px={3}
        >
          {formatMoney(option.sellPayment)}
        </Text>
      </HStack>
      <HStack h="26px" bgColor={darkestColor} color="white" borderBottomRadius="8px">
        {isAvailable ? (
          <Checkbox size="md" px={3} isChecked={isSelected} isDisabled={isDisabled}>
            <Text fontSize="xs">{isSelected ? 'SELECTED' : 'SELECT'}</Text>
          </Checkbox>
        ) : (
          <Text pt={2} fontSize="xs" px={3} mt={-2}>
            UNAVAILABLE
          </Text>
        )}
      </HStack>
    </Card>
  );
};

export default Package;
