import { SystemStyleObject } from '@chakra-ui/react';

export const HorizontalScrollBarProps: SystemStyleObject = {
  '::-webkit-scrollbar': {
    height: '4px',
  },
  '::-webkit-scrollbar-track': {
    background: 'snowyWhite',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'cadetBlue',
    borderRadius: '4px',
  },
};

// This is in DealsSwimlane.tsx, but raw CSS is required instead of the 'sx' prop for the Virtuoso component
export const VerticalScrollBarProps: SystemStyleObject = {
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-track': {
    background: 'snowyWhite',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'cadetBlue',
    borderRadius: '4px',
  },
};
