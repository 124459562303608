import { Deal } from '../../../gql/dealGql';

import { TableColumns } from '../../shared/Table/types';

import { formatDateIfTrue } from '../../../libs/utils';

enum TempTagColumnHeaders {
  CustomerName = 'Customer Name',
  UsState = 'US State',
  RegExp = 'Reg Exp',
  TagsSent = 'Tags Sent',
  TrackingNum = 'Tracking #',
  FinancialSpecialist = 'Financial Specialist',
  Clerk = 'Clerk',
  Actions = 'Actions',
}

export const TEMP_TAG_COLUMNS: TableColumns<Deal> = [
  {
    header: TempTagColumnHeaders.CustomerName,
    getComponent: ({ item: deal }) => `${deal.customer.first_name} ${deal.customer.last_name}`,
  },
  {
    header: TempTagColumnHeaders.UsState,
    getComponent: ({ item: deal }) => deal.customer.address?.state,
  },
  {
    header: TempTagColumnHeaders.RegExp,
    getComponent: ({ item: deal }) => formatDateIfTrue(deal.car.registration_expiration),
  },
  {
    header: TempTagColumnHeaders.TagsSent,
    getComponent: () => 'Date Tags Sent', // Dummy value. Replace with actual value
  },
  {
    header: TempTagColumnHeaders.TrackingNum,
    getComponent: () => 'Tracking Number', // Dummy value. Replace with actual value
  },
  {
    header: TempTagColumnHeaders.FinancialSpecialist,
    getComponent: ({ item: deal }) => deal.closer?.name,
  },
  {
    header: TempTagColumnHeaders.Clerk,
    getComponent: ({ item: deal }) => deal.title_clerk?.name,
  },
  {
    header: TempTagColumnHeaders.Actions,
    getComponent: () => 'Actions', // Dummy value. Replace with actual value
  },
];
