import { ReactNode } from 'react';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { DeepPartial } from '@chakra-ui/react';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';

import { Car } from '../gql/carGql';
import { Deal } from '../gql/dealGql';
import { FinancialInfo } from '../gql/financialInfoGql';
import { Payoff } from '../gql/payoffGql';
import { LienholderPRSType } from '../gql/prs/types';
// eslint-disable-next-line import/order
import { createClient } from 'graphql-ws';

import config from '../config';
import { logger } from '../libs/Logger';
import { cleanCustomerPrequalification } from '../utils/customers';
import { cleanFinancialInfo } from '../utils/financialInfos';
import { cleanPayoff } from '../utils/payoffs';

const { apiRoot } = config.urls;
const { wsRoot } = config.urls;
const { prsApiRoot } = config.urls;

const GraphQLEndpoints = {
  LE: 'le',
  PRS: 'prs',
} as const;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      logger.error(
        'AuthorizedApolloProvider.tsx',
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${JSON.stringify(
          extensions,
        )}`,
      );
    });
  }

  if (networkError) {
    logger.error('AuthorizedApolloProvider.tsx"', `[Network error]: ${networkError}`);
  }
});

const AuthorizedApolloProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const getToken = async () => {
    try {
      return await getAccessTokenSilently();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logger.error('AuthorizedapolloProvider.tsx', 'HTTPS: failed to get token', null, e);
      if (e.error === 'login_required' || e.error === 'consent_required') {
        return loginWithRedirect();
      }
      return null;
    }
  };

  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${await getToken()}`,
    },
  }));

  const waitForNewContainer = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1_000 + Math.random() * 1_500));
  };

  const webSocketLink = new GraphQLWsLink(
    createClient({
      url: `${wsRoot}/graphql`,
      lazy: true,
      connectionParams: async () => {
        return { authorization: `Bearer ${await getToken()}` };
      },
      // will retry at least for 5 minutes to at most 12 1/2 minutes with 1 to 3.5 seconds of delay
      // between each retry.  This should be more than enough time to wait for a new container to be
      // created.  If it still fails after that, then we will just give up and users will need to do a refresh
      // when the service is back up
      retryAttempts: 300,
      shouldRetry: () => true, // we want to retry even if the event is fatal
      retryWait: async () => {
        await waitForNewContainer();
      },
    }),
  );

  const multiApiLink = from([
    new MultiAPILink({
      endpoints: {
        [GraphQLEndpoints.LE]: apiRoot,
        [GraphQLEndpoints.PRS]: prsApiRoot,
      },
      createWsLink: () =>
        split(({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        }, webSocketLink),
      defaultEndpoint: GraphQLEndpoints.LE,
      getContext: async (_, getCurrentContext) => ({
        headers: { ...getCurrentContext().headers, authorization: `Bearer ${await getToken()}` },
      }),
      createHttpLink: () => createHttpLink(),
    }),
  ]);

  // TODO: call cleanDealForUpdate (src/utils/deals.ts) in this middleware
  // and remove almost all the calls made in components.
  // Probably cleanDeal (src/components/CreditApplication/CreditPerson.tsx) can go here too.
  // Check for others.
  const mutationsMiddlewareLink = new ApolloLink((operation, forward) => {
    const dealMutations = new Map([
      ['estimateUpsert', true],
      ['creditAppUpsert', true],
      ['customerInfoUpsert', true],
      ['acquisitionDealInfoUpsert', true],
      ['vehicleInfoUpsert', true],
      ['payoffUpdate', true],
      ['dealInfoUpsert', true],
      ['submitToRouteOne', true],
    ]);

    if (!dealMutations.has(operation.operationName)) {
      return forward(operation);
    }

    if (operation.variables.deal) {
      const deal = operation.variables.deal as Deal;

      const cleanedDeal: DeepPartial<Deal> = {
        ...deal,
        tags: undefined,
        financial_info: cleanFinancialInfo(deal.financial_info),
        ...(deal.car ? { car: { ...deal.car, payoff: cleanPayoff(deal.car.payoff) } } : {}),
      };

      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        deal: cleanedDeal,
      };
    }

    if (operation.variables.deal?.customer) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        deal: {
          ...operation.variables.deal,
          customer: cleanCustomerPrequalification(operation.variables.deal.customer),
        },
      };
    }

    if (operation.variables.customer) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        customer: cleanCustomerPrequalification(operation.variables.customer),
      };
    }

    if (operation.variables.deal?.cobuyer) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        deal: {
          ...operation.variables.deal,
          cobuyer: cleanCustomerPrequalification(operation.variables.deal.cobuyer),
        },
      };
    }

    if (operation.variables.cobuyer) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        cobuyer: cleanCustomerPrequalification(operation.variables.cobuyer),
      };
    }

    if (operation.variables.financialInfo) {
      const financialInfo = operation.variables.financialInfo as FinancialInfo;

      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        financialInfo: cleanFinancialInfo(financialInfo),
      };
    }

    if (operation.variables.car) {
      const car = operation.variables.car as Car;

      const cleanedCar: DeepPartial<Car> = {
        ...car,
        payoff: cleanPayoff(car.payoff),
      };

      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        car: cleanedCar,
      };
    }

    if (operation.variables.payoff) {
      const payoff = operation.variables.payoff as Payoff;

      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        payoff: cleanPayoff(payoff),
      };
    }

    return forward(operation);
  });

  const removeTypenameLink = removeTypenameFromVariables();

  const apolloClient = new ApolloClient({
    link: mutationsMiddlewareLink.concat(
      removeTypenameLink.concat(errorLink.concat(authLink.concat(multiApiLink))),
    ),
    cache: new InMemoryCache({
      typePolicies: {
        r1CreditDecisionData: {
          keyFields: ['applicationNumber'],
        },
        // The issue with the `lienholder` query cache wasn't the variables but the cache key.
        // By default, Apollo uses the `id` field of the returned object to uniquely identify it.
        // For the `lienholder`, same `slug` but different `state` variables referenced the same `id`, leading to a cache conflict.
        // Since `state` is not part of the returned object, we use `double_tax`, `direct_pay`, and `include_sales_tax` to differentiate them instead.
        // Also, we need to use a literal because `lienholders` (plural) doesn't select the same fields and kept loading indefinitely.
        // For more details, refer to nested `keyFields`: https://www.apollographql.com/docs/react/caching/cache-configuration#customizing-cache-ids
        Lienholder: {
          keyFields: (data) => {
            const { slug, double_tax, requirements_to_payoff_lease } =
              data as Partial<LienholderPRSType>;
            const { direct_pay, include_sales_tax } = requirements_to_payoff_lease ?? {};

            const keyParts: string[] = [];
            if (slug) {
              keyParts.push(`slug:"${slug}"`);
            }
            if (double_tax !== undefined) {
              keyParts.push(`double_tax:${double_tax}`);
            }
            if (direct_pay?.value !== undefined) {
              keyParts.push(`direct_pay:${direct_pay.value}`);
            }
            if (include_sales_tax?.value !== undefined) {
              keyParts.push(`include_sales_tax:${include_sales_tax.value}`);
            }
            const objectKeys = keyParts.join(',');
            const cacheKey = `'Lienholder:{${objectKeys}}`;

            return cacheKey;
          },
        },
      },
    }),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
