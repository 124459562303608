import { useContext, useMemo } from 'react';

import { HStack, SkeletonText, Text, VStack } from '@chakra-ui/react';
import Big from 'big.js';
import { addDays, format, parseISO } from 'date-fns';
import DOMPurify from 'dompurify';

import { Maybe, useCreditDecisionDataQuery } from '../../gql/generated/graphql';

import CreditDecisionCommission from './CreditDecisionCommission';
import CreditDecisionInfoRow from './CreditDecisionInfoRow';
import CreditDecisionStatusText from './CreditDecisionStatusText';
import useCreditDecisions, { CreditDecisionWithSubmittedAt } from './useCreditDecisions';

import { CreditDecisionStatusLabelEnum } from '../../constants/creditDecisions';
import { DealContext } from '../../libs/DealContext';
import { formatMoney } from '../../libs/utils';

type CreditDecisionDetailsProps = {
  bankName: ReturnType<ReturnType<typeof useCreditDecisions>['getBankNamesByR1Fsid']>;
  creditDecision?: CreditDecisionWithSubmittedAt;
};

const CreditDecisionDetails = ({ bankName, creditDecision }: CreditDecisionDetailsProps) => {
  const { deal } = useContext(DealContext);
  const { data: creditDecisionData, loading } = useCreditDecisionDataQuery({
    skip: !creditDecision?.id,
    variables: {
      id: Number(creditDecision?.id),
    },
  });

  const notes = useMemo(() => {
    if (!creditDecisionData?.creditDecisionV2?.r1_credit_decision_data?.decision) {
      return '';
    }

    const notesBuilder: Maybe<string>[] = [];
    const { applicationStatus, applicationStatusDetail } =
      creditDecision?.r1_credit_decision_data || {};
    if (applicationStatus && applicationStatusDetail) {
      notesBuilder.push(
        `Application Status: ${CreditDecisionStatusLabelEnum[applicationStatus]} - ${applicationStatusDetail}`,
      );
    }

    const { conditionRejection, decisionComments, stipulations } =
      creditDecisionData?.creditDecisionV2.r1_credit_decision_data.decision || {};

    if (conditionRejection && conditionRejection.length > 0) {
      notesBuilder.push('Rejection Reasons:');
      notesBuilder.push(...conditionRejection.map((cr) => cr?.conditionRejectionText || ''));
    }

    if (stipulations && stipulations.length > 0) {
      notesBuilder.push('Stipulations:');
      notesBuilder.push(...stipulations);
    }

    if (decisionComments && decisionComments.length > 0) {
      notesBuilder.push('Decision Comments:');
      notesBuilder.push(...decisionComments);
    }

    return notesBuilder.length
      ? DOMPurify.sanitize(notesBuilder.filter(Boolean).join('<br />'))
      : '';
  }, [
    creditDecision?.r1_credit_decision_data?.applicationStatusDetail,
    creditDecisionData?.creditDecisionV2?.r1_credit_decision_data?.decision,
  ]);

  const sellRate = new Big(creditDecision?.annual_percentage_rate || 0)
    .plus(creditDecision?.max_markup || 0)
    .toNumber();

  return (
    <VStack w="full" p={4} spacing={8}>
      <VStack bg="gray.100" rounded="sm" w="full" p={2}>
        <HStack w="full" alignItems="flex-start" justifyContent="space-between">
          <VStack alignItems="flex-start" spacing={0}>
            <Text fontWeight="bold" fontSize="xl" color="queenBlue">
              {bankName.displayName}
            </Text>
            <Text fontSize="xs">Bank App #: {creditDecision?.r1_application_number}</Text>
          </VStack>
          <CreditDecisionCommission skeletonSize="sm" fontSize="md" bankName={bankName.name} />
        </HStack>
        <CreditDecisionStatusText creditDecision={creditDecision} />
        <HStack w="full" justifyContent="space-evenly" alignItems="center">
          <VStack alignItems="flex-start">
            <CreditDecisionInfoRow label="Sell Rate" value={sellRate ? `${sellRate}%` : 'N/A'} />
            <CreditDecisionInfoRow
              label="Money Down"
              value={
                deal.financial_info?.money_down
                  ? `${formatMoney(deal.financial_info.money_down, { noDecimal: true })}`
                  : 'N/A'
              }
            />
          </VStack>
          <VStack alignItems="flex-start">
            <CreditDecisionInfoRow
              label="Buy Rate"
              value={
                creditDecision?.annual_percentage_rate
                  ? `${creditDecision.annual_percentage_rate}%`
                  : 'N/A'
              }
            />
            <CreditDecisionInfoRow
              label="Submitted"
              value={
                creditDecision?.submittedAt
                  ? format(parseISO(creditDecision?.submittedAt), 'MM/dd/yyyy')
                  : 'N/A'
              }
            />
          </VStack>
          <VStack alignItems="flex-start">
            <CreditDecisionInfoRow
              label="Term"
              value={creditDecision?.term ? `${creditDecision.term} mos` : 'N/A'}
            />
            <CreditDecisionInfoRow
              label="Expires"
              value={
                creditDecision?.submittedAt
                  ? format(addDays(parseISO(creditDecision.submittedAt), 30), 'MM/dd/yyyy')
                  : 'N/A'
              }
            />
          </VStack>
        </HStack>
      </VStack>
      {loading ? (
        <SkeletonText noOfLines={5} w="full" />
      ) : (
        <VStack>
          <Text dangerouslySetInnerHTML={{ __html: notes }} />
        </VStack>
      )}
    </VStack>
  );
};

export default CreditDecisionDetails;
