import { Deal } from '../gql/dealGql';
import { Auth0RoleName, Maybe, User } from '../gql/generated/graphql';
import {
  CloserNotificationStates,
  FundingNotificationStates,
  SetterNotificationStates,
  TitlingNotificationStates,
} from '../gql/messageGql';

export const getName = (name: string, opts?: { firstInitial?: boolean; lastInitial?: boolean }) => {
  if (!name || !opts) {
    return name;
  }

  const formattedName = [];
  const splitName = name.split(' ');
  if (splitName.length === 1) {
    return splitName[0];
  }

  const firstName = splitName[0];
  if (opts.firstInitial) {
    formattedName.push(firstName.charAt(0));
  } else {
    formattedName.push(firstName);
  }

  const lastName = splitName[splitName.length - 1];
  if (opts.lastInitial) {
    formattedName.push(lastName.charAt(0));
  } else {
    formattedName.push(lastName);
  }

  return formattedName.join(' ');
};

export const isUserAssignedToDeal = (user: User, deal?: Deal) => {
  if (!deal) {
    return false;
  }

  return (
    deal.setter_id === user.id ||
    deal.setter?.id === user.id ||
    deal.closer_id === user.id ||
    deal.closer?.id === user.id ||
    deal.closer2_id === user.id ||
    deal.closer2?.id === user.id ||
    deal.funding_clerk_id === user.id ||
    deal.funding_clerk?.id === user.id ||
    deal.title_clerk_id === user.id ||
    deal.title_clerk?.id === user.id ||
    deal.title_clerk2_id === user.id ||
    deal.title_clerk2?.id === user.id
  );
};

export const isUserAssignedTitleTeamLead = (user: User, deal?: Deal) => {
  if (!deal?.titling_pod?.leads?.length) {
    return false;
  }

  return deal.titling_pod.leads.some((lead) => lead.id === user.id);
};

export const isDealStateRelevantForRole = (user: User, deal?: Deal) => {
  if (!deal) {
    return false;
  }

  return (
    (deal.setter_id === user.id && SetterNotificationStates.includes(deal.state)) ||
    (deal.closer_id === user.id && CloserNotificationStates.includes(deal.state)) ||
    (deal.closer2_id === user.id && CloserNotificationStates.includes(deal.state)) ||
    (deal.funding_clerk_id === user.id && FundingNotificationStates.includes(deal.state)) ||
    (deal.title_clerk_id === user.id && TitlingNotificationStates.includes(deal.state)) ||
    (deal.title_clerk2_id === user.id && TitlingNotificationStates.includes(deal.state))
  );
};

export const filterUsersByRole = (users: Maybe<User>[], role: Auth0RoleName) =>
  users.filter((user) =>
    user?.auth0_roles?.roles?.some(
      (r) => r?.name?.replace(/\s+/g, '').toLowerCase() === role.toLowerCase(),
    ),
  );
