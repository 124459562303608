import React from 'react';

import { Box, Checkbox, Th, Thead, Tr } from '@chakra-ui/react';
import { BsSortAlphaDown, BsSortAlphaUp } from 'react-icons/bs';

import { MediaCenterSorting } from '../hooks/use-media-table-rows';

import { DealMedia } from '../../../../../types/media';
import { isFileDeletable } from '../../../../../utils/media';

interface MediaTableHeadProps {
  isInternal: boolean;
  onSelectMedia: (media: DealMedia[]) => void;
  onSortChange: (sort: MediaCenterSorting['value']) => void;
  selectedFiles: DealMedia[];
  sorting: MediaCenterSorting;
  uploadedMediaFiles: DealMedia[];
}

const MediaTableHead: React.FC<MediaTableHeadProps> = ({
  isInternal,
  onSelectMedia,
  onSortChange,
  selectedFiles,
  sorting,
  uploadedMediaFiles,
}) => {
  const deletableFiles = uploadedMediaFiles.filter((item) => isFileDeletable(item));

  return (
    <Thead bgColor="white" boxShadow="0px 1px 0px gray" position="sticky" top={0} zIndex={1}>
      <Tr>
        {/* Checkbox */}
        <Th alignItems="center" paddingX={2}>
          <Checkbox
            borderColor="gray.600"
            cursor="pointer"
            disabled={uploadedMediaFiles.length === 0 || deletableFiles.length === 0}
            isChecked={selectedFiles.length > 0 && selectedFiles.length === deletableFiles.length}
            mb={0}
            onChange={({ target }) =>
              target.checked ? onSelectMedia(deletableFiles) : onSelectMedia([])
            }
          />
        </Th>
        {/* Preview */}
        <Th />
        {/* Category */}
        <Th
          cursor="pointer"
          onClick={() => onSortChange('category')}
          paddingX={2}
          textTransform="none"
          whiteSpace="pre"
        >
          Category
          {sorting.value === 'category' && (
            <Box display="inline" ml="4px">
              {sorting.order === 'ASC' ? <BsSortAlphaUp /> : <BsSortAlphaDown />}
            </Box>
          )}
        </Th>

        <Th
          cursor="pointer"
          onClick={() => onSortChange('file_name')}
          paddingX={2}
          textTransform="none"
          whiteSpace="pre"
        >
          File Name
          {sorting.value === 'file_name' && (
            <Box display="inline" ml="4px">
              {sorting.order === 'ASC' ? <BsSortAlphaUp /> : <BsSortAlphaDown />}
            </Box>
          )}
        </Th>
        {isInternal && (
          <Th bgColor="azureishWhite" color="secondary" paddingX={2} textTransform="none">
            Req'd Signatures
          </Th>
        )}
        <Th borderLeftColor="gray.100" borderLeftWidth="2px" paddingX={2} textTransform="none">
          Actions
        </Th>
      </Tr>
    </Thead>
  );
};

export default MediaTableHead;
