import { BankEnum } from './constants/bank';

interface ConfigType {
  urls: {
    apiRoot: string;
    wsRoot: string;
    prsApiRoot: string;
    chatUiRoot: string;
  };
  auth0: {
    redirectUri: string;
    logoutUri: string;
    clientId: string;
    domain: string;
    audience: string;
  };
  launchDarklyClientId: string;
}

interface ConfigsType {
  dev: ConfigType;
  testing: ConfigType;
  staging: ConfigType;
  production: ConfigType;
  localRemote: ConfigType;
  localStaging: ConfigType;
}

const configs: ConfigsType = {
  dev: {
    urls: {
      apiRoot: 'http://localhost:8080',
      wsRoot: 'ws://localhost:8080',
      prsApiRoot: 'https://payoff.staging.leaseend.com',
      chatUiRoot: 'http://localhost:3001',
    },
    auth0: {
      redirectUri: 'http://localhost:3000/dashboard',
      logoutUri: 'http://localhost:3000',
      clientId: 'Mo5hQLQQ0Iet74rUqxu6mKxfaiitBaw4',
      domain: 'auth.leaseend.com',
      audience: 'https://api.leaseend.com/graphql',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e1',
  },
  testing: {
    urls: {
      apiRoot: 'https://staging.api.leaseend.com',
      wsRoot: 'wss://staging.api.leaseend.com',
      prsApiRoot: 'https://payoff.staging.leaseend.com',
      chatUiRoot: 'https://chat.staging.leaseend.com',
    },
    auth0: {
      redirectUri: 'https://testing.app.leaseend.com/dashboard',
      logoutUri: 'https://testing.app.leaseend.com',
      clientId: 'Mo5hQLQQ0Iet74rUqxu6mKxfaiitBaw4',
      domain: 'auth.leaseend.com',
      audience: 'https://api.leaseend.com/graphql',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e1',
  },
  staging: {
    urls: {
      apiRoot: 'https://api.staging.leaseend.com',
      wsRoot: 'wss://api.staging.leaseend.com',
      prsApiRoot: 'https://payoff.staging.leaseend.com',
      chatUiRoot: 'https://chat.staging.leaseend.com',
    },
    auth0: {
      redirectUri: 'https://app.staging.leaseend.com/dashboard',
      logoutUri: 'https://app.staging.leaseend.com',
      clientId: 'Mo5hQLQQ0Iet74rUqxu6mKxfaiitBaw4',
      domain: 'auth.leaseend.com',
      audience: 'https://api.leaseend.com/graphql',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e1',
  },
  production: {
    urls: {
      apiRoot: 'https://api.leaseend.com',
      wsRoot: 'wss://api.leaseend.com',
      prsApiRoot: 'https://payoff.leaseend.com',
      chatUiRoot: 'https://chat.leaseend.com',
    },
    auth0: {
      redirectUri: 'https://app.leaseend.com/dashboard',
      logoutUri: 'https://app.leaseend.com',
      clientId: 'Mo5hQLQQ0Iet74rUqxu6mKxfaiitBaw4',
      domain: 'auth.leaseend.com',
      audience: 'https://api.leaseend.com/graphql',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e2',
  },
  localRemote: {
    urls: {
      apiRoot: 'https://api.staging.leaseend.com',
      wsRoot: 'wss://api.staging.leaseend.com',
      prsApiRoot: 'https://payoff.leaseend.com',
      chatUiRoot: 'https://chat.leaseend.com',
    },
    auth0: {
      redirectUri: 'http://localhost:3000/dashboard',
      logoutUri: 'http://localhost:3000',
      clientId: 'Mo5hQLQQ0Iet74rUqxu6mKxfaiitBaw4',
      domain: 'auth.leaseend.com',
      audience: 'https://api.leaseend.com/graphql',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e2',
  },
  localStaging: {
    urls: {
      apiRoot: 'https://staging.api.leaseend.com',
      wsRoot: 'wss://staging.api.leaseend.com',
      prsApiRoot: 'https://payoff.staging.leaseend.com',
      chatUiRoot: 'https://chat.staging.leaseend.com',
    },
    auth0: {
      redirectUri: 'http://localhost:3000/dashboard',
      logoutUri: 'http://localhost:3000',
      clientId: 'Mo5hQLQQ0Iet74rUqxu6mKxfaiitBaw4',
      domain: 'auth.leaseend.com',
      audience: 'https://api.leaseend.com/graphql',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e1',
  },
};

const getLenders = (env: string) => {
  const isProd = env === 'production';

  return [
    {
      name: BankEnum.FifthThird,
      id: isProd ? 'F000DK' : 'FZ00DK',
    },
    {
      name: BankEnum.Mechanics,
      id: isProd ? 'F0DF81' : '',
    },
    {
      name: BankEnum.AllyFinancial,
      id: isProd ? 'F000BA' : 'F0EC11',
    },
    {
      name: BankEnum.CapitalOne,
      id: isProd ? 'F000AG' : '',
    },
    {
      name: BankEnum.PNC,
      id: isProd ? 'F00PNC' : 'FZ0PNC',
    },
    {
      name: BankEnum.Westlake,
      id: isProd ? 'F000WL' : '',
    },
    {
      name: BankEnum.TD,
      id: isProd ? 'F00AAA' : '',
    },
    {
      name: BankEnum.FirstInterstate,
      id: isProd ? 'F000CV' : 'F0EC10',
    },
    {
      name: BankEnum.ICCU,
      id: isProd ? 'F0SL44' : 'F000ZM',
    },
  ];
};

export const getEnv = () => process.env.REACT_APP_STAGE || process.env.NODE_ENV;
export const isLocal = getEnv() === 'dev' || getEnv() === 'development';
export const isStaging = getEnv() === 'staging';
export const isProd = getEnv() === 'production';

export const marketUrl = (() => {
  if (isLocal) {
    return 'http://localhost:3002';
  }
  return `https://${isProd ? 'www.' : 'staging.'}leaseend.com`;
})();

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...configs[(process.env.REACT_APP_STAGE || process.env.NODE_ENV) as keyof ConfigsType],
  lenders: getLenders(process.env.REACT_APP_STAGE || process.env.NODE_ENV),
};
