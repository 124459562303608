import { FC, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Button, Flex, FormLabel, Spacer, Stack, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { getFullName } from '../../../../gql/customerGql';
import { useMediaInsertMutation } from '../../../../gql/generated/graphql';
import { Payoff } from '../../../../gql/payoffGql';
import { PayoffRequest } from '../../../../gql/payoffRequestGql';

import DatePicker from '../../../shared/DatePicker';
import Modal from '../../../shared/Modal';
import NumberInput from '../../../shared/NumberInput';
import { RadioGroup } from '../../../shared/RadioGroup';

import UploadPayoffDocsRadioAndDropzone from './UploadPayoffDocsRadioAndDropzone';

import { DealMediaEdit } from '../../../../types/media';
import { insertMediaByUrl } from '../../../../utils/media';
import { TotalPayoff } from '../../../TotalPayoff/TotalPayoff';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  payoffRequest: PayoffRequest;
  onSubmit: (
    values: Partial<Payoff>,
    needsPayoffDocuments: boolean,
    payoffDocumentsUploaded: boolean,
  ) => Promise<void>;
}

export const ConfirmPayoffForm: FC<Props> = ({ isOpen, payoffRequest, onClose, onSubmit }) => {
  const client = useApolloClient();

  const { values, isValid, setFieldValue, dirty } = useFormikContext<Partial<Payoff>>();

  const [needsPayoffDocuments, setNeedsPayoffDocuments] = useState(false);
  const [payoffDocumentsUploaded, setPayoffDocumentsUploaded] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<DealMediaEdit[]>([]);
  const [loading, setLoading] = useState(false);
  const [isRecalculatingPayoff, setIsRecalculatingPayoff] = useState<boolean>(false);

  const [mediaInsert] = useMediaInsertMutation();

  const modalTitle = `Confirm for ${getFullName(
    payoffRequest.deal?.customer
      ? payoffRequest.deal?.customer
      : payoffRequest.temporary_info?.data ?? {},
  )}`;

  const handleSubmit = async () => {
    setLoading(true);

    if (payoffRequest.deal_id) {
      await insertMediaByUrl(selectedMedia, payoffRequest.deal_id, client, mediaInsert);
    }

    await onSubmit(values, needsPayoffDocuments, payoffDocumentsUploaded);
    setLoading(false);
  };

  return (
    <Modal
      title={modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      hasForm
      size="xl"
      rightButtons={
        <>
          <Button variant="warning" onClick={onClose}>
            CANCEL
          </Button>
          <Button
            isLoading={loading}
            loadingText="CONFIRM"
            isDisabled={!isValid || !dirty || isRecalculatingPayoff}
            type="submit"
            onClick={handleSubmit}
          >
            CONFIRM
          </Button>
        </>
      }
    >
      <VStack spacing={4} align="flex-start">
        <TotalPayoff
          w="full"
          payoffRequest={payoffRequest}
          isRecalculating={isRecalculatingPayoff}
          setIsRecalculating={setIsRecalculatingPayoff}
        />
        {/* In payoffRequests table, good_through_date is a timestamp instead of a date. 
        That's why dateTimeUTC is used here.
        Kept it that way to avoid breaking COM */}
        <DatePicker
          name="good_through_date"
          topLabel="Good Through Date"
          boxStyles={{ w: '100%' }}
          valueFormat="dateTimeUTC"
        />
        <FormLabel>Is sales tax included?</FormLabel>
        <RadioGroup
          onChange={(e) => {
            setFieldValue('payoff_includes_sales_tax', e === 'true');

            if (e === 'false') {
              setFieldValue('sales_tax_from_payoff_entered_manually', false);
            }
          }}
          value={values.payoff_includes_sales_tax}
        />
        {values.payoff_includes_sales_tax && (
          <Flex w="100%">
            <Stack w="100%">
              <FormLabel>Do you know the sales tax amount?</FormLabel>
              <RadioGroup
                onChange={(e) => {
                  setFieldValue('sales_tax_from_payoff_entered_manually', e === 'true');
                }}
                value={values.sales_tax_from_payoff_entered_manually}
              />
            </Stack>
            <Spacer />
            {values.sales_tax_from_payoff_entered_manually && (
              <NumberInput
                label="Included Sales Tax"
                name="sales_tax_from_payoff"
                isMoney
                isRequired
                showThousandSeparator
                w="100%"
              />
            )}
          </Flex>
        )}
        {!!payoffRequest.deal_id && (
          <UploadPayoffDocsRadioAndDropzone
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            needsPayoffDocuments={needsPayoffDocuments}
            setNeedsPayoffDocuments={setNeedsPayoffDocuments}
            setPayoffDocumentsUploaded={setPayoffDocumentsUploaded}
            inConfirmModal
          />
        )}
      </VStack>
    </Modal>
  );
};

export default ConfirmPayoffForm;
