import { MouseEventHandler, useEffect, useRef, useState } from 'react';

import { Button, Divider, Flex, Icon, IconButton, Link, Text } from '@chakra-ui/react';
import { BiCollapse } from 'react-icons/bi';
import { MdPhoneEnabled, MdPhoneForwarded } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useAddParticipantMutation, useHangUpCallMutation } from '../../gql/generated/graphql';

import SmallCurrentCallPanel from './SmallCurrentCallPanel';

import useDebounce from '../../hooks/useDebounce';
import { useCallsContext } from '../../libs/callsContext';
import { formatPhoneNumberForDisplay } from '../../libs/utils';

const CurrentCallPanel = () => {
  const { handleTransferCallModal, setCallAlreadyAnswered, activeCall, current, setCurrent } =
    useCallsContext();
  const [panelMinimized, setPanelMinimized] = useState<boolean>(false);
  const [ringtone, setRingtone] = useState<HTMLAudioElement>();
  const [addParticipantMutation, { data: addParticipantMutationData }] =
    useAddParticipantMutation();
  const panelRef = useRef<HTMLDivElement>(null);
  const [waitingTime, setWaitingTime] = useState(0);
  const [hangUpCallMutation] = useHangUpCallMutation();

  const stopRingtone = () => {
    if (ringtone) {
      ringtone.pause();
      ringtone.currentTime = 0;
    }
  };

  useEffect(() => {
    // creates call waiting interval
    const intervalId = setInterval(() => {
      const timer = Math.floor(
        (new Date().getTime() - new Date(current?.started_at ?? '').getTime()) / 1000,
      );
      setWaitingTime(Math.floor(timer) || 0);
      // clear interval when there is no current calls
      if (current === undefined) {
        clearInterval(intervalId);
      }
    }, 1_000);
    return () => clearInterval(intervalId);
  }, [current?.started_at]);

  useEffect(() => {
    const audio = new Audio('https://leaseend-service-9352.twil.io/incoming_call.mp3');
    audio.volume = 0.2;
    setRingtone(audio);
  }, []);

  useEffect(() => {
    if (addParticipantMutationData?.addParticipant?.call_already_answered) {
      setCallAlreadyAnswered(true);
    }
  }, [addParticipantMutationData]);

  useEffect(() => {
    if (current && !activeCall) {
      ringtone?.play();
    } else {
      stopRingtone();
    }
  }, [activeCall, ringtone, current]);

  const debouncedAnswerCall = useDebounce(
    async (huntGroup: string) => {
      try {
        await addParticipantMutation({
          variables: {
            hunt_group_slug: huntGroup,
          },
        });
      } catch (error) {
        toast.error('Failed to answer call');
      }
    },
    500,
    {
      leading: true,
      trailing: false,
      maxWait: 900,
    },
  );

  const onClose = () => {
    setCurrent(undefined);
    handleTransferCallModal(false);
    stopRingtone();
  };

  // Used to spread data across all open tabs
  const broadcastIgnoreCall = () => {
    localStorage.setItem('callIgnored', JSON.stringify('callIgnored'));
    localStorage.removeItem('callIgnored');
  };

  const receiveIgnoreCall = (ev: StorageEvent) => {
    if (ev.key === 'callIgnored') {
      onClose();
    }
  };

  // used for ringtone stoppage across multiple tabs, hides panel
  useEffect(() => {
    if (current) {
      window.addEventListener('storage', receiveIgnoreCall);
    } else {
      window.removeEventListener('storage', receiveIgnoreCall);
      handleTransferCallModal(false);
    }
  }, [current]);

  const onIgnoreClick = () => {
    broadcastIgnoreCall();
    onClose();
  };

  const onHangUpClick = async () => {
    await hangUpCallMutation({
      variables: {
        conference_id: activeCall?.conferenceId ?? '',
      },
    });

    onClose();
  };

  const handlePanelClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (panelRef.current === e.target) {
      setPanelMinimized(true);
    }
  };

  if (!current) {
    return null;
  }

  if (panelMinimized) {
    return (
      <SmallCurrentCallPanel
        onPanelClick={setPanelMinimized}
        phoneNumber={current.phoneNumber}
        name={current.fullname}
      />
    );
  }

  const isRefi =
    current.dealType === 'refi' ||
    [
      '8339991584',
      '+18339991584',
      '8339991782',
      '+18339991782',
      '2086708843',
      '+12086708843',
      '+18335694156',
      '8335694156',
      '+18556070762',
      '8556070762',
      // fake mailer
      '+15205829264',
      '5205829264',
    ].includes(current.toNumber as string);

  // const isThunder = current.isThunder || false;
  const isThunder = false;

  const bgColor = isThunder ? 'thunderPurple' : isRefi ? 'refiCyan' : 'midnightblue';

  return (
    <Flex
      w="80%"
      h="80px"
      position="fixed"
      bottom={0}
      right={0}
      left={0}
      ml="10%"
      bg={bgColor}
      alignItems="center"
      borderTopRadius="10px"
      zIndex={2000}
      ref={panelRef}
      onClick={(event) => {
        handlePanelClick(event);
      }}
    >
      <Flex
        rounded="full"
        w="50px"
        h="50px"
        bg="green"
        ml={8}
        justifyContent="center"
        alignItems="center"
      >
        <Icon
          as={MdPhoneEnabled}
          color={isRefi || isThunder ? 'midnightblue' : 'white'}
          w={6}
          h={6}
          bg="transparent"
          m={0}
        />
      </Flex>
      <Link
        href={activeCall?.dealId ? `/deals/${activeCall?.dealId}` : undefined}
        isExternal
        ml={8}
        color="white"
        fontSize={{ base: '22px', lg: '22px' }}
        _hover={{ color: 'white' }}
        textTransform={activeCall?.dealId ? 'capitalize' : undefined}
      >
        {activeCall?.fullname && (
          <span>
            {activeCall?.fullname}
            <br />
          </span>
        )}
        {formatPhoneNumberForDisplay(current?.phoneNumber || '')}
      </Link>
      {activeCall?.dealState && <Divider orientation="vertical" mx={8} h="50%" />}
      <Text
        color="white"
        fontSize={{ base: '22px', lg: '22px' }}
        textTransform={activeCall?.dealId ? 'capitalize' : undefined}
      >
        {activeCall?.dealState?.replaceAll('_', ' ') ?? ''}
      </Text>
      {(isRefi || isThunder) && (
        <>
          <Divider orientation="vertical" mx={8} h="50%" />
          <span>
            <Text
              color="white"
              fontSize={{ base: '22px', lg: '22px' }}
              textTransform="capitalize"
              textAlign="center"
            >
              {isRefi ? 'Refi' : 'Lead'}
            </Text>
            <Divider orientation="vertical" mx={8} h="50%" />
          </span>
        </>
      )}
      {activeCall?.dealState && <Divider orientation="vertical" mx={8} h="50%" />}
      {!activeCall && (
        <>
          <Divider orientation="vertical" mx={8} h="50%" />
          <span>
            <Text color="white" fontSize={{ base: '22px', lg: '22px' }} textTransform="capitalize">
              Waiting {waitingTime}
            </Text>
            <Divider orientation="vertical" mx={8} h="50%" />
          </span>
        </>
      )}

      {activeCall?.phoneNumber ? (
        <Flex ml="auto" float="right">
          <Button
            variant={isRefi ? 'textBorder' : isThunder ? 'secondary' : 'secondaryDarkBg'}
            size="lgWithIconLeft"
            leftIcon={<MdPhoneForwarded />}
            mr={3}
            onClick={() => handleTransferCallModal(true)}
            role="group"
          >
            TRANSFER CALL
          </Button>
          <Button size="lgWithIconLeft" leftIcon={<MdPhoneEnabled />} mr={3} isDisabled>
            IN PROGRESS
          </Button>
          <Button variant="boot" onClick={onHangUpClick}>
            {/* Not a boot button, just matches the style */}
            HANG UP
          </Button>
        </Flex>
      ) : (
        <Flex ml="auto" float="right">
          <Button
            size="lgWithIconLeft"
            leftIcon={<MdPhoneEnabled />}
            mr={3}
            onClick={() => debouncedAnswerCall(current.huntGroupSlug || '')}
          >
            ANSWER CALL
          </Button>
          {/* Not a boot button, just matches the style */}
          <Button variant="boot" onClick={onIgnoreClick}>
            IGNORE
          </Button>
        </Flex>
      )}
      <IconButton
        variant="icon"
        icon={<BiCollapse />}
        color="white"
        fontSize={35}
        aria-label="minimize panel"
        onClick={() => setPanelMinimized(true)}
      />
    </Flex>
  );
};

export default CurrentCallPanel;
