import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { MdOutlineAttachMoney } from 'react-icons/md';

import {
  DownPaymentReceived,
  useOnDownPaymentReceivedSubscription,
} from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

const DownPaymentHandler = () => {
  const [payments, setPayments] = useState<DownPaymentReceived[]>([]);

  useOnDownPaymentReceivedSubscription({
    onData: ({ data }) => {
      if (!data.data?.onDownPaymentReceived) {
        return;
      }

      const newPayment = data.data.onDownPaymentReceived;
      setPayments([...payments, newPayment]);
    },
  });

  return (
    <>
      {payments.map((payment) => (
        <NotificationToast
          key={`${payment.dealId}`}
          dealId={payment.dealId ?? null}
          slideFadeIn={!!payments.length}
          title={`${payment.firstName} ${payment.lastName} - Deal ${payment.dealId}`}
          titleIcon={MdOutlineAttachMoney}
          titleIconColor="green"
          subTitle={`Down Payment of $${payment.amount ? payment.amount : '0.00'} Made`}
          details={
            <>
              <Text>Down Payment Made: ${payment.amount ? payment.amount : '0.00'}</Text>
              <Text>Down Payment Type: {payment.type}</Text>
            </>
          }
          onClose={() => setPayments(payments.filter((p) => p.dealId !== payment.dealId))}
        />
      ))}
    </>
  );
};

export default DownPaymentHandler;
