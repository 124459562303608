import { MouseEvent, MouseEventHandler, useEffect, useState } from 'react';

import { Box, Button, Center, Checkbox, Flex, Spinner, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

import { KbbSelectedOption, KbbSelectedOptionObject } from '../../gql/carGql';
import { Deal, DealStateEnum } from '../../gql/dealGql';
import {
  JdpVehicleAccessoriesObject,
  Maybe,
  useCalculateOptionsLazyQuery,
  useJdpValuesLazyQuery,
  useKbbValuesLazyQuery,
  useKbbVinLazyQuery,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';
import Tooltip from '../shared/Tooltip';

import { logger } from '../../libs/Logger';

type Option = {
  categoryGroup: string;
  hasRelationships: boolean;
  isConfigurable: boolean;
  isConsumer: boolean;
  isTypical: boolean;
  isVinDecoded: boolean;
  optionName: string;
  optionType: string;
  sortOrder: number;
  vehicleId: number;
  vehicleOptionId: number;
};

type Vehicle = {
  makeName: string;
  modelName: string;
  trimName: string;
  vehicleId: number;
  vehicleName: string;
  vehicleOptions: Option[];
  yearId: number;
};

interface VehicleValuesProps {
  kbbSelectedOptions?: KbbSelectedOption[];
}

const VehicleValuesCalculator = ({ kbbSelectedOptions }: VehicleValuesProps) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [vehicle, setVehicle] = useState<Maybe<Vehicle>>();
  const [vehicleOptionIds, setVehicleOptionIds] = useState<number[]>([]);
  const { values, setFieldValue, setFieldTouched, errors, validateForm } = useFormikContext<Deal>();
  const updateField = (
    name: string,
    value:
      | string
      | number
      | KbbSelectedOptionObject
      | JdpVehicleAccessoriesObject
      | null
      | undefined,
  ) => {
    setFieldValue(name, value);
    setTimeout(() => setFieldTouched(name, true));
  };
  const [calculateOptions] = useCalculateOptionsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.calculateOptions) {
        const selectedOptionIds =
          data?.calculateOptions?.map((opt) => Number(opt?.vehicleOptionId)) ?? [];

        setVehicleOptionIds(selectedOptionIds);

        setLoading(false);
      }
    },
  });

  interface GetKbbValuesParams {
    vehicleId: number;
    vehicleOptions?: Option[];
    configToModify?: { optionId: number; action: string }[];
    startingVehicleOptions?: number[];
  }

  const getConfigurationOptions = async ({
    vehicleId,
    vehicleOptions,
    configToModify = [],
    startingVehicleOptions = [],
  }: GetKbbValuesParams) => {
    setLoading(true);

    await calculateOptions({
      variables: {
        vehicleId,
        allKbbVehicleOptions: vehicleOptions || vehicle?.vehicleOptions,
        configToModify,
        startingVehicleOptions,
        vin: values.car?.vin,
        color: values.car?.color,
      },
    });
  };

  const [kbbGetByVin, { loading: vinLoading }] = useKbbVinLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async (vinData) => {
      setShowModal(true);
      const vinResultsLength = vinData?.kbbVin?.vinResults?.length;

      if (!vinResultsLength) {
        toast.error('KBB vehicle not found');
        logger.error(
          'VehicleValuesCalculator.tsx',
          'kbbGetByVin',
          values.car.vin,
          'KBB vehicle not found',
        );
        setShowModal(false);
        return;
      }

      setVehicles(vinData.kbbVin?.vinResults as unknown as Vehicle[]);

      const vehicleData = (
        vinResultsLength === 1
          ? vinData.kbbVin?.vinResults?.[0]
          : vinData.kbbVin?.vinResults?.find((veh) => veh?.trimName === values.car?.kbb_trim_name)
      ) as Maybe<Vehicle>;

      setVehicle(vehicleData);
      if (!vehicleData) {
        return;
      }

      const startingVehicleOptions =
        kbbSelectedOptions?.filter((opt) => !opt.removed)?.map((opt) => Number(opt.id)) ?? [];

      await getConfigurationOptions({
        vehicleOptions: vehicleData.vehicleOptions,
        vehicleId: vehicleData.vehicleId,
        startingVehicleOptions,
      });

      // show warning
      if (vinData?.kbbVin?.warning) {
        toast('Warning: Double check VIN', {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          type: 'warning',
        });
      }
    },
    onError: (e) => {
      toast.error('KBB unable to decode vin');
      logger.error('VehicleValuesCalculator.tsx', 'kbbGetByVin', values.car.vin, e);
      setShowModal(false);
    },
  });

  const [kbbGetValues, { loading: kbbValuesLoading }] = useKbbValuesLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async (valuesData) => {
      if (valuesData?.kbbValues?.lending && valuesData?.kbbValues?.retail) {
        updateField('car.retail_book_value', valuesData.kbbValues.retail);
        updateField(
          'car.kbb_retail_mileage_adjustment',
          valuesData.kbbValues.retailMileageAdjustment,
        );
        updateField(
          'car.kbb_retail_option_adjustment',
          valuesData.kbbValues.retailOptionAdjustment,
        );
        updateField('car.book_value', valuesData.kbbValues.lending);
        updateField(
          'car.kbb_lending_mileage_adjustment',
          valuesData.kbbValues.lendingMileageAdjustment,
        );
        updateField(
          'car.kbb_lending_option_adjustment',
          valuesData.kbbValues.lendingOptionAdjustment,
        );
        updateField('car.kbb_valuation_date', valuesData.kbbValues.valuationDate);
        updateField('car.kbb_vehicle_name', vehicle?.vehicleName);

        updateField('car.kbb_trim_name', vehicle?.trimName);

        updateField('car.kbb_selected_options_object', {
          selected_options: valuesData.kbbValues.kbbSelectedOptions as KbbSelectedOption[],
        });

        setShowModal(false);

        toast.success('KBB values updated');
      }
    },
    onError: (e) => {
      toast.error('Unable to get KBB values');
      logger.error('VehicleValuesCalculator.tsx', 'kbbGetValues', null, e);
    },
  });

  const [jdpGetValues, { loading: jdpValuesLoading }] = useJdpValuesLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (jdpData) => {
      if (jdpData?.jdpValues) {
        updateField('car.jdp_mileage_adjustment', jdpData.jdpValues.jdp_mileage_adjustment);
        updateField('car.jdp_adjusted_clean_retail', jdpData.jdpValues.jdp_adjusted_clean_retail);
        updateField('car.jdp_adjusted_clean_trade', jdpData.jdpValues.jdp_adjusted_clean_trade);
        updateField('car.jdp_trim_body', jdpData.jdpValues.jdp_trim_body);
        updateField('car.jdp_valuation_date', jdpData.jdpValues.jdp_valuation_date);
        updateField(
          'car.jdp_vehicle_accessories_object',
          jdpData.jdpValues.jdp_vehicle_accessories_object,
        );

        toast.success('JDP values updated');
      }
    },
    onError: (e) => {
      toast.error('Unable to get JDP values');
      logger.error('VehicleValuesCalculator.tsx', 'jdpGetValues', null, e);
    },
  });

  const openModal = async () => {
    // Get JDP values even if the modal is not opened
    jdpGetValues({
      variables: {
        vin: values.car.vin,
        mileage: values.car.mileage ?? 0,
        stateCode: values.customer.address.state,
      },
    });

    kbbGetByVin({
      variables: {
        vin: values.car.vin,
      },
    });
  };

  const changeConfiguration = (id: number, action: string) => {
    getConfigurationOptions({
      vehicleId: vehicle?.vehicleId as number,
      configToModify: [{ optionId: id, action }],
      startingVehicleOptions: vehicleOptionIds,
    });
  };

  const calculateValues = () => {
    kbbGetValues({
      variables: {
        data: {
          allKbbVehicleOptions: vehicle?.vehicleOptions,
          mileage: values.car.mileage,
          vehicleOptionIds,
          vehicleId: vehicle?.vehicleId,
        },
      },
    });
  };

  const handleVehicleClick = async (e: MouseEvent<HTMLDivElement>, selectedVehicle: Vehicle) => {
    e.stopPropagation();
    setVehicle(selectedVehicle);

    await getConfigurationOptions({
      vehicleOptions: selectedVehicle.vehicleOptions,
      vehicleId: selectedVehicle.vehicleId,
      startingVehicleOptions: vehicleOptionIds,
    });
  };

  const handleDifferentVehicleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setVehicle(null);
    setVehicleOptionIds([]);
  };

  // This useEffect is here because the book_value/KBB Lending field was showing an error even after updating the field in the useEffect above
  useEffect(() => {
    if (showModal === false) {
      validateForm();
    }
  }, [showModal]);

  const renderOptions = () => {
    const sortedByGroup =
      vehicle?.vehicleOptions?.reduce((prev, curr) => {
        const previous = cloneDeep(prev);
        if (!prev[curr.categoryGroup]) {
          previous[curr.categoryGroup] = [];
        }
        previous[curr.categoryGroup].push(curr);

        return previous;
      }, {} as { [x: string]: Option[] }) ?? {};

    return Object.keys(sortedByGroup).map((key) => (
      <Box mb="25px" key={key}>
        <Text fontWeight="bold" mb={2} fontSize="lg">
          {key}
        </Text>
        {sortedByGroup[key].map((o) => {
          const selected = vehicleOptionIds.includes(o.vehicleOptionId);

          return (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mb="5px"
              pb="5px"
              borderBottom="1px solid rgba(0,0,0,0.1)"
              key={o.vehicleOptionId}
            >
              <Text>{o.optionName}</Text>
              <Checkbox
                m={0}
                size="lg"
                type="checkbox"
                id="optionSelected"
                isDisabled={loading}
                isChecked={selected}
                onChange={() => {
                  changeConfiguration(o.vehicleOptionId, !selected ? 'selected' : 'deselected');
                }}
              />
            </Flex>
          );
        })}
      </Box>
    ));
  };

  const renderVehicle = () => {
    return (
      <Flex flexWrap="wrap">
        {vehicles.map((v) => (
          <Box
            p={4}
            bg="white"
            shadow="md"
            borderRadius="md"
            flex="0 0 250px"
            _hover={{ shadow: 'lg', bg: 'gray.50' }}
            cursor="pointer"
            key={v.vehicleId}
            onClick={(e: MouseEvent<HTMLDivElement>) => handleVehicleClick(e, v)}
          >
            <Text fontWeight="bold">{v.vehicleName}</Text>
            <p>{v.trimName}</p>
            <p>{v.modelName}</p>
            <p>{v.yearId}</p>
          </Box>
        ))}
      </Flex>
    );
  };

  const showOptions = !!vehicle;

  const calcKbbButtonErrors = [
    errors.customer?.address?.state,
    errors.customer?.address?.zip,
    errors.car?.vin,
    errors.car?.mileage,
    errors.car?.color,
  ];
  const disabled = calcKbbButtonErrors.some(Boolean);

  return (
    <>
      <Box>
        <Tooltip errors={calcKbbButtonErrors}>
          <Button
            variant="primary"
            size="sm"
            isLoading={vinLoading}
            loadingText="CALCULATE VALUES"
            isDisabled={disabled}
            onClick={openModal}
          >
            CALCULATE VALUES
          </Button>
        </Tooltip>
      </Box>
      <Modal
        title={showOptions ? 'Vehicle Options' : 'Choose a Vehicle'}
        isOpen={showModal}
        size="2xl"
        variant="noMargin"
        onClose={() => {
          setVehicle(null);
          setVehicleOptionIds([]);
          setShowModal(false);
        }}
        rightButtons={
          <>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              CLOSE
            </Button>
            {showOptions ? (
              <Button
                variant="secondary"
                onClick={handleDifferentVehicleClick as MouseEventHandler}
                disabled={values.state === DealStateEnum.Closed}
              >
                CHANGE TRIM
              </Button>
            ) : null}

            <Button
              isLoading={jdpValuesLoading || kbbValuesLoading}
              loadingText="CALCULATE VALUES"
              onClick={calculateValues}
            >
              CALCULATE VALUES
            </Button>
          </>
        }
      >
        {vinLoading ? (
          <Center h="300px">
            <Spinner boxSize="100px" />
          </Center>
        ) : showOptions ? (
          renderOptions()
        ) : (
          renderVehicle()
        )}
      </Modal>
    </>
  );
};

export default VehicleValuesCalculator;
