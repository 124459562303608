import { Dispatch, SetStateAction } from 'react';

import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { MdCheck, MdMoreHoriz } from 'react-icons/md';

import { Direction, SortByEnum, SortByOptionLabels, SortOpt } from '../../utils/dashboard';

type Props = {
  sortByState: [SortOpt, Dispatch<SetStateAction<SortOpt>>];
};

const SortByMenu = ({ sortByState }: Props) => {
  const [sortOpt, setSortOpt] = sortByState;
  const isSelected = ({ type, direction }: SortOpt) => {
    return sortOpt.type === type && sortOpt.direction === direction;
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        color="white"
        rightIcon={<MdMoreHoriz />}
        size="xs"
        variant="icon"
        p={0}
        m={0}
      />
      <MenuList color="darkLiver" zIndex={10}>
        <Text mx={3}>SORT BY</Text>
        {Object.entries(SortByOptionLabels).map(([sortType, labels]) => {
          // Get object for each sort type
          return Object.entries(labels).map(([key, sortLabel]) => {
            // Get each label
            return (
              <MenuItem
                key={`${sortType}-${key}`}
                onClick={() => {
                  setSortOpt({
                    type: sortType as SortByEnum,
                    direction: key as Direction,
                  });
                }}
              >
                <Flex w="100%" align="center" justify="space-between">
                  <Text>{sortLabel}</Text>
                  <Flex minW="25px" justify="right">
                    {/* add check if selected */}
                    {isSelected({ type: sortType as SortByEnum, direction: key as Direction }) && (
                      <MdCheck color="black" />
                    )}
                  </Flex>
                </Flex>
              </MenuItem>
            );
          });
        })}
      </MenuList>
    </Menu>
  );
};

export default SortByMenu;
