import { ChangeEvent, Dispatch, useEffect, useState } from 'react';

import {
  Button,
  FormControl,
  FormErrorMessage,
  InputGroup,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';

import Modal from '../../../shared/Modal';

import { FiltersAction, FiltersActionKind } from '../../utils';

type VinModalProps = {
  dispatch: Dispatch<FiltersAction>;
  value?: string[];
};

const VinModal = ({ value = [], dispatch }: VinModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [array, setArray] = useState<string[]>([]);

  const parse = (newText: string) => {
    const splitOnNewLine = (string: string) => string.split(/\r?\n/);
    const removeNonAlphaNumeric = (string: string) => string.replace(/[^a-zA-Z0-9]/g, '');

    const newArray = splitOnNewLine(newText)
      .map(removeNonAlphaNumeric)
      .filter((vin) => !!vin);

    setArray(newArray);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  useEffect(() => {
    if (isOpen && value.length > 0) {
      setText(value.join('\r\n'));
    }
  }, [isOpen]);

  useEffect(() => parse(text), [text]);

  useEffect(() => {
    if (array.length <= 500) {
      setError('');
      return;
    }
    setError('Maximum 500 VINs');
  }, [array]);

  return (
    <>
      <Button size="sm" variant="secondary" onClick={onOpen}>
        {value.length > 0 ? `FILTERING ${value.length} VINS` : 'FILTER BY VIN'}
      </Button>
      <Modal
        title="Filter by VIN"
        isOpen={isOpen}
        onClose={() => {
          setText('');
          onClose();
        }}
        isCentered
        size="5xl"
        scrollBehavior="inside"
        rightButtons={
          <>
            <Button variant="secondary" onClick={() => setText('')}>
              CLEAR
            </Button>
            <Button
              isDisabled={!!error}
              onClick={() => {
                dispatch({
                  type: FiltersActionKind.SET_VINS,
                  payload: array,
                });
                onClose();
              }}
            >
              APPLY
            </Button>
          </>
        }
      >
        <FormControl isInvalid={!!error}>
          <InputGroup>
            <Textarea onChange={handleChange} value={text} minH={400} autoFocus />
          </InputGroup>
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
      </Modal>
    </>
  );
};

export default VinModal;
