import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, DealStateEnum, dealFinalizeAcquisition } from '../../gql/dealGql';

import PaperworkTypeModal from '../PaperworkOptions/PaperworkTypeModal';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';
import Tooltip from '../shared/Tooltip';

import { DealContext } from '../../libs/DealContext';

// function to determine if payoff is potentially estimated
export const isPayoffEstimated = (payoff: number): boolean => {
  const numStr: string[] = payoff.toString().split('.');

  if (!numStr[1]) {
    return true;
  }

  const last4Chars = numStr[0].slice(-4).split('');

  if (
    last4Chars[0] === last4Chars[1] &&
    last4Chars[0] === last4Chars[2] &&
    last4Chars[0] === last4Chars[3]
  ) {
    return true;
  }

  return false;
};

// function to determine if mileage is potentially estimated
const isMileageEstimated = (mileage: number): boolean => {
  const numStr: string = mileage.toString().split('.')[0];

  if (numStr.length < 3) {
    return false;
  }

  const last2 = numStr.slice(-2);
  if (last2 === '00') {
    return true;
  }

  const last3Chars = numStr.slice(-3).split('');

  if (last3Chars[0] === last3Chars[1] && last3Chars[0] === last3Chars[2]) {
    return true;
  }

  return false;
};

const FinalizeDealButton = () => {
  const { deal } = useContext(DealContext);
  const { values, isValid, isSubmitting, setSubmitting, errors } = useFormikContext<Deal>();
  const [finalizeAcquisition] = useMutation(dealFinalizeAcquisition);

  const finalizeModal = useDisclosure();
  const paperWorkModal = useDisclosure();

  const onFinalizeDeal = () => {
    finalizeAcquisition({
      variables: {
        id: values.id,
        address_line: values.customer.address?.address_line,
        address_line_2: values.customer.address?.address_line_2,
        city: values.customer.address?.city,
        state: values.customer.address?.state,
        zip: values.customer.address?.zip,
        county: values.customer.address?.county,
        dob: values.customer.dob,
        cash_to_customer: values.financial_info_acquisition.cash_to_customer ?? 0,
        mileage: values.car.mileage,
        vehicle_payoff: values.car.payoff.vehicle_payoff,
      },
    });
    finalizeModal.onClose();
    setSubmitting(false);
  };

  const hidden = values.state !== DealStateEnum.Closing;

  const renderTitle = (mileage: number, vehicle_payoff: number) => {
    if (isMileageEstimated(mileage) && isPayoffEstimated(vehicle_payoff)) {
      return 'Please confirm the vehicle mileage and vehicle payoff are accurate and not estimates. Are these correct?';
    }
    if (isMileageEstimated(mileage) && !isPayoffEstimated(vehicle_payoff)) {
      return 'Please confirm the vehicle mileage is accurate and not an estimate. Is this correct?';
    }
    return 'Please confirm the vehicle payoff is accurate and not an estimate. Is this correct?';
  };

  return (
    <>
      <Tooltip errors={errors}>
        <Button
          isDisabled={!isValid}
          hidden={hidden}
          onClick={() =>
            isMileageEstimated(deal.car.mileage ?? 0) ||
            isPayoffEstimated(deal.car.payoff.vehicle_payoff ?? 0)
              ? finalizeModal.onOpen()
              : onFinalizeDeal()
          }
        >
          FINALIZE DEAL
        </Button>
      </Tooltip>
      <Modal
        title={renderTitle(deal.car.mileage ?? 0, deal.car.payoff.vehicle_payoff ?? 0)}
        isOpen={finalizeModal.isOpen}
        onClose={finalizeModal.onClose}
        rightButtons={
          <Button
            isLoading={isSubmitting}
            loadingText="FINALIZE DEAL"
            isDisabled={!isValid}
            hidden={hidden}
            onClick={paperWorkModal.onOpen}
          >
            FINALIZE DEAL
          </Button>
        }
      >
        {isMileageEstimated(deal.car.mileage ?? 0) && (
          <NumberInput label="Mileage" name="car.mileage" isWhole showThousandSeparator />
        )}
        {isPayoffEstimated(deal.car.payoff.vehicle_payoff ?? 0) && (
          <>
            <NumberInput label="Payoff" name="car.payoff.vehicle_payoff" isMoney />
          </>
        )}
      </Modal>
      <PaperworkTypeModal
        isOpen={paperWorkModal.isOpen}
        onClose={paperWorkModal.onClose}
        onCloseDeal={onFinalizeDeal}
      />
    </>
  );
};

export default FinalizeDealButton;
