import { useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { Deal } from '../../../gql/dealGql';
import { PodUser, useDealUpdateAssignmentsMutation } from '../../../gql/generated/graphql';

import CustomReactSelect from '../../shared/CustomReactSelect';

import { PodUsersByPod } from '../../../types/user';

type TitleClerkAutocompleteProps = {
  deal: Deal;
  isRegAssistant?: boolean;
  titlingUsersByPod?: PodUsersByPod;
};

const TitleClerkAutocomplete = ({
  deal,
  titlingUsersByPod,
  isRegAssistant = false,
}: TitleClerkAutocompleteProps) => {
  const [updateDealAssignments] = useDealUpdateAssignmentsMutation();
  const user = isRegAssistant ? deal.title_clerk2 : deal.title_clerk;

  const assignableTitleClerks = useMemo(() => {
    if (!titlingUsersByPod?.all?.length || !deal) {
      return [];
    }

    const podUsers = titlingUsersByPod[deal.titling_pod_id ?? 'active']?.filter(Boolean) ?? [];
    const userIds = podUsers.map((podUser) => podUser?.user_id);
    const uniqueUserIds = new Set<string>();

    const result = podUsers.filter((podUser) => {
      if (uniqueUserIds.has(podUser?.user_id as string)) {
        return false;
      }
      uniqueUserIds.add(podUser?.user_id as string);
      return true;
    });

    if (deal.closer_id && !userIds.includes(`${deal.closer_id}`)) {
      result.push(
        titlingUsersByPod.all?.find((podUser) => podUser.user_id === deal.closer_id) as PodUser,
      );
    }

    if (deal.closer2_id && !userIds.includes(`${deal.closer2_id}`)) {
      result.push(
        titlingUsersByPod.all?.find((podUser) => podUser.user_id === deal.closer2_id) as PodUser,
      );
    }

    return result.filter(Boolean);
  }, []);

  const users = useMemo(() => {
    const newUsers = [...assignableTitleClerks];
    const selectedUserBelongsToCurrentPod = assignableTitleClerks.some(
      (titlingUser) => titlingUser.user_id === user?.id,
    );
    if (user && !selectedUserBelongsToCurrentPod) {
      newUsers.unshift({ user_id: user.id, user });
    }

    return newUsers;
  }, [user?.id, assignableTitleClerks]);

  const userOptions = useMemo(
    () =>
      users.map((userGql) => ({
        value: userGql.user_id ?? '',
        label: userGql.user?.name ?? '',
      })),
    [users],
  );

  const selectedUserOption = useMemo(() => {
    return user ? { value: user.id, label: user.name } : null;
  }, [user?.id, user?.name]);

  const handleChange = async (newSelectedUserOption: { value: string; label: string } | null) => {
    if (!deal.id) {
      return;
    }

    const newSelectedUser = users.find(
      (userGql) => userGql.user_id === newSelectedUserOption?.value,
    );

    try {
      await updateDealAssignments({
        variables: {
          id: deal.id,
          [isRegAssistant ? 'title_clerk2_id' : 'title_clerk_id']: newSelectedUser?.user_id ?? null,
        },
      });

      toast.success('Deal Assignment updated');
    } catch (error) {
      toast.error('Failed to update Deal Assignment');
    }
  };
  return (
    <Box minW="215px" onClick={(e) => e.stopPropagation()}>
      <CustomReactSelect
        placeholder={isRegAssistant ? 'Select Reg Assistant' : 'Select Title Clerk'}
        isClearable
        options={userOptions}
        value={selectedUserOption}
        onChange={handleChange}
      />
    </Box>
  );
};

export default TitleClerkAutocomplete;
