import LeaseEndContainer from '../../components/Container/LEContainer';
import ButtonsRow from '../../components/Dashboards/ButtonsRow';
import FollowUpsTable from '../../components/FollowUp/FollowUpsTable';

const FollowUpsPage = () => {
  return (
    <>
      <ButtonsRow />
      <LeaseEndContainer>
        <FollowUpsTable />
      </LeaseEndContainer>
    </>
  );
};

export default FollowUpsPage;
