import { ComponentProps, FC, useContext, useEffect, useState } from 'react';

import { Button, DeepPartial, StackProps, Text, useDisclosure } from '@chakra-ui/react';
import Big from 'big.js';
import { useFormikContext } from 'formik';

import { Deal, isDeal } from '../../gql/dealGql';
import { usePayoffUpdateMutation } from '../../gql/generated/graphql';
import { Payoff } from '../../gql/payoffGql';
import { PayoffRequest } from '../../gql/payoffRequestGql';

import { DoubleTaxAppliedIcon } from '../PayoffInfo/PayoffStatus';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';

import { useGetUpdatedPayoffValues } from '../../hooks/useGetUpdatedPayoffValues';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { ModalContext } from '../../libs/contextLib';
import { formatMoney } from '../../libs/utils';
import { isNullOrUndefined } from '../../utils';
import { getDoubleTaxApplied, getPayoffRequestOrigin, getTotalPayoff } from '../../utils/payoffs';
import { TemporaryDataWithTotalPayoff } from '../../utils/temporaryData';

interface Props extends StackProps {
  isRecalculating: boolean;
  setIsRecalculating: (isLoading: boolean) => void;
  isDisabled?: boolean;
  hidden?: boolean;
  payoffRequest?: PayoffRequest;
  topLabel?: boolean;
  useDealContextValues?: boolean;
}

/**
 * @param {boolean} hidden - Hides the component, but still allows the recalculation of payoff values
 */
export const TotalPayoff: FC<Props> = ({
  isRecalculating,
  setIsRecalculating,
  isDisabled,
  hidden,
  payoffRequest,
  topLabel,
  useDealContextValues,
  ...rest
}) => {
  const { deal, dispatch, isPayoffRequested } = useContext(DealContext);
  const { modals } = useContext(ModalContext);

  const { values, setFieldValue, getFieldMeta, dirty } = useFormikContext<
    Deal | (Payoff & PayoffRequest)
  >();

  // CreditPerson (and soon other forms) is moving away from using Deal inside of its FormikContext.
  // This means that `valuesAreDeal` will be false when coming from CreditPerson.
  // There is a new flag `useDealContextValues` which means we cannot rely on Formik's values to be the deal
  // and we must use the deal from the DealContext.

  const valuesAreDeal = isDeal(values);
  const baseNamePath = useDealContextValues || valuesAreDeal ? 'car.payoff.' : '';

  const payoff = useDealContextValues
    ? deal.car.payoff
    : valuesAreDeal
    ? values.car.payoff
    : values;
  const payoffRequestOrigin = getPayoffRequestOrigin(payoffRequest);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalText, setModalText] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { getUpdatedPayoffValues } = useGetUpdatedPayoffValues();
  const [updatePayoff] = usePayoffUpdateMutation();

  type NumberInputPropsType = ComponentProps<typeof NumberInput>;
  const numberInputProps: NumberInputPropsType = {
    name: `${baseNamePath}totalPayoff`,
    leftLabel: topLabel ? undefined : valuesAreDeal ? 'Total Payoff' : 'Total Owed to Lienholder',
    isRequired: !valuesAreDeal,
  };

  const getFormNumberValue = (fieldName: keyof Payoff) => {
    const value = useDealContextValues
      ? deal.car.payoff[fieldName]
      : getFieldMeta(`${baseNamePath}${fieldName}`).value;

    if (isNullOrUndefined(value)) {
      return 0;
    }

    try {
      const numberValue = Number(value ?? 0);
      return numberValue;
    } catch (e) {
      return 0;
    }
  };

  const getFormBooleanValue = (fieldName: keyof Payoff) => {
    const value = useDealContextValues
      ? deal.car.payoff[fieldName]
      : getFieldMeta(`${baseNamePath}${fieldName}`).value;

    if (isNullOrUndefined(value)) {
      return undefined;
    }

    return !!value;
  };

  const setNewPayoffValues = async (
    vehiclePayoff?: number,
    salesTaxFromPayoff?: number,
    doubleTaxApplied?: boolean,
    adjustedUserEnteredTotalPayoff?: number,
  ) => {
    const newTotalPayoff = getTotalPayoff({
      ...payoff,
      vehicle_payoff: vehiclePayoff,
      sales_tax_from_payoff: salesTaxFromPayoff,
    });

    // All the payoff related values needs to be dispatched to the `DealContext`, including the ones that didn't change.
    const payoffToDispatch: Payoff = {
      ...payoff,
      vehicle_payoff: vehiclePayoff,
      sales_tax_from_payoff: salesTaxFromPayoff,
      user_entered_total_payoff: adjustedUserEnteredTotalPayoff,

      doubleTaxApplied,
      previousTotalPayoff: newTotalPayoff,
      totalPayoff: newTotalPayoff,
    };

    if (useDealContextValues) {
      await updatePayoff({
        variables: {
          payoff: {
            id: payoff.id,
            vehicle_payoff: vehiclePayoff,
            sales_tax_from_payoff: salesTaxFromPayoff,
            user_entered_total_payoff: adjustedUserEnteredTotalPayoff,
          },
        },
      });

      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          ...deal,
          car: {
            ...deal.car,
            payoff: payoffToDispatch,
          },
        },
      });
      return;
    }

    setFieldValue(`${baseNamePath}vehicle_payoff`, vehiclePayoff);
    setFieldValue(`${baseNamePath}sales_tax_from_payoff`, salesTaxFromPayoff);
    setFieldValue(`${baseNamePath}user_entered_total_payoff`, adjustedUserEnteredTotalPayoff);

    setFieldValue(`${baseNamePath}doubleTaxApplied`, doubleTaxApplied);
    setFieldValue(`${baseNamePath}previousTotalPayoff`, newTotalPayoff);
    setFieldValue(`${baseNamePath}totalPayoff`, newTotalPayoff);

    // `PayoffInfo` dispatches when clicking `Save`.
    if (valuesAreDeal && !modals.PayoffInfo) {
      // Estimate form recalculates initial values when the context deal is updated.
      // We pass the current values to avoid erasing the user's input.
      const valuesForContext = { ...values };
      if (!valuesForContext.addCobuyer) {
        delete valuesForContext.cobuyer;
      }
      if (!valuesForContext.addContact) {
        delete valuesForContext.contact;
      }
      if (!valuesForContext.addSecondContact) {
        delete valuesForContext.second_contact;
      }

      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          ...valuesForContext,
          car: {
            ...valuesForContext.car,
            payoff: payoffToDispatch,
          },
        },
      });
    }
  };

  const showModalIfNecessary = (
    vehiclePayoff?: number,
    salesTaxFromPayoff?: number,
    doubleTaxApplied?: boolean,
    newUserEnteredTotalPayoff?: number,
  ) => {
    onClose();

    const newTotalPayoff = getTotalPayoff({
      ...payoff,
      vehicle_payoff: vehiclePayoff,
      sales_tax_from_payoff: salesTaxFromPayoff,
    });
    const previousTotalPayoff = getFormNumberValue('previousTotalPayoff');
    const adjustedPreviousTotalPayoff = newUserEnteredTotalPayoff ?? previousTotalPayoff;
    if (newTotalPayoff === adjustedPreviousTotalPayoff) {
      return;
    }

    if (doubleTaxApplied) {
      setModalText(
        `This deal now requires double sales tax be collected. ${formatMoney(
          salesTaxFromPayoff,
        )} is being added to the payoff.`,
      );
      onOpen();
      return;
    }

    if (previousTotalPayoff >= newTotalPayoff) {
      setModalText(
        `This deal is no longer considered a double sales tax risk. ${formatMoney(
          new Big(previousTotalPayoff).minus(newTotalPayoff).toNumber(),
        )} is being removed from the payoff.`,
      );
      onOpen();
    }
  };

  const getData = (): Deal | TemporaryDataWithTotalPayoff => {
    if (useDealContextValues) {
      return {
        ...deal,
        customer: {
          ...deal.customer,
          address: { ...deal.customer.address, state: (values as Deal).customer?.address?.state },
        },
        financial_info: {
          ...deal.financial_info,
          buyer_not_lessee: (values as Deal).financial_info?.buyer_not_lessee,
        },
      };
    }

    if (valuesAreDeal) {
      return values;
    }

    if (isDeal(payoffRequestOrigin)) {
      return {
        ...payoffRequestOrigin,
        car: {
          ...payoffRequestOrigin.car,
          payoff: {
            ...payoffRequestOrigin.car.payoff,
            ...values,
          },
        },
      };
    }

    return {
      ...payoffRequestOrigin,
      ...values,
    };
  };

  const recalculatePayoffValues = async (newUserEnteredTotalPayoff?: number) => {
    if (isDisabled) {
      return;
    }

    setIsRecalculating(true);
    const adjustedUserEnteredTotalPayoff =
      newUserEnteredTotalPayoff ?? getFormNumberValue('user_entered_total_payoff');
    try {
      const { vehiclePayoff, salesTaxFromPayoff, doubleTaxApplied } = await getUpdatedPayoffValues({
        data: getData(),
        userEnteredTotalPayoff: adjustedUserEnteredTotalPayoff,
      });

      setNewPayoffValues(
        vehiclePayoff,
        salesTaxFromPayoff,
        doubleTaxApplied,
        adjustedUserEnteredTotalPayoff,
      );

      showModalIfNecessary(
        vehiclePayoff,
        salesTaxFromPayoff,
        doubleTaxApplied,
        newUserEnteredTotalPayoff,
      );
    } catch (e) {
      logger.error(
        'TotalPayoff.tsx',
        'Failed to recalculate payoff',
        {
          data: getData(),
          userEnteredTotalPayoff: adjustedUserEnteredTotalPayoff,
        },
        e,
      );
    } finally {
      setIsRecalculating(false);
    }
  };

  useEffect(() => {
    if (dirty) {
      return;
    }

    const setFieldIfUsesFormValues = (field: string, value: number | boolean) => {
      if (!useDealContextValues) {
        setFieldValue(field, value);
      }
    };

    const setInitialValues = async () => {
      // Set initial values if NOT in the `CreditPerson` form. Payoff doesn't exist there.
      // Dispatch if NOT in the `PayoffRequest` form. `DealContext` doesn't exist there.
      const payoffToDispatch: DeepPartial<Payoff> = {};

      const doubleTaxApplied = getFormBooleanValue('doubleTaxApplied');
      if (isNullOrUndefined(doubleTaxApplied)) {
        const initialDoubleTaxApplied = getDoubleTaxApplied(
          payoff,
          useDealContextValues
            ? deal.financial_info?.buyer_not_lessee
            : valuesAreDeal
            ? values.financial_info?.buyer_not_lessee
            : false,
        );

        setFieldIfUsesFormValues(`${baseNamePath}doubleTaxApplied`, initialDoubleTaxApplied);
        payoffToDispatch.doubleTaxApplied = initialDoubleTaxApplied;
      }

      const vehiclePayoff = getFormNumberValue('vehicle_payoff');
      const initialTotalPayoff = getTotalPayoff(payoff);

      const userEnteredTotalPayoff = getFormNumberValue('user_entered_total_payoff');
      if (
        isNullOrUndefined(userEnteredTotalPayoff) ||
        (userEnteredTotalPayoff === 0 && vehiclePayoff > 0)
      ) {
        setFieldIfUsesFormValues(`${baseNamePath}user_entered_total_payoff`, initialTotalPayoff);
        payoffToDispatch.user_entered_total_payoff = initialTotalPayoff;
      }

      const previousTotalPayoff = getFormNumberValue('previousTotalPayoff');
      if (
        isNullOrUndefined(previousTotalPayoff) ||
        (previousTotalPayoff === 0 && vehiclePayoff > 0)
      ) {
        setFieldIfUsesFormValues(`${baseNamePath}previousTotalPayoff`, initialTotalPayoff);
        payoffToDispatch.previousTotalPayoff = initialTotalPayoff;
      }

      const totalPayoff = getFormNumberValue('totalPayoff');
      if (isNullOrUndefined(totalPayoff) || (totalPayoff === 0 && vehiclePayoff > 0)) {
        setFieldIfUsesFormValues(`${baseNamePath}totalPayoff`, initialTotalPayoff);
        payoffToDispatch.totalPayoff = initialTotalPayoff;
      }

      if (useDealContextValues || valuesAreDeal) {
        dispatch({
          type: DealActionsEnum.DeepUpdateDeal,
          payload: {
            car: {
              payoff: payoffToDispatch,
            },
          },
        });
      }
    };

    setInitialValues();
  }, [values]);

  useEffect(() => {
    if (dirty && !isNullOrUndefined(getFormNumberValue('totalPayoff'))) {
      recalculatePayoffValues();
    }
  }, [
    (values as Deal).customer?.address?.state,
    (values as Deal).financial_info?.buyer_not_lessee,
    (values as Deal).car?.payoff?.lienholder_slug,
    (values as Deal).car?.payoff?.payoff_includes_sales_tax,
    (values as Deal).car?.payoff?.sales_tax_from_payoff_entered_manually,

    (values as Payoff).payoff_includes_sales_tax,
    (values as Payoff).sales_tax_from_payoff_entered_manually,
  ]);

  const setIsFocusedAndIsRecalculating = (value: boolean) => {
    setIsFocused(value);
    setIsRecalculating(value);
  };

  // TODO: this useEffect is the same as setting onFocus, onBlurCapture, and onBlur in the sales_tax_from_payoff NumberInput.
  // Remove when migrating to Chakra UI and creating the PayoffForm component.
  useEffect(() => {
    const onFocusInHandler = () => setIsFocusedAndIsRecalculating(true);
    const onBlurHandler = () => setIsFocusedAndIsRecalculating(false);
    const onFocusOutHandler = () => recalculatePayoffValues();

    const salesTaxFromPayoffInput = document.querySelector(
      `input[name="${baseNamePath}sales_tax_from_payoff"]`,
    );

    if (salesTaxFromPayoffInput) {
      salesTaxFromPayoffInput.addEventListener('focusin', onFocusInHandler);
      salesTaxFromPayoffInput.addEventListener('blur', onBlurHandler);
      salesTaxFromPayoffInput.addEventListener('focusout', onFocusOutHandler);
    }

    return () => {
      if (salesTaxFromPayoffInput) {
        salesTaxFromPayoffInput.removeEventListener('focusin', onFocusInHandler);
        salesTaxFromPayoffInput.removeEventListener('blur', onBlurHandler);
        salesTaxFromPayoffInput.removeEventListener('focusout', onFocusOutHandler);
      }
    };
  }, [
    (values as Deal).car?.payoff?.sales_tax_from_payoff,
    (values as Payoff).sales_tax_from_payoff,
  ]);

  return (
    <>
      {!hidden ? (
        <NumberInput
          label={topLabel ? 'Total Payoff' : undefined}
          {...numberInputProps}
          isLoading={isRecalculating && !isFocused}
          isMoney
          showThousandSeparator
          onFocus={() => setIsFocusedAndIsRecalculating(true)}
          onBlurCapture={() => setIsFocusedAndIsRecalculating(false)}
          onBlur={() => {
            const userEnteredTotalPayoff = getFormNumberValue('totalPayoff');
            const previousTotalPayoff = getFormNumberValue('previousTotalPayoff');
            if (userEnteredTotalPayoff && userEnteredTotalPayoff === previousTotalPayoff) {
              return;
            }

            recalculatePayoffValues(userEnteredTotalPayoff);
          }}
          isDisabled={isDisabled}
          icon={
            !isPayoffRequested && getFormBooleanValue('doubleTaxApplied') ? (
              <DoubleTaxAppliedIcon />
            ) : undefined
          }
          {...rest}
        />
      ) : null}

      <Modal
        title="Payoff"
        isOpen={isOpen}
        onClose={onClose}
        canDismiss={false}
        closeOnOverlayClick={false}
        rightButtons={<Button onClick={onClose}>CONFIRM</Button>}
      >
        <Text mt={4} ml={4}>
          {modalText}
        </Text>
      </Modal>
    </>
  );
};
