import { useContext } from 'react';

import { Box, Button, HStack, Skeleton } from '@chakra-ui/react';
import { BsExclamationOctagon } from 'react-icons/bs';
import { IoMdThumbsUp } from 'react-icons/io';

import { DealContext } from '../../../libs/DealContext';

interface FooterProps {
  loading: boolean;
  switchHasProblem: () => void;
  setShowBootModal: (state: boolean) => void;
}

const Footer = ({ loading, switchHasProblem, setShowBootModal }: FooterProps) => {
  const { deal } = useContext(DealContext);
  const { has_problem } = deal;

  return (
    <Box px={2} position="fixed" bottom={0} w="100%">
      <HStack
        p={4}
        color="white"
        justifyContent="space-between"
        bgColor="oxfordBlue"
        borderTopRadius="10px"
      >
        <Skeleton isLoaded={!loading} w="max-content">
          <HStack spacing="28px">
            <Button variant="boot" onClick={() => setShowBootModal(true)}>
              BOOT DEAL
            </Button>
            {has_problem ? (
              <Button
                size="lgWithIconLeft"
                onClick={switchHasProblem}
                variant="secondaryDarkBg"
                leftIcon={<IoMdThumbsUp />}
                disabled
              >
                RESOLVE THIS PROBLEM
              </Button>
            ) : (
              <Button
                size="lgWithIconLeft"
                onClick={switchHasProblem}
                variant="warning"
                leftIcon={<BsExclamationOctagon />}
              >
                FLAG AS PROBLEM
              </Button>
            )}
          </HStack>
        </Skeleton>
      </HStack>
    </Box>
  );
};

export default Footer;
