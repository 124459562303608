import { useContext, useState } from 'react';

import { Flex, IconButton, NumberInputProps, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BiCheckCircle, BiEditAlt, BiXCircle } from 'react-icons/bi';

import { Deal, DealStateEnum } from '../../../gql/dealGql';
import { getAdjustedRegistrationFee } from '../../../gql/financialInfoGql';
import { TitleRegistrationOption } from '../../../gql/generated/graphql';

import NumberInput from '../../shared/NumberInput';
import { DebouncedSave } from '../DealInfoBuyoutForm';

import { InsertNoteIfValueManuallyChangedArgs } from './TitleAndRegistrationSection';

import { PermissionEnum } from '../../../constants/permissions';
import { DealContext } from '../../../libs/DealContext';
import { AbilityContext } from '../../../libs/contextLib';
import { formatMoney } from '../../../libs/utils';

interface RegistrationProps {
  dealDetailsPageBeingEdited: boolean;
  debouncedSave: DebouncedSave;
  insertNoteIfValueManuallyChanged: (args: InsertNoteIfValueManuallyChangedArgs) => Promise<void>;
  fontSize?: Pick<NumberInputProps, 'fontSize'>['fontSize'];
}

export const Registration = ({
  dealDetailsPageBeingEdited,
  debouncedSave,
  insertNoteIfValueManuallyChanged,
  fontSize = 'md',
}: RegistrationProps) => {
  const { deal } = useContext(DealContext);
  const abilities = useContext(AbilityContext);

  const { values, errors, setFieldValue } = useFormikContext<Deal>();

  const isTitleOnly =
    values.financial_info?.title_registration_option === TitleRegistrationOption.TitleOnly;
  const isRegistrationTransfer =
    values.financial_info?.title_registration_option ===
    TitleRegistrationOption.TitleAndRegistrationTransfer;
  const inStructuringOrClosing =
    deal.state === DealStateEnum.Structuring || deal.state === DealStateEnum.Closing;
  const canEditRegistration =
    abilities.has(PermissionEnum.EditRegistration) &&
    !isTitleOnly &&
    !isRegistrationTransfer &&
    (inStructuringOrClosing || dealDetailsPageBeingEdited);

  const [clickedEditRegistration, setClickedEditRegistration] = useState(false);

  const handleSaveRegistrationClick = async () => {
    setFieldValue(
      'financial_info.new_registration_fee',
      values.financial_info.new_registration_fee,
    );

    await insertNoteIfValueManuallyChanged({
      fieldName: 'registration',
      oldValue: deal.financial_info.new_registration_fee,
      newValue: values.financial_info.new_registration_fee,
    });

    setClickedEditRegistration(false);

    debouncedSave();
  };

  if (canEditRegistration && clickedEditRegistration) {
    return (
      <Flex>
        <NumberInput autoFocus isMoney name="financial_info.new_registration_fee" />
        <Flex alignItems="center" ml={1}>
          <IconButton
            icon={<BiXCircle />}
            variant="iconHover"
            size="xs"
            aria-label="Cancel"
            onClick={() => {
              setFieldValue(
                'financial_info.new_registration_fee',
                deal.financial_info.new_registration_fee,
              );
              setClickedEditRegistration(false);
            }}
          />
          <IconButton
            icon={<BiCheckCircle />}
            variant="iconHover"
            size="xs"
            aria-label="Save Registration"
            isDisabled={!!errors.financial_info?.new_registration_fee}
            onClick={handleSaveRegistrationClick}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" pt={2} pl={3}>
      <Text fontWeight="normal" fontSize={fontSize}>
        {isTitleOnly
          ? 'Title Only'
          : formatMoney(getAdjustedRegistrationFee(values.financial_info))}
      </Text>

      {canEditRegistration ? (
        <IconButton
          icon={<BiEditAlt />}
          variant="iconHover"
          size="xs"
          aria-label="Edit Registration"
          onClick={() => {
            setFieldValue(
              'financial_info.new_registration_fee',
              values.financial_info.new_registration_fee,
            );
            setClickedEditRegistration(true);
          }}
        />
      ) : null}
    </Flex>
  );
};
