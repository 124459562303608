import { useEffect, useMemo, useState } from 'react';

import { Box, InputProps } from '@chakra-ui/react';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import { getFullName } from '../../../gql/customerGql';
import { Deal } from '../../../gql/dealGql';
import {
  Pod,
  TeamType,
  useDealUpdateAssignmentsMutation,
  usePodsQuery,
} from '../../../gql/generated/graphql';

import CustomReactSelect from '../../shared/CustomReactSelect';

import { logger } from '../../../libs/Logger';

type TitlePodOption = {
  value?: string;
  label?: string | null;
};

const getOption = (title_pod: Pod): TitlePodOption => ({
  value: title_pod.id?.toString(),
  label: title_pod.name,
});

type TitleTeamAutocompleteProps = InputProps & {
  deal: Deal;
};

const TitleTeamAutocomplete = ({ deal }: TitleTeamAutocompleteProps) => {
  const [selectedOption, setSelectedOption] = useState<TitlePodOption | null>(
    deal?.titling_pod ? getOption(deal.titling_pod) : null,
  );
  const [updateTitlePod, { loading }] = useDealUpdateAssignmentsMutation();

  const { data } = usePodsQuery({
    variables: { includeDetails: true, team_type: [TeamType.Titling] },
    onError: (error) => {
      toast.error(`Couldn't load teams. Please refresh. Error: ${error.message}`);
    },
  });

  const titlePodOptions = useMemo(
    () =>
      data?.pods?.map((titlingPod) => ({
        value: titlingPod?.id?.toString(),
        label: titlingPod?.name,
      })),
    [data?.pods],
  );

  useEffect(() => {
    if (deal?.titling_pod) {
      setSelectedOption(getOption(deal.titling_pod));
    }
  }, [deal?.titling_pod]);

  const handleChange = async (selected: SingleValue<TitlePodOption>) => {
    if (!deal?.id) {
      return;
    }

    try {
      await updateTitlePod({
        variables: {
          id: deal.id,
          titling_pod_id: selected?.value ? parseInt(selected.value, 10) : null,
        },
      });

      setSelectedOption(selected);
      toast.success(
        `Title Team ${selected?.value ? `updated to ${selected.label}` : 'Unassigned'} for Deal ${
          deal.id
        } - ${getFullName(deal.customer)}`,
      );
    } catch (error) {
      logger.error('TitleTeamAutoComplete.tsx', '', null, error);
      toast.error(
        (error as { message: string })?.message ||
          `Failed to update Title Team for Deal ${deal.id} - ${getFullName(deal.customer)}`,
      );
    }
  };

  return (
    <Box minW="220px" onClick={(e) => e.stopPropagation()}>
      <CustomReactSelect
        placeholder="Select Title Team"
        isClearable
        options={titlePodOptions}
        onChange={handleChange}
        value={selectedOption}
        isLoading={loading}
      />
    </Box>
  );
};

export default TitleTeamAutocomplete;
