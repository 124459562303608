import { ChangeEvent, Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { Box, Checkbox, Flex, HStack, Text } from '@chakra-ui/react';

import { Option } from '../../../shared/types';

import ClearFilterIcon from './ClearFilterIcon';
import DateFilter from './DateFilter';
import DropDown from './DropDown';
import MultiAutocomplete from './MultiAutocomplete';
import RangeFilter from './RangeFilter';
import VinModal from './VinModal';

import SELECT_OPTIONS from '../../../../constants/selectOptions';
import { useBanks } from '../../../../hooks/useBanks';
import { useLienholders } from '../../../../hooks/useLienholders';
import { FiltersContext } from '../../../../libs/contextLib';
import { states as usStates } from '../../../../libs/states';
import { processorOptions } from '../../../../utils/deals';
import { snakeCaseToUpperCase } from '../../../../utils/text';
import { FilterOptionsEnum, FilterOptionsType, FiltersActionKind } from '../../utils';

type FilterInputProps = {
  option: FilterOptionsType;
  selectedFilters: FilterOptionsEnum[];
  setSelectedFilters: Dispatch<SetStateAction<FilterOptionsEnum[]>>;
  maxAge?: string;
};

const FilterInput = ({ option, maxAge, selectedFilters, setSelectedFilters }: FilterInputProps) => {
  const {
    filters: { titling: titlingFilters },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const { banks } = useBanks();

  const { options: lienholderOptions } = useLienholders();

  const parseUSStates = (options: string[]) =>
    options.map((state) => ({ value: state, label: snakeCaseToUpperCase(state) }));

  const handleDealsProblemToggled = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchFilters({ type: FiltersActionKind.SET_PROBLEM_DEALS, payload: e.target.checked });
  };

  const usStatesOptions: Option[] = useMemo(
    () => [{ value: 'blank', label: 'No U.S. State' }, ...parseUSStates(Object.values(usStates))],
    [usStates],
  );

  const banksOptions: Option[] = useMemo(
    () => [
      { value: 'blank', label: 'No Bank Assigned' },
      ...banks.map((bank) => ({
        value: bank.name ?? '',
        label: bank.name ?? '',
      })),
    ],
    [banks],
  );

  const filterProcessorOptions: Option[] = useMemo(
    () => [{ value: 'blank', label: 'No Processor Assigned' }, ...processorOptions],
    [processorOptions],
  );

  switch (option.label) {
    case FilterOptionsEnum.SIGNED_DATE:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_FROM_DATE, FiltersActionKind.SET_TO_DATE]}
          />
          <HStack spacing={1}>
            <DateFilter
              dispatch={dispatchFilters}
              actionKind={FiltersActionKind.SET_FROM_DATE}
              value={titlingFilters.dateFrom}
            />
            <Text color="white">-</Text>
            <DateFilter
              dispatch={dispatchFilters}
              actionKind={FiltersActionKind.SET_TO_DATE}
              value={titlingFilters.dateTo}
            />
          </HStack>
        </Box>
      );
    case FilterOptionsEnum.US_STATE:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_US_STATE]}
          />
          <MultiAutocomplete
            placeholder={option.label}
            options={usStatesOptions}
            dispatch={dispatchFilters}
            actionKind={FiltersActionKind.SET_US_STATE}
            value={titlingFilters.usStates}
          />
        </Box>
      );
    case FilterOptionsEnum.TYPE:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_TYPE]}
          />
          <DropDown
            placeholder={SELECT_OPTIONS.DEAL_TYPES.map(({ label }) => label).join('/')}
            options={SELECT_OPTIONS.DEAL_TYPES}
            dispatch={dispatchFilters}
            actionKind={FiltersActionKind.SET_TYPE}
            value={titlingFilters.type ?? ''}
          />
        </Box>
      );
    case FilterOptionsEnum.DEAL_AGE:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_FROM_DATE, FiltersActionKind.SET_TO_DATE]}
          />
          <RangeFilter
            maxAge={maxAge}
            dispatch={dispatchFilters}
            value={{ from: titlingFilters.dateFrom, to: titlingFilters.dateTo }}
          />
        </Box>
      );
    case FilterOptionsEnum.TITLE_RECEIVED:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_HAS_TITLE_RECEIVED]}
          />
          <DropDown
            placeholder="Yes/No"
            options={[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: `No` },
            ]}
            dispatch={dispatchFilters}
            actionKind={FiltersActionKind.SET_HAS_TITLE_RECEIVED}
            value={titlingFilters.hasTitleReceived?.toString() ?? ''}
          />
        </Box>
      );
    case FilterOptionsEnum.PROBLEM_DEALS:
      return (
        <Flex direction="column">
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_PROBLEM_DEALS]}
          />
          <Flex justify="flex-end" pr={3}>
            <Checkbox
              onChange={handleDealsProblemToggled}
              isChecked={titlingFilters.problemDeals}
            />
          </Flex>
        </Flex>
      );
    case FilterOptionsEnum.BANK:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_BANKS]}
          />
          <MultiAutocomplete
            placeholder={FilterOptionsEnum.BANK}
            options={banksOptions}
            dispatch={dispatchFilters}
            actionKind={FiltersActionKind.SET_BANKS}
            value={titlingFilters.banks}
          />
        </Box>
      );
    case FilterOptionsEnum.PAID_OFF_DATE:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[
              FiltersActionKind.SET_CLOSED_FROM_DATE,
              FiltersActionKind.SET_CLOSED_TO_DATE,
            ]}
          />
          <HStack spacing={1}>
            <DateFilter
              dispatch={dispatchFilters}
              actionKind={FiltersActionKind.SET_CLOSED_FROM_DATE}
              value={titlingFilters.closedDateFrom}
            />
            <Text color="white">-</Text>
            <DateFilter
              dispatch={dispatchFilters}
              actionKind={FiltersActionKind.SET_CLOSED_TO_DATE}
              value={titlingFilters.closedDateTo}
            />
          </HStack>
        </Box>
      );
    case FilterOptionsEnum.TITLE_RECEIVED_DATE:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[
              FiltersActionKind.SET_TITLE_FROM_DATE,
              FiltersActionKind.SET_TITLE_TO_DATE,
            ]}
          />
          <HStack spacing={1}>
            <DateFilter
              dispatch={dispatchFilters}
              actionKind={FiltersActionKind.SET_TITLE_FROM_DATE}
              value={titlingFilters.titleDateFrom}
            />
            <Text color="white">-</Text>
            <DateFilter
              dispatch={dispatchFilters}
              actionKind={FiltersActionKind.SET_TITLE_TO_DATE}
              value={titlingFilters.titleDateTo}
            />
          </HStack>
        </Box>
      );
    case FilterOptionsEnum.VINS:
      return (
        <Box>
          <Box>
            <ClearFilterIcon
              title={option}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              dispatch={dispatchFilters}
              actionKind={[FiltersActionKind.SET_VINS]}
            />
          </Box>
          <VinModal value={titlingFilters.vins} dispatch={dispatchFilters} />
        </Box>
      );
    case FilterOptionsEnum.LIENHOLDER:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_LIENHOLDERS]}
          />
          <MultiAutocomplete
            placeholder={FilterOptionsEnum.LIENHOLDER}
            options={lienholderOptions}
            dispatch={dispatchFilters}
            actionKind={FiltersActionKind.SET_LIENHOLDERS}
            value={titlingFilters.lienholders}
          />
        </Box>
      );
    case FilterOptionsEnum.PROCESSOR:
      return (
        <Box>
          <ClearFilterIcon
            title={option}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            dispatch={dispatchFilters}
            actionKind={[FiltersActionKind.SET_PROCESSORS]}
          />
          <MultiAutocomplete
            placeholder={FilterOptionsEnum.PROCESSOR}
            options={filterProcessorOptions}
            dispatch={dispatchFilters}
            actionKind={FiltersActionKind.SET_PROCESSORS}
            value={titlingFilters.processors}
          />
        </Box>
      );
    default:
      return null;
  }
};

export default FilterInput;
