import { useContext } from 'react';

import { Box, HStack, Table, Tbody, Text, Th, Thead } from '@chakra-ui/react';

import { DealType } from '../../gql/generated/graphql';

import ExportDealsToXlsxButton from '../ExportDealsToXlsxButton/ExportDealsToXlsxButton';
import Loader from '../Loader';

import CompletedDealsRow from './CompletedDealsRow';

import { CompletedDealsProps, useCompletedDeals } from '../../hooks/useCompletedDeals';
import { FiltersContext } from '../../libs/contextLib';
import { completedDealColumns } from '../../pages/CompletedDealsPage/constants';

const CompletedDealsTable = ({
  startDate,
  endDate,
  user,
  dealState,
  addressState,
  notifications,
}: CompletedDealsProps) => {
  const {
    filters: {
      global: {
        showAssignedToMe,
        showBootRequested,
        showAssignedToDeletedUsers,
        types,
        showActiveTags,
        showMatureFollowUps,
      },
    },
  } = useContext(FiltersContext);

  const { query, completedDeals } = useCompletedDeals({
    assignedToMeFilter: showAssignedToMe,
    bootRequestedFilter: showBootRequested,
    assignedToDeletedUsersFilter: showAssignedToDeletedUsers,
    types: types.length
      ? types.filter((type) => type !== DealType.Acquisition)
      : Object.values(DealType).filter((type) => type !== DealType.Acquisition),
    startDate,
    endDate,
    user,
    dealState,
    addressState,
    notifications,
    showActiveTags,
    showMatureFollowUps,
  });

  return (
    <Box>
      <HStack p={3} bgColor="oxfordBlue" borderTopRadius={8} spacing="auto">
        <Text fontSize={20} textColor="white">
          Completed Deals
        </Text>
        <ExportDealsToXlsxButton
          aria-label="Export Completed Deals"
          deals={completedDeals}
          columns={completedDealColumns}
        />
      </HStack>
      <Box overflowX="scroll" overflowY="auto">
        <Table>
          <Thead bgColor="queenBlue" px={6} height="50px">
            {completedDealColumns.map(({ header, minW, maxW }) => (
              <Th key={header} textColor="white" textAlign="center" p={0} maxW={maxW} minW={minW}>
                {header}
              </Th>
            ))}
          </Thead>
          <Tbody>
            {completedDeals.map((d) => (
              <CompletedDealsRow deal={d} />
            ))}
          </Tbody>
        </Table>
        <Loader isLoading={query.loading} />
      </Box>
    </Box>
  );
};

export default CompletedDealsTable;
