import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Button, Checkbox } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';

import {
  FilterOptionsEnum,
  FilterOptionsType,
  FiltersAction,
  handleClearFilter,
} from '../../utils';

type MoreFiltersProps = {
  options: FilterOptionsType[];
  selectedFilters: FilterOptionsEnum[];
  setSelectedFilters: Dispatch<SetStateAction<FilterOptionsEnum[]>>;
  dispatch: Dispatch<FiltersAction>;
};

const MoreFilters = ({
  options,
  selectedFilters,
  setSelectedFilters,
  dispatch,
}: MoreFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (option: FilterOptionsType) => {
    if (selectedFilters.some((sf) => sf === option.label)) {
      handleClearFilter(option, option.actionKind, dispatch, selectedFilters, setSelectedFilters);
    } else {
      setSelectedFilters(() => [...selectedFilters, option.label]);
    }
  };

  return (
    <Box fontSize="20" zIndex="2" cursor="pointer" onMouseLeave={() => setIsOpen(false)} pr={2}>
      <Button
        size="smWithIconLeft"
        leftIcon={<MdAdd size="16px" />}
        variant="secondary"
        onClick={() => setIsOpen(!isOpen)}
      >
        More Filters
      </Button>
      <Box position="absolute" hidden={!isOpen}>
        {options.map((opt) => {
          return (
            <Box mt={1} boxShadow="md" borderRadius={10} key={opt.label}>
              <Checkbox
                size="lg"
                _hover={{ bg: 'gray.100' }}
                bg="white"
                w="200px"
                mb={0}
                mt={-1}
                p={3}
                borderTopRadius={options.indexOf(opt) === 0 ? 10 : 0}
                borderBottomRadius={options.indexOf(opt) === options.length - 1 ? 10 : 0}
                onChange={() => handleChange(opt)}
                isChecked={selectedFilters.some((sf) => sf === opt.label)}
              >
                {opt.label}
              </Checkbox>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MoreFilters;
