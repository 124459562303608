import { useContext, useEffect, useMemo, useRef } from 'react';

import {
  Box,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { MdFilterList } from 'react-icons/md';
import { VscTriangleUp } from 'react-icons/vsc';
import { useHistory, useLocation } from 'react-router-dom';

import { DealSourceEnum, dealSourceOptions, dealTypeOptions } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';
import { Pod } from '../../gql/podGql';

import CustomReactSelect from '../shared/CustomReactSelect';
import UserAutocomplete from '../shared/UserAutocomplete';
import { Option } from '../shared/types';

import MenuFilterContainer from './MenuFilterContainer';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { FiltersActionKind } from '../../globalFiltersUtils';
import useClickOutsideAction from '../../hooks/useClickOutsideAction';
import { useUserAbilityAndVisibleStates } from '../../hooks/useUserAbilityAndVisibleStates';
import { Can } from '../../libs/Can';
import { FiltersContext } from '../../libs/contextLib';
import { CurrentView } from '../../pages/Dashboard';
import { User } from '../../types/user';

const GlobalFiltersMenu = ({ isMobile = false }: { isMobile?: boolean }) => {
  const history = useHistory();
  const location = useLocation();

  const { viewAsUserSources, viewAsUserTypes, loggedUserPods, loggedUserSources } =
    useUserAbilityAndVisibleStates();
  const {
    filters: {
      global: {
        selectedPodId,
        sources,
        types,
        viewAsUserId,
        currentView,
        showOnlyUnread,
        showAssignedToMe,
        showFollowUps,
        showBootRequested,
        showAssignedToDeletedUsers,
        showActiveTags,
        showMatureFollowUps,
      },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const allPodsOption: Option = {
    value: '',
    label: 'All',
  };

  const availableSourceOptions = useMemo(
    () =>
      dealSourceOptions.filter((option) =>
        viewAsUserSources.includes(option.value as DealSourceEnum),
      ),
    [dealSourceOptions, viewAsUserSources],
  );

  const availableTypeOptions = useMemo(
    () => dealTypeOptions.filter((option) => viewAsUserTypes.includes(option.value as DealType)),
    [dealTypeOptions, viewAsUserTypes],
  );

  const appliedFiltersCount = useMemo(
    () =>
      (selectedPodId ? 1 : 0) +
      (sources.length !== availableSourceOptions.length ? 1 : 0) +
      (types.length !== availableTypeOptions.length ? 1 : 0) +
      (viewAsUserId ? 1 : 0) +
      (showOnlyUnread ? 1 : 0) +
      (showAssignedToMe ? 1 : 0) +
      (showBootRequested ? 1 : 0) +
      (showAssignedToDeletedUsers ? 1 : 0) +
      (showAssignedToMe ? 1 : 0) +
      (showFollowUps ? 1 : 0) +
      (showActiveTags ? 1 : 0) +
      (showMatureFollowUps ? 1 : 0),
    [
      selectedPodId,
      sources.length,
      types.length,
      availableSourceOptions.length,
      availableTypeOptions.length,
      viewAsUserId,
      showBootRequested,
      showAssignedToDeletedUsers,
      showOnlyUnread,
      showAssignedToMe,
      showFollowUps,
      showActiveTags,
      showMatureFollowUps,
    ],
  );

  const podOptions = useMemo(
    () => [
      allPodsOption,
      ...loggedUserPods
        .map((pod) => ({
          value: pod?.id?.toString() ?? '',
          label: pod?.name ?? '',
          color: pod?.color ?? '',
        }))
        .sort((a, b) => {
          if (a.label.includes('(ARCHIVED)')) {
            return 1;
          }
          if (b.label.includes('(ARCHIVED)')) {
            return -1;
          }
          return 0;
        }),
    ],
    [loggedUserPods],
  );
  const selectedPodOption = useMemo(
    () => podOptions.find((option) => option.value === selectedPodId?.toString()) ?? allPodsOption,
    [podOptions, selectedPodId],
  );

  useEffect(() => {
    if (location.pathname !== ROUTES.DASHBOARD || currentView !== CurrentView.DashboardView) {
      dispatchFilters({
        type: FiltersActionKind.SET_VIEW_AS_USER_ID,
        payload: undefined,
      });
    }
  }, [location.pathname, currentView]);

  const menuContainerRef = useRef<HTMLDivElement>(null);
  useClickOutsideAction(menuContainerRef, onClose, true);

  return (
    <Box ref={menuContainerRef}>
      <Menu isOpen={isOpen} onOpen={onOpen} isLazy lazyBehavior="unmount">
        {isMobile ? (
          <MenuButton
            as={IconButton}
            icon={<MdFilterList size="20px" />}
            variant="ghost"
            _active={{ bg: 'ceruleanBlue' }}
            _hover={{ bg: 'unset' }}
            bg={isOpen || appliedFiltersCount ? 'ceruleanBlue' : 'white'}
            color={appliedFiltersCount ? 'white' : 'darkLiver'}
            size="md"
            onClick={isOpen ? onClose : onOpen}
          />
        ) : (
          <MenuButton
            as={Button}
            leftIcon={<MdFilterList />}
            variant="lgWithIconLeft"
            bg={isOpen ? 'ceruleanBlue' : 'white'}
            color={isOpen ? 'white' : 'darkLiver'}
            size="md"
            onClick={isOpen ? onClose : onOpen}
          >
            Filter Deals {appliedFiltersCount ? `(${appliedFiltersCount})` : null}
          </MenuButton>
        )}

        <MenuList
          w={500}
          zIndex="dropdown"
          border="1px"
          borderColor="backgroundGray"
          fontSize={{ base: 'xs', sm: 'md' }}
          maxW={{ base: '70%', sm: 'unset' }}
        >
          <HStack margin={2} alignItems="start" spacing={0}>
            <Box
              as={VscTriangleUp}
              boxSize="50px"
              position="absolute"
              top="-25px"
              right="50px"
              color="white"
              pointerEvents="none"
            />
            <VStack w={{ base: '50%', sm: 'full' }} spacing={3}>
              <MenuFilterContainer label="Teams" spacing={3}>
                <CustomReactSelect
                  styles={{
                    option: (_provided, state) => ({
                      borderLeft: `5px solid ${state.data.color || 'black'}`,
                    }),
                    valueContainer: (_provided, state) => ({
                      borderLeft: `5px solid ${state.getValue()?.[0]?.color || 'black'}`,
                    }),
                  }}
                  value={selectedPodOption}
                  options={podOptions}
                  onChange={(option) => {
                    const newSelectedPodOption = option ?? allPodsOption;
                    const newSelectedPod = loggedUserPods.find(
                      (pod) => pod?.id?.toString() === newSelectedPodOption.value,
                    ) as Pod;

                    dispatchFilters({
                      type: FiltersActionKind.SET_SELECTED_POD_ID,
                      payload: newSelectedPod?.id,
                    });
                  }}
                />
              </MenuFilterContainer>

              <MenuFilterContainer label="Source" mb={20} fontWeight="normal" spacing={3}>
                {availableSourceOptions.map((option) => (
                  <Checkbox
                    key={option.value}
                    isChecked={sources.includes(option.value as DealSourceEnum)}
                    onChange={() =>
                      dispatchFilters({
                        type: FiltersActionKind.SET_SOURCES,
                        payload: sources.includes(option.value as DealSourceEnum)
                          ? sources.filter((source) => source !== option.value)
                          : [...sources, option.value as DealSourceEnum],
                      })
                    }
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </MenuFilterContainer>

              <MenuFilterContainer label="Type" mb={20} fontWeight="normal" spacing={3}>
                {availableTypeOptions.map((option) => (
                  <Checkbox
                    key={option.value}
                    isChecked={types.includes(option.value as DealType)}
                    onChange={() =>
                      dispatchFilters({
                        type: FiltersActionKind.SET_TYPES,
                        payload: types.includes(option.value as DealType)
                          ? types.filter((type) => type !== option.value)
                          : [...types, option.value as DealType],
                      })
                    }
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </MenuFilterContainer>
            </VStack>

            <VStack w={{ base: '70%', sm: 'full' }} spacing={3}>
              <Can I={[PermissionEnum.SeeUsers, PermissionEnum.SeeQueue]}>
                <MenuFilterContainer label="View As" spacing={3}>
                  <UserAutocomplete
                    includeEmptyOption
                    emptyOptionLabel="Me"
                    user={viewAsUserId ? new User(viewAsUserId) : undefined}
                    setUser={(newViewingAsUser) => {
                      if (newViewingAsUser?.id) {
                        dispatchFilters({
                          type: FiltersActionKind.SET_VIEW_AS_USER_ID,
                          payload: newViewingAsUser?.id,
                        });
                      }

                      if (!newViewingAsUser?.id) {
                        dispatchFilters({
                          type: FiltersActionKind.SET_SOURCES,
                          payload: loggedUserSources,
                        });
                      }

                      if (newViewingAsUser?.id && location.pathname !== ROUTES.DASHBOARD) {
                        history.push(ROUTES.DASHBOARD);
                      }
                    }}
                  />
                </MenuFilterContainer>
              </Can>

              <MenuFilterContainer label="Filter By" mb={20} fontWeight="normal" spacing={3}>
                <Checkbox
                  isChecked={showOnlyUnread}
                  onChange={(e) =>
                    dispatchFilters({
                      type: FiltersActionKind.SET_SHOW_ONLY_UNREAD,
                      payload: e.target.checked,
                    })
                  }
                >
                  Unread Messages
                </Checkbox>
                <Can I={PermissionEnum.SeeAssignedToMeFilter}>
                  <Checkbox
                    isChecked={showAssignedToMe}
                    onChange={(e) =>
                      dispatchFilters({
                        type: FiltersActionKind.SET_SHOW_ASSIGNED_TO_ME,
                        payload: e.target.checked,
                      })
                    }
                  >
                    Assigned to Me
                  </Checkbox>
                </Can>
                <Checkbox
                  isChecked={showBootRequested}
                  onChange={(e) =>
                    dispatchFilters({
                      type: FiltersActionKind.SET_SHOW_BOOT_REQUESTED,
                      payload: e.target.checked,
                    })
                  }
                >
                  Boot Requested
                </Checkbox>
                <Can I={PermissionEnum.SeeAssignedToDeletedUsersFilter}>
                  <Checkbox
                    isChecked={showAssignedToDeletedUsers}
                    onChange={(e) =>
                      dispatchFilters({
                        type: FiltersActionKind.SET_SHOW_ASSIGNED_TO_DELETED_USERS,
                        payload: e.target.checked,
                      })
                    }
                  >
                    Assigned to Deleted Users
                  </Checkbox>
                </Can>
                <Checkbox
                  isChecked={showActiveTags}
                  onChange={(e) =>
                    dispatchFilters({
                      type: FiltersActionKind.SET_SHOW_ACTIVE_TAGS,
                      payload: e.target.checked,
                    })
                  }
                >
                  Active Tags
                </Checkbox>
                <Checkbox
                  isChecked={showMatureFollowUps}
                  onChange={(e) =>
                    dispatchFilters({
                      type: FiltersActionKind.SET_SHOW_MATURE_FOLLOW_UPS,
                      payload: e.target.checked,
                    })
                  }
                >
                  Mature Follow-Ups
                </Checkbox>
              </MenuFilterContainer>

              <MenuFilterContainer label="Show" mb={20} fontWeight="normal">
                <Checkbox
                  isChecked={showFollowUps}
                  onChange={() =>
                    dispatchFilters({
                      type: FiltersActionKind.SET_SHOW_FOLLOW_UPS,
                      payload: !showFollowUps,
                    })
                  }
                >
                  All Follow-Ups
                </Checkbox>
              </MenuFilterContainer>
            </VStack>
          </HStack>
          <HStack justifyContent="end" margin={3} mr={5}>
            <Button
              variant="secondary"
              size={{ base: 'sm', sm: 'md' }}
              onClick={() =>
                dispatchFilters({
                  type: FiltersActionKind.CLEAR_GLOBAL_FILTERS,
                  payload: {
                    allowedDealSources: loggedUserSources,
                    types: Object.values(DealType),
                  },
                })
              }
            >
              Reset
            </Button>
            <Button size={{ base: 'sm', sm: 'md' }} onClick={onClose}>
              Done
            </Button>
          </HStack>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default GlobalFiltersMenu;
