import { useContext, useMemo } from 'react';

import { InputMaybe, UpdateCreditAppOnDealMutationVariables } from '../../gql/generated/graphql';

import { ModalContext } from '../../libs/contextLib';

export const getPersonCreditAppValues = (person: CreditAppOnDeal['customer']) => ({
  auth0_id: person.auth0_id,
  dob: person.dob,
  ssn: person.ssn,
  first_name: person.first_name,
  middle_name: person.middle_name,
  last_name: person.last_name,
  name_suffix: person.name_suffix,
  phone_number: person.phone_number,
  home_phone_number: person.home_phone_number,
  no_email: person.no_email,
  email: person.email,
  marital_status: person.marital_status,
  relation_to_buyer: person.relation_to_buyer,
  has_same_address_as_cobuyer: person.has_same_address_as_cobuyer,
  wi_notification_agreement: person.wi_notification_agreement,
  address: {
    address_type: person.address?.address_type,
    address_line: person.address?.address_line,
    address_line_2: person.address?.address_line_2,
    city: person.address?.city,
    county: person.address?.county,
    state: person.address?.state,
    zip: person.address?.zip,
    residence_type: person.address?.residence_type,
    monthly_payment: person.address?.monthly_payment,
    months_at_home: person.address?.months_at_home,
    years_at_home: person.address?.years_at_home,
  },
  ...(!!person.prev_address && {
    prev_address: {
      address_type: person.prev_address.address_type,
      address_line: person.prev_address.address_line,
      address_line_2: person.prev_address.address_line_2,
      city: person.prev_address.city,
      county: person.prev_address.county,
      state: person.prev_address.state,
      zip: person.prev_address.zip,
      residence_type: person.prev_address.residence_type,
      months_at_home: person.prev_address.months_at_home,
      years_at_home: person.prev_address.years_at_home,
    },
  }),
  employment: {
    status: person.employment?.status,
    name: person.employment?.name,
    job_title: person.employment?.job_title,
    phone_number: person.employment?.phone_number,
    years_at_job: person.employment?.years_at_job,
    months_at_job: person.employment?.months_at_job,
    gross_income: person.employment?.gross_income,
    pay_frequency: person.employment?.pay_frequency,
  },
  ...(!!person.prev_employment && {
    prev_employment: {
      name: person.prev_employment.name,
      job_title: person.prev_employment.job_title,
      years_at_job: person.prev_employment.years_at_job,
      months_at_job: person.prev_employment.months_at_job,
    },
  }),
  proof_of_insurance: {
    company_name: person.proof_of_insurance?.company_name,
    policy_number: person.proof_of_insurance?.policy_number,
    expires: person.proof_of_insurance?.expires,
  },
});

export const getContactCreditAppValues = (contact: NonNullable<CreditAppOnDeal['contact']>) => ({
  first_name: contact.first_name,
  middle_name: contact.middle_name,
  last_name: contact.last_name,
  phone_number: contact.phone_number,
  email: contact.email,
  relation_to_buyer: contact.relation_to_buyer,
});

export type CreditAppOnDeal = UpdateCreditAppOnDealMutationVariables['input'] & {
  customer: UpdateCreditAppOnDealMutationVariables['input']['customer'] & {
    wi_notification_agreement?: boolean;
  };
  cobuyer?: InputMaybe<
    UpdateCreditAppOnDealMutationVariables['input']['cobuyer'] & {
      wi_notification_agreement?: boolean;
    }
  >;
  addCobuyer: boolean;
  addContact: boolean;
  addSecondContact: boolean;
};

const useCreditAppInitialValues = <TDeal extends CreditAppOnDeal>(formValues: TDeal) => {
  const { modalCloseCount } = useContext(ModalContext);
  return useMemo<CreditAppOnDeal>(
    () => ({
      id: formValues.id,
      state: formValues.state,
      odometer: formValues.odometer_status,
      addCobuyer: formValues.addCobuyer,
      addContact: formValues.addContact,
      addSecondContact: formValues.addSecondContact,
      customer: getPersonCreditAppValues(formValues.customer),
      ...(formValues.cobuyer && { cobuyer: getPersonCreditAppValues(formValues.cobuyer) }),
      ...(formValues.contact && { contact: getContactCreditAppValues(formValues.contact) }),
      ...(formValues.second_contact && {
        second_contact: getContactCreditAppValues(formValues.second_contact),
      }),
      financial_info: {
        term: formValues.financial_info.term,
        money_down: formValues.financial_info.money_down,
        buyer_not_lessee: formValues.financial_info.buyer_not_lessee,
      },
    }),
    // `deal` here, as a dependency, breaks the autosave: updates with previous values if the user continues editing while autosaving.
    // To recalculate initial values, you can use `modals` or the specific deal value you want to update.
    [modalCloseCount],
  );
};

export default useCreditAppInitialValues;
