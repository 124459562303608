const button = {
  baseStyle: {
    textTransform: 'uppercase',
    borderRadius: '5px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '1.4px',
    marginY: '2px',
    marginX: '2px',
    _disabled: {
      opacity: '100%',
      pointerEvents: 'none',
    },
  },

  sizes: {
    lg: { p: '14px 20px', fontSize: '14px' }, // Describes the normal button in the style guide
    lgWithIconLeft: { p: '14px 20px 14px 14px', fontSize: '14px' },
    lgWithIconRight: { p: '14px 14px 14px 20px', fontSize: '14px' },
    md: { p: '10px 14px', fontSize: '14px' },
    sm: {
      p: '5px 7px', // Describes the mini-button in the style guide
      fontSize: '12px',
      fontWeight: 550,
      letterSpacing: '0.6px',
    },
    smWithIconLeft: {
      p: '5px 7px 5px 5px',
      fontSize: '12px',
      fontWeight: 550,
      letterSpacing: '0.6px',
    },
    smWithIconRight: {
      p: '5px 5px 5px 7px',
      fontSize: '12px',
      fontWeight: 550,
      letterSpacing: '0.6px',
    },
  },
  variants: {
    primary: {
      bg: 'primary',
      color: 'oxfordBlue',
      _hover: {
        bg: 'crayolaYellow',
      },
      _disabled: {
        bg: 'brightGray',
        color: 'basicGray',
      },
      _active: { bg: 'primary' },
    },
    secondary: {
      border: '1px',
      color: 'oxfordBlue',
      bg: 'white',
      _hover: {
        bg: 'azureishWhite',
      },
      _disabled: {
        color: 'basicGray',
      },
      _active: { bg: 'azureishWhite' },
    },
    secondaryDarkBg: {
      border: '1px',
      color: 'littleBoyBlue',
      _hover: {
        bg: 'azureishWhite',
        color: 'oxfordBlue',
      },
      _disabled: {
        color: 'basicGray',
      },
      _active: { bg: 'azureishWhite' },
    },
    text: {
      bg: 'transparent',
      color: 'oxfordBlue',
      _hover: {
        opacity: '50%',
      },
      _disabled: {
        color: 'basicGray',
      },
    },
    textBorder: {
      bg: 'transparent',
      border: '1px',
      color: 'oxfordBlue',
      _hover: {
        opacity: '50%',
      },
      _disabled: {
        color: 'basicGray',
      },
    },
    warning: {
      border: '1px',
      color: 'errorsRed',
      _hover: {
        bg: 'spanishPink',
      },
      _disabled: {
        color: 'basicGray',
      },
      _active: { bg: 'errorsRed' },
    },
    boot: {
      bg: 'errorsRed',
      color: 'white',
      _hover: {
        bg: 'rubyRed',
      },
      _disabled: {
        bg: 'gray',
        pointerEvents: 'none',
      },
    },
    icon: {
      bg: 'transparent',
      color: 'primary',
      fontSize: '20',
      m: '0',
      _hover: {
        opacity: '70%',
        background: 'transparent',
      },
    },
    iconHover: {
      bg: 'transparent',
      color: 'darkLiver',
      fontSize: '20',
      m: '0',
      _hover: {
        background: 'platinum',
      },
      _disabled: {
        color: 'basicGray',
      },
    },
    signatureBadge: {
      bg: 'transparent',
      border: '1px',
      _disabled: { opacity: '40%' },
    },
    navBar: {
      bg: 'white',
      color: 'queenBlue',
      borderRadius: 0,
      _hover: {
        bg: 'ceruleanBlue',
        color: 'white',
      },
      _disabled: {
        color: 'basicGray',
      },
      _active: {
        bg: 'ceruleanBlue',
        color: 'white',
        boxShadow: 'inset 0px 3px 6px #00000029',
        borderTop: '1px solid #00000029',
      },
    },
    success: {
      bg: 'white',
      color: 'caribbeanGreen.500',
      border: '1px',
      _hover: {
        bg: 'azureishWhite',
      },
      _disabled: {
        color: 'caribbeanGreen.500',
      },
      _active: { bg: 'azureishWhite' },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'primary',
  },
};

export default button;
