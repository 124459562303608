import { ChangeEventHandler, useEffect } from 'react';

import { Flex, FlexProps, HStack, StyleProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../gql/dealGql';
import { ProcessorEnum } from '../../../gql/financialInfoGql';

import DatePicker from '../../shared/DatePicker';
import Select from '../../shared/Select';
import {
  CUSTOM_DATE_FIELDS,
  CustomDateFieldsKey,
  processorSectionCustomDateFields,
} from '../constants';

import InputRow from './InputRow';
import { SendToProcessorButton } from './buttons/SendToProcessorButton';

import { processorOptions } from '../../../utils/deals';

type ProcessorSectionProps = FlexProps & {
  saveCustomDate: (fieldName: string, value: string) => Promise<void>;
  saveProcessor: (value?: ProcessorEnum) => Promise<void>;
  defaultFontSize?: StyleProps['fontSize'];
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
};

export const ProcessorSection = ({
  saveCustomDate,
  saveProcessor,
  defaultFontSize = 'sm',
  customHandleChange,
  ...rest
}: ProcessorSectionProps) => {
  const { initialValues, values, touched } = useFormikContext<Deal>();

  useEffect(() => {
    if (
      initialValues.financial_info.processor === values.financial_info.processor &&
      !touched.financial_info?.processor
    ) {
      return;
    }

    saveProcessor(values.financial_info.processor);
  }, [values.financial_info.processor]);

  return (
    <Flex direction="column" {...rest}>
      <InputRow label="Processor">
        <Select
          name="financial_info.processor"
          size="sm"
          options={processorOptions}
          onChange={customHandleChange}
        />
      </InputRow>
      <HStack w="full" justify="end" mb={2}>
        <SendToProcessorButton />
      </HStack>
      {Object.entries(processorSectionCustomDateFields).map(([fieldKey, label]) => (
        <InputRow
          key={fieldKey}
          label={label}
          labelProps={{
            whiteSpace: 'normal',
          }}
        >
          <DatePicker
            name={CUSTOM_DATE_FIELDS[fieldKey as CustomDateFieldsKey]}
            boxStyles={{
              w: '100%',
            }}
            inputProps={{
              fontSize: defaultFontSize,
            }}
            additionalHandleChange={(e) =>
              saveCustomDate(e.currentTarget.name, e.currentTarget.value)
            }
          />
        </InputRow>
      ))}
    </Flex>
  );
};
