import { useContext } from 'react';

import {
  // eslint-disable-next-line no-restricted-imports
  RadioGroup as ChakraRadioGroup,
  Radio,
  Stack,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { TitleRegistrationOptionLabels } from '../../../../../gql/financialInfoGql';
import { DealMediaTypeEnum } from '../../../../../gql/generated/graphql';

import FormErrorContainer from '../../../../shared/FormErrorContainer';

import { MismatchSelectionEnum } from '../../../../../constants/media';
import { DealContext } from '../../../../../libs/DealContext';
import {
  DealMedia,
  DriverLicenseMetadata,
  DriverLicenseMetadataValueFields,
  DriverLicenseMismatchFieldMap,
  DriverLicenseVerifiedFieldMap,
  InsuranceCardMetadataValueFields,
  RegistrationMetadataValueFields,
} from '../../../../../types/media';
import { pascalCaseToTitleCase } from '../../../../../utils/text';

type FieldName =
  | DriverLicenseMetadataValueFields
  | InsuranceCardMetadataValueFields
  | RegistrationMetadataValueFields;

type DocumentType =
  | DealMediaTypeEnum.FrontOfDriversLicense
  | DealMediaTypeEnum.FrontOfInsuranceCard
  | DealMediaTypeEnum.Registration;

const RadioLabels: Record<DocumentType, string> = {
  [DealMediaTypeEnum.FrontOfDriversLicense]: 'License',
  [DealMediaTypeEnum.FrontOfInsuranceCard]: 'Insurance Card',
  [DealMediaTypeEnum.Registration]: 'Registration',
};

const DocumentNames: Record<DocumentType, string> = {
  [DealMediaTypeEnum.FrontOfDriversLicense]: 'driver license',
  [DealMediaTypeEnum.FrontOfInsuranceCard]: 'insurance card',
  [DealMediaTypeEnum.Registration]: 'registration',
};

interface Props extends StackProps {
  fieldName: FieldName;
  showError: boolean;
  documentValue: string;
  applicationValue?: string;
  canUpdateApplication?: boolean;
  documentType?: DocumentType;
  documentValueIsValid?: boolean;
}

const FormError = ({
  fieldName,
  showError,
  documentValue,
  applicationValue,
  canUpdateApplication = false,
  documentType = DealMediaTypeEnum.FrontOfInsuranceCard,
  documentValueIsValid = true,
}: Props) => {
  const { deal } = useContext(DealContext);

  const { values, setFieldValue } = useFormikContext<DealMedia>();
  const { metadata } = values as { metadata: DriverLicenseMetadata };

  const isExpirationInDaysField =
    fieldName === 'expirationDate60Days' || fieldName === 'expirationDate90Days';

  const fieldLabel = isExpirationInDaysField ? 'expiration date' : pascalCaseToTitleCase(fieldName);
  const documentRadioLabel = RadioLabels[documentType];
  const documentName = DocumentNames[documentType];
  const isDriversLicense = documentType === DealMediaTypeEnum.FrontOfDriversLicense;

  const matchRequiredMessage = `${
    deal.customer?.address?.state ?? 'The state'
  } requires the ${fieldLabel} on the ${documentName} to match the ${fieldLabel} ${
    isDriversLicense ? 'on the buyout application' : 'entered in DD'
  }.`;
  const getNewDocumentMessage = `Buyer will need to get a new ${documentName}${
    isDriversLicense ? ' from the DMV' : ''
  }.`;
  const fixDocumentValuesMessage = `Fix the ${fieldLabel} on the ${documentName} to be able to update the application.`;
  const updateApplicationMessage = "Ok, we'll update the application.";

  if (!showError) {
    return null;
  }

  if (isExpirationInDaysField && !canUpdateApplication) {
    return (
      <FormErrorContainer fontWeight="normal">
        <Text>
          <b>{TitleRegistrationOptionLabels.title_and_registration_transfer}</b> requires{' '}
          <b>{fieldName === 'expirationDate60Days' ? '60' : '90'} days</b> remaining in the current
          registration. Please change the deal to{' '}
          <b>{TitleRegistrationOptionLabels.title_and_new_registration}</b>.
        </Text>
      </FormErrorContainer>
    );
  }

  if (fieldName === 'expirationDate' && !canUpdateApplication) {
    return (
      <FormErrorContainer fontWeight="normal">
        <Text>{getNewDocumentMessage}</Text>
      </FormErrorContainer>
    );
  }

  if (fieldName === 'cobuyerOnInsurance') {
    return (
      <FormErrorContainer fontWeight="normal">
        <Text>
          The cobuyer on the application must be included on the buyer's insurance policy.
        </Text>
      </FormErrorContainer>
    );
  }

  if (fieldName === 'zip' && !documentValueIsValid) {
    return (
      <FormErrorContainer fontWeight="normal">
        <Text>{fixDocumentValuesMessage}</Text>
      </FormErrorContainer>
    );
  }

  const getSolutionMessage = (mismatchSelection?: MismatchSelectionEnum | '') => {
    if (mismatchSelection === MismatchSelectionEnum.Application) {
      return getNewDocumentMessage;
    }
    if (mismatchSelection === MismatchSelectionEnum.Document) {
      return updateApplicationMessage;
    }
    if (!documentValueIsValid) {
      return fixDocumentValuesMessage;
    }
    return '';
  };

  if (isDriversLicense && canUpdateApplication) {
    const driverLicenseFieldName = fieldName as DriverLicenseMetadataValueFields;
    const mismatchFieldName = DriverLicenseMismatchFieldMap.get(driverLicenseFieldName);
    const mismatchFieldValue = mismatchFieldName ? metadata?.[mismatchFieldName] : '';

    return (
      <FormErrorContainer fontWeight="normal">
        <VStack alignItems="start">
          <Text w="full">{matchRequiredMessage}</Text>
          <Text w="full">What is correct?</Text>
          <ChakraRadioGroup
            onChange={(nextValue) => {
              if (!mismatchFieldName) {
                return;
              }

              setFieldValue(`metadata.${mismatchFieldName}`, nextValue);
              if (nextValue !== MismatchSelectionEnum.Application) {
                return;
              }

              const verifiedFieldName = DriverLicenseVerifiedFieldMap.get(driverLicenseFieldName);
              if (!verifiedFieldName) {
                return;
              }

              setFieldValue(`metadata.${verifiedFieldName}`, false);
              if (fieldName !== 'address') {
                return;
              }

              setFieldValue(`metadata.${DriverLicenseVerifiedFieldMap.get('city')}`, false);
              setFieldValue(`metadata.${DriverLicenseVerifiedFieldMap.get('state')}`, false);
              setFieldValue(`metadata.${DriverLicenseVerifiedFieldMap.get('zip')}`, false);
            }}
            value={mismatchFieldValue}
          >
            <Stack direction="column">
              <Radio value={MismatchSelectionEnum.Application}>
                <b>Application:</b> {applicationValue}
              </Radio>
              <Radio isDisabled={!documentValueIsValid} value={MismatchSelectionEnum.Document}>
                <b>{documentRadioLabel}:</b> {documentValue}
              </Radio>
            </Stack>
          </ChakraRadioGroup>
          <Text>{getSolutionMessage(mismatchFieldValue)}</Text>
        </VStack>
      </FormErrorContainer>
    );
  }

  if (!applicationValue) {
    return null;
  }

  return (
    <FormErrorContainer fontWeight="normal">
      <VStack alignItems="start">
        <Text w="full">{matchRequiredMessage}</Text>
        <VStack>
          <Text w="full">
            <b>Application:</b> {applicationValue}
          </Text>
          <Text w="full">
            <b>{documentRadioLabel}:</b> {documentValue}
          </Text>
          <Text w="full">
            {canUpdateApplication
              ? getSolutionMessage(
                  documentValueIsValid ? MismatchSelectionEnum.Document : undefined,
                )
              : getNewDocumentMessage}
          </Text>
        </VStack>
      </VStack>
    </FormErrorContainer>
  );
};

export default FormError;
