import { FC, useContext, useState } from 'react';

import { Box, Icon, Table, Tbody, Th, Thead } from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import { DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import {
  Deal,
  FollowUpStatusEnum,
  Maybe,
  useGetFollowUpDealsPaginatedQuery,
  useOnFollowUpUpdateSubscription,
} from '../../gql/generated/graphql';

import Pagination from '../Pagination';

import FollowUpRow from './FollowUpRow';

import { PermissionEnum } from '../../constants/permissions';
import usePagination from '../../hooks/usePagination';
import { AbilityContext, FiltersContext } from '../../libs/contextLib';

const FollowUpsTable: FC = () => {
  const paginationContext = usePagination();
  const abilities = useContext(AbilityContext);
  const {
    filters: {
      global: {
        selectedPodId,
        sources,
        types,
        showOnlyUnread,
        showAssignedToMe,
        showBootRequested,
        showAssignedToDeletedUsers,
        showActiveTags,
        showMatureFollowUps,
      },
    },
  } = useContext(FiltersContext);

  const [columnToSort, setColumnToSort] = useState<string>('date');

  const [sortByAscending, setSortByAscending] = useState<boolean>(true);
  const [followUpDeals, setFollowUpDeals] = useState<Maybe<Deal>[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);

  const scheduledByMeFilter = abilities.has(PermissionEnum.FilterDealOwner);

  const { refetch } = useGetFollowUpDealsPaginatedQuery({
    variables: {
      assigned_to_me_filter: showAssignedToMe,
      // For Follow-Ups, we interpret the `FilterDealOwner` permission as the Follow-Up owner/creator/scheduler instead of the Deal assignee
      scheduledByMeFilter,
      boot_requested: showBootRequested,
      assigned_to_deleted_users_filter: showAssignedToDeletedUsers,
      notifications: showOnlyUnread,
      page: currentPage,
      pod_id: selectedPodId,
      results_per_page: itemsPerPage,
      sort_column: columnToSort,
      sort_direction: sortByAscending ? 'asc' : 'desc',
      sources,
      types,
      showActiveTags,
      showMatureFollowUps,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (!data.getFollowUpDealsPaginated?.deals) {
        return;
      }

      paginationContext.setTotalRecords(data.getFollowUpDealsPaginated?.totalRecords ?? 0);
      setFollowUpDeals(data.getFollowUpDealsPaginated?.deals);
    },
  });

  useOnFollowUpUpdateSubscription({
    skip: !sources.length,
    variables: {
      sources,
      types,
      scheduledByMeFilter,
    },
    onData: async ({ data }) => {
      const subscriptionDeals = data.data?.onFollowUpUpdate;
      if (!subscriptionDeals) {
        return;
      }

      // remove deals from followUpDeals that have the same id as one of the deals in subscriptionDeals
      const followUpDealsWithoutSubscriptionDeals = followUpDeals.filter(
        (deal) => !subscriptionDeals.find((subscriptionDeal) => subscriptionDeal?.id === deal?.id),
      );

      // remove deals from subscriptionDeals that have a follow_up that is not scheduled, upcoming, or due
      const uniqueDeals = [
        ...followUpDealsWithoutSubscriptionDeals,
        ...subscriptionDeals.filter(
          (deal) =>
            deal?.follow_up?.status === FollowUpStatusEnum.Scheduled ||
            deal?.follow_up?.status === FollowUpStatusEnum.Upcoming ||
            deal?.follow_up?.status === FollowUpStatusEnum.Due,
        ),
      ];

      // use the columnToSort and sortByAscending to sort the uniqueDeals
      uniqueDeals.sort((a, b) => {
        if (columnToSort === 'date') {
          const aDate = new Date(a?.follow_up?.date_utc ?? '');
          const bDate = new Date(b?.follow_up?.date_utc ?? '');
          return sortByAscending
            ? aDate.getTime() - bDate.getTime()
            : bDate.getTime() - aDate.getTime();
        }

        if (columnToSort === 'name') {
          const aName = (a?.customer?.first_name ?? '').concat(' ', a?.customer?.last_name ?? '');
          const bName = (b?.customer?.first_name ?? '').concat(' ', b?.customer?.last_name ?? '');
          return sortByAscending ? aName.localeCompare(bName) : bName.localeCompare(aName);
        }

        if (columnToSort === 'scheduled_by') {
          const aCreatorName = a?.follow_up?.creator?.name ?? '';
          const bCreatorName = b?.follow_up?.creator?.name ?? '';
          return sortByAscending
            ? aCreatorName.localeCompare(bCreatorName)
            : bCreatorName.localeCompare(aCreatorName);
        }

        if (columnToSort === 'type') {
          const aType = a?.follow_up?.type ?? '';
          const bType = b?.follow_up?.type ?? '';
          return sortByAscending ? aType.localeCompare(bType) : bType.localeCompare(aType);
        }

        if (columnToSort === 'state') {
          const aState = a?.state ?? '';
          const bState = b?.state ?? '';
          return sortByAscending ? aState.localeCompare(bState) : bState.localeCompare(aState);
        }

        return 0;
      });

      setFollowUpDeals(uniqueDeals);
    },
  });

  const HeaderColumn: FC<{
    columnName: string;
    width: string;
    canSort?: boolean;
  }> = ({ columnName, width, canSort = false }) => (
    <Th
      pl={columnName === 'name' ? 1 : undefined}
      w={width}
      color="white"
      cursor={canSort ? 'pointer' : 'default'}
      onClick={() => {
        if (canSort) {
          // Toggle sort direction if already sorting by name, otherwise default to ascending
          if (columnToSort === columnName) {
            setSortByAscending(!sortByAscending);
          } else {
            setColumnToSort(columnName);
            setSortByAscending(true);
          }
        }
      }}
    >
      {columnName.replace('_', ' ').toUpperCase()}
      {columnToSort === columnName && canSort && (
        <Icon as={sortByAscending ? BiChevronDown : BiChevronUp} marginLeft={1} />
      )}
    </Th>
  );

  return (
    <>
      <Box p={6} bgColor="oxfordBlue" borderTopRadius={8} w="100%" />
      <Table>
        <Thead cursor="pointer" color="white" bgColor="queenBlue" w="100%" px={6} height="55px">
          <HeaderColumn width="1%" columnName="" />
          <HeaderColumn width="19%" columnName="name" canSort />
          <HeaderColumn width="15%" columnName="date" canSort />
          <HeaderColumn width="10%" columnName="time" />
          <HeaderColumn width="15%" columnName="type" canSort />
          <HeaderColumn width="15%" columnName="state" canSort />
          <HeaderColumn width="20%" columnName="notes" />
          <HeaderColumn width="15%" columnName="scheduled_by" canSort />
          <HeaderColumn width="5%" columnName="actions" />
        </Thead>
        <Tbody>
          {followUpDeals?.map((deal) => {
            const { id, follow_up, customer } = deal || {};
            if (!id || !follow_up || !customer) {
              return null;
            }

            return (
              <FollowUpRow
                deal={deal as Deal}
                followUp={follow_up}
                name={`${deal?.customer?.first_name} ${deal?.customer?.last_name}`}
                state={deal?.state ? DealStateLabelMap[deal?.state as DealStateEnum] : ''}
                refetch={refetch}
              />
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        filters={{
          currentPage,
          itemsPerPage,
        }}
        parentSetCurrentPage={setCurrentPage}
        parentSetItemsPerPage={setItemsPerPage}
        numItemsShown={followUpDeals.length}
        paginationContext={paginationContext}
        itemsPerPageOptions={[50, 100]}
        borderTop="1px solid"
        borderColor="lightGray"
      />
    </>
  );
};

export default FollowUpsTable;
