import { Button } from '@chakra-ui/react';

import Modal from '../shared/Modal';

interface PayoffReminderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const PayoffReminderModal = ({ isOpen, onClose, onSuccess }: PayoffReminderModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Payoff Reminder"
      rightButtons={
        <>
          <Button w="65px" size="md" variant="secondary" onClick={onClose}>
            NO
          </Button>
          <Button
            w="65px"
            size="md"
            onClick={() => {
              onClose();
              onSuccess();
            }}
          >
            YES
          </Button>
        </>
      }
    >
      Has the payoff sheet been uploaded to the Media Center?
    </Modal>
  );
};

export default PayoffReminderModal;
