import { useContext } from 'react';

import { startCase } from 'lodash';
import { toast } from 'react-toastify';

import { DealStateEnum } from '../gql/dealGql';
import { useCustomDatesUpdateMutation } from '../gql/generated/graphql';

import { DealActionsEnum, DealContext } from '../libs/DealContext';
import { logger } from '../libs/Logger';

type UpdateCustomDatesProps = {
  fieldName: string;
  fieldValue: string | null;
  componentName: string;
};

const useUpdateCustomDates = () => {
  const { deal, dispatch } = useContext(DealContext);

  const [updateCustomDatesMutation] = useCustomDatesUpdateMutation();

  const updateCustomDates = async ({
    fieldName,
    fieldValue,
    componentName,
  }: UpdateCustomDatesProps) => {
    const dateKey = fieldName.split('.').pop();
    const dateLabel = `${
      dateKey === DealStateEnum.WaitingForTitle ? 'Payoff Sent' : startCase(dateKey)
    } Date`;

    if (dateKey && deal.id) {
      try {
        const { data } = await updateCustomDatesMutation({
          variables: {
            deal_id: deal.id,
            date_key: dateKey,
            date: fieldValue ? new Date(fieldValue) : null,
          },
        });
        if (!data?.customDatesUpdate) {
          toast.error(`Failed to update ${dateLabel}`);
          return;
        }

        dispatch({
          type: DealActionsEnum.UpdateDeal,
          payload: {
            deal_dates: { ...deal.deal_dates, custom_dates: data.customDatesUpdate.custom_dates },
          },
        });
        toast.success(`${dateLabel} updated`);
      } catch (error) {
        toast.error(`Failed to update ${dateLabel}`);
        logger.error(componentName, '', null, error);
      }
    }
  };

  return updateCustomDates;
};

export default useUpdateCustomDates;
