import { Dispatch, useEffect, useState } from 'react';

import { MultiValue, PropsValue } from 'react-select';

import CustomReactSelect from '../../../../shared/CustomReactSelect';
import { Option } from '../../../../shared/types';

import LimitedMultiValue from '../../../../Titling/TitleTracker/TableFilters/MultiAutocomplete/LimitedMultiValue';
import { getCustomStyles } from '../../../../Titling/TitleTracker/TableFilters/MultiAutocomplete/styles';
import { FiltersAction, StringArrayPayload } from '../../../util';

type MultiAutocompleteProps = {
  options: Option[];
  dispatch: Dispatch<FiltersAction>;
  actionKind: StringArrayPayload['type'];
  placeholder?: string;
  width?: number;
  value?: string[];
};

const MultiAutocomplete = ({
  options,
  dispatch,
  actionKind,
  placeholder,
  width,
  value,
}: MultiAutocompleteProps) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<PropsValue<Option>>>();

  useEffect(() => {
    if (options.length) {
      const newSelectedOptions: Option[] = options.filter((newOption: Option) =>
        (value ?? []).includes(newOption.value),
      );

      setSelectedOptions(newSelectedOptions);
    }
  }, [options, value]);

  const handleChange = (newSelectedOptions: MultiValue<PropsValue<Option>>) => {
    setSelectedOptions(newSelectedOptions);

    dispatch({
      type: actionKind,
      payload: newSelectedOptions.map(
        (newOption: PropsValue<Option>) => (newOption as Option).value,
      ),
    });
  };

  return (
    <CustomReactSelect
      styles={getCustomStyles(width)}
      placeholder={placeholder}
      isClearable
      isMulti
      hideSelectedOptions={false}
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      aria-multiline={false}
      components={{
        MultiValue: (props) => <LimitedMultiValue {...props} />,
      }}
    />
  );
};

export default MultiAutocomplete;
