import { StrictMode } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'typeface-roboto';

import App from './App';
import customTheme from './chakra';
import config from './config';
import AuthorizedApolloProvider from './providers/AuthorizedApolloProvider';
import reportWebVitals from './reportWebVitals';
import { initSentry } from './services/sentry';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarklyClientId,
    context: {
      kind: 'user',
      key: 'logged-out',
    },
    options: {
      streaming: false,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  root.render(
    <StrictMode>
      <Router>
        <Auth0Provider
          domain={config.auth0.domain} // From Auth0
          clientId={config.auth0.clientId} // From Auth0 App
          redirectUri={config.auth0.redirectUri} // From Auth0 App
          audience={config.auth0.audience} // From Auth0 API
        >
          <AuthorizedApolloProvider>
            <LDProvider>
              <ChakraProvider theme={customTheme}>
                <App />
              </ChakraProvider>
            </LDProvider>
          </AuthorizedApolloProvider>
        </Auth0Provider>
      </Router>
    </StrictMode>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initSentry();
