import { gql } from '@apollo/client';

import { CommissionType, PodUser, StateAbbreviation, TeamType } from './generated/graphql';

import { VSC_MARKUP, VSC_MULTIPLIER } from '../constants/vsc';

export enum TeamTypeDisplay {
  d2d = 'D2D',
  inbound = 'Inbound',
  outbound = 'Outbound',
  region = 'Region',
  titling = 'Titling',
}

export class Pod {
  id?: number;
  name: string;
  color: string;
  setter_commission_rate?: number;
  setter_commission_type?: CommissionType;
  closer_commission_rate?: number;
  closer_commission_type?: CommissionType;
  manager_commission_rate?: number;
  manager_commission_type?: CommissionType;
  special_commission_rate?: number;
  special_commission_type?: CommissionType;
  team_type?: TeamType;
  parent_pod_id?: number;
  created_at?: Date;
  updated_at?: Date;
  usersWithoutTeamRole?: { id: string; name: string }[];
  managers?: { id: string; name: string }[];
  leads?: { id: string; name: string }[];
  hours?: string;
  vsc_multiplier?: number;
  vsc_markup?: number;
  archived?: boolean;
  pod_users?: PodUser[];
  parent_pod?: { id: number; name: string };
  us_states_object?: { states: StateAbbreviation[] };

  constructor(id?: number) {
    this.id = id;
    this.name = '';
    this.color = '';
    this.hours = '';
    this.vsc_multiplier = VSC_MULTIPLIER;
    this.vsc_markup = VSC_MARKUP;
    this.archived = false;
  }
}

export const podsQuery = gql`
  query pods(
    $userId: String
    $permissions: [String]
    $pod_ids: [ID]
    $team_type: [teamType]
    $includeDetails: Boolean = false
    $hideArchived: Boolean = false
  ) {
    pods(
      userId: $userId
      permissions: $permissions
      pod_ids: $pod_ids
      team_type: $team_type
      hideArchived: $hideArchived
    ) {
      id
      name
      color
      hours
      archived
      team_type
      created_at

      vsc_multiplier
      vsc_markup
      manager_commission_rate
      manager_commission_type
      special_commission_rate
      special_commission_type
      closer_commission_rate
      closer_commission_type
      setter_commission_rate
      setter_commission_type

      managers @include(if: $includeDetails) {
        id
        name
      }
      leads @include(if: $includeDetails) {
        id
        name
      }
      problem_solver
      us_states_object {
        states
      }
      parent_pod_id
      pod_users @include(if: $includeDetails) {
        id
        user_id
        pod_id
        team_role
        user {
          id
          name
        }
      }
      parent_pod @include(if: $includeDetails) {
        id
        name
      }
      child_pods @include(if: $includeDetails) {
        id
        name
      }
      usersWithoutTeamRole @include(if: $includeDetails) {
        id
        name
      }
    }
  }
`;

export const podUpsertAndPodUsersUpdate = gql`
  mutation podUpsertAndPodUsersUpdate($newPod: podInput!, $oldPod: podInput) {
    podUpsertAndPodUsersUpdate(newPod: $newPod, oldPod: $oldPod) {
      id
    }
  }
`;

export const podUpsert = gql`
  mutation podUpsert($pod: basePodInput) {
    podUpsert(pod: $pod) {
      id
      name
      color
      hours
      vsc_multiplier
      vsc_markup
      archived
      closer_commission_rate
      team_type
      problem_solver
      us_states_object {
        states
      }
    }
  }
`;

export const archivePod = gql`
  mutation archivePod($id: ID!, $name: String!) {
    archivePod(id: $id, name: $name)
  }
`;

export const revivePod = gql`
  mutation revivePod($id: ID!, $name: String!) {
    revivePod(id: $id, name: $name) {
      id
      name
      color
      hours
      archived
    }
  }
`;

export const titlingPodsAllQuery = gql`
  query titlingPodsAll {
    titlingPodsAll {
      id
      name
      color
      us_states_object {
        states
      }
      problem_solver
      archived
      pod_users {
        id
        user_id
        pod_id
        team_role
        user {
          id
          name
        }
      }
    }
  }
`;
