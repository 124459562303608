import { useContext } from 'react';

import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { DealStateEnum } from '../../gql/dealGql';
import { useDealDetailNetsuiteMutation } from '../../gql/generated/graphql';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

const SendToNetsuiteButton = () => {
  const { deal } = useContext(DealContext);
  const [detailsDealNS, { loading }] = useDealDetailNetsuiteMutation();

  const handleSendToNetsuite = async () => {
    try {
      await detailsDealNS({
        variables: {
          id: deal.id ?? -1,
        },
      });
      toast.success('Sent to Netsuite!');
    } catch (e) {
      logger.error('SendNetsuiteButton.tsx', '', null, e);
      toast.error('Failed to "Sent to Netsuite". Please refresh the page');
    }
  };

  return (
    <Button
      variant="secondary"
      isLoading={loading}
      loadingText="Sending..."
      hidden={deal.state !== DealStateEnum.Funded}
      onClick={handleSendToNetsuite}
    >
      SEND TO NETSUITE
    </Button>
  );
};

export default SendToNetsuiteButton;
