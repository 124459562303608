import { Dispatch, SetStateAction, useContext, useEffect } from 'react';

import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../../gql/dealGql';

import Modal from '../../../shared/Modal';

import ExtraLtvs from './ExtraLtvs';
import PrequalChecksRow from './PrequalChecksRow';
import PrequalificationCreditReportModal from './PrequalificationCreditReportModal';

import { PermissionEnum } from '../../../../constants/permissions';
import useCanAutoStructure from '../../../../hooks/useCanAutoStructure';
import { DealContext } from '../../../../libs/DealContext';
import { AbilityContext } from '../../../../libs/contextLib';
import { PrequalificationStatusType } from '../../../../utils/prequalification';

interface PrequalificationModalProps {
  hasCobuyer: boolean;
  isOpen: boolean;
  onClose: () => void;
  prequalifyPerson: (shouldForceRetry: boolean) => Promise<void>;
  setPrequalificationStatus?: Dispatch<SetStateAction<PrequalificationStatusType>>;
}

const PrequalificationModal = ({
  isOpen,
  hasCobuyer,
  onClose,
  setPrequalificationStatus,
  prequalifyPerson,
}: PrequalificationModalProps) => {
  const { deal, everyoneIsPrequalified } = useContext(DealContext);
  const abilities = useContext(AbilityContext);
  const canViewCreditReport = abilities.has(PermissionEnum.ViewCreditScore);

  const { values } = useFormikContext<Deal>();

  const creditReportModalProps = useDisclosure();

  const {
    details,
    vehicleAge,
    ltv,
    paymentRatio,
    mileage,
    buyerCreditScore,
    cobuyerCreditScore,
    additionalChecksPassed,
    buyerCreditScoreIsAllowedForAutostructure,
    creditScoresPassed,
    isBuyerOrCobuyerLocked,
    isBuyerOrCobuyerFrozen,
  } = useCanAutoStructure(deal);

  const handlePrequalificationRetry = () => {
    prequalifyPerson(true);
    onClose();
  };

  useEffect(() => {
    if (!setPrequalificationStatus) {
      return;
    }

    if (!everyoneIsPrequalified) {
      setPrequalificationStatus('Unstarted');
      return;
    }
    if (isBuyerOrCobuyerLocked) {
      setPrequalificationStatus('Credit Locked');
      return;
    }
    if (isBuyerOrCobuyerFrozen) {
      setPrequalificationStatus('Credit Frozen');
      return;
    }
    if (additionalChecksPassed && creditScoresPassed) {
      setPrequalificationStatus('Passed');
      return;
    }
    // A credit score between 600-679 (Fair) is considered critical, while a score between 680-719 (Good) is seen as a warning.
    // Both should be flagged accordingly, but still allowed to autostructure if additional checks are passed.
    // Only required for buyer, not cobuyer.
    if (additionalChecksPassed && buyerCreditScoreIsAllowedForAutostructure) {
      setPrequalificationStatus('Review');
      return;
    }
    setPrequalificationStatus('Review');
  }, [
    everyoneIsPrequalified,
    values.addCobuyer,
    additionalChecksPassed,
    creditScoresPassed,
    buyerCreditScoreIsAllowedForAutostructure,
  ]);

  return (
    <>
      <Modal
        title="Prequalification Results"
        variant="noMargin"
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        showDivider={false}
        rightButtons={
          <>
            {buyerCreditScore?.status === 'frozen' ||
            buyerCreditScore?.status === 'locked' ||
            cobuyerCreditScore?.status === 'frozen' ||
            cobuyerCreditScore?.status === 'locked' ? (
              <Button size="md" variant="secondary" onClick={handlePrequalificationRetry}>
                RETRY
              </Button>
            ) : canViewCreditReport ? (
              <Button
                size="md"
                variant="secondary"
                onClick={() => {
                  creditReportModalProps.onOpen();
                  onClose();
                }}
              >
                View Credit Report
              </Button>
            ) : null}

            <Button size="md" onClick={onClose}>
              CONTINUE
            </Button>
          </>
        }
      >
        <Stack mx={5} mt={5}>
          {!!buyerCreditScore && (
            <PrequalChecksRow
              label="Buyer Credit"
              value={buyerCreditScore.category}
              creditScoreNumber={buyerCreditScore.number}
              status={buyerCreditScore.status}
              statusNotes={buyerCreditScore.statusNotes}
              isCreditRow
              removeCobuyerNotes={hasCobuyer}
            />
          )}
          {!!(hasCobuyer && cobuyerCreditScore) && (
            <PrequalChecksRow
              label="Cobuyer Credit"
              value={cobuyerCreditScore?.category}
              creditScoreNumber={cobuyerCreditScore?.number}
              status={cobuyerCreditScore?.status}
              statusNotes={cobuyerCreditScore?.statusNotes}
              isCreditRow
              removeCobuyerNotes
            />
          )}
          <PrequalChecksRow
            label="Loan to Value"
            value={`${(ltv * 100).toFixed()}%`}
            status={details.ltv.status}
            statusNotes={details.ltv.statusNotes}
          />
          <ExtraLtvs deal={deal} ltv={ltv} />
          <PrequalChecksRow
            label="Payment to Income"
            value={`${(paymentRatio * 100).toFixed()}%`}
            status={details.paymentRatio.status}
            statusNotes={details.paymentRatio.statusNotes}
            removeCobuyerNotes={hasCobuyer}
          />
          <PrequalChecksRow
            label="Mileage"
            value={`${mileage.toLocaleString()} miles`}
            status={details.mileage.status}
            statusNotes={details.mileage.statusNotes}
          />
          <PrequalChecksRow
            label="Vehicle Age"
            value={`${vehicleAge} year${vehicleAge === 1 ? '' : 's'} `}
            status={details.vehicleAge.status}
            statusNotes={details.vehicleAge.statusNotes}
          />
        </Stack>
      </Modal>
      {canViewCreditReport && (
        <PrequalificationCreditReportModal
          buyerCreditInfo={{
            creditScore: buyerCreditScore?.number,
            creditStatus: buyerCreditScore?.status,
          }}
          cobuyerCreditInfo={{
            creditScore: cobuyerCreditScore?.number,
            creditStatus: cobuyerCreditScore?.status,
          }}
          {...creditReportModalProps}
        />
      )}
    </>
  );
};

export default PrequalificationModal;
