import { ChangeEvent, useContext, useState } from 'react';

import { Button, Select, Textarea } from '@chakra-ui/react';

import { getFullName } from '../../gql/customerGql';
import { BootReason, BootReasonsEnum } from '../../gql/dealGql';

import Modal from '../shared/Modal';

import { DealContext } from '../../libs/DealContext';

const MAX_COMMENT_LENGTH = 150;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onBootConfirm: (newBootReason: BootReason) => void;
}

const BootDealModal = ({ isOpen, onClose, onBootConfirm }: Props) => {
  const { deal } = useContext(DealContext);
  const { customer, cobuyer } = deal;

  const [comment, setComment] = useState('');
  const [reason, setReason] = useState<BootReasonsEnum>();

  const bootOptions = Object.values(BootReasonsEnum).map((r) => (
    <option key={`${r}-reason`} value={r}>
      {r}
    </option>
  ));

  const handleChangeReason = (e: ChangeEvent<HTMLSelectElement>) => {
    setReason(e.target.value as BootReasonsEnum);
  };

  const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleBootConfirm = () => {
    if (!reason || !comment) {
      return;
    }

    const newBootReason = new BootReason(reason, comment);
    onBootConfirm(newBootReason);
  };

  const isCommentValid = comment.length <= MAX_COMMENT_LENGTH && comment.length > 0;
  const isButtonDisabled = !reason || !isCommentValid;

  return (
    <Modal
      title="Boot Deal?"
      isOpen={isOpen}
      onClose={onClose}
      centerButtons={
        <Button isDisabled={isButtonDisabled} onClick={handleBootConfirm}>
          YES, BOOT
        </Button>
      }
    >
      Remove {`${getFullName(customer)}${cobuyer ? ` / ${getFullName(cobuyer)}?` : '?'}`}
      <Select
        name="deal.boot_reason.reason"
        my={2}
        placeholder="Select reason..."
        onChange={handleChangeReason}
        required
      >
        {bootOptions}
      </Select>
      <Textarea
        placeholder="Comment"
        borderRadius={3}
        name="deal.boot_reason.comment"
        rows={2}
        value={comment}
        onChange={handleChangeComment}
        maxLength={MAX_COMMENT_LENGTH}
        required
      />
    </Modal>
  );
};

export default BootDealModal;
