import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';

import { Box, Checkbox, Flex, HStack, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { DealMediaTypeEnum } from '../../../../../gql/generated/graphql';

import Input from '../../../../shared/Input';
import Select from '../../../../shared/Select';
import { enterValidZipMessage } from '../validations';

import FormError from './FormError';

import { MediaModalType } from '..';
import { MismatchSelectionEnum } from '../../../../../constants/media';
import SELECT_OPTIONS from '../../../../../constants/selectOptions';
import { isValidStateAbbreviation } from '../../../../../libs/states';
import {
  DealMedia,
  DriverLicenseMetadata,
  DriverLicenseMetadataValueFields,
  DriverLicenseMismatchFieldMap,
  DriverLicenseVerifiedFieldMap,
} from '../../../../../types/media';
import {
  getAddressValue,
  haveAllAddressFieldsBeenFilled,
  isAddressField,
  isSomeAddressFieldNotMatching,
} from '../../../../../utils/addresses';
import { removeWhiteSpaces } from '../../../../../utils/text';

interface LicenseFormProps {
  setIsVerifiable: (verifiable: boolean) => void;
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  isLoading: boolean;
  applicationValues: DriverLicenseMetadata;
  requiredFields?: Map<DriverLicenseMetadataValueFields, boolean>;
}

const LicenseForm = ({
  setIsVerifiable,
  showModal,
  setShowModal,
  isLoading,
  applicationValues,
  requiredFields,
}: LicenseFormProps) => {
  const {
    initialValues,
    values,
    errors: formikErrors,
    handleChange,
    setFieldValue,
    validateForm,
  } = useFormikContext<DealMedia>();
  const { metadata } = values as { metadata?: DriverLicenseMetadata };
  const { metadata: errors } = formikErrors as { metadata?: DriverLicenseMetadata };

  const stateIsValid = !errors?.state;
  const zipIsValid = errors?.zip !== enterValidZipMessage;

  const someAddressFieldIsNotMatching = isSomeAddressFieldNotMatching(errors);
  const allAddressFieldsHaveBeenFilled = haveAllAddressFieldsBeenFilled(metadata);

  // Ask the user to validate the state when the extracted state is not valid, even if it's not required.
  const initialStateIsValidStateAbbreviation = isValidStateAbbreviation(
    initialValues?.metadata?.state as string | undefined,
  );
  const stateIsValidStateAbbreviation = isValidStateAbbreviation(metadata?.state);

  useEffect(() => {
    // Validates form on first render.
    // This is needed to avoid enabled verification checkboxes when form is invalid.
    if (!isLoading) {
      validateForm();
    }
  }, [isLoading, requiredFields]);

  useEffect(() => {
    if (metadata && showModal.dealMedia?.type === DealMediaTypeEnum.FrontOfDriversLicense) {
      setTimeout(
        () =>
          setIsVerifiable(
            (!requiredFields?.has('name') || !!metadata.nameVerified) &&
              (!requiredFields?.has('address') || !!metadata.addressVerified) &&
              (!requiredFields?.has('address2') || !!metadata.address2Verified) &&
              (!requiredFields?.has('city') || !!metadata.cityVerified) &&
              ((!requiredFields?.has('state') && initialStateIsValidStateAbbreviation) ||
                !!metadata.stateVerified) &&
              (!requiredFields?.has('zip') || !!metadata.zipVerified) &&
              (!requiredFields?.has('expirationDate') || !!metadata.expirationDateVerified),
          ),
        0,
      );
    }

    setTimeout(
      () =>
        setShowModal({
          ...showModal,
          dealMedia: {
            ...showModal.dealMedia,
            verified: !!values.verified,
            metadata,
          },
        }),
      0,
    );
  }, [metadata, values.verified, requiredFields, initialStateIsValidStateAbbreviation]);

  // Uncheck verified field when a value field is changed.
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const fieldPath = e.target?.name ?? '';
    const fieldName = fieldPath.replace('metadata.', '') as DriverLicenseMetadataValueFields;
    const verifiedFieldName = DriverLicenseVerifiedFieldMap.get(fieldName);

    const resetVerifiedAndMismatchFields = () => {
      if (!fieldPath) {
        return;
      }

      // Means a `value` field was changed because it can be mapped to a `verified` field.
      if (verifiedFieldName) {
        setFieldValue(`metadata.${verifiedFieldName}`, false);
        setFieldValue('verified', false);
      }

      if (fieldName === 'name') {
        setFieldValue(`metadata.${DriverLicenseMismatchFieldMap.get('name')}`, '', false);
        return;
      }
      if (isAddressField(fieldName)) {
        setFieldValue(`metadata.${DriverLicenseMismatchFieldMap.get('address')}`, '', false);
      }
    };

    resetVerifiedAndMismatchFields();
    handleChange(e);
  };

  return (
    <Box
      flexBasis="50%"
      marginBottom={2}
      hidden={isLoading || showModal.dealMedia?.type !== DealMediaTypeEnum.FrontOfDriversLicense}
    >
      <Flex direction="column" justifyContent="center" flexBasis="50%">
        <VStack hidden={!requiredFields?.has('name')} flexBasis="50%">
          <HStack w="100%">
            <Input
              label="Name:"
              name="metadata.name"
              formControlVariant="inline"
              onChange={onChange}
              showErrorMessage={false}
            />

            <Checkbox
              isChecked={metadata?.nameVerified as unknown as boolean}
              name="metadata.nameVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={
                !!errors?.name && metadata?.nameMismatchSelection !== MismatchSelectionEnum.Document
              }
            />
          </HStack>
          <FormError
            fieldName="name"
            showError={!!errors?.name}
            documentValue={(values.metadata?.name as string) ?? ''}
            documentValueIsValid={!!removeWhiteSpaces(values.metadata?.name as string)}
            applicationValue={applicationValues.name}
            canUpdateApplication
            documentType={DealMediaTypeEnum.FrontOfDriversLicense}
          />
        </VStack>
        <HStack hidden={!requiredFields?.has('address')}>
          <Input
            label="Address Line 1:"
            name="metadata.address"
            formControlVariant="inline"
            onChange={onChange}
            showErrorMessage={false}
          />
          <Checkbox
            isChecked={metadata?.addressVerified as unknown as boolean}
            name="metadata.addressVerified"
            onChange={onChange}
            colorScheme="whatsapp"
            disabled={
              !!errors?.address &&
              metadata?.addressMismatchSelection !== MismatchSelectionEnum.Document
            }
          />
        </HStack>
        <HStack hidden={!requiredFields?.has('address2')}>
          <Input
            label="Apt., Suite, Unit #:"
            name="metadata.address2"
            formControlVariant="inline"
            onChange={onChange}
            showErrorMessage={false}
            inputGroupProps={{
              flex: '0 1 50%',
              flexBasis: '50%',
            }}
          />
          <Checkbox
            isChecked={metadata?.address2Verified as unknown as boolean}
            name="metadata.address2Verified"
            onChange={onChange}
            colorScheme="whatsapp"
            disabled={
              !!errors?.address2 &&
              metadata?.addressMismatchSelection !== MismatchSelectionEnum.Document
            }
          />
        </HStack>
        <HStack hidden={!requiredFields?.has('city')}>
          <Input
            label="City:"
            name="metadata.city"
            formControlVariant="inline"
            onChange={onChange}
            showErrorMessage={false}
          />
          <Checkbox
            isChecked={metadata?.cityVerified as unknown as boolean}
            name="metadata.cityVerified"
            onChange={onChange}
            colorScheme="whatsapp"
            disabled={
              !!errors?.city &&
              metadata?.addressMismatchSelection !== MismatchSelectionEnum.Document
            }
          />
        </HStack>
        <VStack hidden={!requiredFields?.has('state')}>
          <HStack w="100%">
            <Select
              label="State:"
              name="metadata.state"
              formControlVariant="inline"
              options={SELECT_OPTIONS.US_STATES}
              defaultValue={initialValues?.metadata?.state as string | undefined}
              emptyOption
              onChange={onChange}
              showErrorMessage={false}
            />
            <Checkbox
              isChecked={metadata?.stateVerified as unknown as boolean}
              name="metadata.stateVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={
                !stateIsValid &&
                metadata?.addressMismatchSelection !== MismatchSelectionEnum.Document
              }
            />
          </HStack>
          <FormError
            fieldName="state"
            showError={!stateIsValid}
            documentValue={metadata?.state ?? ''}
            applicationValue={applicationValues.state}
            documentType={DealMediaTypeEnum.FrontOfDriversLicense}
          />
        </VStack>
        {/* Ask the user to validate the state when the extracted state is not valid, even if it's not required. */}
        <VStack hidden={requiredFields?.has('state') || initialStateIsValidStateAbbreviation}>
          <HStack w="100%">
            <Select
              label="State:"
              name="metadata.state"
              formControlVariant="inline"
              options={SELECT_OPTIONS.US_STATES}
              defaultValue={initialValues?.metadata?.state as string | undefined}
              emptyOption
              onChange={onChange}
              showErrorMessage={false}
            />
            <Checkbox
              isChecked={metadata?.stateVerified as unknown as boolean}
              name="metadata.stateVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={!stateIsValidStateAbbreviation}
            />
          </HStack>
        </VStack>
        <VStack hidden={!requiredFields?.has('zip')}>
          <HStack w="100%">
            <Input
              label="Zip:"
              name="metadata.zip"
              formControlVariant="inline"
              onChange={onChange}
              showErrorMessage={false}
              mask="99999"
            />
            <Checkbox
              isChecked={metadata?.zipVerified as unknown as boolean}
              name="metadata.zipVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={
                !!errors?.zip &&
                metadata?.addressMismatchSelection !== MismatchSelectionEnum.Document
              }
            />
          </HStack>
          <FormError
            fieldName="zip"
            documentValue={metadata?.zip ?? ''}
            applicationValue={applicationValues.zip}
            showError={!!metadata?.zip && !zipIsValid}
            documentValueIsValid={!!metadata?.zip && zipIsValid}
          />
        </VStack>
        <HStack hidden={!requiredFields?.has('address')}>
          <FormError
            fieldName="address"
            showError={
              zipIsValid &&
              stateIsValid &&
              (someAddressFieldIsNotMatching || !allAddressFieldsHaveBeenFilled)
            }
            documentValue={getAddressValue({
              address_line: metadata?.address,
              address_line_2: metadata?.address2,
              city: metadata?.city,
              state: metadata?.state,
              zip: metadata?.zip,
            })}
            documentValueIsValid={allAddressFieldsHaveBeenFilled}
            applicationValue={applicationValues.address}
            canUpdateApplication={zipIsValid && stateIsValid}
            documentType={DealMediaTypeEnum.FrontOfDriversLicense}
          />
        </HStack>
        <VStack hidden={!requiredFields?.has('expirationDate')}>
          <HStack w="100%">
            <Input
              label="Exp. Date:"
              type="date"
              name="metadata.expirationDate"
              formControlVariant="inline"
              onChange={onChange}
              showErrorMessage={false}
            />
            <Checkbox
              isChecked={metadata?.expirationDateVerified as unknown as boolean}
              name="metadata.expirationDateVerified"
              onChange={onChange}
              colorScheme="whatsapp"
              disabled={!!errors?.expirationDate}
            />
          </HStack>
          <FormError
            fieldName="expirationDate"
            showError={!!errors?.expirationDate}
            documentValue={metadata?.expirationDate ?? ''}
            documentType={DealMediaTypeEnum.FrontOfDriversLicense}
          />
        </VStack>
      </Flex>
    </Box>
  );
};

export default LicenseForm;
