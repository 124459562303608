import { PropsWithChildren } from 'react';

import { Box, Flex, StackProps } from '@chakra-ui/react';

type TopSectionDataProps = PropsWithChildren<
  StackProps & {
    label: string;
  }
>;

const TopSectionData = ({ label, children, ...rest }: TopSectionDataProps) => (
  <Flex direction="row" flexWrap="wrap" p={2} alignItems="start" {...rest}>
    <Box mt={1} mr={2}>
      {label}
    </Box>
    <Box fontWeight="bold" fontSize="xl" color="queenBlue">
      {children ?? '-'}
    </Box>
  </Flex>
);

export default TopSectionData;
