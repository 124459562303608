import { ReactNode } from 'react';

import { StackProps, VStack } from '@chakra-ui/react';

interface FormErrorContainerProps extends StackProps {
  children: ReactNode;
}

const FormErrorContainer = ({ children, ...rest }: FormErrorContainerProps) => {
  return (
    <VStack
      w="full"
      mb="6 !important"
      alignItems="start"
      p={4}
      bgColor="red.50"
      borderRadius="xl"
      spacing={6}
      color="blackAlpha.800"
      {...rest}
    >
      {children}
    </VStack>
  );
};

export default FormErrorContainer;
