import LeaseEndContainer from '../../components/Container/LEContainer';
import NewEstimateWithFollowUpWrapper from '../../components/NewEstimateWithFollowUp/NewEstimateWithFollowUpWrapper';

const ScheduleFollowUpPage = () => {
  return (
    <LeaseEndContainer>
      <NewEstimateWithFollowUpWrapper />
    </LeaseEndContainer>
  );
};
export default ScheduleFollowUpPage;
