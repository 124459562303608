import { Maybe } from '../gql/generated/graphql';

export const formatTwilioPhone = (phoneNum: Maybe<string>) => {
  if (!phoneNum) {
    return '';
  }

  const cleaned = phoneNum.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+1${match[2]}${match[3]}${match[4]}`;
  }
  return '';
};
