import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { KeyMetric } from '.';
import {
  KeyMetricsTypeEnum,
  METRICS,
  METRICS_TO_METRICS_TYPE,
  MetricType,
} from '../../constants/metrics';
import { formatMoney } from '../../libs/utils';

const BUYOUT = 'Buyout';
const ACQUISITIONS = 'Acquisitions';
const TOTAL = 'Total';

const getIndentationByKey = (key: string) => {
  switch (key) {
    case METRICS.REVENUE:
    case METRICS.FEE_REVENUE:
      return '30px';
    default:
      return '0px';
  }
};

const formatMetric = (keyMetric: MetricType, value: number): string => {
  if (value == null) {
    return '';
  }

  if (METRICS_TO_METRICS_TYPE[KeyMetricsTypeEnum.MONEY].includes(keyMetric)) {
    return formatMoney(value);
  }

  if (METRICS_TO_METRICS_TYPE[KeyMetricsTypeEnum.PERCENTAGE].includes(keyMetric)) {
    return `${value}%`;
  }

  return value.toString();
};

const MetricsTable = ({ keyMetrics }: { keyMetrics: KeyMetric[] }) => {
  return (
    <Table variant="striped">
      <Thead>
        <Tr>
          <Th />
          <Th>{BUYOUT}</Th>
          <Th>{ACQUISITIONS}</Th>
          <Th>{TOTAL}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {keyMetrics?.map((metric) => (
          <Tr key={metric.keyMetric}>
            <Td>
              <Box as="b" sx={{ marginLeft: getIndentationByKey(metric.keyMetric) }}>
                {metric.keyMetric}
              </Box>
            </Td>
            <Td>{formatMetric(metric.keyMetric, metric.value.buyout)}</Td>
            <Td>{formatMetric(metric.keyMetric, metric.value.acquisition)}</Td>
            {!METRICS_TO_METRICS_TYPE[KeyMetricsTypeEnum.PERCENTAGE].includes(metric.keyMetric) ? (
              <Td>
                {metric.keyMetric === METRICS.RPT
                  ? formatMetric(metric.keyMetric, metric.value.total)
                  : formatMetric(metric.keyMetric, metric.value.buyout + metric.value.acquisition)}
              </Td>
            ) : (
              <Td />
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default MetricsTable;
