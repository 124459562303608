export enum BankEnum {
  AFCU = 'America First Credit Union',
  FifthThird = 'Fifth Third Bank NA',
  Mechanics = 'Mechanics Bank',
  WellsFargo = 'Wells Fargo Auto',
  BankOfAmerica = 'Bank of America NA',
  CapitalOne = 'Capital One',
  AllyFinancial = 'Ally Financial',
  PNC = 'PNC Bank, NA',
  Westlake = 'Westlake Financial Services',
  TD = 'TD Bank N.A.',
  FirstInterstate = 'First Interstate Bank',
  ICCU = 'Idaho Central Credit Union',
  MountainAmerica = 'Mountain America FCU',
  LentegrityAutoFinance = 'Lentegrity Auto Finance',
  Lookout = 'Lookout Credit Union',
  Upgrade = 'Upgrade Inc.',
  Chase = 'JPMorgan Chase Bank, N.A.',
  Wings = 'Wings Credit Union',
  PrestigeFinancial = 'Prestige Financial Services',
  Santander = 'Santander Consumer USA',
}
