import { useMemo } from 'react';

import { Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Auth0Role, HuntGroup, Maybe, Pod, useGetUsersQuery } from '../../../gql/generated/graphql';

import LEMultiSelect from '../../../components/LEMultiSelect/LEMultiSelect';
import Input from '../../../components/shared/Input';
import Select from '../../../components/shared/Select';

import { CreateUserModalFormikFields } from './CreateUserModal';
import { EditUserModalFormikFields } from './EditUserModal';
import TwilioNumberSearch from './TwilioNumberSearch';

interface UserFormProps {
  pods?: Maybe<Pod[]>;
  huntGroups?: Maybe<HuntGroup[]>;
  auth0Roles?: Maybe<Auth0Role[]>;
  isEdit?: boolean;
}

const UserForm = ({
  pods = [],
  huntGroups = [],
  auth0Roles = [],
  isEdit = false,
}: UserFormProps) => {
  const { handleChange } = useFormikContext<
    CreateUserModalFormikFields | EditUserModalFormikFields
  >();

  const { data: getUsersData } = useGetUsersQuery({
    fetchPolicy: 'cache-first',
  });

  const podOptions = useMemo(
    () =>
      pods?.map((p) => ({
        id: p?.id ?? -1,
        name: p?.name ?? '',
        color: p?.color ?? '',
      })) ?? [],
    [pods],
  );
  const huntGroupOptions = useMemo(
    () => huntGroups?.map((hg) => ({ id: hg?.id ?? -1, name: hg?.name ?? '' })) ?? [],
    [huntGroups],
  );
  const auth0RoleOptions = useMemo(
    () => auth0Roles?.map((r) => ({ id: r?.id ?? '', name: r?.name ?? '' })) ?? [],
    [auth0Roles],
  );

  const recruiterOptions = useMemo(
    () =>
      getUsersData?.getUsers?.results?.map((u) => ({ value: u?.id ?? '', label: u?.name ?? '' })) ??
      [],
    [getUsersData?.getUsers?.results],
  );

  return (
    <Flex direction="column" gap={2}>
      <Flex gap={2}>
        <Input label="Name" name="name" onChange={handleChange} />
      </Flex>

      <Flex gap={2}>
        <Input label="Email" name="email" onChange={handleChange} />
        {!isEdit && <Input label="Password" name="password" onChange={handleChange} />}
      </Flex>
      <TwilioNumberSearch />
      <Flex gap={2}>
        <LEMultiSelect name="pods" label="Pods" options={podOptions} />
        <LEMultiSelect name="huntGroups" label="Hunt Groups" options={huntGroupOptions} />
      </Flex>
      <Flex gap={2}>
        <LEMultiSelect name="auth0Roles" label="Auth0 Roles" options={auth0RoleOptions} />
        <Select name="recruiterId" label="Recruiter" options={recruiterOptions} />
      </Flex>
    </Flex>
  );
};

export default UserForm;
