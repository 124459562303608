import { useContext } from 'react';

import { Form, Formik } from 'formik';

import { DealStateEnum } from '../../gql/dealGql';
import { FinancialInfoAcquisition } from '../../gql/financialInfoAcquisitionGql';

import ClosingView from './ClosingView';
import LogisticsView from './LogisticsView';
import SoftCloseView from './SoftCloseView';
import StructuringAndClosedView from './StructuringAndClosedView';
import {
  validationSchemaClosing,
  validationSchemaSoftClose,
  validationSchemaStructuring,
} from './validationSchemas';

import { DealContext } from '../../libs/DealContext';
import { passValuesToSchema } from '../../libs/utils';

const DealInfoAcquisition = () => {
  const { deal } = useContext(DealContext);
  const initialVals = {
    ...deal,
    financial_info_acquisition:
      deal.financial_info_acquisition ?? new FinancialInfoAcquisition(deal.id, deal.car.book_value),
  };

  const renderDealInfoByState = () => {
    switch (deal.state) {
      case DealStateEnum.SoftClose:
        return <SoftCloseView />;
      case DealStateEnum.Structuring:
      case DealStateEnum.Closed:
      case DealStateEnum.SentForSignatures:
      case DealStateEnum.Signed:
        return <StructuringAndClosedView />;
      case DealStateEnum.Closing:
        return <ClosingView />;
      case DealStateEnum.ReadyForPickup:
      case DealStateEnum.PaidOff:
      case DealStateEnum.AtAuction:
      case DealStateEnum.Sold:
      case DealStateEnum.TitleSent:
      case DealStateEnum.Finalized:
        return <LogisticsView />;
      default:
        return null;
    }
  };

  const validationSchemaPicker = () => {
    switch (deal.state) {
      case DealStateEnum.SoftClose:
        return validationSchemaSoftClose;
      case DealStateEnum.Structuring:
        return validationSchemaStructuring;
      case DealStateEnum.Closing:
        return validationSchemaClosing;
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={initialVals}
      onSubmit={() => undefined}
      validateOnMount
      enableReinitialize
      validate={(value) => passValuesToSchema(value, validationSchemaPicker())}
    >
      {() => {
        return <Form>{renderDealInfoByState()}</Form>;
      }}
    </Formik>
  );
};

export default DealInfoAcquisition;
