import { HStack, Text } from '@chakra-ui/react';

type CreditDecisionInfoRowProps = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

const CreditDecisionInfoRow = ({ label, value, isDisabled }: CreditDecisionInfoRowProps) => {
  return (
    <HStack spacing={1}>
      <Text fontSize="sm" fontWeight="normal" color={isDisabled ? 'gray.500' : 'gray.600'}>
        {label}:
      </Text>
      <Text fontSize="sm" color={isDisabled ? 'gray.500' : 'queenBlue'}>
        {value}
      </Text>
    </HStack>
  );
};

export default CreditDecisionInfoRow;
