import React from 'react';

import { Box, Checkbox, Flex, Spinner, Td, Tr } from '@chakra-ui/react';
import { MdWarning } from 'react-icons/md';

import { DealMediaTypeEnum } from '../../../../../gql/generated/graphql';

import CustomReactSelect from '../../../../shared/CustomReactSelect';
import { MediaTableItem } from '../hooks/use-media-table-rows';

import MediaRowActions from './MediaRowActions';

import { DealMediaCustomerTypes, DealMediaTypeLabels } from '../../../../../constants/media';
import useLazyExtractDataFromFile from '../../../../../hooks/useLazyExtractDataFromFile';
import { DealMedia } from '../../../../../types/media';
import { ShowMediaModalType } from '../../MediaModal';
import UploadPreview from '../../UploadPreview';

interface CustomerMediaRowProps {
  item: MediaTableItem;
  openUploadModal: () => void;
  onClickTextColumn: () => void;
  onMediaTypeChange: (value: Partial<MediaTableItem>) => void;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUploadPreviewClick: () => void;
  selectedFiles: DealMedia[];
  updateModalProps: (showModal: ShowMediaModalType) => void;
}

const CustomerMediaRow: React.FC<CustomerMediaRowProps> = ({
  item,
  openUploadModal,
  onClickTextColumn,
  onSelect,
  onMediaTypeChange,
  onUploadPreviewClick,
  selectedFiles,
  updateModalProps,
}) => {
  const { extractDataFromFile, loading: isExtractingData } = useLazyExtractDataFromFile();

  return isExtractingData ? (
    <Tr bgColor="white">
      <Td colSpan={5}>
        <Flex alignItems="center" justifyContent="center" w="100%" minH="90px">
          <Spinner color="blue.500" size="md" />
        </Flex>
      </Td>
    </Tr>
  ) : (
    <Tr
      _hover={{ filter: 'brightness(0.9)' }}
      bgColor={item.verified ? 'lightGreen.50' : 'white'}
      cursor="pointer"
    >
      {/* Checkbox */}
      <Td alignItems="center" maxW="36px" paddingX={2} w="2.5%">
        <Checkbox
          borderColor="gray.600"
          cursor="pointer"
          disabled={!item.key}
          isChecked={selectedFiles.some((file) => file.id === item.id && item.type === file.type)}
          mb={0}
          onChange={onSelect}
        />
      </Td>
      {/* Preview */}
      <Td paddingX={2} w="5%">
        <Flex alignItems="center" cursor="pointer" direction="row" w={65}>
          {item.isUploaded ? (
            <UploadPreview
              onClick={onUploadPreviewClick}
              sourceFileName={item.sourceFileName}
              url={item.signed_url ?? ''}
            />
          ) : (
            <Box ml="5px">
              <MdWarning color="#F3C43D" size={44} onClick={openUploadModal} />
            </Box>
          )}
        </Flex>
      </Td>
      {/* Category */}
      <Td
        paddingX={2}
        alignItems="center"
        onClick={item.type ? onClickTextColumn : undefined}
        fontWeight="medium"
        cursor="pointer"
        onFocus={(e) => e.preventDefault()}
        width="25%"
      >
        {item.type ? (
          DealMediaTypeLabels[item.type]
        ) : (
          <CustomReactSelect
            onChange={async (selected) => {
              const extractedMetadata = await extractDataFromFile(
                selected?.value as DealMediaTypeEnum,
                item.metadata,
                item.key,
              );

              onMediaTypeChange({
                ...item,
                type: selected?.value as DealMediaTypeEnum,
                metadata: extractedMetadata,
              });
            }}
            placeholder="Select Category"
            options={Object.entries(DealMediaTypeLabels)
              .filter(([key]) => DealMediaCustomerTypes.includes(key as DealMediaTypeEnum))
              .sort((a, b) => (a[1] > b[1] ? 1 : -1))
              .map(([value, label]) => ({
                label,
                value,
              }))}
          />
        )}
      </Td>
      {/* File Name */}
      <Td
        borderRightColor="gray.100"
        borderRightWidth="2px"
        cursor="pointer"
        fontWeight="normal"
        maxWidth="200px"
        onClick={onClickTextColumn}
        overflow="hidden"
        paddingX={2}
        textOverflow="ellipsis"
        textTransform="none"
        whiteSpace="nowrap"
        width="50%"
      >
        {item.sourceFileName}
      </Td>
      {/* Actions */}
      <MediaRowActions
        isInternalFile={false}
        sourceFileName={item.sourceFileName ?? ''}
        filePath={decodeURIComponent(item.key ?? '')}
        fileUrl={item.signed_url ?? ''}
        dealMedia={{ ...item, fileName: item.friendlyName }}
        updateModalProps={updateModalProps}
        isUploaded={item.isUploaded}
      />
    </Tr>
  );
};

export default CustomerMediaRow;
