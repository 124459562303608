import { ReactElement } from 'react';

import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  useBreakpointValue,
} from '@chakra-ui/react';

type DynamicSizedButtonProps = (ButtonProps | IconButtonProps) & {
  ariaLabel: string;
  icon: ReactElement;
  label: string;
};

const DynamicSizedButton = ({ ariaLabel, icon, label, size, ...rest }: DynamicSizedButtonProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });
  if (isMobile) {
    return <IconButton aria-label={ariaLabel} icon={icon} size="md" {...rest} />;
  }
  return (
    <Button size={size ?? 'lgWithIconLeft'} aria-label={ariaLabel} leftIcon={icon} {...rest}>
      {label}
    </Button>
  );
};

export default DynamicSizedButton;
