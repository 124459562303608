import { getIn } from 'formik';

import {
  Maybe,
  PayoffRequestPopulated,
  PayoffRequestStatus,
  SortDirection,
} from '../gql/generated/graphql';
import { PayoffRequest } from '../gql/payoffRequestGql';

import { FiltersState, filterActionsDic } from '../components/PayoffDealsTable/util';
import { PermissionEnum } from '../constants/permissions';

import { parseTempInfoAsDeal } from './deals';

export const parsePayoffRequestsFromDashboard = (
  payoffRequests: PayoffRequest[],
): PayoffRequest[] => {
  return payoffRequests.map((payoffRequest) => {
    if (!payoffRequest.deal && payoffRequest.temporary_info?.data) {
      return {
        ...payoffRequest,
        deal: parseTempInfoAsDeal(payoffRequest.temporary_info.data),
      };
    }

    return payoffRequest;
  });
};

export const sortPayoffRequests = (
  payoffRequests: PayoffRequest[],
  field: string,
  direction: SortDirection,
): PayoffRequest[] => {
  return payoffRequests.sort((a, b) => {
    const aField = getIn(a, field);
    const bField = getIn(b, field);

    if (aField > bField || !aField) {
      return direction === SortDirection.Asc ? 1 : -1;
    }
    if (aField < bField || !bField) {
      return direction === SortDirection.Asc ? -1 : 1;
    }
    return 0;
  });
};

export const filterPayoffRequests = (
  payoffRequests: PayoffRequest[],
  filters: FiltersState,
): PayoffRequest[] => {
  const filterKeys = Object.keys(filterActionsDic) as (keyof typeof filterActionsDic)[];

  return payoffRequests.filter((payoffRequest) =>
    filterKeys.every((filterKey) => filterActionsDic[filterKey](payoffRequest, filters[filterKey])),
  );
};

// Ifs conditions are the same as in `payoffDashboardDeals` in API . Duplicated to solve performance issues. Keep in sync.
export const shouldAddOrUpdatePayoffRequest = (
  payoffRequest: Maybe<PayoffRequestPopulated>,
  abilities: Set<PermissionEnum>,
) => {
  if (!payoffRequest?.status) {
    return false;
  }

  if (
    abilities.has(PermissionEnum.SeeAllPayoffRequests) &&
    [
      PayoffRequestStatus.Fetching,
      PayoffRequestStatus.Pending,
      PayoffRequestStatus.FailureRequested,
    ].includes(payoffRequest.status)
  ) {
    return true;
  }

  if (
    !abilities.has(PermissionEnum.SeeAllPayoffRequests) &&
    [PayoffRequestStatus.Fetching, PayoffRequestStatus.Pending].includes(payoffRequest.status)
  ) {
    return true;
  }

  if (
    [PayoffRequestStatus.Completed].includes(payoffRequest.status) &&
    payoffRequest.needs_payoff_documents === true &&
    payoffRequest.payoff_documents_uploaded === false
  ) {
    return true;
  }

  return false;
};
