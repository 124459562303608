import { Deal, DealStateEnum, DealStateLabelMap, DealTypeLabelMap } from '../../../gql/dealGql';
import { TitleRegistrationOptionLabels } from '../../../gql/financialInfoGql';
import { TitleRegistrationOption } from '../../../gql/generated/graphql';

import { TableColumns } from '../../shared/Table/types';

import DealAge from './DealAge';
import TitleClerkAutocomplete from './TitleClerkAutocomplete';
import TitlingTableName from './TitleTableName';
import TitleTeamAutocomplete from './TitleTeamAutoComplete';

import { formatDateIfTrue } from '../../../libs/utils';

enum TitleColumnHeaders {
  AGE = 'Age',
  NAME = 'Name',
  VIN = 'VIN',
  BANK = 'Bank',
  STATE = 'State',
  TRANSACTION = 'Transaction',
  TYPE = 'Type',
  DEAL_STATE = 'Deal State',
  LIENHOLDER = 'Lienholder',
  COMPLETED = 'Completed Date',
  TITLE_RECEIVED = 'Title Received',
  PROCESSOR = 'Processor',
  TITLE_TEAM = 'Title Team',
  TITLE_CLERK = 'Title Clerk',
  TITLE_CLERK2 = 'Reg Assistant',
}

export const TITLE_TRACKER_COLUMNS: TableColumns<Deal> = [
  {
    header: TitleColumnHeaders.AGE,
    getComponent: ({ item: deal }) => <DealAge deal={deal} />,
    styleProps: { padding: 0, textAlign: 'center' },
    sortingKey: 'completion_date_utc',
    tooltip: 'Days Since Funded',
  },
  {
    header: TitleColumnHeaders.NAME,
    getComponent: ({ item: deal }) => <TitlingTableName deal={deal} />,
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'customer.first_name',
  },
  {
    header: TitleColumnHeaders.VIN,
    getComponent: ({ item: deal }) => deal.car.vin.toUpperCase(),
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'car.vin',
  },
  {
    header: TitleColumnHeaders.BANK,
    getComponent: ({ item: deal }) => deal.financial_info?.bank,
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'financial_info.bank',
  },
  {
    header: TitleColumnHeaders.STATE,
    getComponent: ({ item: deal }) => deal.customer.address?.state,
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'customer.address.state',
  },
  {
    header: TitleColumnHeaders.TRANSACTION,
    getComponent: ({ item: deal }) =>
      `${
        TitleRegistrationOptionLabels[
          deal.financial_info?.title_registration_option as TitleRegistrationOption
        ] ?? '-'
      }`,
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'transaction_number',
  },
  {
    header: TitleColumnHeaders.TYPE,
    getComponent: ({ item: deal }) => (deal.type ? DealTypeLabelMap[deal.type] : '-'),
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'type',
  },
  {
    header: TitleColumnHeaders.DEAL_STATE,
    getComponent: ({ item: deal }) => `${DealStateLabelMap[deal.state] ?? '-'}`,
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'state',
  },
  {
    header: TitleColumnHeaders.LIENHOLDER,
    getComponent: ({ item: deal }) => deal.car?.payoff?.lienholder_name,
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'car.payoff.lienholder_name',
  },
  {
    header: TitleColumnHeaders.COMPLETED,
    getComponent: ({ item: deal }) => formatDateIfTrue(deal.completion_date_utc),
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'completion_date_utc',
  },
  {
    header: TitleColumnHeaders.TITLE_RECEIVED,
    getComponent: ({ item: deal }) =>
      formatDateIfTrue(deal.deal_dates?.custom_dates?.title_received),
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: `deal_dates.custom_dates.${DealStateEnum.TitleReceived}`,
  },
  {
    header: TitleColumnHeaders.PROCESSOR,
    getComponent: ({ item: deal }) => deal?.financial_info?.processor ?? '-',
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'financial_info.processor',
  },
  {
    header: TitleColumnHeaders.TITLE_TEAM,
    getComponent: ({ item: deal }) => <TitleTeamAutocomplete deal={deal} />,
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'titling_pod.name',
  },
  {
    header: TitleColumnHeaders.TITLE_CLERK,
    getComponent: ({ item: deal, componentData }) => (
      <TitleClerkAutocomplete deal={deal} titlingUsersByPod={componentData?.titlingUsersByPod} />
    ),
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'title_clerk.name',
  },
  {
    header: TitleColumnHeaders.TITLE_CLERK2,
    getComponent: ({ item: deal, componentData }) => (
      <TitleClerkAutocomplete
        deal={deal}
        titlingUsersByPod={componentData?.titlingUsersByPod}
        isRegAssistant
      />
    ),
    styleProps: { whiteSpace: 'nowrap' },
    sortingKey: 'title_clerk2.name',
  },
];
