import { Fragment, useState } from 'react';

import { Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { TemporaryInfo } from '../../../gql/generated/graphql';
import { PayoffRequest } from '../../../gql/payoffRequestGql';

import { payoffTableColumns } from './ColumnComponents';
import PayoffDetail from './PayoffDetail';

import { handleClickOrCommandClick } from '../../../libs/eventHandlers';

interface Props {
  payoffRequest: PayoffRequest;
}

const DealRowContainer = ({ payoffRequest }: Props) => {
  const { deal } = payoffRequest;
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const history = useHistory();
  const tableColumns = Object.entries(payoffTableColumns);

  return deal?.id ? (
    <>
      <Tr
        bgColor="white"
        cursor="pointer"
        _hover={{ bgColor: 'hoverGray' }}
        onClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
        onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${deal.id}`, history)}
        whiteSpace="nowrap"
      >
        {tableColumns.map(([header, { getComponent }]) => (
          <Fragment key={header}>
            {getComponent({
              collapsed,
              setCollapsed,
              tempInfo: payoffRequest.temporary_info as TemporaryInfo,
              payoffRequest,
              deal,
            })}
          </Fragment>
        ))}
      </Tr>
      <PayoffDetail collapsed={collapsed} payoffRequest={payoffRequest} />
    </>
  ) : (
    <>
      <Tr>
        {tableColumns.map(([header, { getComponent }]) => (
          <Fragment key={header}>
            {getComponent({
              collapsed,
              setCollapsed,
              tempInfo: payoffRequest.temporary_info as TemporaryInfo,
              payoffRequest,
              deal,
            })}
          </Fragment>
        ))}
      </Tr>
      <PayoffDetail collapsed={collapsed} payoffRequest={payoffRequest} />
    </>
  );
};

export default DealRowContainer;
