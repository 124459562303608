import { Flex } from '@chakra-ui/react';

import { Deal } from '../../../gql/dealGql';

import { getDealAge, getDealAgeColor } from '../../../utils/deals';

type DealAgeProps = {
  deal: Deal;
};

const DealAge = ({ deal }: DealAgeProps) => {
  const dealAge = getDealAge(deal.completion_date_utc, deal.completion_date_tz ?? '').toString();

  return (
    <Flex
      align="center"
      justify="center"
      bgColor={getDealAgeColor(parseInt(dealAge, 10))}
      w="75px"
      h="65px"
      color="white"
      textAlign="center"
      position="relative"
    >
      {dealAge}
    </Flex>
  );
};

export default DealAge;
