import { useContext, useMemo, useState } from 'react';

import { Button, Icon } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { RiSendPlaneFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { Deal } from '../../../../gql/dealGql';
import {
  ProcessorEnum,
  mapProcessorToTTProcessor,
  mapTTProcessorToProcessor,
} from '../../../../gql/financialInfoGql';
import {
  CreateTransactionInput,
  StateAbbreviation,
  TitleRegistrationOption,
  TtProductType,
  useCreateTransactionMutation,
  useJurisdictionProcessorQuery,
  useOnTransactionCreatedSubscription,
} from '../../../../gql/generated/graphql';
import { getCreateTransactionCallbackUrl } from '../../../../gql/taterTitleGql';

import Tooltip from '../../../shared/Tooltip';

import useRequiredDocsVariables from '../../../../hooks/useRequiredDocsVariables';
import { DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';
import { getBothAddressLines } from '../../../../utils/addresses';
import { mapDealToGetTTFeesVariables } from '../../../../utils/deals';
import { CUSTOM_DATE_FIELDS } from '../../constants';

export const SendToProcessorButton = () => {
  const {
    deal,
    documentMedia: { mediaList },
  } = useContext(DealContext);

  const { values, setFieldValue } = useFormikContext<Deal>();

  const {
    requiredDocsVariables: { conditions },
  } = useRequiredDocsVariables(values, mediaList);

  const [processingCreateTransaction, setProcessingCreateTransaction] = useState<boolean>(false);

  const [createTransactionMutation] = useCreateTransactionMutation();

  const alreadySent = !!values.financial_info.tt_transaction_id;
  const buttonText = alreadySent
    ? 'SENT'
    : `SEND TO ${values.financial_info.processor?.toUpperCase() || 'PROCESSOR'}`;

  const { data: jurisdictionProcessorData } = useJurisdictionProcessorQuery({
    skip: !deal.customer?.address?.state,
    variables: {
      state: deal.customer.address.state as StateAbbreviation,
    },
    fetchPolicy: 'network-only',
  });

  const supportedProcessors = useMemo(() => {
    if (jurisdictionProcessorData?.jurisdiction?.supportedProcessors?.length) {
      return jurisdictionProcessorData.jurisdiction.supportedProcessors
        .map(mapTTProcessorToProcessor)
        .filter(Boolean) as ProcessorEnum[];
    }
    return [];
  }, [jurisdictionProcessorData?.jurisdiction?.supportedProcessors]);

  const allErrors = {
    ...(!deal.id ? { dealId: 'Deal ID is required' } : {}),
    ...(!deal.customer?.address?.state ? { addressState: 'State is required' } : {}),
    ...(!values.financial_info.processor ? { processor: 'Processor is required' } : {}),
    ...(values.financial_info.processor &&
    !supportedProcessors.includes(values.financial_info.processor)
      ? { processor: 'Processor is not supported' }
      : {}),
  };
  const canSendToProcessor = Object.keys(allErrors).length === 0;

  useOnTransactionCreatedSubscription({
    skip: !canSendToProcessor,
    variables: {
      dealId: deal.id ?? 0,
    },
    onData: ({ data }) => {
      if (!data?.data?.onTransactionCreated?.tt_transaction_id) {
        return;
      }

      setFieldValue(
        'financial_info.tt_transaction_id',
        data.data.onTransactionCreated.tt_transaction_id,
      );

      setProcessingCreateTransaction(false);
      toast.success(`Sent to ${values.financial_info.processor} successfully`);
    },
  });

  const handleClick = async () => {
    const input: CreateTransactionInput = {
      state: values.customer.address.state as StateAbbreviation,
      types:
        values.financial_info.title_registration_option === TitleRegistrationOption.TitleOnly
          ? [TtProductType.Title]
          : [TtProductType.Title, TtProductType.Registration],
      parameters: {
        ...mapDealToGetTTFeesVariables(values, { ttEndpoint: 'createTransaction' }),
        address: getBothAddressLines(values.customer.address),
        estimatedPayoff: values.car.payoff.estimated_payoff ?? values.car.payoff.vehicle_payoff,
        warranty: undefined,
        odometerReading: values.car.mileage,
        transferringPlates: conditions?.transferringPlates,
        dealId: values.id?.toString(),
        // Cobuyer fields
        cobuyerAddress: getBothAddressLines(values.cobuyer?.address ?? {}),
        cobuyerCity: values.cobuyer?.address?.city,
        cobuyerFirstName: values.cobuyer?.first_name,
        cobuyerLastName: values.cobuyer?.last_name,
        cobuyerMiddleName: values.cobuyer?.middle_name,
        cobuyerState: values.cobuyer?.address?.state as StateAbbreviation,
        cobuyerZip: values.cobuyer?.address?.zip,
        // New Lienholder fields
        newBank: values.financial_info.new_lienholder?.name,
        newBankAddress: values.financial_info.new_lienholder?.address,
        newBankAddress2: '',
        newBankCity: values.financial_info.new_lienholder?.city,
        newBankState: values.financial_info.new_lienholder?.state as StateAbbreviation,
        newBankZip: values.financial_info.new_lienholder?.zip,
        newBankZipPlus4: '',
        signedDate: format(
          new Date(
            values.deal_dates?.custom_dates?.signed ??
              values.deal_dates?.dates?.signed ??
              new Date().toISOString(),
          ),
          'yyyy-MM-dd',
        ),
      },
      conditions,
      processor: mapProcessorToTTProcessor(values.financial_info.processor),
      callbackUrl: getCreateTransactionCallbackUrl(),
    };

    try {
      setProcessingCreateTransaction(true);
      await createTransactionMutation({
        variables: {
          input,
        },
      });
      setFieldValue(CUSTOM_DATE_FIELDS.customSentToAuditField, new Date().toISOString());
    } catch (e) {
      setProcessingCreateTransaction(false);
      logger.error('DealInfoBuyoutForm.tsx', 'Failed to create TT transaction', input, e);
      toast.error(`Error sending to ${values.financial_info.processor}`);
    }
  };

  return (
    <Tooltip errors={allErrors}>
      <Button
        isLoading={processingCreateTransaction}
        loadingText={buttonText}
        isDisabled={!canSendToProcessor || alreadySent}
        variant="secondary"
        size="sm"
        leftIcon={<Icon as={RiSendPlaneFill} fontSize="md" />}
        onClick={handleClick}
        mt={0}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
};
