import { parseISO, startOfMonth } from 'date-fns';

import { DealStateEnum } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import { User } from '../../types/user';

export type FiltersStates = {
  type?: DealType;
  startDate: Date;
  endDate: Date;
  dealState?: DealStateEnum;
  addressState?: string;
  user?: User;
};

export const getFiltersStates = (searchParams?: {
  [K in keyof FiltersStates]?: string;
}): FiltersStates => ({
  type: searchParams?.type as DealType,
  startDate: searchParams?.startDate ? parseISO(searchParams.startDate) : startOfMonth(new Date()),
  endDate: searchParams?.endDate ? parseISO(searchParams.endDate) : new Date(),
  dealState: searchParams?.dealState as DealStateEnum,
  addressState: searchParams?.addressState,
  user: searchParams?.user ? new User(searchParams.user) : undefined,
});

export type FiltersActions =
  | { type: 'setDealType'; payload: string }
  | { type: 'setStartDate'; payload: string }
  | { type: 'setEndDate'; payload: string }
  | { type: 'setDealState'; payload: string }
  | { type: 'setAddressState'; payload: string }
  | { type: 'setUser'; payload: User | undefined };

export const filtersReducer = (state: FiltersStates, action: FiltersActions): FiltersStates => {
  switch (action.type) {
    case 'setDealType': {
      return {
        ...state,
        type: action.payload === 'all' ? undefined : (action.payload as DealType),
      };
    }
    case 'setStartDate': {
      return { ...state, startDate: parseISO(action.payload) };
    }
    case 'setEndDate': {
      return { ...state, endDate: parseISO(action.payload) };
    }
    case 'setDealState': {
      return {
        ...state,
        dealState: action.payload === 'all' ? undefined : (action.payload as DealStateEnum),
      };
    }
    case 'setAddressState': {
      return {
        ...state,
        addressState: action.payload === 'all' ? undefined : action.payload,
      };
    }
    case 'setUser': {
      return { ...state, user: action.payload };
    }
    default:
      return state;
  }
};
