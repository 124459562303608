import { ChangeEvent, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { isBefore } from 'date-fns';
import { Form, Formik, useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toggle/style.css';

import { MileageWarningsEnum, OdometerStatus } from '../../gql/carGql';
import { Customer } from '../../gql/customerGql';
import { DealContact } from '../../gql/dealContactGql';
import {
  DealStateEnum,
  creditAppAutomate,
  isDealInOrPastFunded,
  isUneditable,
} from '../../gql/dealGql';
import {
  PayoffVerificationStatus,
  useUpdateCreditAppOnDealMutation,
  useVehicleMileageLazyQuery,
} from '../../gql/generated/graphql';

import ConfirmEditModal from '../ConfirmEditModal/ConfirmEditModal';
import MileageWarningModal from '../MileageWarningModal';
import PayoffWarningModal from '../PayoffWarningModal/PayoffWarningModal';
import Card from '../shared/Card';
import Modal from '../shared/Modal';
import AddCobuyerOrContactButton from './components/AddCobuyerOrContactButton';
import PrequalificationSection from './components/Prequalification/PrequalificationSection';
import SubmitApplicationButtons from './components/SubmitApplicationButtons';

import AdditionalContact from './AdditionalContact';
import { CreditPerson } from './CreditPerson';
import SwapBuyerCobuyerButton from './SwapBuyerCobuyerButton';
import useCreditAppInitialValues, {
  CreditAppOnDeal,
  getContactCreditAppValues,
  getPersonCreditAppValues,
} from './useCreditAppInitialValues';
import { cleanCreditAppValues } from './utils';
import { validationSchema } from './validationSchema';

import { LDFlags } from '../../constants/experiments';
import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import useDebounce from '../../hooks/useDebounce';
import useFlag from '../../hooks/useFlag';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { passValuesToSchema } from '../../libs/utils';
import { getMileageWarning } from '../../utils/cars';

type OnSaveProps = {
  creditAppFormValues: CreditAppOnDeal;
  autoStructure?: boolean;
};

interface CreditApplicationFormProps {
  isSaving: boolean;
  onSave: (onSaveProps: OnSaveProps) => Promise<void>;
  closeModal?: () => void;
  showPrequalification?: boolean;
}

const CreditApplicationForm = memo(
  ({ isSaving, onSave, closeModal, showPrequalification = false }: CreditApplicationFormProps) => {
    const prequalAutoStructureFlag = useFlag(LDFlags.PREQUAL_AUTOSTRUCTURE);
    const ignoreTTVehicleMileageError = useFlag(LDFlags.IGNORE_TT_VEHICLE_MILEAGE_ERROR);

    const {
      deal,
      dispatch,
      isRecalculatingPayoff,
      autosaving,
      setAutosaving,
      setUserChangedSomething,
      userChangedSomething,
    } = useContext(DealContext);
    const abilities = useContext(AbilityContext);
    const { setModal, modals } = useContext(ModalContext);
    const history = useHistory();

    const { values, handleSubmit, setFieldValue, validateForm, handleChange } =
      useFormikContext<CreditAppOnDeal>();

    const mileageModal = useDisclosure();
    const payoffModal = useDisclosure();

    const [dealState, setDealState] = useState<DealStateEnum>(DealStateEnum.Structuring);
    const [mileageWarning, setMileageWarning] =
      useState<{ status: MileageWarningsEnum; warning: string }>();

    const [ignoreInvalidPayoff, setIgnoreInvalidPayoff] = useState<boolean>(false);
    const [ignoreInvalidMileage, setIgnoreInvalidMileage] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const isInsideModal = !!closeModal;

    const canEditFundedOnwards =
      abilities.has(PermissionEnum.EditFundedOnwards) && isDealInOrPastFunded(deal.state);

    useEffect(() => {
      if (values.customer.email !== deal.customer.email && deal.customer.auth0_id) {
        setFieldValue('customer.auth0_id', null);
      }
      if (values?.cobuyer?.email !== deal.cobuyer?.email && deal.cobuyer?.auth0_id) {
        setFieldValue('cobuyer.auth0_id', null);
      }
    }, [values.customer.email, values?.cobuyer?.email]);

    const handleOnSave = async (dealStateParam?: DealStateEnum) => {
      const dealStateToSave = dealStateParam ?? dealState;

      await onSave({
        creditAppFormValues: {
          ...values,
          state: dealStateToSave,
          ...(deal.car.mileage && {
            odometer_status:
              mileageWarning?.status === MileageWarningsEnum.RoundNumber ||
              mileageWarning?.status === MileageWarningsEnum.RepeatingEnd
                ? OdometerStatus.Warning
                : OdometerStatus.Verified,
          }),
        },
        autoStructure: dealStateToSave === DealStateEnum.StructuringInProgress,
      });

      history.push(ROUTES.DASHBOARD);
    };

    const [checkVehicleMileage, { loading: isCheckingMileage }] = useVehicleMileageLazyQuery({
      fetchPolicy: 'network-only',
    });
    const isSavingOrCheckingMileage = isSaving || autosaving || isCheckingMileage;

    const validatePayoffAndMileage = useCallback(
      async ({
        dealStateParam,
        skipPayoffValidation = false,
        skipMileageValidation = false,
      }: {
        dealStateParam?: DealStateEnum;
        skipPayoffValidation?: boolean;
        skipMileageValidation?: boolean;
      } = {}) => {
        const validations = [];
        if (!skipPayoffValidation) {
          const { good_through_date, verification_status } = deal.car.payoff;
          const payoffIsInvalid =
            verification_status !== PayoffVerificationStatus.Verified ||
            (good_through_date && isBefore(new Date(good_through_date), new Date()));
          if (payoffIsInvalid) {
            payoffModal.onOpen();
          }

          validations.push(!payoffIsInvalid);
        }

        if (!skipMileageValidation) {
          const { error: vehicleMileageError, data: vehicleMileageData } =
            await checkVehicleMileage({
              variables: { vin: deal.car.vin },
            });
          if (vehicleMileageError) {
            toast.error('Error checking vehicle mileage');

            validations.push(ignoreTTVehicleMileageError);
          } else {
            const newMileageWarning = getMileageWarning(
              vehicleMileageData?.vehicleMileage,
              deal.car.mileage,
            );
            if (newMileageWarning) {
              setMileageWarning(newMileageWarning);
              mileageModal.onOpen();
            }

            validations.push(!newMileageWarning);
          }
        }

        if (validations.every((result) => result === true)) {
          handleOnSave(dealStateParam);
        }
      },
      [
        payoffModal,
        deal.car.payoff.good_through_date,
        deal.car.payoff.verification_status,
        mileageModal,
        checkVehicleMileage,
        deal.car.vin,
        deal.car.mileage,
        ignoreTTVehicleMileageError,
        handleOnSave,
      ],
    );

    const [updateCreditAppOnDeal] = useUpdateCreditAppOnDealMutation();
    const [autoSaveTime, setAutoSaveTime] = useState<string>('');

    const debouncedSave = useDebounce(async (currentValues: CreditAppOnDeal) => {
      try {
        const cleanedValues = cleanCreditAppValues({ values: currentValues });
        const res = await updateCreditAppOnDeal({
          variables: {
            input: {
              ...cleanedValues,
              state: currentValues.state,
              odometer_status: currentValues.odometer_status,
              financial_info: currentValues.financial_info,
            },
          },
        });

        if (!res.data?.updateCreditAppOnDeal) {
          throw new Error('No data returned from creditAppUpsert');
        }

        setAutoSaveTime(new Date().toLocaleTimeString('en-US'));

        const creditAppOnDeal = res.data.updateCreditAppOnDeal;
        dispatch({
          type: DealActionsEnum.DeepUpdateDeal,
          payload: {
            id: creditAppOnDeal.id,
            state: creditAppOnDeal.state as DealStateEnum,
            car: {
              odometer_status: creditAppOnDeal.odometer_status ?? undefined,
            },
            financial_info: {
              id: creditAppOnDeal.financial_info.id ?? undefined,
              term: creditAppOnDeal.financial_info.term ?? undefined,
              money_down: creditAppOnDeal.financial_info.money_down ?? undefined,
              buyer_not_lessee: creditAppOnDeal.financial_info.buyer_not_lessee ?? undefined,
            },
            customer: getPersonCreditAppValues(creditAppOnDeal.customer) as Customer,
            cobuyer: creditAppOnDeal.cobuyer
              ? (getPersonCreditAppValues(creditAppOnDeal.cobuyer) as Customer)
              : null,
            contact: creditAppOnDeal.contact
              ? (getContactCreditAppValues(creditAppOnDeal.contact) as DealContact)
              : null,
            second_contact: creditAppOnDeal.second_contact
              ? (getContactCreditAppValues(creditAppOnDeal.second_contact) as DealContact)
              : null,
          },
        });
      } catch (e) {
        const error = e as Error;
        logger.error('CreditPerson.tsx', '', null, error);
        toast.error('Error while autosaving Credit Application. Please try manually saving it.');
      } finally {
        setAutosaving(false);
      }
    });

    useEffect(() => {
      // `setFieldTouched` makes `dirty` true so we can't use it here. We could use dirty if we set those values in the initial values.
      if (!userChangedSomething) {
        return;
      }

      setAutosaving(true);
      debouncedSave(values);
    }, [
      values.addCobuyer,
      values.addContact,
      values.addSecondContact,
      values.contact,
      values.second_contact,
      values.customer,
      values.cobuyer,
      values.financial_info,
    ]);

    const customHandleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | undefined) => {
        setUserChangedSomething(true);
        handleChange(e);
      },
      [handleChange],
    );

    return (
      <Card variant="roundedWithBorder">
        <Form noValidate onSubmit={handleSubmit}>
          <CreditPerson
            isCobuyer={false}
            adminEditOverride={isEditing}
            autoSaveTime={autoSaveTime}
            customHandleChange={customHandleChange}
            isInsideModal={isInsideModal}
          />

          <HStack>
            {/* Add/Remove cobuyer button */}
            <AddCobuyerOrContactButton
              isAdded={values.addCobuyer}
              isDisabled={canEditFundedOnwards ? !isEditing : isUneditable(deal.state)}
              type="cobuyer"
            />
            {/* Add/Remove contact button above cobuyer info */}
            {!values.addCobuyer ? (
              <AddCobuyerOrContactButton isAdded={values.addContact} type="contact" />
            ) : null}
          </HStack>

          {values.addCobuyer ? (
            <CreditPerson
              isCobuyer
              autoSaveTime={autoSaveTime}
              adminEditOverride={isEditing}
              customHandleChange={customHandleChange}
              isInsideModal={isInsideModal}
            />
          ) : null}
          {/* Add/Remove Contact button below cobuyer info */}
          {values.addCobuyer ? (
            <AddCobuyerOrContactButton isAdded={values.addContact} type="contact" />
          ) : null}
          {values.addContact ? <AdditionalContact customHandleChange={customHandleChange} /> : null}
          {/* Add/Remove second contact button */}
          {values.addContact ? (
            <AddCobuyerOrContactButton isAdded={values.addSecondContact} type="secondContact" />
          ) : null}
          {values.addSecondContact ? (
            <AdditionalContact customHandleChange={customHandleChange} isSecond />
          ) : null}

          {prequalAutoStructureFlag && showPrequalification && <PrequalificationSection />}

          <HStack
            justifyContent="right"
            pt={5}
            pr={5}
            borderTopColor="gray.100"
            borderTopWidth="2px"
            flexWrap="wrap"
            spacing={1}
          >
            {isEditing || !canEditFundedOnwards ? (
              <Button
                variant="secondary"
                isLoading={isSavingOrCheckingMileage}
                isDisabled={isRecalculatingPayoff}
                loadingText="SAVE"
                onClick={() => {
                  validateForm();
                  setIsEditing(false);
                  onSave({ creditAppFormValues: values });
                }}
              >
                Save
              </Button>
            ) : (
              <>
                <Button
                  variant="secondary"
                  hidden={!canEditFundedOnwards}
                  onClick={() => {
                    setModal({ ConfirmEdit: true });
                  }}
                >
                  Edit
                </Button>
                <ConfirmEditModal
                  isOpen={modals.ConfirmEdit}
                  onConfirm={() => {
                    setIsEditing(true);
                  }}
                />
              </>
            )}

            <SwapBuyerCobuyerButton isSavingOrCheckingMileage={isSavingOrCheckingMileage} />

            {deal.state === DealStateEnum.SoftClose && (
              <>
                <SubmitApplicationButtons
                  isSavingOrCheckingMileage={isSavingOrCheckingMileage}
                  setDealState={setDealState}
                  validatePayoffAndMileage={validatePayoffAndMileage}
                />

                <MileageWarningModal
                  {...mileageModal}
                  status={mileageWarning?.status}
                  warning={mileageWarning?.warning}
                  onSuccess={() => {
                    const newIgnoreInvalidMileage = true;
                    setIgnoreInvalidMileage(newIgnoreInvalidMileage);
                    mileageModal.onClose();

                    validatePayoffAndMileage({
                      skipMileageValidation: newIgnoreInvalidMileage,
                      skipPayoffValidation: ignoreInvalidPayoff,
                    });
                  }}
                  onClose={() => {
                    setIgnoreInvalidMileage(false);
                    mileageModal.onClose();
                  }}
                />

                <PayoffWarningModal
                  {...payoffModal}
                  payoffValue={deal.car?.payoff?.vehicle_payoff}
                  onMoveDeal={() => {
                    const newIgnoreInvalidPayoff = true;
                    setIgnoreInvalidPayoff(newIgnoreInvalidPayoff);
                    payoffModal.onClose();

                    validatePayoffAndMileage({
                      skipPayoffValidation: newIgnoreInvalidPayoff,
                      skipMileageValidation: ignoreInvalidMileage,
                    });
                  }}
                  onClose={() => {
                    setIgnoreInvalidPayoff(false);
                    payoffModal.onClose();
                  }}
                />
              </>
            )}
            <Button
              hidden={!isInsideModal}
              loadingText="CLOSE"
              onClick={() => {
                if (isInsideModal) {
                  closeModal();
                }
              }}
            >
              Close
            </Button>
          </HStack>
          {prequalAutoStructureFlag && showPrequalification && (
            <Text
              mt="10px"
              mx="20px"
              fontWeight="normal"
              fontStyle="italic"
              color="silverSlateGray"
            >
              *By clicking Submit Application, I am confirming that I have obtained consent from the
              driver to submit an application for credit.
            </Text>
          )}
        </Form>
      </Card>
    );
  },
);

type CreditApplicationProps = {
  showInsideModal?: boolean;
};

const CreditApplication = ({ showInsideModal }: CreditApplicationProps) => {
  const { deal, dispatch } = useContext(DealContext);
  const { modals, setModal } = useContext(ModalContext);

  const [isSaving, setSaving] = useState<boolean>(false);

  const [automateCreditApp] = useMutation(creditAppAutomate);

  const [updateCreditAppOnDeal] = useUpdateCreditAppOnDealMutation();

  const initialValues = useCreditAppInitialValues({
    id: deal.id ?? -1,
    state: deal.state,
    odometer_status: deal.car.odometer_status,
    customer: deal.customer,
    cobuyer: deal.cobuyer,
    contact: deal.contact,
    second_contact: deal.second_contact,
    financial_info: deal.financial_info,
    addCobuyer: !!deal.cobuyer,
    addContact: !!deal.contact,
    addSecondContact: !!deal.second_contact,
  });

  const notificationWasPreviouslyAccepted = useMemo<boolean>(() => {
    const prevStates = deal.deal_states.map((dealState) => dealState.state);
    return prevStates.includes(DealStateEnum.Structuring);
  }, [deal.deal_states]);

  if (initialValues.cobuyer) {
    initialValues.cobuyer.wi_notification_agreement = notificationWasPreviouslyAccepted;
  }

  const onSave = async ({ creditAppFormValues, autoStructure = false }: OnSaveProps) => {
    setSaving(true);
    const cleanedValues = cleanCreditAppValues({ values: creditAppFormValues });

    try {
      const res = await updateCreditAppOnDeal({
        variables: {
          input: {
            ...cleanedValues,
            state: creditAppFormValues.state,
            odometer_status: creditAppFormValues.odometer_status,
            financial_info: creditAppFormValues.financial_info,
          },
        },
      });
      if (!res.data?.updateCreditAppOnDeal) {
        throw new Error('No data returned from updateCreditAppOnDeal');
      }

      toast.success('Credit Application saved successfully.');

      const creditAppOnDeal = res.data.updateCreditAppOnDeal;
      dispatch({
        type: DealActionsEnum.DeepUpdateDeal,
        payload: {
          id: creditAppOnDeal.id,
          state: creditAppOnDeal.state as DealStateEnum,
          car: {
            odometer_status: creditAppOnDeal.odometer_status ?? undefined,
          },
          financial_info: {
            id: creditAppOnDeal.financial_info.id ?? undefined,
            term: creditAppOnDeal.financial_info.term ?? undefined,
            money_down: creditAppOnDeal.financial_info.money_down ?? undefined,
            buyer_not_lessee: creditAppOnDeal.financial_info.buyer_not_lessee ?? undefined,
          },
          customer: getPersonCreditAppValues(creditAppOnDeal.customer) as Customer,
          cobuyer: creditAppOnDeal.cobuyer
            ? (getPersonCreditAppValues(creditAppOnDeal.cobuyer) as Customer)
            : null,
          contact: creditAppOnDeal.contact
            ? (getContactCreditAppValues(creditAppOnDeal.contact) as DealContact)
            : null,
          second_contact: creditAppOnDeal.second_contact
            ? (getContactCreditAppValues(creditAppOnDeal.second_contact) as DealContact)
            : null,
        },
      });

      if (autoStructure) {
        automateCreditApp({
          variables: {
            deal_id: deal.id,
          },
        });
      }
    } catch (e) {
      const error = e as Error;
      logger.error('CreditApplication.tsx', '', null, error);
      toast.error('Error while saving Credit Application. Please refresh and try again.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Formik
      validate={(values) => passValuesToSchema(values, validationSchema)}
      onSubmit={() => undefined}
      initialValues={initialValues}
      validateOnMount
      validateOnChange={false}
      enableReinitialize
    >
      {({ values }) =>
        showInsideModal ? (
          <Modal
            title="Customer Info"
            variant="fullContent"
            size="4xl"
            isOpen={modals.CustomerInfo}
            onClose={async () => {
              await onSave({ creditAppFormValues: values });
              setModal({ CustomerInfo: false });
            }}
          >
            <CreditApplicationForm
              isSaving={isSaving}
              onSave={onSave}
              closeModal={() => setModal({ CustomerInfo: false })}
            />
          </Modal>
        ) : (
          <CreditApplicationForm
            isSaving={isSaving}
            onSave={onSave}
            showPrequalification={deal.state === DealStateEnum.SoftClose}
          />
        )
      }
    </Formik>
  );
};

export default CreditApplication;
