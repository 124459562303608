import { useContext, useState } from 'react';

import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BiCheckCircle, BiEditAlt, BiXCircle } from 'react-icons/bi';

import { Deal } from '../../../gql/dealGql';

import TextArea from '../../shared/TextArea';
import { DebouncedSave } from '../DealInfoBuyoutForm';
import { QUICK_NOTES_MAX_LENGTH } from '../validationSchema';

import { DealContext } from '../../../libs/DealContext';

type QuickNotesProps = {
  debouncedSave: DebouncedSave;
};

export const QuickNotes = ({ debouncedSave }: QuickNotesProps) => {
  const { deal } = useContext(DealContext);

  const { values, errors, setFieldValue } = useFormikContext<Deal>();

  const [clickedEditQuickNotes, setClickedEditQuickNotes] = useState(false);

  const handleSaveQuickNotesClick = async () => {
    setFieldValue('financial_info.quick_notes', values.financial_info.quick_notes?.trim());

    setClickedEditQuickNotes(false);

    debouncedSave({ forceSave: true });
  };

  if (clickedEditQuickNotes) {
    return (
      <Flex alignItems="end" direction="column">
        <TextArea
          name="financial_info.quick_notes"
          label=""
          maxH={200}
          maxLength={QUICK_NOTES_MAX_LENGTH}
        />

        <Flex alignItems="center" mt={2} ml={1}>
          <IconButton
            icon={<BiXCircle />}
            variant="iconHover"
            size="xs"
            aria-label="Cancel"
            onClick={() => {
              setFieldValue('financial_info.quick_notes', deal.financial_info.quick_notes);
              setClickedEditQuickNotes(false);
            }}
          />
          <IconButton
            icon={<BiCheckCircle />}
            variant="iconHover"
            size="xs"
            aria-label="Save Quick Notes"
            isDisabled={!!errors.financial_info?.quick_notes}
            onClick={handleSaveQuickNotesClick}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex alignItems="start" justifyContent="space-between" pt={2} pl={3} w="100%" overflow="auto">
      <Box overflow="auto">
        <Text
          fontWeight="normal"
          fontStyle={values.financial_info.quick_notes ? 'normal' : 'italic'}
          fontSize="sm"
        >
          {values.financial_info.quick_notes ? values.financial_info.quick_notes : 'No notes added'}
        </Text>
      </Box>

      <IconButton
        icon={<BiEditAlt />}
        variant="iconHover"
        size="xs"
        ml={1}
        aria-label="Edit Quick Notes"
        onClick={() => {
          setFieldValue('financial_info.quick_notes', values.financial_info.quick_notes);
          setClickedEditQuickNotes(true);
        }}
      />
    </Flex>
  );
};
