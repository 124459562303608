import { Auth0Role, PodUser, TeamRole } from '../gql/generated/graphql';
import { Pod } from '../gql/podGql';

import { PermissionEnum } from '../constants/permissions';

export class User {
  id: string;
  email: string;
  name: string;
  nickname: string;
  phone_number?: string;
  twilio_number?: string;
  permissions?: PermissionEnum[];
  role?: string;
  pods?: Pod[];
  titlingPods?: Pod[];
  deleted_at?: string;
  created_at?: string;
  updated_at?: string;

  // Easier way for admins to control if the user can claim deals
  can_claim_as_closer?: boolean;

  hours: string;
  on_vacation: boolean;
  auto_assign_deals: boolean;
  overnight_deals: boolean;
  auth0_roles?: { roles: Auth0Role[] };

  constructor(id?: string) {
    this.id = id || '';
    this.email = '';
    this.name = '';
    this.nickname = '';
    this.phone_number = '';
    this.twilio_number = '';
    this.hours = '';
    this.on_vacation = false;
    this.auto_assign_deals = false;
    this.overnight_deals = false;
  }
}

export type getAvailableUsersStatusType = {
  id: number;
  email: string;
  name: string;
  nickname: string;
  phone_number: string;
  twilio_number: string;
  in_call: string | null;
};

export type UserWithTitlingRoleType = {
  id: string;
  name: string;
  teamRole: TeamRole;
};

export type PodUsersByPod = {
  all: PodUser[];
  active: PodUser[];
  [key: string]: PodUser[];
};
