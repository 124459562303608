import { useCallback } from 'react';

import { toast } from 'react-toastify';

import {
  Maybe,
  useCreateJdpBookSheetMutation,
  useCreateKbbBookSheetMutation,
  useMediaLazyQuery,
} from '../gql/generated/graphql';

import { logger } from '../libs/Logger';

/**
 * Generates Book Sheets, fetches media and shows toasts.
 */
const useGenerateBookSheets = () => {
  const [createKBBBookSheet, { loading: createKBBBookSheetLoading }] =
    useCreateKbbBookSheetMutation();
  const [createJDPBookSheet, { loading: createJdpBookSheetLoading }] =
    useCreateJdpBookSheetMutation();

  const [mediaLazyQuery] = useMediaLazyQuery();

  const isLoading = createKBBBookSheetLoading || createJdpBookSheetLoading;

  const generateKBBBookSheet = useCallback(
    async (dealId: number) => {
      try {
        await createKBBBookSheet({
          variables: {
            dealId,
          },
        });

        toast.success('KBB Book Sheet created.');
      } catch (e) {
        logger.error('useGenerateBookSheets', 'generateKBBBookSheet', null, e);
        toast.error('Failed to create KBB Book Sheet.');
        throw e;
      }
    },
    [createKBBBookSheet],
  );

  const generateJDPBookSheet = useCallback(
    async (dealId: number, customerState: string) => {
      try {
        await createJDPBookSheet({
          variables: {
            dealId,
            customerState,
          },
        });

        toast.success('JDP Book Sheet created.');
      } catch (e) {
        logger.error('useGenerateBookSheets', 'generateJDPBookSheet', null, e);
        toast.error('Failed to create JDP Book Sheet.');
        throw e;
      }
    },
    [createJDPBookSheet],
  );

  const generateBookSheets = useCallback(
    async ({ dealId, customerState }: { dealId: Maybe<number>; customerState: Maybe<string> }) => {
      if (!dealId || !customerState) {
        toast.error('Failed to create Book Sheets due to missing data.');
        return;
      }

      const results = await Promise.allSettled([
        generateKBBBookSheet(dealId),
        generateJDPBookSheet(dealId, customerState),
      ]);

      if (results.some(({ status }) => status === 'fulfilled')) {
        await mediaLazyQuery({
          variables: {
            dealId,
          },
          fetchPolicy: 'cache-and-network',
        });
      }
    },
    [generateKBBBookSheet, generateJDPBookSheet, mediaLazyQuery],
  );

  return {
    isLoading,
    generateBookSheets,
  };
};

export default useGenerateBookSheets;
