import { PropsWithChildren } from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

type TopSectionProps = PropsWithChildren<FlexProps>;

const TopSection = ({ children, ...rest }: TopSectionProps) => (
  <Flex alignItems="start" p={2} {...rest}>
    {children}
  </Flex>
);

export default TopSection;
