import { Box } from '@chakra-ui/react';

import { CustomerContactTypeEnum } from '../../../gql/customerGql';

import { ColorsEnum } from '../../../libs/colorLib';
import { getInitials } from '../../../utils/text';

type CustomerBubbleProps = {
  firstName: string;
  lastName: string;
  customerType: string;
};

const CustomerBubble = ({ firstName, lastName, customerType }: CustomerBubbleProps) => {
  const getConversationColor = (role: string) => {
    switch (role) {
      case CustomerContactTypeEnum.Customer:
        return '#48BB78';
      case CustomerContactTypeEnum.Cobuyer:
        return 'teal.400';
      case CustomerContactTypeEnum.Contact:
        return 'blue.700';
      case CustomerContactTypeEnum.Second:
        return 'orange.400';
      default:
        return ColorsEnum.BLUE;
    }
  };

  return (
    <Box
      borderRadius="50%"
      w="42px"
      h="42px"
      bgColor={getConversationColor(customerType)}
      color="white"
      fontSize={19}
      p="7px 2px 2px 2px"
      textAlign="center"
      alignSelf="end"
    >
      {getInitials(`${firstName} ${lastName}`)}
    </Box>
  );
};

export default CustomerBubble;
