import { useMemo } from 'react';

import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { SingleValue } from 'react-select';

import {
  Auth0RoleName,
  Pod as GeneratedPod,
  Maybe,
  SortDirection,
  TeamType,
  useGetUsersQuery,
} from '../../gql/generated/graphql';
import { Pod } from '../../gql/podGql';

import CustomReactSelect from './CustomReactSelect';

import { User } from '../../types/user';

interface UserAutocompleteProps extends InputGroupProps {
  size?: 'sm' | 'lg';
  label?: string;
  includeEmptyOption?: boolean;
  emptyOptionLabel?: string;
  user?: User;
  setUser: (user?: User) => void;
  podType?: 'sales' | 'titling';
  selectedPod?: Pod | Maybe<GeneratedPod>;
  name?: string;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: string;
}

const UserAutocomplete = ({
  label,
  size,
  includeEmptyOption,
  emptyOptionLabel,
  user,
  setUser,
  podType = 'sales',
  selectedPod,
  name,
  loading = false,
  disabled = false,
  required = false,
  error = '',
  ...rest
}: UserAutocompleteProps) => {
  const styles = useMultiStyleConfig('FormControl');

  const roleNames = useMemo(() => {
    switch (name) {
      case 'closer':
      case 'closer2':
        return [Auth0RoleName.InboundFinancialSpecialist];
      case 'funding_clerk':
        return [Auth0RoleName.FundingClerk];
      case 'structuring_manager':
        return [Auth0RoleName.StructuringManager];
      case 'title_clerk':
      case 'title_clerk2':
        return [Auth0RoleName.TitleClerk];
      default:
        return [];
    }
  }, [name]);

  const { data: getUsersData } = useGetUsersQuery({
    variables: {
      podId:
        name && ['setter', 'title_clerk', 'title_clerk2'].includes(name)
          ? selectedPod?.id
          : undefined, // Only Advisors and Title Clerks have pods
      roleNames,
      sortDirection: SortDirection.Asc,
      sortColumn: 'name',
      teamType: podType === 'titling' ? TeamType.Titling : undefined,
    },
  });

  const users = useMemo(() => {
    const newUsers = [...(getUsersData?.getUsers?.results || [])]; // Spread in to allow use of .unshift()
    if (!newUsers.length) {
      return [];
    }

    const selectedUserBelongsToCurrentPod = newUsers.some((podUser) => podUser?.id === user?.id);
    if (user && !selectedUserBelongsToCurrentPod) {
      newUsers.unshift(user);
    }

    return newUsers as User[];
  }, [getUsersData?.getUsers?.results, user]);

  const userOptions = useMemo(
    () =>
      users?.map((userGql: User) => ({
        value: userGql?.id,
        label: userGql?.name,
      })),
    [users],
  );

  const selectedUserOption = useMemo(() => {
    if (!user || !users) {
      return null;
    }

    if (user?.name) {
      return { value: user.id, label: user.name };
    }

    const userFromGql = users.find((userGql) => userGql?.id === user?.id);
    return userFromGql ? { value: userFromGql?.id, label: userFromGql?.name } : null;
  }, [user, users]);

  const handleChange = async (
    newSelectedUserOption: SingleValue<{ value: Maybe<number> | string; label: Maybe<string> }>,
  ) => {
    const newUser = users?.find((userGql) => userGql?.id === newSelectedUserOption?.value);

    if (newUser) {
      setUser(newUser);
    } else if (includeEmptyOption) {
      setUser(undefined);
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <InputGroup
        size={size}
        opacity={disabled ? 0.5 : 1}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        {...rest}
      >
        {!!label && <InputLeftAddon width="160px"> {label}</InputLeftAddon>}
        <CustomReactSelect
          hasLeftAddon={!!label}
          isClearable={includeEmptyOption}
          placeholder={emptyOptionLabel}
          options={userOptions}
          value={selectedUserOption}
          onChange={handleChange}
          name={name}
          isLoading={loading}
          isDisabled={disabled}
          required={required}
          isInvalid={!!error}
        />
      </InputGroup>
      <FormErrorMessage sx={styles.error}>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default UserAutocomplete;
