import { useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';

import { File } from '../gql/contractGql';
import {
  Deal,
  dealUpdateMissingRequiredDocuments,
  updateDealNeedsElectronicSignatureVerificationMutation,
} from '../gql/dealGql';
import {
  DealMediaTypeEnum,
  DealMediaTypeEnum as GeneratedDealMediaTypeEnum,
  Maybe,
  TtDocumentName,
  TtJurisdiction,
  useMediaQuery,
  useRequiredDocumentsQuery,
} from '../gql/generated/graphql';
import { TTJurisdiction } from '../gql/taterTitleGql';

import { parseFilename } from '../components/MediaCenter/utils';
import { DealMediaCustomerTypes, DealMediaNoSignatureUpload } from '../constants/media';
import { DealActions, DealActionsEnum } from '../libs/DealContext';
import { logger } from '../libs/Logger';
import { DealMedia } from '../types/media';
import {
  isNotaryRequired,
  isVerifiedDigitalSignatureRequired,
  isWetSignatureRequired,
  mapTTDocumentType,
} from '../utils/media';

import useRequiredDocsVariables from './useRequiredDocsVariables';

export interface DocumentMedia {
  mediaList: DealMedia[];
  internalMediaList: DealMedia[];
  customerMediaList: DealMedia[];
  unverifiedRequiredMediaList: DealMedia[];
  missingRequiredMediaList: DealMedia[];
  signatureFileList: File[];
}

export interface UseDocumentMedia {
  documentMedia: DocumentMedia;
  jurisdiction: TtJurisdiction;
}

interface UseDocumentMediaProps {
  deal: Deal;
  dealDispatch: (value: DealActions) => void;
}

const useDocumentMedia = ({ deal, dealDispatch }: UseDocumentMediaProps) => {
  const mediaGql = useMediaQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dealId: deal.id!,
    },
    skip: !deal.id,
    fetchPolicy: 'cache-and-network',
  });

  const { skipRequiredDocs, requiredDocsVariables } = useRequiredDocsVariables(
    deal,
    mediaGql.data?.media,
  );

  const { data: requiredDocumentsData, refetch: refetchRequiredDocuments } =
    useRequiredDocumentsQuery({
      variables: requiredDocsVariables,
      skip: skipRequiredDocs,
    });

  const jurisdiction = requiredDocumentsData?.jurisdiction as Maybe<TTJurisdiction>;

  const [updateMissingRequiredDocuments] = useMutation(dealUpdateMissingRequiredDocuments);
  const [
    updateDealNeedsElectronicSignatureVerification,
    { loading: updateDealNeedsElectronicSignatureVerificationLoading },
  ] = useMutation<{
    updateDealNeedsElectronicSignatureVerification?: Deal;
  }>(updateDealNeedsElectronicSignatureVerificationMutation);
  const [mediaList, setMediaList] = useState<DealMedia[]>([]);

  const { internal: internalMediaList, customer: customerMediaList } = useMemo(
    () =>
      mediaList.reduce(
        (acc, media) => {
          if (media.type && !DealMediaCustomerTypes.includes(media.type)) {
            acc.internal.push(media);
          } else {
            acc.customer.push(media);
          }
          return acc;
        },
        { internal: [], customer: [] } as {
          internal: DealMedia[];
          customer: DealMedia[];
        },
      ),
    [mediaList],
  );

  const requiredMediaList = useMemo((): DealMedia[] => {
    const requiredTTDocuments =
      jurisdiction?.products.items.flatMap((p) => p?.documents?.items) || [];

    return requiredTTDocuments
      .filter(
        (ttDocument, index, array) =>
          index === array.findIndex((t) => t?.type?.name === ttDocument?.type?.name) &&
          Object.values(TtDocumentName).includes(ttDocument?.type?.name as TtDocumentName),
      )
      .map((doc) => ({
        type: mapTTDocumentType(doc?.type?.name) as DealMediaTypeEnum,
        verified_digital_signature_required: isVerifiedDigitalSignatureRequired(doc?.validations),
        wet_signature_required: isWetSignatureRequired(doc?.validations),
        notary_required: isNotaryRequired(doc?.validations),
      }));
  }, [jurisdiction]);

  // verified null means the user has already uploaded the document but it has not been verified yet
  const unverifiedRequiredMediaList = useMemo(
    () =>
      customerMediaList.filter((m) => !!m.type && (m.verified === null || m.verified === false)),
    [customerMediaList],
  );

  const missingRequiredMediaList = useMemo(
    () => customerMediaList.filter((m) => !!m.type && !m.key),
    [customerMediaList],
  );

  const signatureFileList: File[] = useMemo(
    () =>
      mediaList
        .filter(
          (media) =>
            parseFilename(media.key ?? '').isPdf &&
            media.type &&
            !DealMediaNoSignatureUpload.includes(media.type),
        )
        .map((media) => ({
          url: media.signed_url ?? '',
          filename: parseFilename(media.key ?? '').sourceFileName,
          key: media.key ?? '',
          mediaType: media.type as GeneratedDealMediaTypeEnum,
        })),
    [mediaList],
  );

  const handleMissingRequiredDocumentsUpdate = async (
    newMissingRequiredExternalDocuments: boolean,
  ) => {
    try {
      const result = await updateMissingRequiredDocuments({
        variables: {
          id: deal.id,
          missing_required_external_documents: deal.missing_required_external_documents,
          new_missing_required_external_documents: newMissingRequiredExternalDocuments,
        },
      });

      if (result.data?.dealUpdateMissingRequiredDocuments !== undefined) {
        dealDispatch({
          type: DealActionsEnum.UpdateDeal,
          payload: {
            missing_required_external_documents: result.data?.dealUpdateMissingRequiredDocuments,
          },
        });
      }

      refetchRequiredDocuments();
    } catch (e) {
      logger.error('useDocumentMedia.ts', '', null, e);
    }
  };

  const handleUpdateDealNeedsElectronicSignatureVerification = async (
    needsElectronicSignatureVerification: boolean,
  ) => {
    try {
      const result = await updateDealNeedsElectronicSignatureVerification({
        variables: {
          deal_id: deal.id,
          needs_electronic_signature_verification: needsElectronicSignatureVerification,
        },
      });

      if (result.data?.updateDealNeedsElectronicSignatureVerification) {
        dealDispatch({
          type: DealActionsEnum.UpdateDeal,
          payload: {
            needs_electronic_signature_verification:
              result.data.updateDealNeedsElectronicSignatureVerification
                .needs_electronic_signature_verification,
          },
        });
      }
    } catch (e) {
      logger.error('useDocumentMedia.ts', '', null, e);
    }
  };

  // TODO: Finish this refactor and try to move it to backend
  const extractDocumentMedia = async () => {
    const dealMedia = (mediaGql?.data?.media as DealMedia[]) || [];
    const media: DealMedia[] = dealMedia.map((dm) => {
      const matchedRequiredDocument = requiredMediaList.find((rm) => rm.type === dm.type);
      if (matchedRequiredDocument) {
        return {
          ...dm,
          ...matchedRequiredDocument,
        };
      }
      return dm;
    });

    const uploadedTypes = dealMedia.map((m) => m.type) || [];
    const notUploadedRequiredMedia = requiredMediaList
      .filter((m) => !uploadedTypes.includes(m.type))
      .map((m) => ({
        ...m,
        has_verified_digital_signature: false,
        has_wet_signature: false,
        is_notarized: false,
      }));
    const notUploadedRequiredCustomerMedia = notUploadedRequiredMedia.filter((m) =>
      DealMediaCustomerTypes.includes(m.type as DealMediaTypeEnum),
    );

    setMediaList([...media, ...notUploadedRequiredMedia]);

    if (!deal?.id || !jurisdiction) {
      return;
    }

    if (notUploadedRequiredCustomerMedia.length === 0 && deal.missing_required_external_documents) {
      await handleMissingRequiredDocumentsUpdate(false);
    } else if (
      notUploadedRequiredCustomerMedia.length > 0 &&
      deal.missing_required_external_documents !== undefined &&
      !deal.missing_required_external_documents
    ) {
      await handleMissingRequiredDocumentsUpdate(true);
    }

    const needsElectronicSignatureVerification = requiredMediaList.some(
      (m) =>
        m.type === DealMediaTypeEnum.ElectronicSignatureAttestation ||
        m.type === DealMediaTypeEnum.ElectronicSignatureCertification,
    );
    if (
      !updateDealNeedsElectronicSignatureVerificationLoading &&
      needsElectronicSignatureVerification !== deal.needs_electronic_signature_verification
    ) {
      await handleUpdateDealNeedsElectronicSignatureVerification(
        needsElectronicSignatureVerification,
      );
    }
  };

  useEffect(() => {
    extractDocumentMedia();
  }, [mediaGql.data, requiredDocumentsData, mediaList.length]);

  useEffect(() => {
    if (!skipRequiredDocs && refetchRequiredDocuments) {
      refetchRequiredDocuments(requiredDocsVariables);
    }
  }, [skipRequiredDocs, requiredDocsVariables]);

  const documentMedia: DocumentMedia = {
    mediaList,
    internalMediaList,
    customerMediaList,
    unverifiedRequiredMediaList,
    missingRequiredMediaList,
    signatureFileList,
  };

  const jurisdictionBooleans: TtJurisdiction = {
    hasRegistrationOnly: jurisdiction?.hasRegistrationOnly,
    hasTitleOnly: jurisdiction?.hasTitleOnly,
    hasTitleAndRegistration: jurisdiction?.hasTitleAndRegistration,
    allowRegistrationTransfer: jurisdiction?.allowRegistrationTransfer,
    allowPlateTransfer: jurisdiction?.allowPlateTransfer,
    requireWalkIn: jurisdiction?.requireWalkIn,
  };

  return { documentMedia, jurisdiction: jurisdictionBooleans };
};

export default useDocumentMedia;
