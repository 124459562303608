import { Button } from '@chakra-ui/react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  CreateUserInput,
  HuntGroup,
  useCreateUserMutation,
  useGetAuth0RolesQuery,
  useGetUserCreationDataQuery,
} from '../../../gql/generated/graphql';
import { Pod } from '../../../gql/podGql';

import Modal, { LEModalProps } from '../../../components/shared/Modal';
import { createUserValidationSchema } from '../utils';

import UserForm from './UserForm';

import { logger } from '../../../libs/Logger';

export type CreateUserModalFormikFields = Omit<CreateUserInput, 'podIds' | 'huntGroupIds'> & {
  pods: Pod[];
  huntGroups: HuntGroup[];
};

const CreateUserModal = ({ isOpen, onClose, ...rest }: LEModalProps) => {
  const [createUser, { loading }] = useCreateUserMutation();
  const { data: userCreationData } = useGetUserCreationDataQuery({
    skip: !isOpen,
  });
  const { data: auth0RolesData } = useGetAuth0RolesQuery({
    skip: !isOpen,
  });

  const handleCloseModal = (formikHelpers: { resetForm: () => void }) => {
    onClose();
    formikHelpers.resetForm();
  };

  const initialValues: CreateUserModalFormikFields = {
    name: '',
    email: '',
    password: '',
    auth0Roles: [],

    phoneNumber: '',
    pods: [],
    recruiterId: '',
    huntGroups: [],
  };

  const onSave = async (
    values: CreateUserModalFormikFields,
    formikHelpers: { resetForm: () => void },
  ) => {
    try {
      const { data } = await createUser({
        variables: {
          createUserInput: {
            name: values.name,
            email: values.email,
            password: values.password,
            auth0Roles: values.auth0Roles ?? [],

            phoneNumber: values.phoneNumber || null,
            podIds: values.pods?.map((pod) => pod.id),
            recruiterId: values.recruiterId,
            huntGroupIds: values.huntGroups?.map((huntGroup) => huntGroup.id),
          },
        },
      });

      if (data?.createUser) {
        handleCloseModal({ resetForm: formikHelpers.resetForm });
        toast.success('User created successfully');
      }
    } catch (e) {
      const error = e as Error;
      toast.error(`Failed to create user: ${error.message}`);
      logger.error('CreateUserModal.tsx', 'Failed to create user', null, e);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createUserValidationSchema}
      validateOnMount
      onSubmit={onSave}
      enableReinitialize
    >
      {({ isValid, resetForm, values }) => {
        return (
          <Modal
            {...rest}
            title="Create New User"
            size="3xl"
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={() => {
              handleCloseModal({ resetForm });
            }}
            leftButtons={
              <Button
                variant="warning"
                onClick={() => {
                  handleCloseModal({ resetForm });
                }}
              >
                CANCEL
              </Button>
            }
            rightButtons={
              <Button
                variant="primary"
                type="submit"
                isDisabled={!isValid}
                isLoading={loading}
                onClick={() => onSave(values, { resetForm })}
              >
                ADD USER
              </Button>
            }
          >
            <UserForm
              pods={userCreationData?.getUserCreationData?.pods}
              huntGroups={userCreationData?.getUserCreationData?.huntGroups}
              auth0Roles={auth0RolesData?.getAuth0Roles}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreateUserModal;
