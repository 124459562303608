import { useState } from 'react';

import { Flex, Icon, IconButton, StyleProps, Text, useDisclosure } from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp, BiEdit } from 'react-icons/bi';
import { MdBlock, MdCancel, MdCheckCircle } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Auth0RoleName,
  Maybe,
  SortDirection,
  User,
  useGetUsersQuery,
} from '../../gql/generated/graphql';

import LETable from '../../components/shared/Table/LETable';
import { TableColumns } from '../../components/shared/Table/types';

import UserSettingsModal from './UserSettingsModal';
import { getWorkingHoursTableValue } from './utils';

import colors from '../../chakra/foundations/colors';
import { LDFlags } from '../../constants/experiments';
import useFlag from '../../hooks/useFlag';

const OptionIcon = ({
  value,
  allowed = true,
}: {
  value: Maybe<boolean>;
  allowed?: Maybe<boolean>;
}) => (
  <Flex justifyContent="center">
    {!allowed ? (
      <MdBlock size={20} />
    ) : value ? (
      <MdCheckCircle size={20} color={colors.green} />
    ) : (
      <MdCancel size={20} color={colors.errorsRed} />
    )}
  </Flex>
);

const CollapsibleHours = ({ hours }: Pick<User, 'hours'>) => {
  const { Today, ...week } = getWorkingHoursTableValue(hours);
  const collapse = useDisclosure();

  return (
    <Flex direction="column" onClick={collapse.onToggle} cursor="pointer">
      <Text>
        Today: {Today} <Icon as={collapse.isOpen ? BiChevronUp : BiChevronDown} />
      </Text>
      {collapse.isOpen ? (
        <Flex direction="column" mt={2}>
          {Object.entries(week).map(([key, value]) => (
            <Text key={key}>
              {key}: {value}
            </Text>
          ))}
        </Flex>
      ) : null}
    </Flex>
  );
};

const optionStyleProps: StyleProps = {
  width: '1%',
  whiteSpace: 'nowrap',
  pr: { base: 0, xl: '100px', '3xl': '150px', '4xl': '200px' },
};

const columns: TableColumns<User> = [
  {
    header: 'Name',
    getComponent: ({ item }) => item.name,
    sortingKey: 'name',
    styleProps: { whiteSpace: 'nowrap' },
  },
  {
    header: 'Auto-Assign',
    getComponent: ({ item }) => <OptionIcon value={item.auto_assign_deals} />,
    sortingKey: 'auto_assign_deals',
    styleProps: optionStyleProps,
  },
  {
    header: 'Overnight Deals',
    getComponent: ({ item }) => (
      <OptionIcon value={item.overnight_deals} allowed={item.auto_assign_deals} />
    ),

    styleProps: optionStyleProps,
  },
  {
    header: 'Vacation',
    getComponent: ({ item }) => (
      <OptionIcon value={item.on_vacation} allowed={item.auto_assign_deals} />
    ),

    styleProps: optionStyleProps,
  },
  {
    header: 'Hours',
    styleProps: { minW: '300px', w: '300px', whiteSpace: 'nowrap' },
    getComponent: ({ item }) =>
      item.auto_assign_deals ? <CollapsibleHours hours={item.hours} /> : ' - ',
  },
  {
    header: 'Actions',
    getComponent: ({ item, handleEdit }) => (
      <IconButton
        icon={<BiEdit />}
        variant="iconHover"
        size="xs"
        aria-label="Edit User"
        onClick={(e) => {
          e.stopPropagation();
          handleEdit?.(item);
        }}
      />
    ),
    styleProps: { width: '100px' },
  },
];

const FinancialSpecialist = () => {
  const autoAssigningDealsFlag = useFlag(LDFlags.AUTO_ASSIGNING_DEALS);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState(SortDirection.Asc);

  const userSettingsModal = useDisclosure();

  const { data, refetch, loading } = useGetUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      roleNames: [Auth0RoleName.InboundFinancialSpecialist],
      sortColumn,
      sortDirection,
    },
    onError: (error) => {
      toast.error(`Couldn't load Users. Please refresh. Error: ${error.message}`);
    },
  });
  const financialSpecialists = data?.getUsers?.results || [];

  return autoAssigningDealsFlag ? (
    <>
      <LETable
        columns={columns}
        rowKeyPrefix="financial-specialist"
        rowKeyProp="id"
        items={financialSpecialists}
        isLoading={loading}
        sortProps={{
          sortColumn,
          setSortColumn,
          sortDirection,
          setSortDirection,
        }}
        handleEdit={(user) => {
          setSelectedUser(user);
          userSettingsModal.onOpen();
        }}
      />

      <UserSettingsModal
        isOpen={userSettingsModal.isOpen}
        onClose={userSettingsModal.onClose}
        user={selectedUser}
        refetchUsers={refetch}
      />
    </>
  ) : null;
};

export default FinancialSpecialist;
