import { useContext } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { BsExclamationCircleFill } from 'react-icons/bs';

import { DealSourceEnum, DealSourceLabelMap, DealTypeLabelMap } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import InfoCardV2 from '../InfoCard/InfoCardV2';
import { getAttributionLine, getTitleAttributionLine } from '../Task/Task.utils';
import UserAssignment from '../UserAssignment';

import colors from '../../chakra/foundations/colors';
import { PermissionEnum } from '../../constants/permissions';
import { Can } from '../../libs/Can';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { capitalizeFirstLetter } from '../../utils/text';
import { getName } from '../../utils/user';

const DealInfo = () => {
  const { modals, setModal } = useContext(ModalContext);
  const { deal } = useContext(DealContext);
  const abilities = useContext(AbilityContext);

  const isRefi = deal?.type === DealType.Refi;
  const dealOwnerStr = getAttributionLine({ deal, separator: ',' });
  const structuringManagerName = getName(deal?.structuring_manager?.name || '', {
    lastInitial: true,
  });
  const titleAttributionLine = getTitleAttributionLine(deal);

  return (
    <>
      <InfoCardV2
        name="Deal Info"
        values={{
          'Deal Type': {
            render: (
              <Flex alignItems="center">
                {deal.type ? DealTypeLabelMap[deal.type] : ''}
                {isRefi ? (
                  <Box minW={4} ml={1} mb={1}>
                    <BsExclamationCircleFill color={colors.skyBlue} size={20} />
                  </Box>
                ) : null}
              </Flex>
            ),
            value: deal.type,
          },
          [`Deal Owner${dealOwnerStr.includes(',') ? 's' : ''}`]: dealOwnerStr,
          ...(structuringManagerName && { 'Structuring Manager': structuringManagerName }),
          ...(titleAttributionLine && { 'Title Work': titleAttributionLine }),
          ...(deal.source && { Source: DealSourceLabelMap[deal.source as DealSourceEnum] }),
          'Paperwork Type': capitalizeFirstLetter(deal.paperwork_type ?? ''),
        }}
        showEditable={
          abilities.has(PermissionEnum.ReassignDeal) || abilities.has(PermissionEnum.EditDealSource)
        }
        editAction={() => setModal({ UserAssignment: true })}
      />
      <Can I={[PermissionEnum.ReassignDeal, PermissionEnum.EditDealSource]}>
        <UserAssignment
          isOpen={modals.UserAssignment}
          onClose={() => setModal({ UserAssignment: false })}
        />
      </Can>
    </>
  );
};

export default DealInfo;
