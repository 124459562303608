import { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';

import {
  AccidentsEnum,
  ConditionReport as ConditionReportClass,
  ExteriorEnum,
  InteriorEnum,
  OverallConditionEnum,
  TiresEnum,
  conditionReportUpsert,
} from '../../gql/conditionReportGql';
import { DealStateEnum } from '../../gql/dealGql';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

const openDealStates = [DealStateEnum.SoftClose, DealStateEnum.Structuring, DealStateEnum.Closing];

interface ConditionReportProps {
  setShowModal: (showModal: boolean) => void;
  isEditing?: boolean;
}

const ConditionReport = ({ setShowModal, isEditing = false }: ConditionReportProps) => {
  const { deal, dealRefetch } = useContext(DealContext);
  const { condition_report } = deal;
  const [upsertConditionReport] = useMutation(conditionReportUpsert);
  const [isSaving, setSaving] = useState<boolean>(false);

  const [accidents, setAccidents] = useState(condition_report?.accidents);
  const [tires, setTires] = useState(condition_report?.tires);
  const [exterior, setExterior] = useState(condition_report?.exterior);
  const [interior, setInterior] = useState(condition_report?.interior);
  const [smokedIn, setSmokedIn] = useState(condition_report?.smoked_in);
  const [lightsOnDash, setLightsOnDash] = useState(condition_report?.lights_on_dash);
  const [overallCondition, setOverallCondition] = useState(condition_report?.overall_condition);
  const showSaveButton = openDealStates.includes(deal.state) || isEditing;

  const handleOnClickArray = (
    arr: string[] | undefined,
    setArr: (arr: string[]) => void,
    value: string,
  ) => {
    if (!arr) {
      setArr([value]);
    } else if (arr?.indexOf(value) === -1) {
      setArr([...arr, value]);
    } else if (arr.includes(value)) {
      const index = arr.indexOf(value);
      const newArr = [...arr];
      newArr.splice(index, 1);
      setArr(newArr);
    }
  };

  const onSave = async (conditionReport: ConditionReportClass) => {
    setSaving(true);
    try {
      await upsertConditionReport({
        variables: {
          conditionReport: { ...conditionReport, year: deal.car.year },
        },
      });
      setShowModal(false);
      dealRefetch();
    } catch (error) {
      logger.error('ConditionReport.tsx', '', null, error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <VStack>
      <Text mt={2}>Has this vehicle been in any accidents?</Text>
      <HStack>
        <Button
          variant="secondary"
          isActive={accidents === AccidentsEnum.NoAccidents}
          onClick={() => setAccidents(AccidentsEnum.NoAccidents)}
          name="no-accidents"
        >
          NO ACCIDENTS
        </Button>
        <Button
          variant="secondary"
          isActive={accidents === AccidentsEnum.OneAccident}
          onClick={() => setAccidents(AccidentsEnum.OneAccident)}
          name="one-accident"
        >
          1 ACCIDENT
        </Button>
        <Button
          variant="secondary"
          isActive={accidents === AccidentsEnum.TwoOrMoreAccidents}
          onClick={() => setAccidents(AccidentsEnum.TwoOrMoreAccidents)}
          name="two-accidents"
        >
          2 OR MORE ACCIDENTS
        </Button>
      </HStack>
      <Text mt={2}>What is the condition of the tires?</Text>
      <HStack>
        <Button
          variant="secondary"
          isActive={tires === TiresEnum.New}
          onClick={() => setTires(TiresEnum.New)}
          name="new-tires"
        >
          NEW
        </Button>
        <Button
          variant="secondary"
          isActive={tires === TiresEnum.Fine}
          onClick={() => setTires(TiresEnum.Fine)}
          name="fine-tires"
        >
          FINE
        </Button>
        <Button
          variant="secondary"
          isActive={tires === TiresEnum.NeedReplacing}
          onClick={() => setTires(TiresEnum.NeedReplacing)}
          name="bad-tires"
        >
          NEED REPLACING
        </Button>
      </HStack>
      <HStack>
        <Text mt={2}>Do any of these apply to the exterior?</Text>
      </HStack>
      <HStack>
        <Button
          variant="secondary"
          isActive={exterior?.includes(ExteriorEnum.MinorScuffs)}
          onClick={() => handleOnClickArray(exterior, setExterior, ExteriorEnum.MinorScuffs)}
          name="minor-scuffs"
        >
          MINOR SCUFFS
        </Button>
        <Button
          variant="secondary"
          isActive={exterior?.includes(ExteriorEnum.PaintDamage)}
          onClick={() => handleOnClickArray(exterior, setExterior, ExteriorEnum.PaintDamage)}
          name="paint-damage"
        >
          PAINT DAMAGE
        </Button>
        <Button
          variant="secondary"
          isActive={exterior?.includes(ExteriorEnum.DentsOrDings)}
          onClick={() => handleOnClickArray(exterior, setExterior, ExteriorEnum.DentsOrDings)}
          name="dings"
        >
          DENTS OR DINGS
        </Button>
      </HStack>
      <HStack>
        <Button
          variant="secondary"
          isActive={exterior?.includes(ExteriorEnum.Rusting)}
          onClick={() => handleOnClickArray(exterior, setExterior, ExteriorEnum.Rusting)}
          name="rusting"
        >
          RUSTING
        </Button>
        <Button
          variant="secondary"
          isActive={exterior?.includes(ExteriorEnum.HailDamage)}
          onClick={() => handleOnClickArray(exterior, setExterior, ExteriorEnum.HailDamage)}
          name="hail-damage"
        >
          HAIL DAMAGE
        </Button>
        <Button
          variant="secondary"
          isActive={exterior?.includes(ExteriorEnum.GlassWindshieldDamage)}
          onClick={() =>
            handleOnClickArray(exterior, setExterior, ExteriorEnum.GlassWindshieldDamage)
          }
          name="windshield-damage"
        >
          GLASS/WINDSHIELD DAMAGE
        </Button>
      </HStack>
      <HStack>
        <Text mt={2}>Do any of these apply to the interior?</Text>
      </HStack>
      <HStack>
        <Button
          variant="secondary"
          isActive={interior?.includes(InteriorEnum.Stains)}
          onClick={() => handleOnClickArray(interior, setInterior, InteriorEnum.Stains)}
          name="stains"
        >
          STAINS
        </Button>
        <Button
          variant="secondary"
          isActive={interior?.includes(InteriorEnum.RipsOrTears)}
          onClick={() => handleOnClickArray(interior, setInterior, InteriorEnum.RipsOrTears)}
          name="rips-tears"
        >
          RIPS OR TEARS
        </Button>
      </HStack>
      <HStack>
        <Button
          variant="secondary"
          isActive={interior?.includes(InteriorEnum.SmellsOrOdors)}
          onClick={() => handleOnClickArray(interior, setInterior, InteriorEnum.SmellsOrOdors)}
          name="smells"
        >
          SMELLS OR ODORS
        </Button>
        <Button
          variant="secondary"
          isActive={interior?.includes(InteriorEnum.EntertainmentConsoleDamage)}
          onClick={() =>
            handleOnClickArray(interior, setInterior, InteriorEnum.EntertainmentConsoleDamage)
          }
          name="radio-damage"
        >
          ENTERTAINMENT CONSOLE DAMAGE
        </Button>
      </HStack>
      <HStack>
        <Text mt={2}>Has this vehicle been smoked in?</Text>
      </HStack>
      <HStack>
        <Button
          variant="secondary"
          name="yesSmoking"
          isActive={smokedIn}
          onClick={() => setSmokedIn(true)}
        >
          YES
        </Button>
        <Button
          variant="secondary"
          name="noSmoking"
          isActive={smokedIn === false}
          onClick={() => setSmokedIn(false)}
        >
          NO
        </Button>
      </HStack>
      <HStack>
        <Text mt={2}>Are there any lights on the dash (e.g. check engine light)</Text>
      </HStack>
      <HStack>
        <Button
          variant="secondary"
          name="yesLights"
          isActive={lightsOnDash}
          onClick={() => setLightsOnDash(true)}
        >
          YES
        </Button>
        <Button
          variant="secondary"
          name="noLights"
          isActive={lightsOnDash === false}
          onClick={() => setLightsOnDash(false)}
        >
          NO
        </Button>
      </HStack>
      <HStack>
        <Text mt={2}>What would you say is the overall condition of the vehicle?</Text>
      </HStack>
      <HStack>
        <Button
          variant="secondary"
          isActive={overallCondition === OverallConditionEnum.LikeNew}
          onClick={() => setOverallCondition(OverallConditionEnum.LikeNew)}
          name="like-new"
        >
          LIKE NEW
        </Button>
        <Button
          variant="secondary"
          isActive={overallCondition === OverallConditionEnum.SlightlyUsed}
          onClick={() => setOverallCondition(OverallConditionEnum.SlightlyUsed)}
          name="slightly-used"
        >
          SLIGHTLY USED
        </Button>
        <Button
          variant="secondary"
          isActive={overallCondition === OverallConditionEnum.NeedsWork}
          onClick={() => setOverallCondition(OverallConditionEnum.NeedsWork)}
          name="needs-work"
        >
          NEEDS WORK
        </Button>
      </HStack>
      <HStack>
        <Button
          mt={4}
          variant="primary"
          isLoading={isSaving}
          loadingText="SAVE"
          hidden={!showSaveButton}
          onClick={() => {
            onSave(
              new ConditionReportClass(
                condition_report?.id,
                deal.id,
                accidents,
                tires,
                exterior,
                interior,
                smokedIn,
                lightsOnDash,
                overallCondition,
              ),
            );
          }}
          name="save-cr"
        >
          SAVE
        </Button>
      </HStack>
    </VStack>
  );
};

export default ConditionReport;
