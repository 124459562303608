import { Dispatch, SetStateAction } from 'react';

import { Box, Button, IconButton, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';

import { TeamType, useArchivePodMutation } from '../../gql/generated/graphql';
import { Pod, TeamTypeDisplay } from '../../gql/podGql';

import Modal from '../../components/shared/Modal';

import { logger } from '../../libs/Logger';
import { formatDate } from '../../libs/utils';

interface Props {
  pod: Pod;
  refetch: () => Promise<unknown>;
  setSelectedPod: Dispatch<SetStateAction<Pod | null>>;
  onOpenPodModal: () => void;
}

const TeamRow = ({ pod, refetch, setSelectedPod, onOpenPodModal }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [archivePodMutation] = useArchivePodMutation();

  const handleArchive = async () => {
    if (!pod.id) {
      return;
    }

    try {
      await archivePodMutation({
        variables: { id: pod.id, name: pod.name },
      });

      onClose();
      toast.success('Team archived');
      await refetch?.();
      onClose();
    } catch (e) {
      const error = e as Error;
      logger.error('TeamRow.tsx', 'Team Row', error);
      toast.error(`Error archiving team: ${error.message || error}. Please refresh and try again.`);
    }
  };

  return (
    <Tr
      height="55px"
      borderBottom="2px"
      borderX="1px"
      borderColor="backgroundGray"
      bgColor="white"
      cursor="pointer"
      _hover={{ bgColor: 'hoverGray' }}
      key={pod.id}
      onClick={() => {
        setSelectedPod(pod);
        onOpenPodModal();
      }}
      onAuxClick={() => {
        setSelectedPod(pod);
        onOpenPodModal();
      }}
    >
      <Td px="12px" w="1%">
        <Box w="40px" h="40px" borderRadius="8px" bgColor={pod?.color} />
      </Td>
      <Td pl={1} w="19%">
        {pod?.name}
      </Td>
      <Td w="15%"> {formatDate(pod?.created_at?.toString())}</Td>

      <Td w="10%">{pod?.team_type ? TeamTypeDisplay[pod.team_type] : ''}</Td>
      <Td w="15%">{pod?.parent_pod?.name}</Td>
      <Td w="15%">
        {pod?.team_type && [TeamType.D2d, TeamType.Region].includes(pod?.team_type)
          ? pod?.managers?.map((mgr) => mgr?.name).join(', ')
          : pod?.leads?.map((lead) => lead?.name).join(', ')}
      </Td>
      <Td w="10%">{pod?.pod_users?.length}</Td>
      <Td w="10%">
        <IconButton
          icon={<BiTrash />}
          variant="iconHover"
          size="xs"
          color="errorsRed"
          aria-label="Delete Follow-Up"
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        />
        <Modal
          title="Archive Team"
          isOpen={isOpen}
          onClose={onClose}
          leftButtons={
            <Button variant="warning" onClick={onClose}>
              CANCEL
            </Button>
          }
          rightButtons={
            <Button variant="boot" onClick={handleArchive} loadingText="Delete">
              Archive
            </Button>
          }
        >
          <Text align="center">Are you sure you want to archive this team?</Text>
        </Modal>
      </Td>
    </Tr>
  );
};

export default TeamRow;
