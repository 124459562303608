import { FocusEvent } from 'react';

import { Flex, HStack, Input, Text } from '@chakra-ui/react';
import { parseISO } from 'date-fns';

import { formatDateISO } from '../../libs/utils';

interface Props {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const DateForm = ({ startDate, endDate, setStartDate, setEndDate }: Props) => {
  return (
    <>
      <HStack gap={3} mx={4} mt={2}>
        <Flex flexDirection="column">
          <Text>Start Date</Text>
          <Input
            name="startDate"
            type="date"
            bgColor="white"
            defaultValue={formatDateISO(startDate)}
            onChange={(e: FocusEvent<HTMLInputElement>) => setStartDate(parseISO(e.target.value))}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text>End Date</Text>
          <Input
            name="endDate"
            type="date"
            bgColor="white"
            defaultValue={formatDateISO(endDate)}
            onChange={(e: FocusEvent<HTMLInputElement>) => setEndDate(parseISO(e.target.value))}
          />
        </Flex>
      </HStack>
    </>
  );
};

export default DateForm;
