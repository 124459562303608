import { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { BsExclamationOctagonFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Deal, dealHasProblemSwitch, isTitling } from '../../gql/dealGql';
import { Note } from '../../gql/noteGql';

import Modal from '../../components/shared/Modal';
import TextArea from '../../components/shared/TextArea';

import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { NotesContext } from '../../libs/contextLib';
import { getTimezoneStr } from '../../libs/utils';

type Props = {
  deal: Deal;
};

const DealProblem = ({ deal }: Props) => {
  const { dispatch } = useContext(DealContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { notesRefetch } = useContext(NotesContext);

  const [hasProblem, setHasProblem] = useState(deal.has_problem);

  const [dealHasProblemSwitchMutation] = useMutation(dealHasProblemSwitch);

  if (!isTitling(deal?.state) || !deal.id) {
    return null;
  }

  const validationSchema = Yup.object().shape({
    text: Yup.string().required('Please enter a note'),
  });

  const initialValues = new Note(deal.id);

  const label = hasProblem ? 'How was the problem resolved?' : 'Why does this deal have a problem?';
  const placeholder = `Enter a descriptive resolution so a stranger would know what ${
    hasProblem ? 'the problem is and what is needed to resolve it' : 'happened'
  }`;

  const handleSubmit = async (note: Note) => {
    try {
      const {
        data: { dealHasProblemSwitch: res },
      } = await dealHasProblemSwitchMutation({
        variables: {
          id: deal.id,
          has_problem: !hasProblem,
          note: {
            ...note,
            creation_date_tz: getTimezoneStr(),
          },
        },
      });
      dispatch({ type: DealActionsEnum.UpdateDeal, payload: res });
      const { has_problem } = res;
      setHasProblem(has_problem);
      toast.success(has_problem ? 'Problem deal enabled' : 'Problem deal removed');
      notesRefetch();
    } catch {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <IconButton
        icon={<BsExclamationOctagonFill />}
        variant="icon"
        size="xs"
        fontSize={18}
        color={hasProblem ? 'errorsRed' : 'gray'}
        aria-label="Deal Problem"
        onClick={onOpen}
      />
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
          onClose();
        }}
        initialValues={initialValues}
        validateOnBlur={false}
        isInitialValid={false}
      >
        {({ handleChange, handleBlur, values, touched, isValid, errors }) => (
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            showDivider={false}
            title="Deal Problem"
            hasForm
            rightButtons={
              <>
                <Button variant="warning" onClick={onClose}>
                  CANCEL
                </Button>
                <Button type="submit" isDisabled={!isValid} loadingText="SAVING">
                  SAVE
                </Button>
              </>
            }
          >
            <Form>
              <HStack>
                <TextArea
                  label={label}
                  name="text"
                  placeholder={placeholder}
                  value={values.text}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.text && !!errors.text}
                />
              </HStack>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default DealProblem;
