import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useSubscription } from '@apollo/client';
import {
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { MdInfo } from 'react-icons/md';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import { useMediaQuery, useMediaUpdateMutation } from '../../gql/generated/graphql';
import { onNewMessage } from '../../gql/messageGql';

import MediaCenterHeader, { MediaFilter } from './components/MediaCenterHeader';
import MediaModal, {
  ADD,
  BATCH_DELETE,
  MediaModalType,
  PRINT_FILES,
  ShowMediaModalType,
} from './components/MediaModal';
import MediaTable from './components/MediaTable';
import RequiredDocumentsModal from './components/RequiredDocumentsModal';

import { DealMediaStatusEnum, getDealMediaTypeLabel } from '../../constants/media';
import { DealContext } from '../../libs/DealContext';
import { formatPhone } from '../../libs/utils';
import { DealMedia } from '../../types/media';

const MediaCenter = () => {
  const { deal, documentMedia } = useContext(DealContext);
  const { mediaList, internalMediaList, customerMediaList } = documentMedia;

  const [selectedFiles, setSelectedFiles] = useState<DealMedia[]>([]);
  const [filterValue, setFilterValue] = useState<MediaFilter>({
    label: 'All',
    value: DealMediaStatusEnum.All,
  });
  const {
    isOpen: isRequiredDocumentsModalOpen,
    onOpen: onRequiredDocumentsModalOpen,
    onClose: onRequiredDocumentsModalClose,
  } = useDisclosure();

  const [showModal, setShowModal] = useState<MediaModalType>({});
  const {
    isOpen: isMediaModalOpen,
    onOpen: onMediaModalOpen,
    onClose: onMediaModalClose,
  } = useDisclosure();
  const [updateMedia] = useMediaUpdateMutation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(1);

  const dealPhoneNumbers = [formatPhone(deal.customer.phone_number)];
  if (deal.cobuyer) {
    dealPhoneNumbers.push(formatPhone(deal.cobuyer.phone_number));
  }

  const subGql = useSubscription(onNewMessage, {
    variables: {
      dealPhoneNumbers,
    },
    skip: !deal.customer.phone_number,
  });

  const mediaGql = useMediaQuery({
    skip: !deal.id,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dealId: deal.id!,
    },
    fetchPolicy: 'cache-and-network',
  });

  const signatures = useMemo(
    () => mediaList.filter((media) => media.wet_signature_required),
    [mediaList],
  );

  const missingSignatures = useMemo(
    () => signatures.filter((media) => !media.has_wet_signature),
    [signatures],
  );

  const onMediaTypeChange = async (val: DealMedia) => {
    await updateMedia({
      variables: {
        dealId: val.deal_id as number,
        oldKey: val.key as string,
        type: val.type?.toString() === DealMediaStatusEnum.Uncategorized ? null : val.type,
        has_verified_digital_signature: val.has_verified_digital_signature,
        has_wet_signature: val.has_wet_signature,
        is_notarized: val.is_notarized,
        ...(val.metadata ? { metadata: val.metadata } : {}),
      },
    });
    await mediaGql.refetch();
  };

  const updateModalProps = ({ show, ...rest }: ShowMediaModalType) => {
    if (show) {
      onMediaModalOpen();
    } else {
      onMediaModalClose();
    }
    setShowModal(rest);
  };

  useEffect(() => {
    if (subGql.data?.onNewMessage?.resolvedMedia?.length) {
      mediaGql.refetch();
    }
  }, [subGql.data]);

  const filterMedia = useCallback(
    (media: DealMedia[]) => {
      if (filterValue.value === DealMediaStatusEnum.All) {
        return media;
      }
      if (filterValue.value === DealMediaStatusEnum.Missing) {
        return media.filter((x) => !x.key);
      }
      if (filterValue.value === DealMediaStatusEnum.Uncategorized) {
        return media.filter((x) => !x.type);
      }
      if (filterValue.value === DealMediaStatusEnum.Unverified) {
        return media.filter((x) => !x.verified);
      }
      return media.filter((x) => x.verified);
    },
    [filterValue.value],
  );

  return (
    <Box>
      <MediaCenterHeader
        canDelete={!selectedFiles.length}
        filterValue={filterValue}
        onAddMedia={() => updateModalProps({ show: true, content: ADD })}
        onDelete={() =>
          updateModalProps({
            show: true,
            content: BATCH_DELETE,
          })
        }
        onFilter={(value) => setFilterValue(value)}
        onPrint={() => updateModalProps({ show: true, content: PRINT_FILES })}
      />
      <Box borderColor="cadetBlue" borderWidth="1px" borderTop="none">
        {signatures.length ? (
          <Badge
            backgroundColor={missingSignatures.length ? 'azureishWhite' : 'lightGreen.50'}
            display="flex"
            fontSize="sm"
            justifyContent="center"
            py={1}
          >
            <Text
              color={missingSignatures.length ? 'secondary' : 'lightGreen.500'}
              cursor="pointer"
              fontSize="16px"
              fontWeight="medium"
              letterSpacing={1.2}
              whiteSpace="normal"
              textAlign="center"
            >
              {missingSignatures.length ? (
                <>
                  This deal requires physical signatures on
                  <Tooltip
                    hasArrow
                    label={
                      <List>
                        {missingSignatures.map((sig, index) => (
                          <ListItem key={sig.id ?? `missingSignature${index}`}>
                            {getDealMediaTypeLabel(sig.type)}
                          </ListItem>
                        ))}
                      </List>
                    }
                    placement="bottom"
                  >
                    <Text as="span" color="secondary" ml={1} textDecoration="underline">
                      {`${missingSignatures.length} ${
                        missingSignatures.length > 1 ? 'files' : 'file'
                      }`}
                    </Text>
                  </Tooltip>
                </>
              ) : (
                'All Physical Signatures have been completed'
              )}
            </Text>
          </Badge>
        ) : null}

        <SimpleReactLightbox>
          <SRLWrapper>
            <Tabs
              index={selectedTabIndex}
              onChange={(index) => setSelectedTabIndex(index)}
              variant="enclosed"
            >
              <TabList bgColor="cadetBlue" pt={1}>
                <Tab
                  bgColor={selectedTabIndex === 0 ? 'white' : 'gray.200'}
                  borderRadius="8px 8px 0 0"
                  fontWeight="bold"
                  ml={1}
                >
                  Internal Files
                </Tab>
                <Tab
                  bgColor={selectedTabIndex === 1 ? 'white' : 'gray.200'}
                  borderRadius="8px 8px 0 0"
                  fontWeight="bold"
                  ml={1.5}
                >
                  Customer Files
                  {deal?.customer?.address?.state && deal?.financial_info && deal?.car && (
                    <IconButton
                      icon={<MdInfo />}
                      variant="iconHover"
                      size="xs"
                      aria-label="Document Verification"
                      onClick={onRequiredDocumentsModalOpen}
                    />
                  )}
                </Tab>
              </TabList>
              <TabPanels overflow="scroll" minH={400} maxH={620}>
                <TabPanel p={0}>
                  <MediaTable
                    isInternalFile
                    media={filterMedia(internalMediaList)}
                    onSelectMedia={setSelectedFiles}
                    selectedFiles={selectedFiles}
                    onMediaTypeChange={onMediaTypeChange}
                    updateModalProps={updateModalProps}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <MediaTable
                    media={filterMedia(customerMediaList)}
                    onMediaTypeChange={onMediaTypeChange}
                    onSelectMedia={setSelectedFiles}
                    selectedFiles={selectedFiles}
                    updateModalProps={updateModalProps}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </SRLWrapper>
        </SimpleReactLightbox>
      </Box>

      <MediaModal
        isOpen={isMediaModalOpen}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedFiles={selectedFiles}
        dealId={deal.id}
        onClose={onMediaModalClose}
        setSelectedFiles={setSelectedFiles}
        refetchMedia={mediaGql.refetch}
        // TODO: temporary conversion. We should remove all classes and use the generated classes
        allFiles={mediaGql.data?.media as DealMedia[]}
      />

      <RequiredDocumentsModal
        isOpen={isRequiredDocumentsModalOpen}
        onClose={onRequiredDocumentsModalClose}
      />
    </Box>
  );
};

export default MediaCenter;
