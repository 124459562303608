import { useEffect, useMemo, useState } from 'react';

import { FormControl, Stack, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { useGetTwilioNumbersQuery } from '../../../gql/generated/graphql';

import CustomReactSelect from '../../../components/shared/CustomReactSelect';

import { EditUserModalFormikFields } from './EditUserModal';

import { formatTwilioPhone } from '../../../utils/phoneNumbers';

const TwilioNumberSearch = () => {
  const { values, setFieldValue } = useFormikContext<EditUserModalFormikFields>();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string | undefined>(
    formatTwilioPhone(values.phoneNumber),
  );

  const { data, loading } = useGetTwilioNumbersQuery();
  const twilioNumberOptions = useMemo(
    // Format the data from the API to be used in CustomReactSelect
    () =>
      data?.getTwilioNumbers?.map((num) => ({
        label: { name: num?.friendlyName as string, number: num?.number as string },
        value: num?.number as string,
      })) ?? [],
    [data],
  );

  const [options, setOptions] = useState(twilioNumberOptions);

  useEffect(() => setPhoneNumberValue(formatTwilioPhone(values.phoneNumber)), [values.phoneNumber]);

  const handleInputChange = (inputValue: string) =>
    setOptions(
      twilioNumberOptions.filter(
        (num) =>
          // Checks to see if the input value matches the number or name of the Twilio number
          num.label.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          num.label.number.includes(inputValue),
      ),
    );

  return (
    <FormControl>
      <Stack>
        <Text fontSize="sm">Phone Number</Text>
        <CustomReactSelect
          name="phoneNumber"
          filterOption={() => true} // This shows all options since we are filtering manually
          isClearable
          isLoading={loading}
          onChange={(opt) => setFieldValue('phoneNumber', opt?.value)}
          onInputChange={handleInputChange}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          options={options}
          noOptionsMessage={() => (loading ? 'Loading...' : 'No numbers found')}
          value={options.find(({ value }) => value === phoneNumberValue) || null}
          formatOptionLabel={({ label }) => {
            return (
              // Shows the number on one line when the menu is closed
              <Stack direction={menuIsOpen ? 'column' : 'row'}>
                <Text fontWeight="bold">{label.number}</Text>
                <Text fontWeight="light">{label.name}</Text>
              </Stack>
            );
          }}
        />
      </Stack>
    </FormControl>
  );
};

export default TwilioNumberSearch;
