import { useEffect, useState } from 'react';

import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../gql/dealGql';
import { Rate } from '../../../gql/generated/graphql';

import { getTermName } from '../constants';
import { VscPackageTypeEnum } from '../types';

import { formatMoney } from '../../../libs/utils';

interface VscPickerProps {
  options: Rate[];
}

const getUniqueNumberValues = (arr: Rate[], key: 'term_miles' | 'term_months') =>
  arr
    .map((rate) => (rate[key] ? +(rate[key] ?? '') : 0))
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a - b);

const VscPicker = ({ options }: VscPickerProps) => {
  const [terms, setTerms] = useState<number[]>([]);
  const [miles, setMiles] = useState<number[]>([]);
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Deal>();

  const updateField = (name: string, value: number | string | undefined) => {
    setFieldValue(name, value);
    setTimeout(() => setFieldTouched(name, true));
  };

  useEffect(() => {
    setTerms(getUniqueNumberValues(options, 'term_months'));
    setMiles(getUniqueNumberValues(options, 'term_miles'));
  }, [options]);

  return options.length ? (
    <Table>
      <Thead>
        <Tr>
          <Th />
          {terms.map((term) => (
            <Th key={term} textAlign="center">
              {term} Months
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {miles.map((mile, index) => (
          // Default striped variant doesn't allow to customize bgColor
          <Tr key={mile} bgColor={index % 2 === 0 ? 'gray.100' : 'white'}>
            <Td>{mile / 1000}K Miles</Td>
            {terms.map((term) => {
              const rate: Rate | undefined = options.find(
                (o) => +(o.term_miles ?? '') === mile && +(o.term_months ?? '') === term,
              );

              const cost = rate?.deductible?.dealer_cost;
              const sessionId = rate?.session_id ?? undefined;
              const rateId = rate?.deductible?.rate_id ?? undefined;
              const formId = rate?.form_id ?? undefined;
              const parsedCost = cost ? parseInt(cost, 10) : undefined;
              const price = rate?.deductible?.retail_price
                ? parseFloat(rate?.deductible.retail_price)
                : undefined;
              const result = formatMoney(price, { noDecimal: true });
              const termName = getTermName(term, mile);
              const selectedOption =
                values.financial_info.vsc_price === price &&
                termName === values.financial_info.vsc_term;

              return (
                <Td
                  key={mile + term}
                  textAlign="center"
                  p="16px"
                  cursor={result ? 'pointer' : 'unset'}
                  bgColor={selectedOption ? 'cornflowerBlue' : 'unset'}
                  color={selectedOption ? 'white' : 'unset'}
                  _hover={{
                    bgColor: result ? 'cornflowerBlue' : 'unset',
                    color: result ? 'white' : 'unset',
                    opacity: result ? 0.8 : 1,
                  }}
                  onClick={() => {
                    if (!result) {
                      // isDisabled prop doesn't work for some reason
                      return;
                    }
                    updateField('financial_info.vsc_price', price);
                    updateField('financial_info.vsc_cost', parsedCost);
                    updateField('financial_info.vsc_type', VscPackageTypeEnum.Major);
                    updateField('financial_info.vsc_term', termName);
                    updateField('financial_info.pen_vsc_session_id', sessionId);
                    updateField('financial_info.pen_vsc_rate_id', rateId);
                    updateField('financial_info.pen_vsc_form_id', formId);
                  }}
                >
                  {result ?? null}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : (
    <Text my={4} textAlign="center" fontSize={20}>
      No Rates Found
    </Text>
  );
};

export default VscPicker;
