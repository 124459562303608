/* eslint-disable func-names */
import 'react-toggle/style.css';
import * as Yup from 'yup';
import { SchemaLike } from 'yup/lib/types';

import { isEmploymentInfoRequired } from '../../gql/employmentGql';
import {
  EmploymentStatusType,
  MaritalStatusEnum,
  RelationToBuyerEnum,
  StateAbbreviation,
} from '../../gql/generated/graphql';

import { isLocal, isStaging } from '../../config';
import { phoneRegExp, ssnRegExpFull, zipRegExp } from '../../constants/RegExps';
import { dobValidation } from '../../libs/yup-validators/dob';

export const JOB_TITLE_MAX_LENGTH = 20;

export const requirePreviousAddress = (yearsAtHome: number | undefined | null = null) =>
  yearsAtHome !== null && yearsAtHome < 2;
export const requirePreviousEmployment = (yearsAtJob: number | undefined | null = null) =>
  yearsAtJob !== null && yearsAtJob < 2;

const preAddressReq = (then: SchemaLike) =>
  Yup.object()
    .nullable()
    .when('address.years_at_home', {
      is: (years: number) => requirePreviousAddress(years),
      then,
    });

const preEmplReq = (then: SchemaLike) =>
  Yup.object()
    .nullable()
    .when('employment.years_at_job', {
      is: (years: number) => requirePreviousEmployment(years),
      then,
    });

const employmentStrReq = (then: SchemaLike) =>
  Yup.string()
    .nullable()
    .when(['status', 'years_at_job', 'months_at_job'], {
      is: (status: EmploymentStatusType) => isEmploymentInfoRequired(status),
      then,
    });

const addressReg = (person?: string, monthlyPayment?: boolean) =>
  Yup.object().shape({
    address_line: Yup.string().required(`${person || ''}Address is required`),
    address_line_2: Yup.string().optional().nullable(),
    zip: Yup.string()
      .required(`${person || ''}Zip Code is required`)
      .matches(zipRegExp, `Please enter a valid ${person || ''}zip code`),
    city: Yup.string()
      .nullable()
      .required(`${person || ''}City is required`),
    state: Yup.string()
      .nullable()
      .required(`${person || ''}State is required`)
      .test('customer.address.state-select-state', `${person || ''}State is required`, (value) => {
        if (value === 'State') {
          return false;
        }
        return true;
      }),
    county: Yup.string()
      .required(`${person || ''}County is required`)
      .nullable(true),
    monthly_payment: monthlyPayment
      ? Yup.number()
          .nullable()
          .required(`${person || ''}Monthly Payment is required`)
      : Yup.number().nullable(),
    years_at_home: Yup.number()
      .nullable()
      .required(`${person || ''}Years at home is required`),
    months_at_home: Yup.number()
      .nullable()
      .required(`${person || ''}Months at home is required`),
  });

const ContactValidation = (person?: string) =>
  Yup.object().shape({
    relation_to_buyer: Yup.string()
      .nullable()
      .required(`${person || ''}Relation To Buyer is required`),
    first_name: Yup.string()
      .nullable()
      .required(`${person || ''}First Name is required`),
    last_name: Yup.string()
      .required(`${person || ''}Last Name is required`)
      .nullable(),
    phone_number: Yup.string()
      .nullable()
      .required(`${person || ''}Phone Number is required`)
      .matches(phoneRegExp, {
        message: `Please input a valid ${person} phone number`,
      }),
    email: Yup.string()
      .nullable()
      .email(`Please input a valid ${person || ''}email address`)
      .lowercase()
      .notOneOf(['idk@email.com', 'no@email.com'], `If no email, please leave field blank`),
  });

const CreditPersonValidation = (person?: string) =>
  Yup.object().shape({
    first_name: Yup.string().required(`${person || ''}First Name is required`),
    last_name: Yup.string().required(`${person || ''}Last Name is required`),
    phone_number: Yup.string()
      .nullable()
      .required(`${person || ''}Phone Number is required`)
      .matches(phoneRegExp, {
        message: `Please input a valid ${person || ''}phone number`,
        excludeEmptyString: true,
      })
      .test(`phone_test`, `${person || ''}Phone number is required`, function () {
        const { home_phone_number, phone_number } =
          this.options.context?.[person ? 'cobuyer' : 'customer'] || {};

        const phoneNumberRequired = !home_phone_number;
        if (phoneNumberRequired) {
          return !!phone_number;
        }
        return true;
      }),
    home_phone_number: Yup.string()
      .nullable()
      .matches(phoneRegExp, `Please input a valid ${person || ''}phone number`),
    email: Yup.string()
      .email(`Please input a valid ${person || ''}email address`)
      .lowercase()
      .notOneOf(['idk@email.com', 'no@email.com'], `If no email, please leave field blank`)
      .test('customer.no_email', `${person || ''} Email or checkbox required`, function () {
        const p =
          (person === 'Cobuyer '
            ? this.options.context?.cobuyer
            : this.options.context?.customer) || {};
        const { email, no_email } = p;

        const emailIsRequired = !no_email;
        if (emailIsRequired) {
          return !!email;
        }
        return true;
      })
      .nullable(),
    // Skip SSN regex validation for local and staging environments because of Prequalification test SSNs
    ssn:
      isLocal || isStaging
        ? Yup.string().required(`${person || ''}SSN is required`)
        : Yup.string()
            .required(`${person || ''}SSN is required`)
            .matches(ssnRegExpFull, `Please input a valid SSN`),
    dob: dobValidation(person),
    address: addressReg(person, true),
    prev_address: preAddressReq(addressReg(person).nullable()),
    employment: Yup.object().shape({
      status: Yup.string()
        .required(`${person || ''}Employment Status is required`)
        .oneOf(
          Object.values(EmploymentStatusType) as string[],
          `Please select a valid ${person || ''}Employment Status value`,
        ),
      name: employmentStrReq(
        Yup.string()
          .nullable()
          .required(`${person || ''}Employer Name is required`),
      ),
      job_title: employmentStrReq(
        Yup.string()
          .nullable()
          .required(`${person || ''}Job Title is required`)
          .max(JOB_TITLE_MAX_LENGTH, `Please limit to ${JOB_TITLE_MAX_LENGTH} characters`),
      ),
      phone_number: employmentStrReq(
        Yup.string()
          .nullable()
          .required(`${person || ''}Employer Phone Number is required`)
          .matches(phoneRegExp, `Please input a valid phone number`),
      ),
      years_at_job: Yup.number()
        .nullable()
        .required(`${person || ''} Years At Job is required`),
      months_at_job: Yup.number()
        .nullable()
        .required(`${person || ''}Months At Job is required`),
      gross_income: Yup.number()
        .nullable()
        .required(`${person || ''}Gross Income is required`),
      pay_frequency: Yup.string()
        .nullable()
        .required(`${person || ''}Pay Frequency is required`),
    }),
    prev_employment: preEmplReq(
      Yup.object()
        .shape({
          name: Yup.string().required(`${person || ''}Previous Employer Name is required`),
          job_title: Yup.string()
            .required(`${person || ''}Previous job title is required`)
            .max(JOB_TITLE_MAX_LENGTH, `Please limit to ${JOB_TITLE_MAX_LENGTH} characters`),
          years_at_job: Yup.number()
            .nullable()
            .required(`${person || ''}Years at previous job is required`),
          months_at_job: Yup.number()
            .nullable()
            .required(`${person || ''}Months at previous job is required`),
        })
        .nullable(),
    ),
    marital_status: Yup.string()
      .optional()
      .nullable()
      .test('maritalStatusRequired', 'Marital Status is required', (value, context) => {
        if (context?.parent?.address?.state === StateAbbreviation.Wi) {
          return !!value;
        }

        return true;
      }),
    wi_notification_agreement: Yup.boolean().test(
      'cobuyer.wi_notification_agreement',
      'Cobuyer Credit Acknowledgement is required',
      function test(value) {
        const isCobuyer = person === 'Cobuyer ';
        if (!isCobuyer) {
          return true;
        }

        const { address, relation_to_buyer, marital_status } = this.options.context?.cobuyer || {};
        if (
          address?.state !== StateAbbreviation.Wi ||
          relation_to_buyer !== RelationToBuyerEnum.Spouse ||
          ![MaritalStatusEnum.Married, MaritalStatusEnum.Separated].includes(marital_status)
        ) {
          return true;
        }

        return !!value;
      },
    ),
  });

export const validationSchema = Yup.object().shape({
  addCobuyer: Yup.boolean(),
  addContact: Yup.boolean(),
  addSecondContact: Yup.boolean(),
  customer: CreditPersonValidation(),
  cobuyer: Yup.object()
    .nullable()
    .when('addCobuyer', {
      is: true,
      then: CreditPersonValidation('Cobuyer '),
    }),
  contact: Yup.object()
    .nullable()
    .when('addContact', { is: true, then: ContactValidation('Contact ') }),
  second_contact: Yup.object()
    .nullable()
    .when('addSecondContact', {
      is: true,
      then: ContactValidation('Second Contact '),
    }),
  financial_info: Yup.object()
    .shape({
      buyer_not_lessee: Yup.boolean().optional().nullable(),
    })
    .optional()
    .nullable(),
});
