import { useContext, useMemo, useState } from 'react';

import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Deal, isDealInOrPastFunded, isUneditable } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import ConfirmEditModal from '../ConfirmEditModal/ConfirmEditModal';
import RequestPayoffButton from '../EstimateForm/RequestPayoffButton';
import { TotalPayoff } from '../TotalPayoff/TotalPayoff';
import DatePicker from '../shared/DatePicker';
import Input from '../shared/Input';
import LenderAutocomplete from '../shared/LenderAutocomplete';
import LienholderSelects from '../shared/LienholderSelects';
import Modal from '../shared/Modal';
import NumberInput from '../shared/NumberInput';
import { RadioGroup } from '../shared/RadioGroup';
import Tooltip from '../shared/Tooltip';

import { PermissionEnum } from '../../constants/permissions';
import { DealContext } from '../../libs/DealContext';
import { AbilityContext, ModalContext } from '../../libs/contextLib';
import { passValuesToSchema } from '../../libs/utils';
import { validateFuture } from '../../libs/yup-validators/dates';
import { payoffAmountValidation } from '../../libs/yup-validators/payoff';
import DisabledLienholderModal from '../../pages/DealDetail/DisabledLienholderModal';
import { getFieldLabel } from '../../utils/deals';

interface PayoffInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: Deal) => void;
}

const PayoffInformationModal = ({ isOpen, onClose, onSubmit }: PayoffInformationModalProps) => {
  const { deal, isRecalculatingPayoff, setIsRecalculatingPayoff, isPayoffRequested } =
    useContext(DealContext);
  const { modals, setModal } = useContext(ModalContext);
  const abilities = useContext(AbilityContext);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showVirtualLienholderSelect, setShowVirtualLienholderSelect] = useState<boolean>(false);

  const canEditFundedOnwards =
    abilities.has(PermissionEnum.EditFundedOnwards) && isDealInOrPastFunded(deal.state);
  const isLocked = canEditFundedOnwards ? !isEditing : isUneditable(deal.state);
  const isRefi = deal.type === DealType.Refi;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string(),
        car: Yup.object().shape({
          payoff: Yup.object().shape({
            lienholder_name: Yup.string().optional().nullable(),
            lienholder_slug: isRefi
              ? Yup.string().nullable()
              : Yup.string().nullable().required('Lienholder is required'),
            lender_name: isRefi
              ? Yup.string().nullable().required('Lender is required')
              : Yup.string().nullable(),
            account_number: Yup.string().nullable().optional(),
            vehicle_payoff: payoffAmountValidation,
            good_through_date: Yup.date()
              .optional()
              .nullable(true)
              .test('futureDate', 'Date should be in the future', validateFuture),
            payoff_includes_sales_tax: Yup.boolean().required('Please select an option'),
            sales_tax_from_payoff_entered_manually: Yup.boolean().nullable(),
            sales_tax_from_payoff: Yup.number()
              .nullable()
              .when('sales_tax_from_payoff_entered_manually', {
                is: true,
                then: Yup.number().required('Please enter a value').nullable(),
              }),
            next_payment_date: Yup.date().optional().nullable(true),
          }),
        }),
      }),
    [isRefi],
  );

  return (
    <Modal title="Payoff Information" size="lg" isOpen={isOpen} onClose={onClose}>
      <Formik
        validate={(values) => passValuesToSchema(values, validationSchema)}
        validateOnMount
        onSubmit={(values: Deal) => {
          onSubmit({ ...values });
        }}
        initialValues={deal}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue, isValid, errors }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              <Stack spacing="10px">
                {isRefi ? (
                  <LenderAutocomplete isLocked={isLocked} />
                ) : (
                  <LienholderSelects
                    showVirtualLienholderSelect={showVirtualLienholderSelect}
                    setShowVirtualLienholderSelect={setShowVirtualLienholderSelect}
                    isLocked={isLocked}
                    direction="column"
                  />
                )}
                <Input
                  name="car.payoff.account_number"
                  label="Account Number"
                  isDisabled={isLocked}
                />
                <TotalPayoff
                  isDisabled={isLocked || isPayoffRequested}
                  topLabel
                  isRecalculating={isRecalculatingPayoff}
                  setIsRecalculating={setIsRecalculatingPayoff}
                />
                <HStack spacing="auto">
                  <Text fontWeight="normal" fontSize="14px" mt={2}>
                    Is sales tax included in total payoff?
                  </Text>
                  <RadioGroup
                    size="sm"
                    value={values.car.payoff.payoff_includes_sales_tax}
                    onChange={(val) => {
                      const newPayoffIncludesSalesTax = val === 'true';
                      setFieldValue(
                        'car.payoff.payoff_includes_sales_tax',
                        newPayoffIncludesSalesTax,
                      );
                      if (!newPayoffIncludesSalesTax) {
                        setFieldValue('car.payoff.sales_tax_from_payoff_entered_manually', false);
                      }
                    }}
                    isDisabled={isLocked || isPayoffRequested}
                  />
                </HStack>
                {values.car.payoff.payoff_includes_sales_tax && (
                  <HStack spacing="auto">
                    <Text fontWeight="normal" fontSize="14px">
                      Do you know the sales tax amount?
                    </Text>
                    <RadioGroup
                      size="sm"
                      value={values.car.payoff.sales_tax_from_payoff_entered_manually}
                      onChange={(val) => {
                        const newSalesTaxFromPayoffEnteredManually = val === 'true';
                        setFieldValue(
                          'car.payoff.sales_tax_from_payoff_entered_manually',
                          newSalesTaxFromPayoffEnteredManually,
                        );
                      }}
                      isDisabled={isLocked || isPayoffRequested}
                    />
                  </HStack>
                )}
                {values.car.payoff.sales_tax_from_payoff_entered_manually && (
                  <NumberInput
                    name="car.payoff.sales_tax_from_payoff"
                    label="Included Sales Tax"
                    isMoney
                    isDisabled={isLocked || isPayoffRequested}
                  />
                )}
                <DatePicker
                  name="car.payoff.good_through_date"
                  topLabel="Good Through Date"
                  valueFormat="dateUTC"
                  isDisabled={isPayoffRequested}
                />
              </Stack>
              <HStack justifyContent="right" pt={2}>
                <RequestPayoffButton size="sm" />
              </HStack>
              <Stack>
                <NumberInput
                  name="car.payoff.old_lease_payment"
                  label={getFieldLabel('oldPayment', values.type)}
                  isMoney
                  isDisabled={isLocked}
                />
                <DatePicker
                  name="car.payoff.next_payment_date"
                  topLabel="Next Payment Date"
                  valueFormat="dateUTC"
                />

                <NumberInput
                  name="car.payoff.remaining_payments"
                  label="Payments Remaining"
                  isDisabled={isLocked}
                />
                <DatePicker
                  name="car.payoff.maturity_date"
                  topLabel="Maturity Date"
                  valueFormat="dateUTC"
                />
              </Stack>
            </Form>
            <HStack spacing="auto" mt={5} mb={1}>
              <Button
                variant="warning"
                onClick={() => {
                  setIsEditing(false);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <HStack spacing={1}>
                <Button
                  variant="secondary"
                  hidden={!canEditFundedOnwards || isEditing}
                  onClick={() => {
                    setModal({ ConfirmEdit: true });
                  }}
                >
                  Edit
                </Button>
                <ConfirmEditModal
                  isOpen={modals.ConfirmEdit}
                  onConfirm={() => {
                    setIsEditing(true);
                  }}
                />
                <Tooltip errors={errors?.car?.payoff}>
                  <Button
                    variant="primary"
                    isDisabled={!isValid || isRecalculatingPayoff}
                    hidden={canEditFundedOnwards && !isEditing}
                    onClick={() => {
                      setIsEditing(false);
                      onSubmit(values);
                    }}
                  >
                    Save
                  </Button>
                </Tooltip>
              </HStack>
            </HStack>

            <DisabledLienholderModal />
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default PayoffInformationModal;
