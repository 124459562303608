import { useContext, useState } from 'react';

import { Box, Tab, TabList, Table, Tabs, Tbody, Tooltip } from '@chakra-ui/react';
import { MdAccountCircle } from 'react-icons/md';

import { getFullNameWithMiddle } from '../../gql/customerGql';
import { DealStateEnum } from '../../gql/dealGql';

import CreditApplication from '../CreditApplication/CreditApplication';
import CustomerContact from '../CustomerContact';
import { InfoCardValue, InfoRow } from '../InfoCard/InfoCardV2';
import MaskedSsnCustomerInfo from '../MaskedSsn/MaskedSsnCustomerInfo';
import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';

import './CustomerInfo.css';

import { DealContext } from '../../libs/DealContext';
import { ModalContext } from '../../libs/contextLib';

export type IBuyerInfo = {
  Name?: InfoCardValue;
  'Address Line 1'?: InfoCardValue;
  'Apt., Suite, Unit #'?: InfoCardValue;
  City?: InfoCardValue;
  State?: InfoCardValue;
  Zip?: InfoCardValue;
  Phone?: InfoCardValue;
  SSN?: InfoCardValue;
  Email?: InfoCardValue;
};

type ICobuyerInfo = {
  'CB Name'?: InfoCardValue;
  'CB Address Line 1'?: InfoCardValue;
  'CB Apt., Suite, Unit #'?: InfoCardValue;
  'CB City'?: InfoCardValue;
  'CB State'?: InfoCardValue;
  'CB Zip'?: InfoCardValue;
  'CB Phone'?: InfoCardValue;
  'CB Email'?: InfoCardValue;
};

const CustomerInfo = () => {
  const { deal } = useContext(DealContext);
  const { modals, setModal } = useContext(ModalContext);

  const getEmailComponent = (email?: string, auth0Id?: string) => (
    <Box display="flex" alignItems="center">
      <Box mr={1}>{email}</Box>
      {email && auth0Id ? (
        <Tooltip label="This email has a linked web account" placement="top" hasArrow>
          <Box minW={4}>
            <MdAccountCircle color="#32CDD4" size={24} />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );

  const buyerInfo: IBuyerInfo = {
    Name: getFullNameWithMiddle(deal.customer),
    'Address Line 1': deal.customer?.address?.address_line,
    'Apt., Suite, Unit #': deal.customer?.address?.address_line_2,
    City: deal.customer?.address?.city,
    State: deal.customer?.address?.state,
    Zip: deal.customer?.address?.zip,
    Phone: deal.customer?.phone_number,
    SSN: { render: <MaskedSsnCustomerInfo customer={deal.customer} />, value: deal.customer?.ssn },
    Email: {
      render: getEmailComponent(deal.customer?.email, deal.customer?.auth0_id),
      value: deal.customer?.email,
    },
  };

  let coBuyerInfo: ICobuyerInfo = {};
  if (deal.cobuyer) {
    coBuyerInfo = {
      'CB Name': getFullNameWithMiddle(deal.cobuyer),
      'CB Address Line 1': deal.cobuyer.address?.address_line,
      'CB Apt., Suite, Unit #': deal.cobuyer.address?.address_line_2,
      'CB City': deal.cobuyer.address?.city,
      'CB State': deal.cobuyer.address?.state,
      'CB Zip': deal.cobuyer.address?.zip,
      'CB Phone': deal.cobuyer.phone_number,
      'CB Email': {
        render: getEmailComponent(deal.cobuyer.email, deal.cobuyer.auth0_id),
        value: deal.cobuyer.email,
      },
    };
  }

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const selectedInfo: IBuyerInfo | ICobuyerInfo = selectedTabIndex === 0 ? buyerInfo : coBuyerInfo;

  return (
    <>
      <Card variant="roundedNoMargin">
        <CardHeaderV2
          title="Customer Info"
          onClick={
            deal.state === DealStateEnum.SoftClose
              ? undefined
              : () => setModal({ CustomerInfo: true })
          }
        />
        <Tabs
          index={selectedTabIndex}
          onChange={(index) => setSelectedTabIndex(index)}
          variant="enclosed"
        >
          <TabList bgColor="queenBlue" pt={1}>
            <Tab
              bgColor={selectedTabIndex === 0 ? 'white' : 'gray.200'}
              borderRadius="8px 8px 0 0"
              fontWeight="bold"
              ml={1}
              px={8}
            >
              Buyer
            </Tab>
            {deal.cobuyer && (
              <Tab
                bgColor={selectedTabIndex === 1 ? 'white' : 'gray.200'}
                borderRadius="8px 8px 0 0"
                fontWeight="bold"
                ml={1.5}
                px={8}
              >
                Co-Buyer
              </Tab>
            )}
          </TabList>
        </Tabs>
        <Table mt={3}>
          <Tbody>
            {Object.entries(selectedInfo).map(([key, value]) => (
              <InfoRow key={key} name={key} value={value} />
            ))}
          </Tbody>
        </Table>
        <CustomerContact />
      </Card>

      {modals.CustomerInfo && <CreditApplication showInsideModal />}
    </>
  );
};

export default CustomerInfo;
