import { useContext } from 'react';

import { Box, Button } from '@chakra-ui/react';
import { MdPhone } from 'react-icons/md';

import { useCallOutMutation } from '../../gql/generated/graphql';

import { DealContext } from '../../libs/DealContext';
import { formatPhone } from '../../libs/utils';

interface CallWidgetProps {
  widgetOpen: boolean;
  toggleCallWidget: () => void;
}

const CallWidget = ({ widgetOpen, toggleCallWidget }: CallWidgetProps) => {
  const { deal } = useContext(DealContext);
  const [callOutbound] = useCallOutMutation();

  const onCall = (phoneNum: string) => {
    callOutbound({
      variables: {
        calledNumber: formatPhone(phoneNum),
        id: deal.id,
      },
    });

    toggleCallWidget();
  };

  return (
    <Box pos="relative">
      {widgetOpen && (
        <>
          <Box position="absolute">
            <Box
              borderLeft="20px solid transparent"
              borderRight="20px solid transparent"
              borderBottomColor="oxfordBlue"
              borderBottomWidth="20px"
            />
            <Box
              borderLeft="20px solid transparent"
              borderRight="20px solid transparent"
              borderBottom="20px solid white"
              position="absolute"
              top="1px"
              zIndex={1000}
            />
            <Box bgColor="white" pos="absolute" width="20px" />
          </Box>
          <Box
            bgColor="white"
            borderColor="oxfordBlue"
            borderRadius="4px"
            borderWidth="1px"
            boxShadow="3px 3px 2px 0px rgb(0 0 0 / 20%)"
            display="flex"
            flexDir="column"
            gap={3}
            p={3}
            position="absolute"
            top="16px"
            transform="translateX(-50%)"
            zIndex={100}
          >
            {deal.cobuyer && <strong color="black">Buyer</strong>}
            <Button
              leftIcon={<MdPhone />}
              size="lgWithIconLeft"
              variant="secondary"
              onClick={() => onCall(deal.customer.phone_number)}
            >
              CELL PHONE: {deal.customer.phone_number}
            </Button>
            {deal.customer.home_phone_number && (
              <Button
                leftIcon={<MdPhone />}
                size="lgWithIconLeft"
                variant="secondary"
                onClick={() => onCall(deal.customer.home_phone_number)}
              >
                HOME PHONE: {deal.customer.home_phone_number}
              </Button>
            )}
            {deal.cobuyer && <strong color="black">Cobuyer</strong>}
            {deal.cobuyer && deal.cobuyer.phone_number && (
              <Button
                leftIcon={<MdPhone />}
                size="lgWithIconLeft"
                variant="secondary"
                onClick={() => deal.cobuyer && onCall(deal.cobuyer.phone_number)}
              >
                CELL PHONE: {deal.cobuyer.phone_number}
              </Button>
            )}
            {deal.cobuyer && deal.cobuyer.home_phone_number && (
              <Button
                leftIcon={<MdPhone />}
                size="lgWithIconLeft"
                variant="secondary"
                onClick={() => deal.cobuyer && onCall(deal.cobuyer.home_phone_number)}
              >
                HOME PHONE: {deal.cobuyer.home_phone_number}
              </Button>
            )}
            {deal.contact && <strong color="black">Contact</strong>}
            {deal.contact && deal.contact?.phone_number && (
              <Button
                leftIcon={<MdPhone />}
                size="lgWithIconLeft"
                variant="secondary"
                onClick={() => deal.contact?.phone_number && onCall(deal.contact.phone_number)}
              >
                CELL PHONE: {deal.contact?.phone_number}
              </Button>
            )}
            {deal.second_contact && <strong color="black">Second Contact</strong>}
            {deal.second_contact && deal.second_contact?.phone_number && (
              <Button
                leftIcon={<MdPhone />}
                size="lgWithIconLeft"
                variant="secondary"
                onClick={() =>
                  deal.second_contact?.phone_number && onCall(deal.second_contact.phone_number)
                }
              >
                CELL PHONE: {deal.second_contact?.phone_number}
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CallWidget;
