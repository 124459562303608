import * as Yup from 'yup';

import { DealStateEnum } from '../../gql/dealGql';
import { DealMediaTypeEnum } from '../../gql/generated/graphql';

import {
  getTitleReceivedDateValidation,
  validateTodayOrEarlier,
} from '../../libs/yup-validators/dates';
import { CustomDates } from '../../types/dealDates';

export const getDealMediaValidationSchema = (customDates?: CustomDates | null) =>
  Yup.object().shape({
    fileName: Yup.string().optional().nullable().required('File Name is required'),
    type: Yup.string()
      .optional()
      .nullable()
      .required('Media Type is required')
      .oneOf(Object.values(DealMediaTypeEnum), 'Invalid Media Type'),
    metadata: Yup.object().optional().nullable(),
    // Optional date fields for specific media types.
    [DealStateEnum.TitleReceived]: getTitleReceivedDateValidation(customDates),
    wet_sig_received_date: Yup.date()
      .optional()
      .nullable()
      .test('testCantBeFuture', `Can't be in the future`, validateTodayOrEarlier),
  });

export const getValidationSchema = (customDates?: CustomDates | null) =>
  Yup.object().shape({
    dealMedia: Yup.array().of(getDealMediaValidationSchema(customDates)),
  });

export interface FileDetails {
  fileExtension: string;
  sourceFileName: string;
  friendlyName: string;
  isPdf: boolean;
}

export const acceptedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
const acceptedFileExtensions = ['pdf', 'png', 'jpg', 'jpeg'];

export const parseFilename = (name: string): FileDetails => {
  if (!name) {
    return {
      fileExtension: '',
      sourceFileName: '',
      friendlyName: '',
      isPdf: false,
    };
  }

  const dealIdSeparatorIndex = name.indexOf('/');
  const filenameSeparatorIndex = name.indexOf('__');
  const fileNameStartIndex =
    filenameSeparatorIndex === -1 ? dealIdSeparatorIndex + 1 : filenameSeparatorIndex + 2;
  const sourceFileName = decodeURIComponent(name.substring(fileNameStartIndex));

  const extensionSeparatorIndex = sourceFileName.lastIndexOf('.');
  const extension =
    extensionSeparatorIndex === -1 ? '' : sourceFileName.substring(extensionSeparatorIndex + 1);

  const fileExtension = acceptedFileExtensions.includes(extension.toLowerCase()) ? extension : '';
  const friendlyName =
    extensionSeparatorIndex === -1
      ? sourceFileName
      : sourceFileName.substring(0, extensionSeparatorIndex);
  const isPdf = fileExtension.toLowerCase() === 'pdf'; // will not work for MMS with no extension

  return {
    fileExtension,
    sourceFileName,
    friendlyName,
    isPdf,
  };
};
