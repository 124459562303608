import { ChangeEvent, Dispatch } from 'react';

import { Input } from '@chakra-ui/react';

import { formatDateISO } from '../../../../libs/utils';
import { FiltersAction, FiltersActionKind } from '../../utils';

type DateFilterProps = {
  actionKind:
    | FiltersActionKind.SET_FROM_DATE
    | FiltersActionKind.SET_TO_DATE
    | FiltersActionKind.SET_CLOSED_FROM_DATE
    | FiltersActionKind.SET_CLOSED_TO_DATE
    | FiltersActionKind.SET_TITLE_FROM_DATE
    | FiltersActionKind.SET_TITLE_TO_DATE;
  dispatch: Dispatch<FiltersAction>;
  value?: Date;
};

const DateFilter = ({ dispatch, actionKind, value }: DateFilterProps) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const payload = e.target.value ? new Date(e.target.value) : undefined;
    dispatch({ type: actionKind, payload });
  };

  return (
    <Input
      type="date"
      w="135px"
      borderRadius={2}
      bgColor="white"
      onChange={handleInputChange}
      value={value ? formatDateISO(value) : ''}
    />
  );
};

export default DateFilter;
