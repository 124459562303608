import React, { SyntheticEvent, useContext } from 'react';

import { Box, Icon, Image, useDisclosure } from '@chakra-ui/react';
import { MdModeComment } from 'react-icons/md';
import { VscFilePdf } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';

import { Deal, DealStateEnum, DealStateLabelMap } from '../../../gql/dealGql';
import { MessageData } from '../../../gql/generated/graphql';

import NotificationToast from './NotificationToast';

import { TextWidgetContext } from '../../../libs/contextLib';
import { formatPhoneWithParentheses, getTimeString } from '../../../libs/utils';
import { getActiveConversation } from '../../../utils/messages';
import MultipleDealsModal from '../../MultipleDealsModal';

type TextNotificationProps = {
  messageData: MessageData;
  onClose: () => void;
};

const TextNotificationToast = ({ messageData, onClose }: TextNotificationProps) => {
  const history = useHistory();
  const { setActiveConversation, setTextDealId } = useContext(TextWidgetContext);

  const {
    isOpen: isOpenMultipleDealModal,
    onOpen: onOpenMultipleDealModal,
    onClose: onCloseMultipleDealModal,
  } = useDisclosure();

  const { message, deals, messageDetail } = messageData;

  const title = `${messageDetail?.shortNames ? messageDetail.shortNames[0] : ''}${
    messageDetail?.shortNames && messageDetail.shortNames.length > 1
      ? `/${messageDetail.shortNames[1]}`
      : ''
  } - ${formatPhoneWithParentheses(message?.from ?? '')}`;

  const subTitle = `${
    messageDetail?.dealStates
      ? DealStateLabelMap[messageDetail?.dealStates[0] as DealStateEnum]
      : ''
  }
  ${
    messageDetail?.dealStates && messageDetail?.dealStates.length > 1
      ? `/${DealStateLabelMap[messageDetail?.dealStates[1] as DealStateEnum]}`
      : ''
  }`;

  const { customDetailsClick, details } =
    deals?.length === 1
      ? {
          customDetailsClick: () => {
            if (deals[0]) {
              const deal = deals[0];
              if (deal && message) {
                getActiveConversation(deal, message, setActiveConversation);
              }
              setTextDealId(deal?.id ?? null);
            }
          },
          details: (
            <>
              {message?.body ?? ''}
              {message?.mediaListObj?.data?.length &&
                message.mediaListObj?.data?.map((media) => (
                  <Image mt={2} maxH="120px" src={media?.url ?? ''} />
                ))}
            </>
          ),
        }
      : {
          customDetailsClick: (e: SyntheticEvent) => {
            e.stopPropagation();
            if (messageDetail?.dealIds && messageDetail.dealIds.length === 1) {
              history.push(`/deals/${messageDetail?.dealIds[0]}`);
              onClose();
            } else if (messageDetail?.dealIds && messageDetail.dealIds.length > 1) {
              onOpenMultipleDealModal();
            }
          },
          details: (
            <>
              {message?.body ?? ''}
              {message?.mediaListObj?.data?.length
                ? message?.mediaListObj?.data?.map((media) => {
                    if (media?.url && media?.contentType === 'application/pdf') {
                      return (
                        <a href={media.url} target="_blank" rel="noreferrer">
                          <Icon as={VscFilePdf} boxSize={12} />
                        </a>
                      );
                    }
                    if (media?.url) {
                      return (
                        <Box>
                          <Image src={media.url} minH="150px" maxH="250px" />
                        </Box>
                      );
                    }
                    return null;
                  })
                : null}
            </>
          ),
        };

  return (
    <>
      <NotificationToast
        slideFadeIn={
          message?.body
            ? message.body.length > 0
            : message?.mediaListObj?.data
            ? message.mediaListObj.data.length > 0
            : false
        }
        title={title}
        titleIcon={MdModeComment}
        titleIconColor="green"
        rightTitle={message ? <Box>{getTimeString(message)}</Box> : null}
        subTitle={subTitle}
        details={details}
        onClose={onClose}
        customDetailsClick={customDetailsClick}
      />

      <MultipleDealsModal
        deals={deals as unknown as Deal[]}
        isOpen={isOpenMultipleDealModal}
        onClose={onCloseMultipleDealModal}
      />
    </>
  );
};

export default TextNotificationToast;
