import { MouseEventHandler } from 'react';

import { Box, Image } from '@chakra-ui/react';

type LeaseEndLogoProps = {
  onClick: MouseEventHandler<HTMLDivElement>;
};
const LeaseEndLogo = ({ onClick }: LeaseEndLogoProps) => (
  <Box w={{ base: '150px', sm: '136px' }} cursor="pointer" onClick={onClick} onAuxClick={onClick}>
    <Image src="/2020_Goode_Logo_All_Variations_LE_FC.png" alt="Lease End" />
  </Box>
);

export default LeaseEndLogo;
