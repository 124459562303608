import { MouseEventHandler, useContext, useEffect, useState } from 'react';

import { Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BiCheckCircle, BiEditAlt, BiXCircle } from 'react-icons/bi';

import { Deal } from '../../../../gql/dealGql';

import NumberInput from '../../../shared/NumberInput';

import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { formatMoney } from '../../../../libs/utils';
import { DebouncedSave } from '../../DealInfoBuyoutForm';
import { paymentOptionsTypes } from '../../types';

type ReserveProps = {
  debouncedSave: DebouncedSave;
  noProductOption: paymentOptionsTypes;
};

const Reserve = ({
  debouncedSave,
  noProductOption: { profit: noProductsProfit, reserve: noProductsReserve },
}: ReserveProps) => {
  const { deal, dispatch, setUserChangedSomething } = useContext(DealContext);

  const { values, errors, setFieldValue } = useFormikContext<Deal>();

  const [isEditing, setIsEditing] = useState(false);

  const handleSaveReserveClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();

    setUserChangedSomething(true);
    // This triggers the recalculation of the Payment Options in the hook `usePaymentOptions`
    // When the result comes back, `PaymentOptions` component updates the `reserve` value
    // 0 is allowed as a value. `null` indicates that the Bank reserve calculation should be used
    setFieldValue(
      'financial_info.user_entered_reserve',
      values.financial_info.tempUserEnteredReserve ?? null,
    );

    debouncedSave();
    setIsEditing(false);
  };

  useEffect(() => {
    setFieldValue('financial_info.user_entered_reserve', null);
  }, [values.financial_info.bank]);

  useEffect(() => {
    if (deal.financial_info.tempUserEnteredReserve !== noProductsReserve) {
      // Keep in sync the context value with the formik value so it can be used in the auto-save logic
      // Needed because the context value because is overwritten by other dispatches
      dispatch({
        type: DealActionsEnum.DeepUpdateDeal,
        payload: {
          financial_info: {
            tempUserEnteredReserve: noProductsReserve,
          },
        },
      });
    }
  }, [deal.financial_info.tempUserEnteredReserve, noProductsReserve]);

  if (isEditing) {
    return (
      <Tooltip
        hasArrow
        placement="top"
        aria-label="Bank Fees"
        label={`- ${formatMoney(values.financial_info?.bank_fees)} (Bank Fees)`}
      >
        <Flex alignItems="center">
          <NumberInput autoFocus isMoney name="financial_info.tempUserEnteredReserve" />
          <Flex alignItems="center" ml={1}>
            <IconButton
              icon={<BiXCircle />}
              variant="iconHover"
              aria-label="Cancel"
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(false);
              }}
              size="xs"
            />
            <IconButton
              icon={<BiCheckCircle />}
              variant="iconHover"
              size="xs"
              aria-label="Save Reserve"
              isDisabled={!!errors.financial_info?.tempUserEnteredReserve}
              onClick={handleSaveReserveClick}
            />
          </Flex>
        </Flex>
      </Tooltip>
    );
  }

  // We show the `profit` unless the user clicks edit and then we show the input field with the `reserve` value
  return (
    <Flex alignItems="center">
      <Text fontWeight="normal">{formatMoney(noProductsProfit)}</Text>
      <IconButton
        icon={<BiEditAlt />}
        variant="iconHover"
        size="xs"
        aria-label="Edit Reserve"
        onClick={(e) => {
          e.stopPropagation();
          setIsEditing(true);
        }}
      />
    </Flex>
  );
};

export default Reserve;
