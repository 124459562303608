import { useEffect, useState } from 'react';

import { groupBy } from 'lodash';

import { DealMediaTypeEnum, Maybe } from '../../../../../gql/generated/graphql';

import { getDealMediaTypeLabel } from '../../../../../constants/media';
import { DealMedia } from '../../../../../types/media';
import { parseFilename } from '../../../utils';

export interface MediaCenterSorting {
  order: 'ASC' | 'DESC';
  value: 'category' | 'file_name';
}

export interface MediaTableItem extends DealMedia {
  fileExtension: string;
  filePath: string;
  friendlyName: string;
  group: string;
  isPdf: boolean;
  isUploaded: boolean;
  sourceFileName: string;
}

const replaceMap = new Map([
  // PowerOfAttorney
  [DealMediaTypeEnum.StateSpecificPowerOfAttorney, DealMediaTypeEnum.PowerOfAttorney],
  [DealMediaTypeEnum.SecurePowerOfAttorney, DealMediaTypeEnum.PowerOfAttorney],
  [DealMediaTypeEnum.RegistrationSpecificPowerOfAttorney, DealMediaTypeEnum.PowerOfAttorney],
  [DealMediaTypeEnum.PowerOfAttorneyCobuyer, DealMediaTypeEnum.PowerOfAttorney],
  [DealMediaTypeEnum.StateSpecificPowerOfAttorneyCobuyer, DealMediaTypeEnum.PowerOfAttorney],
  [DealMediaTypeEnum.CobuyerSecurePowerOfAttorney, DealMediaTypeEnum.PowerOfAttorney],
  [DealMediaTypeEnum.CobuyerRegistrationSpecificPowerOfAttorney, DealMediaTypeEnum.PowerOfAttorney],
  [DealMediaTypeEnum.StateAndLienholderSpecificPowerOfAttorney, DealMediaTypeEnum.PowerOfAttorney],
  // OdoDoc
  [DealMediaTypeEnum.LeaseendOdoDoc, DealMediaTypeEnum.OdoDoc],
  [DealMediaTypeEnum.LeaseendOdoDocCobuyer, DealMediaTypeEnum.OdoDoc],
  [DealMediaTypeEnum.LeaseendOdoDocJoint, DealMediaTypeEnum.OdoDoc],
  [DealMediaTypeEnum.StateSpecificOdoDoc, DealMediaTypeEnum.OdoDoc],
  [DealMediaTypeEnum.CobuyerStateSpecificOdoDoc, DealMediaTypeEnum.OdoDoc],
  [DealMediaTypeEnum.HondaSpecificOdometerDoc, DealMediaTypeEnum.OdoDoc],
  [DealMediaTypeEnum.AcuraSpecificOdometerDoc, DealMediaTypeEnum.OdoDoc],
  // StateSpecificDocs
  [DealMediaTypeEnum.ColoradoSupplementalIdentificationClause, DealMediaTypeEnum.StateSpecificDocs],
  [
    DealMediaTypeEnum.ColoradoCobuyerSupplementalIdentificationClause,
    DealMediaTypeEnum.StateSpecificDocs,
  ],
  [DealMediaTypeEnum.ColoradoJointTenancy, DealMediaTypeEnum.StateSpecificDocs],
  [
    DealMediaTypeEnum.ConnecticutElectronicRegistrationAndTitleAgreement,
    DealMediaTypeEnum.StateSpecificDocs,
  ],
  // Others
  [DealMediaTypeEnum.ProofOfResidence2, DealMediaTypeEnum.ProofOfResidence],
  [DealMediaTypeEnum.StateSpecificPurchaseOrder, DealMediaTypeEnum.PurchaseOrder],
  [DealMediaTypeEnum.CaliforniaStatementOfFacts, DealMediaTypeEnum.TitleApplication],
  [DealMediaTypeEnum.CaliforniaStatementOfFactsCobuyer, DealMediaTypeEnum.TitleApplication],
  [
    DealMediaTypeEnum.ElectronicSignatureAttestation,
    DealMediaTypeEnum.ElectronicSignatureVerification,
  ],
  [
    DealMediaTypeEnum.ElectronicSignatureCertification,
    DealMediaTypeEnum.ElectronicSignatureVerification,
  ],
]);

const getMediaCategory = (type: Maybe<DealMediaTypeEnum>) => {
  if (!type) {
    return undefined;
  }
  return replaceMap.get(type) || type;
};

const mapGroupMedia = (media: DealMedia): MediaTableItem => {
  const { sourceFileName, fileExtension, isPdf, friendlyName } = parseFilename(media.key ?? '');
  const isUploaded = !!media.id;
  const filePath = decodeURIComponent(media.key ?? '');

  return {
    ...media,
    group: getDealMediaTypeLabel(getMediaCategory(media.type)) || 'Uncategorized',
    filePath,
    isUploaded,
    sourceFileName,
    fileExtension,
    isPdf,
    friendlyName,
  };
};

const sortMediaRowsFactory = (sort: MediaCenterSorting) => (a: DealMedia, b: DealMedia) => {
  if (sort.value === 'category') {
    if (!a.type) {
      return 1;
    }

    if (!b.type) {
      return -1;
    }

    return sort.order === 'DESC'
      ? getDealMediaTypeLabel(getMediaCategory(a.type)) >
        getDealMediaTypeLabel(getMediaCategory(b.type))
        ? 1
        : -1
      : getDealMediaTypeLabel(getMediaCategory(a.type)) >
        getDealMediaTypeLabel(getMediaCategory(b.type))
      ? -1
      : 1;
  }
  const { sourceFileName: aKey } = parseFilename(a.key ?? '');
  const { sourceFileName: bKey } = parseFilename(b.key ?? '');
  return sort.order === 'DESC' ? (aKey > bKey ? 1 : -1) : aKey < bKey ? 1 : -1;
};

export const useMediaCenterRows = (rawMedia: DealMedia[]) => {
  const [media, setMedia] = useState(
    groupBy(
      rawMedia.map((x) => mapGroupMedia(x)),
      (x) => x.group,
    ),
  );
  const [sorting, setSorting] = useState<MediaCenterSorting>({
    order: 'DESC',
    value: 'category',
  });
  const [hiddenGroups, setHiddenGroups] = useState<string[]>([]);

  const onSortChange = (value: MediaCenterSorting['value']) => {
    const isCurrentValue = sorting.value === value;
    setSorting({
      order: isCurrentValue ? (sorting.order === 'ASC' ? 'DESC' : 'ASC') : 'DESC',
      value,
    });
  };

  useEffect(() => {
    const sortFn = sortMediaRowsFactory(sorting);
    setMedia(groupBy(rawMedia.map((x) => mapGroupMedia(x)).sort(sortFn), (x) => x.group));
  }, [rawMedia, sorting]);

  return { hiddenGroups, setHiddenGroups, media, onSortChange, setMedia, sorting, setSorting };
};
