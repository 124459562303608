import {
  AT_AUCTION,
  CLOSED,
  CLOSING,
  Deal,
  DealStateEnum,
  DealStateLabelMap,
  FUNDED,
  NEW_ESTIMATES,
  PAID_OFF,
  READY_FOR_PICKUP,
  SEND_PAYOFF,
  SENT,
  SENT_TO_PROCESSOR,
  SIGNED,
  SIGNING,
  SOFT_CLOSE,
  SOLD,
  STRUCTURING,
  TITLE_RECEIVED,
  TITLE_SENT,
  WAITING_FOR_TITLE,
} from '../gql/dealGql';

import { valueOfDate } from './dates';

export type Swimlane = { [K in DealStateEnum]: Deal[] };

export const allSwimlanes = [
  {
    title: NEW_ESTIMATES,
    states: [DealStateEnum.Floor, DealStateEnum.Estimate],
  },
  {
    title: SOFT_CLOSE,
    states: [DealStateEnum.SoftClose],
  },
  {
    title: STRUCTURING,
    states: [DealStateEnum.StructuringInProgress, DealStateEnum.Structuring],
  },
  {
    title: CLOSING,
    states: [DealStateEnum.Closing],
  },
  {
    title: SIGNING,
    states: [DealStateEnum.Closed, DealStateEnum.SentForSignatures],
  },
  {
    title: CLOSED,
    states: [DealStateEnum.Closed],
  },
  {
    title: SENT,
    states: [DealStateEnum.SentForSignatures],
  },
  {
    title: SIGNED,
    states: [DealStateEnum.Signed],
  },
  {
    title: PAID_OFF,
    states: [DealStateEnum.PaidOff],
  },
  {
    title: READY_FOR_PICKUP,
    states: [DealStateEnum.ReadyForPickup],
  },
  {
    title: AT_AUCTION,
    states: [DealStateEnum.AtAuction],
  },
  {
    title: SOLD,
    states: [DealStateEnum.Sold],
  },
  {
    title: TITLE_SENT,
    states: [DealStateEnum.TitleSent],
  },
  {
    title: FUNDED,
    states: [DealStateEnum.Funded],
  },
  {
    title: SEND_PAYOFF,
    states: [DealStateEnum.SendPayoff],
  },
  {
    title: WAITING_FOR_TITLE,
    label: DealStateLabelMap[DealStateEnum.WaitingForTitle],
    states: [DealStateEnum.WaitingForTitle],
  },
  {
    title: TITLE_RECEIVED,
    label: DealStateLabelMap[DealStateEnum.TitleReceived],
    states: [DealStateEnum.TitleReceived],
  },
  {
    title: SENT_TO_PROCESSOR,
    label: DealStateLabelMap[DealStateEnum.SentToProcessor],
    states: [DealStateEnum.SentToProcessor],
  },
];
export type Direction = 'asc' | 'desc' | 'default';
export type SortOptionLabels = {
  asc?: string;
  desc: string;
};

export enum SortByEnum {
  DealAge = 'dealAge',
  Default = 'default',
  LastName = 'lastName',
  TimeInLane = 'timeInLane',
}

export const SortByOptionLabels: Record<SortByEnum, SortOptionLabels> = {
  [SortByEnum.Default]: {
    desc: 'Default',
  },
  [SortByEnum.DealAge]: {
    asc: 'Deal Age · Oldest to Newest',
    desc: 'Deal Age · Newest to Oldest',
  },
  [SortByEnum.TimeInLane]: {
    asc: 'Time in Lane · Oldest to Newest',
    desc: 'Time in Lane · Newest to Oldest',
  },
  [SortByEnum.LastName]: {
    asc: 'Last Name · A to Z',
    desc: 'Last Name · Z to A',
  },
};

export type SortOpt = {
  direction: Direction;
  type: SortByEnum;
};

export const groupDealsByState = (deals: Deal[]): Swimlane =>
  deals.reduce((sl, deal) => {
    if (sl[deal.state]) {
      sl[deal.state].push(deal); // Push the deal to the swimlane that corresponds to its state
    } else {
      // eslint-disable-next-line no-param-reassign
      sl[deal.state] = [deal]; // If the swimlane doesn't exist, create it
    }

    return sl;
  }, {} as Swimlane);

export const customSortDeals = (deals: Deal[], sortOpt?: SortOpt): Deal[] => {
  if (!sortOpt) {
    return deals;
  }
  const isAsc = sortOpt.direction === 'asc';

  return deals.sort((d1, d2) => {
    switch (sortOpt.type) {
      case SortByEnum.DealAge:
        if (!d1.created_at || !d2.created_at) {
          return 0;
        }
        return isAsc
          ? valueOfDate(d1.created_at) - valueOfDate(d2.created_at)
          : valueOfDate(d2.created_at) - valueOfDate(d1.created_at);

      case SortByEnum.LastName:
        if (!d1.customer?.last_name || !d2.customer?.last_name) {
          return 0;
        }
        return isAsc
          ? d1.customer.last_name.localeCompare(d2.customer.last_name)
          : d2.customer.last_name.localeCompare(d1.customer.last_name);

      case SortByEnum.TimeInLane:
        if (!d1.lane_arrival_utc || !d2.lane_arrival_utc) {
          return 0;
        }
        return isAsc
          ? valueOfDate(d1.lane_arrival_utc) - valueOfDate(d2.lane_arrival_utc)
          : valueOfDate(d2.lane_arrival_utc) - valueOfDate(d1.lane_arrival_utc);

      default:
        return valueOfDate(d2.default_sort_date) - valueOfDate(d1.default_sort_date);
    }
  });
};
