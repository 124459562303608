import { ApolloClient } from '@apollo/client';

import {
  DealMediaTypeEnum,
  GetUploadUrlDocument,
  Maybe,
  TtDocumentName,
  useMediaInsertMutation,
} from '../gql/generated/graphql';
import { TTValidationName, TTValidationType } from '../gql/taterTitleGql';

import { DocumentTypeMap } from '../constants/media';
import {
  DealMedia,
  DealMediaEdit,
  DriverLicenseMetadata,
  DriverLicenseMetadataValueFields,
  GetUploadUrlResult,
  InsuranceCardMetadataValueFields,
  RegistrationMetadataValueFields,
} from '../types/media';

import { getFileNameAndExtension, normalizeFileName } from './files';

export const transformMetadata = (
  metadata?: { [key: string]: string } | DriverLicenseMetadata | null,
) => {
  if (!metadata) {
    return null;
  }

  const values = Object.values(metadata);
  if (values.length === 0 || values.every((value) => !value)) {
    return null;
  }

  return metadata;
};

export const mapTTDocumentType = (type: Maybe<TtDocumentName>): DealMediaTypeEnum | string => {
  if (!type) {
    return '';
  }

  return DocumentTypeMap[type] || '';
};

export const isVerifiedDigitalSignatureRequired = (validations?: TTValidationType[]): boolean =>
  validations?.some(({ name }) => name === TTValidationName.VerifiedDigitalSignature) ?? false;

export const isWetSignatureRequired = (validations?: TTValidationType[]): boolean =>
  validations?.some(({ name }) => name === TTValidationName.WetSignature) ?? false;

export const isNotaryRequired = (validations?: TTValidationType[]): boolean =>
  validations?.some(({ name }) => name === TTValidationName.NotarizationRequired) ?? false;

export const mapTTValidationNamesToRequiredMetadataFields = (
  ttValidationNames: TTValidationName[],
) => {
  const alwaysRequiredDriverLicenseMetadataFields = new Map<
    DriverLicenseMetadataValueFields,
    boolean
  >();
  alwaysRequiredDriverLicenseMetadataFields.set('expirationDate', true);

  const alwaysRequiredInsuranceCardMetadataFields = new Map<
    InsuranceCardMetadataValueFields,
    boolean
  >();
  alwaysRequiredInsuranceCardMetadataFields.set('expirationDate', true);
  alwaysRequiredInsuranceCardMetadataFields.set('insuranceCompany', true);
  alwaysRequiredInsuranceCardMetadataFields.set('policyNo', true);

  const alwaysRequiredRegistrationMetadataFields = new Map<
    RegistrationMetadataValueFields,
    boolean
  >();

  const requiredMetadataFields = ttValidationNames.reduce(
    (acc, ttValidationName) => {
      switch (ttValidationName) {
        case TTValidationName.DriversLicenseAddressMatch:
          acc.requiredDriverLicenseMetadataFields.set('address', true);
          acc.requiredDriverLicenseMetadataFields.set('address2', true);
          acc.requiredDriverLicenseMetadataFields.set('city', true);
          acc.requiredDriverLicenseMetadataFields.set('state', true);
          acc.requiredDriverLicenseMetadataFields.set('zip', true);
          return acc;
        case TTValidationName.DriversLicenseNameMatch:
          acc.requiredDriverLicenseMetadataFields.set('name', true);
          return acc;
        case TTValidationName.DriversLicenseStateMatch:
          acc.requiredDriverLicenseMetadataFields.set('state', true);
          return acc;
        case TTValidationName.InsuranceNameMatch:
          acc.requiredInsuranceCardMetadataFields.set('name', true);
          return acc;
        case TTValidationName.InsuranceStateMatch:
          acc.requiredInsuranceCardMetadataFields.set('state', true);
          return acc;
        case TTValidationName.InsuranceVinMatch:
          acc.requiredInsuranceCardMetadataFields.set('vin', true);
          return acc;
        case TTValidationName.CobuyerOnInsurance:
          acc.requiredInsuranceCardMetadataFields.set('cobuyerOnInsurance', true);
          return acc;
        case TTValidationName.RegistrationNotExpired:
          acc.requiredRegistrationMetadataFields.set('expirationDate', true);
          return acc;
        case TTValidationName.RegistrationNotExpired60Days:
          acc.requiredRegistrationMetadataFields.set('expirationDate60Days', true);
          return acc;
        case TTValidationName.RegistrationNotExpired90Days:
          acc.requiredRegistrationMetadataFields.set('expirationDate90Days', true);
          return acc;
        default:
          return acc;
      }
    },
    {
      requiredDriverLicenseMetadataFields: alwaysRequiredDriverLicenseMetadataFields,
      requiredInsuranceCardMetadataFields: alwaysRequiredInsuranceCardMetadataFields,
      requiredRegistrationMetadataFields: alwaysRequiredRegistrationMetadataFields,
    },
  );

  // Some state like MA returns both `expirationDate` and `expirationDate60Days` so we keep the most restrictive.
  if (requiredMetadataFields.requiredRegistrationMetadataFields.has('expirationDate90Days')) {
    requiredMetadataFields.requiredRegistrationMetadataFields.delete('expirationDate');
    requiredMetadataFields.requiredRegistrationMetadataFields.delete('expirationDate60Days');
  } else if (
    requiredMetadataFields.requiredRegistrationMetadataFields.has('expirationDate60Days')
  ) {
    requiredMetadataFields.requiredRegistrationMetadataFields.delete('expirationDate');
  }

  return requiredMetadataFields;
};

export const insertMediaByUrl = async (
  selectedMedia: DealMediaEdit[],
  dealId: number,
  client: ApolloClient<object>,
  mediaInsert: ReturnType<typeof useMediaInsertMutation>[0],
) => {
  if (dealId && selectedMedia.length) {
    await Promise.all(
      selectedMedia.map(async (media) => {
        const { data } = await client.query<GetUploadUrlResult>({
          query: GetUploadUrlDocument,
          variables: {
            dealId,
            fileName: normalizeFileName(`${media.fileName}.${media.extension}`),
          },
        });

        const { url, key } = data.getUploadUrl;

        await fetch(url, {
          method: 'PUT',
          body: media.selectedFile,
        });

        return mediaInsert({
          variables: {
            key,
            dealId,
            type: media.type,
          },
        });
      }),
    );
  }
};

export const getDealMediaFromAcceptedFiles = async (acceptedFiles: File[]) =>
  Promise.all(
    acceptedFiles.map(async (file) => {
      const normalizedFileName = normalizeFileName(file.name);
      const { fileName, extension } = getFileNameAndExtension(normalizedFileName);

      return {
        selectedFile: file,
        sourceFileName: normalizedFileName,
        fileName,
        extension,
        type: DealMediaTypeEnum.PayoffDocs,
      } as DealMediaEdit;
    }),
  );

export const isFileDeletable = (file: DealMedia) =>
  !file.has_verified_digital_signature &&
  !file.has_wet_signature &&
  !file.is_notarized &&
  file.type !== DealMediaTypeEnum.R1EContract &&
  file.type !== DealMediaTypeEnum.SignedDocs;
