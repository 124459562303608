import { MouseEvent, useState } from 'react';

import { IconButton } from '@chakra-ui/react';
import { BiCopyAlt } from 'react-icons/bi';

import Tooltip from '../shared/Tooltip';

import { formatNumber } from '../../libs/utils';

interface CopyButtonProps {
  value: string;
  isFormatted?: boolean;
  visibility?: boolean;
}

export const CopyButton = ({
  value,
  isFormatted = false,
  visibility = true,
  ...props
}: CopyButtonProps) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (isFormatted) {
      navigator.clipboard.writeText(formatNumber(value));
    } else {
      navigator.clipboard.writeText(value.toString().toUpperCase());
    }

    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 2750);
  };

  return (
    <Tooltip label="Copied" hidden={!show}>
      <IconButton
        icon={<BiCopyAlt />}
        variant="iconHover"
        size="xs"
        fontSize={17}
        aria-label="Copy"
        visibility={visibility ? 'visible' : 'hidden'}
        {...props}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export const InputCopyButton = ({ value, isFormatted = false, ...props }: CopyButtonProps) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClick = () => {
    if (isFormatted) {
      navigator.clipboard.writeText(formatNumber(value));
    } else {
      navigator.clipboard.writeText(value.toString().toUpperCase());
    }

    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 2750);
  };
  return (
    <Tooltip label="Copied" hidden={!show} onClick={handleClick}>
      <CopyButton value={value} isFormatted={isFormatted} {...props} />
    </Tooltip>
  );
};
