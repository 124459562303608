import { ApolloQueryResult } from '@apollo/client';
import { Flex, Td, Tr } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Deal, FollowUp, GetFollowUpDealsPaginatedQuery } from '../../gql/generated/graphql';
import { Notification, getDealNotificationCountByType } from '../../gql/notificationsGql';

import TextNotificationBadgeV2 from '../NotificationBadge/TextNotificationBadgeV2';

import DeleteFollowUp from './DeleteFollowUp';
import EditFollowUp from './EditFollowUp';

import { handleClickOrCommandClick } from '../../libs/eventHandlers';
import { formatDate } from '../../libs/utils';
import { getFollowUpTypeLabel } from '../../utils/followUps';

interface Props {
  deal: Deal;
  followUp: FollowUp;
  name: string;
  state: string;
  refetch: () => Promise<ApolloQueryResult<GetFollowUpDealsPaginatedQuery>>;
}

const FollowUpRow = ({ deal, followUp, name, state, refetch }: Props) => {
  const history = useHistory();
  const notifications =
    (deal.notifications?.filter((notif) => notif != null) as Notification[]) ?? [];
  const { unseenMessages } = getDealNotificationCountByType({ notifications });

  return (
    <Tr
      height="55px"
      borderBottom="2px"
      borderX="1px"
      borderColor="backgroundGray"
      bgColor="white"
      cursor="pointer"
      _hover={{ bgColor: 'hoverGray' }}
      key={followUp.deal_id}
      onClick={(e) => handleClickOrCommandClick(e, `/deals/${followUp.deal_id}`, history)}
      onAuxClick={(e) => handleClickOrCommandClick(e, `/deals/${followUp.deal_id}`, history)}
    >
      <Td px="12px" w="1%">
        <TextNotificationBadgeV2 count={unseenMessages} deal={deal} size={25} />
      </Td>
      <Td pl={1} w="19%">
        {name}
      </Td>
      <Td w="15%"> {formatDate(followUp?.date_utc.toString(), followUp?.tz || undefined)}</Td>
      <Td w="10%">
        {new Date(followUp?.date_utc).toLocaleString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        })}
      </Td>
      <Td w="10%">{getFollowUpTypeLabel(followUp?.type || undefined)}</Td>
      <Td w="15%">{state}</Td>
      <Td w="25%">{followUp?.note}</Td>
      <Td w="10%">{followUp?.creator?.name}</Td>
      <Td w="10%">
        <Flex justifyContent="center">
          <EditFollowUp followUp={followUp} refetch={refetch} showIconButton />
          <DeleteFollowUp followUp={followUp} refetch={refetch} showIconButton />
        </Flex>
      </Td>
    </Tr>
  );
};

export default FollowUpRow;
