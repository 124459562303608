import { FC, ReactNode } from 'react';

import { Box, FormLabel, FormLabelProps, HStack, StackProps, Tooltip } from '@chakra-ui/react';
import { MdInfo } from 'react-icons/md';

interface InputRowProps extends StackProps {
  label: string;
  children: ReactNode;
  labelProps?: FormLabelProps;
  infoTooltipLabel?: string;
}

const InputRow: FC<InputRowProps> = ({
  label,
  children,
  labelProps,
  infoTooltipLabel,
  ...rest
}) => {
  const infoTooltipLabelProps = infoTooltipLabel
    ? { display: 'inline-flex', justifyContent: 'space-between', alignItems: 'start' }
    : {};

  return (
    <HStack mt={1} alignItems="start" {...rest}>
      <FormLabel
        minW={{ base: '50%', xl: '40%', '2xl': '50%' }}
        maxW={{ base: '50%', xl: '40%', '2xl': '50%' }}
        mt={3}
        fontSize="sm"
        whiteSpace={{ base: 'normal', '2xl': 'nowrap' }}
        {...infoTooltipLabelProps}
        {...labelProps}
      >
        {label}
        {infoTooltipLabel ? (
          <Tooltip
            label={infoTooltipLabel}
            placement="top"
            hasArrow
            backgroundColor="gray.700"
            borderRadius="5px"
            marginX={2}
            textAlign="center"
          >
            <Box ml={1} mt={0.5}>
              <MdInfo />
            </Box>
          </Tooltip>
        ) : null}
      </FormLabel>
      {children}
    </HStack>
  );
};

export default InputRow;
