import { Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { DealStateEnum } from '../../../gql/dealGql';
import { DealType } from '../../../gql/generated/graphql';

import Tooltip from '../../shared/Tooltip';
import { CreditAppOnDeal } from '../useCreditAppInitialValues';

import { LDFlags } from '../../../constants/experiments';
import { PermissionEnum } from '../../../constants/permissions';
import useFlag from '../../../hooks/useFlag';
import { DealContext } from '../../../libs/DealContext';
import { AbilityContext } from '../../../libs/contextLib';
import { validateFuture } from '../../../libs/yup-validators/dates';

interface Props {
  isSavingOrCheckingMileage: boolean;
  setDealState: Dispatch<SetStateAction<DealStateEnum>>;
  validatePayoffAndMileage: ({
    dealStateParam,
    skipPayoffValidation,
    skipMileageValidation,
  }: {
    dealStateParam?: DealStateEnum | undefined;
    skipPayoffValidation?: boolean | undefined;
    skipMileageValidation?: boolean | undefined;
  }) => Promise<void>;
}

const SubmitApplicationButtons = ({
  isSavingOrCheckingMileage,
  setDealState,
  validatePayoffAndMileage,
}: Props) => {
  const prequalAutoStructureFlag = useFlag(LDFlags.PREQUAL_AUTOSTRUCTURE);

  const {
    deal,
    canAutoStructure,
    isRecalculatingPayoff,
    everyoneIsPrequalified,
    autosaving,
    isPayoffRequested,
  } = useContext(DealContext);
  const ability = useContext(AbilityContext);

  const { errors, isValid } = useFormikContext<CreditAppOnDeal>();
  const {
    hasVehiclePayoff,
    isVehiclePayoffMoreThanZero,
    hasGoodThroughDate,
    isGoodThroughDateInTheFuture,
  } = useMemo(
    () => ({
      hasVehiclePayoff: deal.car.payoff.vehicle_payoff != null,
      isVehiclePayoffMoreThanZero:
        deal.car.payoff.vehicle_payoff && deal.car.payoff.vehicle_payoff > 0,

      hasGoodThroughDate: deal.car.payoff.good_through_date != null,
      isGoodThroughDateInTheFuture: validateFuture(
        deal.car.payoff.good_through_date ? new Date(deal.car.payoff.good_through_date) : null,
      ),
    }),
    [deal.car.payoff.vehicle_payoff, deal.car.payoff.good_through_date],
  );

  const canMoveToStructuring = ability.has(PermissionEnum.MoveToStructuring);

  const isRefi = deal.type === DealType.Refi;
  const isMissingPrequalification = prequalAutoStructureFlag && !everyoneIsPrequalified;
  const isFollowUpScheduled = !!deal.follow_up;

  const getIsDisabled = (button: 'move' | 'submit') =>
    !isValid ||
    isPayoffRequested ||
    !hasVehiclePayoff ||
    !isVehiclePayoffMoreThanZero ||
    !hasGoodThroughDate ||
    !isGoodThroughDateInTheFuture ||
    isRecalculatingPayoff ||
    (isMissingPrequalification && (button === 'submit' || !canMoveToStructuring)) ||
    isFollowUpScheduled ||
    autosaving;

  const { moveIsDisabled, submitIsDisabled } = useMemo(
    () => ({
      moveIsDisabled: getIsDisabled('move'),
      submitIsDisabled: getIsDisabled('submit'),
    }),
    [
      isValid,
      isPayoffRequested,
      hasVehiclePayoff,
      isVehiclePayoffMoreThanZero,
      hasGoodThroughDate,
      isGoodThroughDateInTheFuture,
      isRecalculatingPayoff,
      isMissingPrequalification,
      canMoveToStructuring,
      isFollowUpScheduled,
      autosaving,
    ],
  );

  const getAllErrors = (button: 'move' | 'submit') => ({
    ...errors,
    ...(isPayoffRequested ? { payoffRequestError: 'Payoff was requested' } : {}),
    ...(isMissingPrequalification && (button === 'submit' || !canMoveToStructuring)
      ? { prequalificationError: 'Prequalification is required' }
      : {}),
    ...(isFollowUpScheduled ? { followupError: 'Cannot have a current follow up scheduled' } : {}),
    ...(!hasVehiclePayoff ? { payoffError: 'Vehicle payoff is required' } : {}),
    ...(!isVehiclePayoffMoreThanZero
      ? { payoffError: 'Vehicle Payoff must be greater than 0' }
      : {}),
    ...(!hasGoodThroughDate ? { goodThroughError: 'Must have a good through date' } : {}),
    ...(!isGoodThroughDateInTheFuture
      ? { goodThroughError: 'Good through date must be in the future' }
      : {}),
  });

  const { moveAllErrors, submitAllErrors } = useMemo(
    () => ({
      moveAllErrors: getAllErrors('move'),
      submitAllErrors: getAllErrors('submit'),
    }),
    [
      errors,
      isPayoffRequested,
      isMissingPrequalification,
      canMoveToStructuring,
      isFollowUpScheduled,
      hasVehiclePayoff,
      isVehiclePayoffMoreThanZero,
      hasGoodThroughDate,
      isGoodThroughDateInTheFuture,
    ],
  );

  const handleOnClick = (
    newDealState: DealStateEnum.Structuring | DealStateEnum.StructuringInProgress,
  ) => {
    if (!isValid) {
      return;
    }

    setDealState(newDealState);
    validatePayoffAndMileage({ dealStateParam: newDealState });
  };

  return (
    <>
      {canMoveToStructuring && (
        <Tooltip errors={moveAllErrors}>
          <Button
            variant="secondary"
            isLoading={isSavingOrCheckingMileage}
            loadingText="MOVE TO STRUCTURING"
            isDisabled={moveIsDisabled}
            onClick={() => handleOnClick(DealStateEnum.Structuring)}
          >
            MOVE TO STRUCTURING
          </Button>
        </Tooltip>
      )}

      <Tooltip errors={submitAllErrors}>
        <Button
          isLoading={isSavingOrCheckingMileage}
          loadingText="SUBMIT APPLICATION"
          isDisabled={submitIsDisabled}
          onClick={() =>
            handleOnClick(
              !isRefi &&
                (!prequalAutoStructureFlag || canAutoStructure) &&
                ability.has(PermissionEnum.AutomateCreditApplication)
                ? DealStateEnum.StructuringInProgress
                : DealStateEnum.Structuring,
            )
          }
        >
          SUBMIT APPLICATION
        </Button>
      </Tooltip>
    </>
  );
};

export default SubmitApplicationButtons;
