import { useContext, useState } from 'react';

import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { BiHistory } from 'react-icons/bi';

import { DealStateLabelMap } from '../../gql/dealGql';

import Modal from '../../components/shared/Modal';

import { DealContext } from '../../libs/DealContext';
import { formatDateWithTime } from '../../libs/utils';

const DealStatesModal = () => {
  const {
    deal: { deal_states, set_date, completion_date_utc },
  } = useContext(DealContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <IconButton
        icon={<BiHistory size={23} />}
        variant="icon"
        color="primary"
        size="xs"
        float="right"
        aria-label="Deal States History"
        onClick={() => setShowModal(true)}
      />
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Deal State History"
        size="2xl"
        variant="fullContent"
      >
        <Flex
          justify="center"
          textAlign="center"
          textColor="white"
          bg="queenBlue"
          h={10}
          align="center"
        >
          <Text w="50%">
            Set Date:
            {set_date ? ` ${formatDateWithTime(set_date?.toString())}` : ' - '}
          </Text>
          <Text w="50%">
            Completion Date:
            {completion_date_utc
              ? ` ${formatDateWithTime(completion_date_utc?.toString())}`
              : ' - '}
          </Text>
        </Flex>
        <Box mb={2}>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>State</Th>
                <Th>User</Th>
              </Tr>
            </Thead>
            <Tbody>
              {deal_states?.map((deal_state) => (
                <Tr key={deal_state.id}>
                  <Td>{formatDateWithTime(deal_state?.updated_date_utc?.toString())}</Td>
                  <Td>{DealStateLabelMap[deal_state.state]}</Td>
                  <Td>{deal_state.user?.name || 'System'}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Modal>
    </>
  );
};

export default DealStatesModal;
