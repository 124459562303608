import { useEffect, useRef, useState } from 'react';

import { ModalType, initialModalState } from '../libs/contextLib';

const useModal = () => {
  const [modals, _setModal] = useState<ModalType>(initialModalState);
  const prevModalsRef = useRef(modals);
  const [modalCloseCount, setModalCloseCount] = useState(0);

  const setModal = (update: Partial<ModalType>) => {
    _setModal((prev) => ({ ...prev, ...update }));
  };

  // Increment `modalCloseCount` when any modal is closed to trigger the re-calculation on the form initial values.
  // This avoids the re-calculation when a modal is opened.
  useEffect(() => {
    const prevModals = prevModalsRef.current;
    const anyModalClosed = Object.keys(modals).some((key) => {
      const typedKey = key as keyof typeof modals;
      return prevModals[typedKey] && !modals[typedKey];
    });
    if (anyModalClosed) {
      setModalCloseCount((prev) => prev + 1);
    }
    prevModalsRef.current = modals;
  }, [modals]);

  return { modals, setModal, modalCloseCount };
};

export default useModal;
