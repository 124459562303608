import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BiArrowBack, BiEdit, BiTrash } from 'react-icons/bi';
import { FaNewspaper } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Article,
  createArticle,
  deleteArticle,
  listArticles,
  updateArticle,
} from '../../gql/articleGql';

import LeaseEndContainer from '../../components/Container/LEContainer';
import DeleteArticleModal from './components/DeleteArticleModal';
import UpsertArticleModal from './components/UpsertArticleModal';

import ROUTES from '../../constants/routes';
import { formatDate, truncateString } from '../../libs/utils';

const PressManagerPage = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const [articleToEdit, setArticleToEdit] = useState<Article | null>(null);
  const [createArticleMutation] = useMutation(createArticle);
  const [deleteArticleMutation] = useMutation(deleteArticle);
  const [updateArticleMutation] = useMutation(updateArticle);

  const { data, loading } = useQuery(listArticles, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const dataArticles = data?.articles;

    if (dataArticles) {
      setArticles(dataArticles);
    }
  }, [data]);

  const handleUpdate = async (values: Article) => {
    try {
      const article = { ...values };
      delete article.thumbnail;
      await updateArticleMutation({ variables: { article } });
      setArticles((prev) =>
        prev.map((prevArticle) => (prevArticle.id === values.id ? values : prevArticle)),
      );

      return values.id;
    } catch {
      toast.error('Failed to update article.');
      return undefined;
    }
  };

  const handleCreate = async (values: Article) => {
    try {
      const article = { ...values };
      delete article.thumbnail;
      const {
        data: {
          createArticle: { id },
        },
      } = await createArticleMutation({
        variables: { article },
      });
      setArticles((prev) => [{ ...values, id }, ...prev]);

      return id;
    } catch {
      toast.error('Failed to add article.');
      return undefined;
    }
  };

  const handleSubmit = async (values: Article) => {
    if (articleToEdit) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }

    setShowModal(false);
  };

  const handleDelete = async () => {
    try {
      await deleteArticleMutation({
        variables: { id: idToDelete },
      });
      setArticles((prev) => prev.filter((article) => article.id !== idToDelete));
      setShowDeleteModal(false);
    } catch {
      toast.error('Failed to delete article.');
    }
  };

  return (
    <>
      <HStack
        direction={{ base: 'column', lg: 'row' }}
        py={2}
        px={8}
        bgColor="queenBlue"
        justify="space-between"
      >
        <Button
          as={RouterLink}
          to={ROUTES.DASHBOARD}
          size="lgWithIconLeft"
          leftIcon={<BiArrowBack />}
          variant="secondary"
        >
          DASHBOARD
        </Button>
      </HStack>
      <LeaseEndContainer isLoading={loading}>
        <HStack p={3} bgColor="oxfordBlue" borderTopRadius={8} spacing="auto">
          <Text fontSize={20} textColor="white">
            Featured Articles
          </Text>
          <Button
            size="smWithIconLeft"
            variant="secondary"
            leftIcon={<FaNewspaper />}
            onClick={() => {
              setArticleToEdit(null);
              setShowModal(true);
            }}
          >
            ADD ARTICLE
          </Button>
        </HStack>
        <Table>
          <Thead bgColor="queenBlue" height="50px">
            <Tr>
              {['Thumbnail', 'Title', 'Headline', 'Date Added', 'COM Visibility'].map((header) => (
                <Th textColor="white" key={header}>
                  {header}
                </Th>
              ))}
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {articles.map((article) => (
              <Tr key={article.id} bgColor="white">
                <Td>
                  <Image src={article.thumbnail} alt="thumbnail" height="100px" width="100px" />
                </Td>
                <Td>{article.title}</Td>
                <Td>{truncateString(article.headline, 50)}</Td>
                <Td>{formatDate(article.created_at)}</Td>
                <Td>
                  <Flex justify="center">
                    <Switch
                      name="com_visible"
                      isChecked={article.com_visible}
                      onChange={() =>
                        handleUpdate({
                          ...article,
                          com_visible: !article.com_visible,
                        })
                      }
                    />
                  </Flex>
                </Td>
                <Td>
                  <IconButton
                    icon={<BiEdit />}
                    variant="iconHover"
                    size="xs"
                    aria-label="Edit Article"
                    onClick={() => {
                      setShowModal(true);
                      setArticleToEdit(article);
                    }}
                  />
                  <IconButton
                    icon={<BiTrash />}
                    variant="iconHover"
                    size="xs"
                    color="errorsRed"
                    aria-label="Delete Article"
                    onClick={() => {
                      setShowDeleteModal(true);
                      setIdToDelete(article.id);
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </LeaseEndContainer>
      <UpsertArticleModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        articleToEdit={articleToEdit}
      />
      <DeleteArticleModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default PressManagerPage;
