import React from 'react';

import { Box, HStack } from '@chakra-ui/react';

import { Customer, CustomerContactTypeEnum, getFullName } from '../../../gql/customerGql';

import CustomerBubble from './CustomerBubble';

import TextNotificationBadge from '../../NotificationBadge/TextNotificationBadgeV2';

type CustomerBubbleProps = {
  customer: Customer;
  customerType: CustomerContactTypeEnum;
  handleClick: () => void;
  isActive: boolean;
  nameShown: boolean;
  unreadCount?: number;
};

const ContactBox = ({
  customer,
  customerType,
  handleClick,
  isActive,
  nameShown,
  unreadCount = 0,
}: CustomerBubbleProps) => {
  return (
    <HStack
      pos="relative"
      bgColor={isActive ? 'gray.200' : 'white'}
      p="6px 8px 6px 8px"
      _hover={{ bgColor: 'gray.100' }}
      w="100%"
      cursor="pointer"
      onClick={handleClick}
      fontSize={16}
    >
      <CustomerBubble
        firstName={customer.first_name}
        lastName={customer.last_name}
        customerType={customerType}
      />
      {nameShown ? (
        <>
          <Box whiteSpace="nowrap" text-overflow="clip" overflow="hidden">
            {getFullName(customer).substring(0, 18)}
          </Box>
          <Box pos="absolute" right="10px">
            <TextNotificationBadge count={unreadCount} />
          </Box>
        </>
      ) : (
        <Box pos="absolute" right="0px" top="0px">
          <TextNotificationBadge count={unreadCount} size={20} />
        </Box>
      )}
    </HStack>
  );
};

export default ContactBox;
