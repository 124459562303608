import { Dispatch, useContext, useMemo } from 'react';

import { Button, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { getFullName } from '../../../gql/customerGql';
import { BootReason, BootReasonsEnum, Deal, DealStateEnum } from '../../../gql/dealGql';
import { useDealUpdateRequestBootMutation } from '../../../gql/generated/graphql';
import { Note } from '../../../gql/noteGql';

import DynamicSizedButton from '../../shared/DynamicSizedButton';
import Modal from '../../shared/Modal';
import Select from '../../shared/Select';
import TextArea from '../../shared/TextArea';

import { PermissionEnum } from '../../../constants/permissions';
import SELECT_OPTIONS from '../../../constants/selectOptions';
import { useUser } from '../../../hooks/useUser';
import { DealActions, DealActionsEnum, DealContext } from '../../../libs/DealContext';
import { AbilityContext } from '../../../libs/contextLib';
import { getTimezoneStr, passValuesToSchema } from '../../../libs/utils';

const MAX_COMMENT_LENGTH = 150;

const validationSchema = Yup.object().shape({
  reason: Yup.string()
    .required('Reason is required')
    .oneOf(Object.values(BootReasonsEnum), 'Invalid Reason'),
  comment: Yup.string().max(MAX_COMMENT_LENGTH).required('Comment is required'),
});

type BootActionProps = {
  deal: Deal;
  dispatch?: Dispatch<DealActions>;
  renderAsIcon?: boolean;
  renderAsIconSize?: number;
  allowAllToBoot?: boolean;
};

export const BootActions = ({
  deal,
  dispatch,
  renderAsIcon = false,
  renderAsIconSize = 15,
  allowAllToBoot = false,
}: BootActionProps) => {
  const user = useUser();
  const ability = useContext(AbilityContext);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const standardBootPermission = ability.has(PermissionEnum.BootDeal);
  const selectCaseBootPermission =
    allowAllToBoot && ability.has(PermissionEnum.BootAcquisitionDeal);
  const renderBoot = standardBootPermission || selectCaseBootPermission;
  const bootIsRequested = deal.request_boot;

  const [requestBoot, { loading }] = useDealUpdateRequestBootMutation();

  const initialValues = useMemo(
    () => ({
      reason: deal?.boot_reason?.reason || ('' as BootReasonsEnum),
      comment: deal?.boot_reason?.comment || '',
    }),
    [deal.boot_reason?.comment, deal.boot_reason?.reason],
  );

  const handleSubmit = async ({ reason, comment }: BootReason, boot_deal: boolean) => {
    const bootReason = new BootReason(reason, comment);

    try {
      if (!deal.id) {
        throw new Error('No deal id');
      }

      const partial_note = {
        deal_id: deal.id,
        author_id: user.id,
        creation_date_tz: getTimezoneStr(),
      } as Note;

      const response = await requestBoot({
        variables: {
          partial_note,
          id: deal.id,
          request_boot: false,
          boot_reason: bootReason,
          boot_deal,
        },
      });
      if (!response.data?.dealUpdateRequestBoot) {
        throw new Error('No response');
      }

      // Dispatches when the button is clicked from the `DealDetail` page.
      const { request_boot, boot_reason, state } = response.data?.dealUpdateRequestBoot;
      dispatch?.({
        type: DealActionsEnum.DeepUpdateDeal,
        payload: {
          request_boot: request_boot ?? undefined,
          boot_reason,
          state: state as DealStateEnum,
        },
      });

      toast.success(boot_deal ? 'Deal removed' : 'Boot request canceled');
    } catch (e) {
      toast.error('Failed to remove deal.');
    } finally {
      onClose();
    }
  };

  return renderBoot ? (
    <>
      {renderAsIcon ? (
        <IconButton
          icon={<MdDelete />}
          variant="iconHover"
          color="errorsRed"
          size="xxs"
          fontSize={renderAsIconSize}
          aria-label="Boot Deal"
          onClick={onOpen}
        />
      ) : (
        <DynamicSizedButton
          ariaLabel="boot-deal"
          variant="boot"
          isRound
          size={{ base: 'md', sm: 'lgWithIconLeft' }}
          icon={<MdDelete size="20px" />}
          label="Boot Deal"
          onClick={onOpen}
          hidden={deal.state === DealStateEnum.Booted}
        />
      )}
      <Formik
        onSubmit={(values) => handleSubmit(values, true)}
        initialValues={initialValues}
        validateOnMount
        validateOnChange
        validate={(value) => passValuesToSchema(value, validationSchema)}
        enableReinitialize
      >
        {({ isValid, handleChange }) => (
          <Modal
            title={`Remove ${getFullName(deal.customer)}?`}
            isOpen={isOpen}
            onClose={onClose}
            hasForm
            rightButtons={
              <>
                {bootIsRequested ? (
                  <Button variant="boot" onClick={() => handleSubmit(initialValues, false)}>
                    DECLINE REQUEST
                  </Button>
                ) : null}
                <Button isLoading={loading} loadingText="BOOT" isDisabled={!isValid} type="submit">
                  CONFIRM BOOT
                </Button>
              </>
            }
          >
            <Form>
              <Flex direction="column" gap={4}>
                <Select
                  label="Reason"
                  placeholder=""
                  id="reason"
                  name="reason"
                  options={SELECT_OPTIONS.BOOT_REASONS}
                  isRequired
                  onChange={handleChange}
                />
                <TextArea
                  as="textarea"
                  label="Comment"
                  placeholder="Comment"
                  id="comment"
                  name="comment"
                  maxLength={MAX_COMMENT_LENGTH}
                  rows={3}
                  isRequired
                  onChange={handleChange}
                />
              </Flex>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  ) : (
    <IconButton
      icon={<MdDelete />}
      variant="iconHover"
      color="errorsRed"
      size="xxs"
      fontSize={renderAsIconSize}
      aria-label="Boot Deal"
      onClick={() => undefined}
    />
  );
};

export const BootActionsWithContext = ({
  renderAsIcon = false,
  renderAsIconSize = 15,
  allowAllToBoot = false,
}: Omit<BootActionProps, 'deal' | 'dispatch'>) => {
  const { deal, dispatch } = useContext(DealContext);

  return (
    <BootActions
      deal={deal}
      dispatch={dispatch}
      renderAsIcon={renderAsIcon}
      renderAsIconSize={renderAsIconSize}
      allowAllToBoot={allowAllToBoot}
    />
  );
};
