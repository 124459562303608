import { useContext, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';

import { Deal } from '../gql/dealGql';
import { PaymentOptionsQueryVariables, usePaymentOptionsLazyQuery } from '../gql/generated/graphql';

import { initialPaymentOptions } from '../components/DealInfoBuyout/types';
import { BankEnum } from '../constants/bank';
import { DealContext } from '../libs/DealContext';

import { useBanks } from './useBanks';
import useDebounce from './useDebounce';

type UsePaymentOptionsProps = {
  bankName?: BankEnum;
};

export const usePaymentOptions = ({ bankName }: UsePaymentOptionsProps = {}) => {
  const { deal } = useContext(DealContext);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const { banks, loading: banksLoading } = useBanks({ onlyActive: true, deal });

  const [getPaymentOptions, { loading: paymentOptionsLoading, data }] =
    usePaymentOptionsLazyQuery();

  const loading = banksLoading || paymentOptionsLoading;

  const {
    values: {
      financial_info,
      car: { payoff, mileage, year },
    },
  } = useFormikContext<Deal>();

  const dataInput: PaymentOptionsQueryVariables['data'] = {
    carYear: Number.parseInt(year, 10),
    carMileage: mileage,
    prequalificationCreditScore: deal.customer.prequalification?.credit_score,
    vehiclePayoff: payoff.vehicle_payoff,
    moneyDown: financial_info.money_down,
    bankFees: financial_info.bank_fees,
    userEnteredReserve: financial_info.user_entered_reserve,
    baseTaxAmount: financial_info.base_tax_amount,
    warrantyTaxAmount: financial_info.warranty_tax_amount,
    titleRegistrationOption: financial_info.title_registration_option,
    titleFee: financial_info.title_fee,
    newRegistrationFee: financial_info.new_registration_fee,
    registrationTransferFee: financial_info.registration_transfer_fee,
    docFee: financial_info.doc_fee,
    bank: bankName || financial_info.bank,
    daysToPayment: financial_info.days_to_payment,
    vscPrice: financial_info.vsc_price,
    vscCost: financial_info.vsc_cost,
    gapPrice: financial_info.gap_price,
    gapCost: financial_info.gap_cost,
    buyRate: financial_info.buy_rate,
    sellRate: financial_info.sell_rate,
    term: financial_info.term,
    setterId: deal.setter_id,
    closerId: deal.closer_id,
    closer2Id: deal.closer2_id,
    banks,
  };

  const debouncedGetPaymentOptions = useDebounce((value: typeof dataInput) => {
    getPaymentOptions({
      variables: {
        data: value,
      },
    });
    setIsDebouncing(false);
  }, 500);

  const [paymentOptions, setPaymentOptions] = useState({
    ...initialPaymentOptions,
  });

  const listeningToArr = [
    year,
    mileage,
    deal.customer.prequalification?.credit_score,
    payoff.vehicle_payoff,
    financial_info.money_down,
    financial_info.bank_fees,
    financial_info.user_entered_reserve,
    financial_info.warranty_tax_amount,
    financial_info.base_tax_amount,
    financial_info.title_registration_option,
    financial_info.title_fee,
    financial_info.new_registration_fee,
    financial_info.registration_transfer_fee,
    bankName || financial_info.bank,
    financial_info.days_to_payment,
    financial_info.vsc_price,
    financial_info.vsc_cost,
    financial_info.gap_price,
    financial_info.gap_cost,
    financial_info.buy_rate,
    financial_info.sell_rate,
    financial_info.term,
    deal.setter_id,
    deal.closer_id,
    deal.closer2_id,
    banks,
  ];

  useEffect(() => {
    if (
      banks.length >= 1 &&
      (bankName || financial_info.bank) &&
      payoff.vehicle_payoff &&
      financial_info.days_to_payment &&
      financial_info.buy_rate &&
      financial_info.sell_rate &&
      financial_info.term
    ) {
      setIsDebouncing(true);
      debouncedGetPaymentOptions(dataInput);
    }
  }, listeningToArr);

  useEffect(() => {
    if (!paymentOptionsLoading && data?.paymentOptions) {
      setPaymentOptions(data.paymentOptions as typeof initialPaymentOptions);
    }
  }, [data]);

  return [paymentOptions, loading, isDebouncing] as const;
};
