import React from 'react';

import { Box, Button, Checkbox, Flex, Td, Tr } from '@chakra-ui/react';
import { FaCertificate, FaCheck, FaSignature } from 'react-icons/fa';
import { MdWarning } from 'react-icons/md';

import { DealMediaTypeEnum } from '../../../../../gql/generated/graphql';

import CustomReactSelect from '../../../../shared/CustomReactSelect';
import { MediaTableItem } from '../hooks/use-media-table-rows';

import MediaRowActions from './MediaRowActions';

import { DealMediaCustomerTypes, DealMediaTypeLabels } from '../../../../../constants/media';
import { DealMedia } from '../../../../../types/media';
import { isFileDeletable } from '../../../../../utils/media';
import { ShowMediaModalType } from '../../MediaModal';
import UploadPreview from '../../UploadPreview';

interface InternalMediaRowProps {
  item: MediaTableItem;
  openUploadModal: () => void;
  onClickTextColumn: () => void;
  onMediaTypeChange: (value: Partial<MediaTableItem>) => void;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUploadPreviewClick: () => void;
  selectedFiles: DealMedia[];
  updateModalProps: (showModal: ShowMediaModalType) => void;
}

const InternalMediaRow: React.FC<InternalMediaRowProps> = ({
  item,
  openUploadModal,
  onClickTextColumn,
  onSelect,
  onMediaTypeChange,
  onUploadPreviewClick,
  selectedFiles,
  updateModalProps,
}) => {
  const canDelete = isFileDeletable(item);

  return (
    <Tr
      cursor="pointer"
      _hover={{ filter: 'brightness(0.9)' }}
      bgColor={item.verified ? 'lightGreen.50' : 'white'}
    >
      {/* Checkbox */}
      <Td alignItems="center" maxW="36px" paddingX={2} w="2.5%">
        <Checkbox
          cursor="pointer"
          borderColor="gray.600"
          disabled={!item.key || !canDelete}
          isChecked={selectedFiles.some((file) => file.id === item.id && item.type === file.type)}
          mb={0}
          onChange={onSelect}
        />
      </Td>
      {/* Preview */}
      <Td paddingX={2} w="5%">
        <Flex alignItems="center" cursor="pointer" direction="row" w={65}>
          {item.isUploaded ? (
            <UploadPreview
              onClick={onUploadPreviewClick}
              sourceFileName={item.sourceFileName}
              url={item.signed_url ?? ''}
            />
          ) : (
            <Box ml="5px">
              <MdWarning color="#F3C43D" size={44} onClick={openUploadModal} />
            </Box>
          )}
        </Flex>
      </Td>
      {/* Category */}
      <Td
        paddingX={2}
        alignItems="center"
        onClick={item.type ? onClickTextColumn : undefined}
        fontWeight="medium"
        cursor="pointer"
        onFocus={(e) => e.preventDefault()}
        width="15%"
      >
        {item.type ? (
          DealMediaTypeLabels[item.type]
        ) : (
          <CustomReactSelect
            onChange={(selected) =>
              onMediaTypeChange({
                ...item,
                type: selected?.value as DealMediaTypeEnum,
              })
            }
            placeholder="Select Category"
            options={Object.entries(DealMediaTypeLabels)
              .filter(([key]) => DealMediaCustomerTypes.includes(key as DealMediaTypeEnum))
              .sort((a, b) => (a[1] > b[1] ? 1 : -1))
              .map(([value, label]) => ({
                label,
                value,
              }))}
          />
        )}
      </Td>
      {/* File Name */}
      <Td
        cursor="pointer"
        fontWeight="normal"
        maxWidth="200px"
        onClick={onClickTextColumn}
        overflow="hidden"
        paddingX={2}
        textOverflow="ellipsis"
        textTransform="none"
        whiteSpace="nowrap"
        width="30%"
      >
        {item.sourceFileName}
      </Td>
      {/* Signatures */}
      <Td borderRightColor="gray.100" borderRightWidth="2px" width="25%">
        <Box display="flex">
          {item.notary_required && (
            <Button
              variant="signatureBadge"
              size="smWithIconLeft"
              leftIcon={item.is_notarized ? <FaCheck /> : <FaCertificate />}
              color="white"
              bg={item.is_notarized ? 'green' : 'skyBlue'}
              isDisabled={!item.isUploaded}
              onClick={() =>
                onMediaTypeChange({
                  key: item.key,
                  deal_id: item.deal_id,
                  is_notarized: !item.is_notarized,
                })
              }
            >
              NOTARY
            </Button>
          )}

          {item.verified_digital_signature_required && (
            <Button
              variant="signatureBadge"
              size="smWithIconLeft"
              leftIcon={
                item.has_verified_digital_signature ? <FaCheck /> : <FaSignature size={14} />
              }
              color={item.has_verified_digital_signature ? 'green' : 'lavender'}
              isDisabled={!item.isUploaded}
              onClick={() =>
                onMediaTypeChange({
                  key: item.key,
                  deal_id: item.deal_id,
                  has_verified_digital_signature: !item.has_verified_digital_signature,
                })
              }
            >
              DIGITAL
            </Button>
          )}

          {item.wet_signature_required && (
            <Button
              variant="signatureBadge"
              size="smWithIconLeft"
              leftIcon={item.has_wet_signature ? <FaCheck /> : <FaSignature size={14} />}
              color={item.has_wet_signature ? 'green' : 'skyBlue'}
              isDisabled={!item.isUploaded}
              onClick={() =>
                onMediaTypeChange({
                  key: item.key,
                  deal_id: item.deal_id,
                  has_wet_signature: !item.has_wet_signature,
                })
              }
            >
              WET SIG
            </Button>
          )}
        </Box>
      </Td>
      {/* Actions */}
      <MediaRowActions
        isInternalFile
        dealMedia={{ ...item, fileName: item.friendlyName }}
        filePath={decodeURIComponent(item.key ?? '')}
        fileUrl={item.signed_url ?? ''}
        isUploaded={item.isUploaded}
        sourceFileName={item.sourceFileName ?? ''}
        updateModalProps={updateModalProps}
        canDelete={canDelete}
      />
    </Tr>
  );
};

export default InternalMediaRow;
