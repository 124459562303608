import { ChangeEvent } from 'react';

import { Box, HStack } from '@chakra-ui/react';

import { relationshipToBuyerOptions } from '../../gql/customerGql';

import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import Input from '../shared/Input';
import Select from '../shared/Select';

import { phoneNumberMask } from '../../constants/masks';

type AdditionalContactProps = {
  customHandleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;

  isSecond?: boolean;
};

const AdditionalContact = ({ customHandleChange, isSecond }: AdditionalContactProps) => {
  const name = isSecond ? 'second_contact' : 'contact';

  return (
    <Card variant="rounded">
      <CardHeaderV2 title={`${isSecond ? 'Second ' : ''}Contact`} variant="square" />
      <Box mx={6}>
        <HStack pt={3} justifyContent="space-between">
          <Select
            label="Relation To Buyer"
            name={`${name}.relation_to_buyer`}
            onChange={customHandleChange}
            emptyOption={false}
            options={relationshipToBuyerOptions}
            formControlProps={{
              width: '25%',
            }}
          />
        </HStack>
        <HStack mt={3} alignItems="start">
          <Input label="First Name" name={`${name}.first_name`} onChange={customHandleChange} />
          <Input label="Middle Name" name={`${name}.middle_name`} onChange={customHandleChange} />
          <Input label="Last Name" name={`${name}.last_name`} onChange={customHandleChange} />
        </HStack>
        <HStack mt={3} w="66.66%" alignItems="start">
          <Input
            label="Phone Number"
            name={`${name}.phone_number`}
            onChange={customHandleChange}
            mask={phoneNumberMask}
            copyButton
          />
          <Input label="Email" name={`${name}.email`} onChange={customHandleChange} copyButton />
        </HStack>
      </Box>
    </Card>
  );
};

export default AdditionalContact;
