import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react';
import { formatISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DealStateEnum, dealUpdateStateAndSendEmails } from '../../../../gql/dealGql';
import { Deal, DealType, DocumentProgressStatus } from '../../../../gql/generated/graphql';

import ConfirmManualValidationModal from '../modals/ConfirmManualValidationModal';
import ContractNotFoundModal from '../modals/ContractNotFoundModal';
import SelectSigningModal from '../modals/SigningSelectionModal';

import { PermissionEnum } from '../../../../constants/permissions';
import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';
import { AbilityContext } from '../../../../libs/contextLib';
import {
  CONTRACT_NOT_FOUND_ERROR,
  isDocumentProgressUploaded,
  isDocumentProgressValidated,
} from '../../../../utils/routeOne';

interface Props {
  width?: string;
  size?: ButtonProps['size'];
}

const SendContractsButton = ({ width, size = 'sm' }: Props) => {
  const { deal, isRecalculatingPayoff, dispatch } = useContext(DealContext);
  const abilities = useContext(AbilityContext);
  const canSeeSigningOnCom = abilities.has(PermissionEnum.SeeSigningOnCom);

  const { id } = useParams<{ id: string }>();

  const {
    isOpen: isOpenManualValidation,
    onClose: onCloseManualValidation,
    onOpen: onOpenManualValidation,
  } = useDisclosure();

  const {
    isOpen: isOpenSelectSigning,
    onClose: onCloseSelectSigning,
    onOpen: onOpenSelectSigning,
  } = useDisclosure();

  const {
    isOpen: isOpenContractNotFound,
    onClose: onCloseContractNotFound,
    onOpen: onOpenContractNotFound,
  } = useDisclosure();

  const r1ContractIsValidated = isDocumentProgressValidated(deal.document_progress_status);
  const r1ContractIsUploaded = isDocumentProgressUploaded(deal.document_progress_status);

  const isDisabled =
    (!r1ContractIsUploaded ||
      deal.document_progress_status !== DocumentProgressStatus.ReadyForSignatures) &&
    !abilities.has(PermissionEnum.SuperUser) &&
    !abilities.has(PermissionEnum.FundingManager);

  const [updateDealStateAndFirstPaymentDate, { loading }] = useMutation<{
    dealUpdateStateAndSendEmails?: Deal;
  }>(dealUpdateStateAndSendEmails, {
    onCompleted: (data) => {
      if (!data.dealUpdateStateAndSendEmails) {
        return;
      }

      const { state, signing_on_com, tags } = data.dealUpdateStateAndSendEmails;
      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          state: state as DealStateEnum,
          signing_on_com: !!signing_on_com,
          tags: tags ?? [],
        },
      });

      toast.success('Moved deal to "Sent for Signatures"!');
    },
    onError: (e) => {
      const error = e as Error;
      if (error.message === CONTRACT_NOT_FOUND_ERROR) {
        onOpenContractNotFound();
        return;
      }

      logger.error('MarkAsSentButton.tsx', 'updateDealStateAndFirstPaymentDate', null, e);
      toast.error(`Error updating deal: ${e.message ?? e}`);
    },
  });

  const handleMarkAsSent = async (signingOnCom: boolean) => {
    await updateDealStateAndFirstPaymentDate({
      variables: {
        id,
        state: DealStateEnum.SentForSignatures,
        date: formatISO(new Date()),
        isR1ContractValidated: r1ContractIsValidated,
        isR1ContractUploaded: r1ContractIsUploaded,
        dealIsSigningOnCom: signingOnCom,
      },
    });
  };

  const handleConfirmManualValidation = (): void => {
    if (canSeeSigningOnCom) {
      onOpenSelectSigning();
    } else {
      handleMarkAsSent(false);
    }
  };

  return (
    <>
      <Button
        name="mark-sent"
        variant="secondary"
        size={deal.type !== DealType.Acquisition ? size : 'md'}
        width={width}
        isDisabled={isDisabled || isRecalculatingPayoff}
        isLoading={loading}
        loadingText="SEND"
        onClick={() => {
          if (!r1ContractIsValidated || !r1ContractIsUploaded) {
            onOpenManualValidation();
          } else if (canSeeSigningOnCom) {
            onOpenSelectSigning();
          } else {
            handleMarkAsSent(false);
          }
        }}
      >
        SEND
      </Button>
      <ConfirmManualValidationModal
        contractValidated={r1ContractIsValidated}
        isOpen={isOpenManualValidation}
        onClose={onCloseManualValidation}
        handleConfirm={handleConfirmManualValidation}
      />
      <SelectSigningModal
        handleMarkAsSent={handleMarkAsSent}
        isOpen={isOpenSelectSigning}
        onClose={onCloseSelectSigning}
      />
      <ContractNotFoundModal
        isOpen={isOpenContractNotFound}
        onClose={onCloseContractNotFound}
        handleOk={() => {
          onCloseContractNotFound();
          onCloseSelectSigning();
          onCloseManualValidation();
        }}
      />
    </>
  );
};

export default SendContractsButton;
