import { useCallback, useContext, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';

import { Maybe, useCreditApplicationSubmissionsQuery } from '../../gql/generated/graphql';

import { BankEnum } from '../../constants/bank';
import { useBanks } from '../../hooks/useBanks';
import { DealContext } from '../../libs/DealContext';
import {
  isCreditDecisionDenied,
  isCreditDecisionPending,
  isCreditDecisionSelectable,
} from '../../utils/creditDecisions';

type UseCreditDecisionsProps = {
  isManuallySelectBankChecked: boolean;
};

const useCreditDecisions = ({ isManuallySelectBankChecked }: UseCreditDecisionsProps) => {
  const { deal } = useContext(DealContext);
  const { banks } = useBanks({ deal, onlyActive: true });
  const { data, loading, startPolling, stopPolling } = useCreditApplicationSubmissionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        dealIds: [deal?.id?.toString()],
      },
    },
    onError: () => {
      toast.error('Error fetching credit decisions, please try again later');
    },
  });

  useEffect(() => {
    if (isManuallySelectBankChecked) {
      stopPolling();
    } else {
      startPolling(5000);
    }
  }, [isManuallySelectBankChecked]);

  const creditDecisions = useMemo(() => {
    const allCreditApplications =
      data?.creditApplicationSubmissions.flatMap((submission) =>
        submission?.credit_applications?.map((app) => ({
          submittedAt: submission.created_at,
          ...app,
        })),
      ) || [];

    // Give me the newest unique credit decisions by r1_fsid for each application
    const creditApplicationsToCreditDecisions = allCreditApplications.reduce(
      (acc, app) => {
        if (!app) {
          return acc;
        }

        const credit_decisions = app.credit_decisions || [];
        if (!credit_decisions.length) {
          return acc;
        }

        const appId = app.id;
        if (!appId) {
          return acc;
        }

        const r1FsidToCreditDecision = credit_decisions.reduce((decisionsAcc, decision) => {
          if (!decision) {
            return decisionsAcc;
          }

          const decisionWithSubmittedAt = {
            ...decision,
            submittedAt: app.submittedAt,
          };

          const { r1_fsid, created_at } = decisionWithSubmittedAt;
          if (!r1_fsid || !created_at) {
            return decisionsAcc;
          }

          if (
            !decisionsAcc[r1_fsid] ||
            (decisionsAcc[r1_fsid]?.created_at && decisionsAcc[r1_fsid].created_at < created_at)
          ) {
            // eslint-disable-next-line no-param-reassign
            decisionsAcc[r1_fsid] = decisionWithSubmittedAt;
          }

          return decisionsAcc;
        }, {} as Record<string, typeof credit_decisions[number] & { submittedAt: string }>);

        if (r1FsidToCreditDecision) {
          acc[appId] = r1FsidToCreditDecision;
        }
        return acc;
      },
      {} as {
        [appId: string]: {
          [r1Fsid: string]: NonNullable<
            NonNullable<typeof allCreditApplications[number]>['credit_decisions']
          >[number] & {
            submittedAt: string;
          };
        };
      },
    );

    return (
      Object.values(creditApplicationsToCreditDecisions)
        .flatMap((r1FsidToCreditDecision) => Object.values(r1FsidToCreditDecision))
        // sort by credit decision status. A -> S -> D
        .sort((a, b) => {
          const equal = 0;
          if (!a || !b) {
            return equal;
          }
          const aApproved = isCreditDecisionSelectable(a);
          const bApproved = isCreditDecisionSelectable(b);
          const aPending = isCreditDecisionPending(a.application_status);
          const bPending = isCreditDecisionPending(b.application_status);
          const aDenied = isCreditDecisionDenied(a.application_status);
          const bDenied = isCreditDecisionDenied(b.application_status);

          // Put all the approvals first before any pendings
          if (aApproved && !bApproved) {
            return -1;
          }
          if (!aApproved && bApproved) {
            return 1;
          }
          if (aPending && !bPending) {
            return -1;
          }
          if (!aPending && bPending) {
            return 1;
          }
          if (aDenied && !bDenied) {
            return -1;
          }
          if (!aDenied && bDenied) {
            return 1;
          }

          // If any pair is equal to each other
          return equal;
        })
    );
  }, [data?.creditApplicationSubmissions]);

  useEffect(() => {
    if (!creditDecisions?.length) {
      return;
    }

    const shouldKeepPolling = creditDecisions?.some((decision) =>
      isCreditDecisionPending(decision?.application_status),
    );

    if (shouldKeepPolling) {
      startPolling(5000);
      return;
    }

    stopPolling();
  }, [creditDecisions]);

  const getBankNamesByR1Fsid = useCallback(
    (r1Fsid?: Maybe<string>) => {
      const bank = banks?.find((b) => b.r1_fsid === r1Fsid);
      return {
        displayName: bank?.display_name,
        name: (bank?.name ?? undefined) as BankEnum | undefined,
      };
    },
    [banks],
  );

  return {
    creditDecisions,
    creditDecisionsLoading: loading,
    getBankNamesByR1Fsid,
  } as const;
};

export type CreditDecisionWithSubmittedAt = ReturnType<
  typeof useCreditDecisions
>['creditDecisions'][number];

export default useCreditDecisions;
