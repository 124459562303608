import { useLazyQuery } from '@apollo/client';

import { Deal } from '../gql/dealGql';
import { TemporaryData } from '../gql/generated/graphql';
import { lienholderQuery } from '../gql/prs/lienholderGql';
import { LienholderPRSType } from '../gql/prs/types';

import { getLienholderQueryVariables } from '../utils/prs';

export const useLazyLienholder = () => {
  const [getLienholderInfo] = useLazyQuery<{ lienholder: LienholderPRSType }>(lienholderQuery, {
    // `cache-first` doesn't work here. Couldn't solve the issue.
    fetchPolicy: 'cache-and-network',
  });

  const getLienholder = async (data: Deal | TemporaryData) => {
    const { state, slug } = getLienholderQueryVariables(data);

    if (!state || !slug) {
      return {};
    }

    const response = await getLienholderInfo({
      variables: {
        state,
        slug,
      },
    });

    if (!response.data?.lienholder) {
      return {};
    }

    const { lienholder } = response.data;
    return {
      doubleTax: lienholder.double_tax,
      includesSalesTax: lienholder.requirements_to_payoff_lease?.include_sales_tax?.value,
    };
  };

  return { getLienholder };
};
