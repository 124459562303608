import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { File, sendContractsForSigning } from '../../gql/contractGql';
import { getFullName } from '../../gql/customerGql';
import { DealStateEnum, DealTypeLabelMap } from '../../gql/dealGql';
import { useDealUpdateStateMutation, useMediaQuery } from '../../gql/generated/graphql';

import PDFViewer from '../PDFViewer.tsx';
import Modal from '../shared/Modal';

import DocumentsModal from './DocumentsModal';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';

interface ModalProps {
  files: File[];
  show: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  r1EContractGenerated?: boolean;
}

const PreviewModals = ({ files, show, setShowModal, r1EContractGenerated }: ModalProps) => {
  const { deal } = useContext(DealContext);
  const [showConfirmationModal, setConfirmationShowModal] = useState(false);
  const [agreementId, setAgreementId] = useState<string>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  const [sendContracts, { loading: sendContractsLoading }] = useMutation(sendContractsForSigning);
  const [updateDealState] = useDealUpdateStateMutation();

  const mediaGql = useMediaQuery({
    skip: !deal.id,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dealId: deal.id!,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (deal.document_info?.adobe_agreement_id) {
      setAgreementId(deal.document_info.adobe_agreement_id);
    }
  }, [deal.document_info?.adobe_agreement_id]);

  useEffect(() => {
    if (!show) {
      mediaGql.refetch();
    }
  }, [show]);

  const onHide = () => {
    setConfirmationShowModal(false);
    setShowModal(false);
  };

  const handleSend = () => {
    if (!deal.id) {
      return;
    }

    sendContracts({
      variables: {
        deal_id: deal.id,
        keys: files.map((file) => file.key),
      },
    })
      .then(() => {
        updateDealState({
          variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: deal.id!,
            state: DealStateEnum.SentForSignatures,
          },
        });
      })
      .then(() => {
        toast.success('Success');
        setShowModal(false);
        setConfirmationShowModal(false);
      })
      .catch((e: unknown) => logger.error('PreviewModals.tsx', '', null, e));
  };

  const openConfirmation = () => {
    setShowModal(false);
    setConfirmationShowModal(true);
  };

  const handleOpenFile = (file: File) => {
    setFileUrl(file.url);
    setFileName(file.filename);
    setShowPreview(true);
  };

  const handleSendContracts = agreementId ? openConfirmation : handleSend;

  return (
    <>
      <DocumentsModal
        isOpen={show}
        files={files}
        sendContractsLoading={sendContractsLoading}
        onClose={onHide}
        onOpenFile={handleOpenFile}
        onSendContracts={handleSendContracts}
        r1EContractGenerated={r1EContractGenerated}
      />
      <Modal
        isOpen={showPreview}
        onClose={() => setShowPreview(false)}
        closeOnEsc={false}
        title={`Previewing: ${fileName}`}
        size="full"
      >
        <PDFViewer fileUrl={fileUrl} />
      </Modal>
      <Modal
        isOpen={showConfirmationModal}
        onClose={onHide}
        closeOnEsc={false}
        leftButtons={
          <Button variant="warning" onClick={() => onHide()}>
            CANCEL
          </Button>
        }
        rightButtons={
          <Button onClick={handleSend} isLoading={sendContractsLoading} loadingText="SEND AGAIN">
            SEND AGAIN
          </Button>
        }
      >
        {deal.type ? DealTypeLabelMap[deal.type] : 'Deal'} forms have already been sent to{' '}
        {getFullName(deal.customer)}.
      </Modal>
    </>
  );
};

export default PreviewModals;
